import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { transparentize } from "color2k";
import { useMutation } from "@apollo/client";
import { Exchange } from "__generated__/graphql";
import { CREATE_CREDENTIAL } from "graphql/mutations";
import { GET_CREDENTIALS_WITH_FUNDS } from "graphql/queries";
import {
  GETTING_STARTED_CONTEXT_QUERY,
  useGettingStartedContext,
} from "contexts/GettingStartedContext";
import ConnectCredentialHeader from "./ConnectCredentialHeader";
import ConnectCredentialBackButton from "./ConnectCredentialBackButton";
import ExchangeGuideLinks, { getExchangeLinks } from "./ExchangeGuideLinks";
import ExchangeGuideImageTooltip from "core/exchange/ExchangeGuideImageTooltip";
import Button from "core/button/Button";
import TextField from "core/forms/fields/TextField";
import ColorAnchor from "core/element/ColorAnchor";
import binanceKeyImg from "images/exchangeguides/binance-key.png";
import binanceSecretImg from "images/exchangeguides/binance-secret.png";
import bitmexKeyImg from "images/exchangeguides/bitmex-key.png";
import bitmexSecretImg from "images/exchangeguides/bitmex-secret.png";
import bybitKeyImg from "images/exchangeguides/bybit-key.png";
import bybitSecretImg from "images/exchangeguides/bybit-secret.png";
import coinbaseKeyImg from "images/exchangeguides/coinbase-key.png";
import coinbaseSecretImg from "images/exchangeguides/coinbase-secret.png";
import huobiKeyImg from "images/exchangeguides/huobi-key.png";
import huobiSecretImg from "images/exchangeguides/huobi-secret.png";
import krakenKeyImg from "images/exchangeguides/kraken-key.png";
import krakenSecretImg from "images/exchangeguides/kraken-secret.png";
import cryptoComKeyImg from "images/exchangeguides/crypto-com-key.png";
import cryptoComSecretImg from "images/exchangeguides/crypto-com-secret.png";
import coinbasePassphraseImg from "images/exchangeguides/coinbase-passphrase.png";
import { logEvent } from "helpers/analytics";
import { CircularProgress } from "@material-ui/core";
import colors from "styles/colors";

interface ExchangeConfig {
  apiKeyImg: string;
  apiSecretImg: string;
  hasSubaccount?: boolean;
  hasPassphrase?: boolean;
  hasPrivateKey?: boolean;
}

function getExchangeConfig(exchange: Exchange): ExchangeConfig {
  switch (exchange) {
    case Exchange.Binance:
    case Exchange.BinanceCoinFutures:
    case Exchange.BinanceFutures:
      return {
        apiKeyImg: binanceKeyImg,
        apiSecretImg: binanceSecretImg,
      };
    case Exchange.Bitmex:
      return {
        apiKeyImg: bitmexKeyImg,
        apiSecretImg: bitmexSecretImg,
      };
    case Exchange.Bybit:
    case Exchange.BybitLinear:
      return {
        apiKeyImg: bybitKeyImg,
        apiSecretImg: bybitSecretImg,
        hasPrivateKey: true,
      };
    case Exchange.Coinbase:
      return {
        apiKeyImg: coinbaseKeyImg,
        apiSecretImg: coinbaseSecretImg,
        hasPassphrase: true,
      };
    case Exchange.HuobiCoinSwaps:
      return {
        apiKeyImg: huobiKeyImg,
        apiSecretImg: huobiSecretImg,
      };
    case Exchange.Kraken:
      return {
        apiKeyImg: krakenKeyImg,
        apiSecretImg: krakenSecretImg,
        hasPrivateKey: true,
      };
    case Exchange.CryptoDotCom:
      return {
        apiKeyImg: cryptoComKeyImg,
        apiSecretImg: cryptoComSecretImg,
      };
    case Exchange.Forex:
      return {
        apiKeyImg: cryptoComKeyImg,
        apiSecretImg: cryptoComSecretImg,
      };
    case Exchange.Nash:
      throw new Error("Nash is no longer used");
  }
}

type Props = Readonly<{
  exchange: Exchange;
  onBackClick: () => void;
  onSuccess: () => void;
}>;

function ConnectExchangeKeyStep({ exchange, onBackClick, onSuccess }: Props) {
  const { t } = useTranslation();

  const { gettingStarted } = useGettingStartedContext();

  const [error, setError] = useState<string>();

  const [createCredential, { loading: loadingCreateCredential }] = useMutation(
    CREATE_CREDENTIAL,
    {
      refetchQueries: !gettingStarted?.addedCredential
        ? [GET_CREDENTIALS_WITH_FUNDS, GETTING_STARTED_CONTEXT_QUERY]
        : [GET_CREDENTIALS_WITH_FUNDS],
      onCompleted: onSuccess,
      onError: (error) => {
        setError(error.message);
        const errorCode = error.graphQLErrors.find(
          (err) => err.extensions?.error_code,
        )?.extensions?.error_code;
        switch (errorCode) {
          case "INVALID_API_KEY":
            setError(t("error.invalid_exchange_api_key"));
            break;
          case "NAME_IN_USE":
            setError(t("error.name_or_api_key_already_exists"));
            break;
          default:
            setError(t("error.having_trouble_connecting_to_exchange"));
            break;
        }
      },
    },
  );

  const [name, setName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [subAccountName, setSubAccountName] = useState("");

  const canSubmit =
    name.length !== 0 &&
    apiKey.length !== 0 &&
    apiSecret.length !== 0 &&
    (exchange !== Exchange.Coinbase || passphrase.length !== 0);

  const {
    apiKeyImg,
    apiSecretImg,
    hasSubaccount,
    hasPassphrase,
    hasPrivateKey,
  } = getExchangeConfig(exchange);

  const onConnectClick = async () => {
    if (loadingCreateCredential || !canSubmit) {
      return;
    }

    await createCredential({
      variables: {
        in: {
          name,
          exchange,
          apiKey,
          apiSecret,
          passphrase: hasPassphrase ? passphrase : undefined,
          subAccountName:
            hasSubaccount && subAccountName.length ? subAccountName : undefined,
        },
      },
    });
  };

  return (
    <>
      <ConnectCredentialHeader
        title={t("message.connect_your_api_key")}
        subtitle={t("message.enter_api_details_below")}
      />
      <div className={css(styles.body)}>
        <form
          className={css(styles.form)}
          onSubmit={(e) => {
            e.preventDefault();
            onConnectClick();
          }}
        >
          <TextField
            required
            showErrorIcon
            name="name"
            readableName={t("common.connection_name")}
            value={name}
            onChange={setName}
            label={t("message.enter_your_connection_name")}
            errorMessage={
              !name?.length
                ? t("error.enter_connection_name_to_connect")
                : undefined
            }
          />
          <TextField
            required
            showErrorIcon
            name="apiKey"
            readableName={t("common.api_key")}
            value={apiKey}
            onChange={setApiKey}
            label={t("message.enter_your_api_key")}
            errorMessage={
              !apiKey?.length ? t("error.enter_api_key_to_connect") : undefined
            }
            endAdornment={<ExchangeGuideImageTooltip img={apiKeyImg} />}
          />
          <TextField
            required
            showErrorIcon
            name="apiSecret"
            readableName={
              hasPrivateKey ? t("common.private_key") : t("common.api_secret")
            }
            value={apiSecret}
            onChange={setApiSecret}
            label={
              hasPrivateKey
                ? t("message.enter_your_private_key")
                : t("message.enter_your_secret_key")
            }
            errorMessage={
              !apiSecret?.length
                ? hasPrivateKey
                  ? t("error.enter_private_key_to_connect")
                  : t("error.enter_secret_key_to_connect")
                : undefined
            }
            endAdornment={<ExchangeGuideImageTooltip img={apiSecretImg} />}
          />
          {hasPassphrase && (
            <TextField
              required
              showErrorIcon
              name="passphrase"
              readableName={t("common.passphrase")}
              value={passphrase}
              onChange={setPassphrase}
              label={t("message.enter_your_passphrase")}
              errorMessage={
                !passphrase?.length
                  ? t("error.enter_passphrase_to_connect")
                  : undefined
              }
              endAdornment={
                <ExchangeGuideImageTooltip img={coinbasePassphraseImg} />
              }
            />
          )}
          {hasSubaccount && (
            <TextField
              name="subAccountName"
              readableName={t("common.sub_account_name")}
              value={subAccountName}
              onChange={setSubAccountName}
              label={t("message.enter_your_sub_account_name")}
            />
          )}
          <button className={css(styles.formHiddenButton)} type="submit" />
          {error && (
            <div className={css(styles.errorContainer)}>
              <div className={css(styles.errorTitle)}>
                {t("error.hmm_something_went_wrong")}
              </div>
              <div className={css(styles.errorMessage)}>{error}</div>
              <div className={css(styles.errorHelp)}>
                <Trans
                  i18nKey="message.read_full_guide_or_chat_with_support"
                  components={[
                    <ColorAnchor
                      key="read_full_guide_or_chat_with_support"
                      color="redErrorUnderline"
                      href={getExchangeLinks(exchange).guide}
                      onClick={() =>
                        logEvent("ConnectExchangeModalOpenGuideLink", {
                          exchange,
                          guide: getExchangeLinks(exchange).guide,
                        })
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      -
                    </ColorAnchor>,
                  ]}
                />
              </div>
            </div>
          )}
        </form>
        <ExchangeGuideLinks exchange={exchange} />
      </div>
      <div className={css(styles.footer)}>
        <div className={css(styles.footerBackButton)}>
          <ConnectCredentialBackButton onClick={onBackClick} />
        </div>
        <div className={css(styles.footerButton)}>
          <Button onClick={onConnectClick} disabled={!canSubmit}>
            {loadingCreateCredential ? (
              <CircularProgress
                size={14}
                className={css(styles.footerButtonLoading)}
              />
            ) : (
              t("message.connect_api_key")
            )}
          </Button>
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    marginBottom: "100px",
    "@media (max-width: 1019.98px)": {
      flexDirection: "column",
      marginBottom: "30px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flex: "5 1",
    "@media (max-width: 1019.98px)": {
      marginBottom: "30px",
    },
  },
  formHiddenButton: {
    width: "0px",
    height: "0px",
    border: "0",
    padding: "0",
  },
  buttonContainer: {
    width: "295px",
    marginTop: "10px",
    "@media (max-width: 767.98px)": {
      maxWidth: "100%",
      width: "unset",
    },
  },
  errorContainer: {
    border: `1px solid ${colors.redError}`,
    borderRadius: "5px",
    background: transparentize(colors.redError, 0.9),
    padding: "25px",
    marginTop: "10px",
    color: colors.redError,
    textAlign: "center",
  },
  errorTitle: {
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "5px",
  },
  errorMessage: {
    fontWeight: 400,
    fontSize: "14px",
    marginBottom: "25px",
  },
  errorHelp: {
    fontWeight: 400,
    fontSize: "14px",
  },
  footer: {
    position: "relative",
    display: "flex",
    background: colors.black,
    marginTop: "auto",
    paddingBottom: "30px",
    zIndex: 2,
    "@media (max-width: 767.98px)": {
      flexDirection: "column-reverse",
    },
  },
  footerBackButton: {
    "@media (min-width: 768px)": {
      position: "absolute",
      left: "0",
      top: "11px",
    },
    "@media (max-width: 767.98px)": {
      marginTop: "15px",
    },
  },
  footerButton: {
    margin: "auto",
    width: "385px",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
  footerButtonLoading: {
    verticalAlign: "middle",
  },
});

export default ConnectExchangeKeyStep;
