export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: any;
  Instant: string;
  Money: string;
  MVTParam: string;
  Resolution: string;
  Url: string;
};

export type AddCompletedTourInput = {
  type: UserTourType;
};

export type AddCompletedTourPayload = {
  __typename?: "AddCompletedTourPayload";
  user?: Maybe<User>;
};

export type AdminQueueStats = {
  __typename?: "AdminQueueStats";
  consumersCount?: Maybe<Scalars["Int"]>;
  inProgressTaskCount?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  taskCount?: Maybe<Scalars["Int"]>;
  tasksCompletedPerSecond?: Maybe<Scalars["Float"]>;
};

export type AdminStats = {
  __typename?: "AdminStats";
  queues?: Maybe<Array<Maybe<AdminQueueStats>>>;
};

export type AdminStopExecutionsInput = {
  ids: Array<Scalars["ID"]>;
  stopStatus: ExecutionStatus;
};

export type AdminStopExecutionsPayload = {
  __typename?: "AdminStopExecutionsPayload";
  executions: Array<Execution>;
};

export type AdminUpdateBotIsBasketInput = {
  id: Scalars["ID"];
  isBasket: Scalars["Boolean"];
};

export type AdminUpdateBotIsBasketPayload = {
  __typename?: "AdminUpdateBotIsBasketPayload";
  bot: PublicSyndication;
};

export type AdminUpdateBotVisibilityInput = {
  id: Scalars["ID"];
  visibility: BotVisibility;
};

export type AdminUpdateBotVisibilityPayload = {
  __typename?: "AdminUpdateBotVisibilityPayload";
  bot?: Maybe<PublicSyndication>;
};

/** Query for admin allInvoices query */
export type AllInvoicesQuery = {
  email?: InputMaybe<Scalars["String"]>;
  periodEnd?: InputMaybe<Scalars["DateTime"]>;
  periodStart?: InputMaybe<Scalars["DateTime"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type AllowListInfo = {
  __typename?: "AllowListInfo";
  canRemove: Scalars["Boolean"];
  nickname: Scalars["String"];
};

export type AllSubscriptionsQuery = {
  email?: InputMaybe<Scalars["String"]>;
  excludeStatus?: InputMaybe<Array<SubscriptionStatus>>;
  includeStatus?: InputMaybe<Array<SubscriptionStatus>>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type AmountInput = {
  fixed?: InputMaybe<FixedAmountInput>;
  rate?: InputMaybe<RateAmountInput>;
};

export type Analysis = {
  __typename?: "Analysis";
  avgEquityPeakDays?: Maybe<Scalars["Decimal"]>;
  bestMonth?: Maybe<Scalars["Decimal"]>;
  dailyProfits?: Maybe<DailyChart>;
  monthlyProfits?: Maybe<MonthlyChart>;
  realizedProfitPerc?: Maybe<Scalars["Decimal"]>;
  worstMonth?: Maybe<Scalars["Decimal"]>;
};

export type Announcement = {
  __typename?: "Announcement";
  createdAt: Scalars["DateTime"];
  endTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  isActive: Scalars["Boolean"];
  message: Scalars["String"];
  type: AnnouncementType;
};

export type AnnouncementCreation = {
  durationMinutes?: InputMaybe<Scalars["Int"]>;
  message: Scalars["String"];
  type: AnnouncementType;
};

export enum AnnouncementType {
  Maintenance = "MAINTENANCE",
  None = "NONE",
  Outage = "OUTAGE",
}

export type ApplicationInfo = {
  __typename?: "ApplicationInfo";
  version: Scalars["String"];
};

export type Asset = {
  __typename?: "Asset";
  amount: Scalars["Decimal"];
  btcValue?: Maybe<Scalars["Decimal"]>;
  currency: Scalars["String"];
  usdValue?: Maybe<Scalars["Decimal"]>;
};

export type AssetDetails = {
  __typename?: "AssetDetails";
  allocated: Array<Maybe<Asset>>;
  free: Array<Maybe<Asset>>;
  total: Array<Maybe<Asset>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum AssetType {
  Cryptocurrency = "CRYPTOCURRENCY",
}

export type AssetWatchlistItem = WatchlistItem & {
  __typename?: "AssetWatchlistItem";
  assetId: Scalars["ID"];
  assetName: Scalars["String"];
  assetSymbol: Scalars["String"];
  assetType: AssetType;
  rank: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export enum AssociationType {
  Product = "PRODUCT",
  Subscription = "SUBSCRIPTION",
}

export type BacktestSettings = {
  endDate?: InputMaybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
};

export type BarColorInfo = {
  __typename?: "BarColorInfo";
  color: ColorInput;
  editable?: Maybe<Scalars["Boolean"]>;
  offset?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
};

export type BgColorInfo = {
  __typename?: "BGColorInfo";
  color: ColorInput;
  editable?: Maybe<Scalars["Boolean"]>;
  offset?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  transparency: Scalars["Int"];
};

export type BillingAddress = {
  addressLine1: Scalars["String"];
  addressLine2: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
  state: Scalars["String"];
  zip: Scalars["String"];
};

export type BillingPaymentProviderAllowList = {
  __typename?: "BillingPaymentProviderAllowList";
  createdAt?: Maybe<Scalars["DateTime"]>;
  paymentProvider?: Maybe<PaymentProvider>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["Int"]>;
};

export type BillingPaymentProviderAllowListConnection = {
  __typename?: "BillingPaymentProviderAllowListConnection";
  edges?: Maybe<Array<Maybe<BillingPaymentProviderAllowListConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BillingPaymentProviderAllowListConnectionEdge = {
  __typename?: "BillingPaymentProviderAllowListConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<BillingPaymentProviderAllowList>;
};

export type BillingSubscription = {
  __typename?: "BillingSubscription";
  endTime?: Maybe<Scalars["Instant"]>;
  entitlements?: Maybe<Array<Entitlement>>;
  id: Scalars["ID"];
  nextBillingTime?: Maybe<Scalars["Instant"]>;
  paymentProvider?: Maybe<Scalars["String"]>;
  /** only admins can view */
  product: Product;
  startTime?: Maybe<Scalars["Instant"]>;
  status?: Maybe<SubscriptionStatus>;
  statusDetails?: Maybe<BillingSubscriptionStatusDetails>;
  /**
   * TODO Remove
   * Deprecated
   */
  statusReason?: Maybe<Scalars["String"]>;
  user: User;
  userStatus: SubscriptionUserStatus;
};

export type BillingSubscriptionConnection = {
  __typename?: "BillingSubscriptionConnection";
  edges?: Maybe<Array<Maybe<BillingSubscriptionConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BillingSubscriptionConnectionEdge = {
  __typename?: "BillingSubscriptionConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<BillingSubscription>;
};

export type BillingSubscriptionFilter = {
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  includeUserStatus?: InputMaybe<Array<SubscriptionUserStatus>>;
};

export type BillingSubscriptionStatusDetails = {
  __typename?: "BillingSubscriptionStatusDetails";
  creationFailure?: Maybe<SubscriptionCreationFailure>;
  hasCreationFailed: Scalars["Boolean"];
  hasLatestRenewalFailed: Scalars["Boolean"];
  hasPaymentPending: Scalars["Boolean"];
  latestRenewalFailure?: Maybe<SubscriptionRenewalFailure>;
  mayRevokeUnsubscription: Scalars["Boolean"];
  mayUnsubscribe: Scalars["Boolean"];
  mayUseEntitlements: Scalars["Boolean"];
  renewalStatus: SubscriptionRenewalProcessStatus;
  userHasUnsubscribed: Scalars["Boolean"];
  willBeCancelledAtEndOfCycle: Scalars["Boolean"];
};

export enum BotVisibility {
  Hidden = "HIDDEN",
  Visible = "VISIBLE",
  VisibleByAdmin = "VISIBLE_BY_ADMIN",
}

export type Candle = {
  __typename?: "Candle";
  close: Scalars["Decimal"];
  endTime: Scalars["Instant"];
  high: Scalars["Decimal"];
  low: Scalars["Decimal"];
  open: Scalars["Decimal"];
  startTime: Scalars["Instant"];
  volume: Scalars["Decimal"];
};

export enum CandleType {
  Standard = "STANDARD",
}

export type ChangeProductBaseCharge = {
  billingPeriod: SyndicationBillingPeriod;
  productId: Scalars["ID"];
  recurringFeeUsd: Scalars["Decimal"];
};

export type CheckoutPaymentMetadata = PaymentMetadata & {
  __typename?: "CheckoutPaymentMetadata";
  redirectLink3DS2: Scalars["String"];
  type: Scalars["String"];
};

export type ColorChange = {
  __typename?: "ColorChange";
  plotId: Scalars["String"];
  time: Scalars["Instant"];
  value: Scalars["String"];
};

export type ColorInput = {
  __typename?: "ColorInput";
  type: InputType;
  value?: Maybe<Scalars["String"]>;
};

export type ColumnsPreset = {
  __typename?: "ColumnsPreset";
  columns: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type CreateShortLinkInput = {
  description?: InputMaybe<Scalars["String"]>;
  imageUrl?: InputMaybe<Scalars["Url"]>;
  link: Scalars["Url"];
  title?: InputMaybe<Scalars["String"]>;
};

export type CreateShortLinkPayload = {
  __typename?: "CreateShortLinkPayload";
  url?: Maybe<Scalars["Url"]>;
};

export type CreatorFilters = {
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  userFilters?: InputMaybe<Array<InputMaybe<UserFilter>>>;
};

export type CreatorWatchlistItem = WatchlistItem & {
  __typename?: "CreatorWatchlistItem";
  creatorId: Scalars["ID"];
  publicUser?: Maybe<PublicUser>;
  rank: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type Credential = {
  __typename?: "Credential";
  assets: AssetDetails;
  exchange: Exchange;
  id: Scalars["ID"];
  maskedApiKey: Scalars["String"];
  name: Scalars["String"];
  status: CredentialStatus;
};

export type CredentialAssetsArgs = {
  syncWithExchange?: InputMaybe<Scalars["Boolean"]>;
};

export type CredentialCreation = {
  apiKey: Scalars["String"];
  apiSecret?: InputMaybe<Scalars["String"]>;
  exchange: Exchange;
  name: Scalars["String"];
  passphrase?: InputMaybe<Scalars["String"]>;
  subAccountName?: InputMaybe<Scalars["String"]>;
};

export enum CredentialStatus {
  Active = "ACTIVE",
  Disconnected = "DISCONNECTED",
  Failing = "FAILING",
  Revoked = "REVOKED",
}

export type CreditCardData = {
  billingAddress?: InputMaybe<BillingAddress>;
  cardHolderName?: InputMaybe<Scalars["String"]>;
  expiryMonth: Scalars["Int"];
  expiryYear: Scalars["Int"];
  number: Scalars["String"];
  phoneNumber?: InputMaybe<PhoneNumber>;
};

export enum Currency {
  Aave = "AAVE",
  Ach = "ACH",
  Ada = "ADA",
  Alcx = "ALCX",
  Algo = "ALGO",
  Alice = "ALICE",
  Amp = "AMP",
  Ankr = "ANKR",
  Atom = "ATOM",
  Avax = "AVAX",
  Axs = "AXS",
  Bal = "BAL",
  Band = "BAND",
  Bat = "BAT",
  Bch = "BCH",
  Bnb = "BNB",
  Bond = "BOND",
  Brd = "BRD",
  Btc = "BTC",
  Bts = "BTS",
  Btt = "BTT",
  Busd = "BUSD",
  Cake = "CAKE",
  Celr = "CELR",
  Cgld = "CGLD",
  Chz = "CHZ",
  Comp = "COMP",
  Cont = "CONT",
  Cro = "CRO",
  Crv = "CRV",
  Ctsi = "CTSI",
  Cvc = "CVC",
  Cvx = "CVX",
  Dash = "DASH",
  Data = "DATA",
  Dent = "DENT",
  Doge = "DOGE",
  Dot = "DOT",
  Egld = "EGLD",
  Enj = "ENJ",
  Eos = "EOS",
  Etc = "ETC",
  Eth = "ETH",
  Eur = "EUR",
  Evx = "EVX",
  Farm = "FARM",
  Fet = "FET",
  Fil = "FIL",
  Flm = "FLM",
  Flow = "FLOW",
  Ftm = "FTM",
  Ftt = "FTT",
  Fxs = "FXS",
  Gala = "GALA",
  Gas = "GAS",
  Gbp = "GBP",
  Gno = "GNO",
  Gnt = "GNT",
  Grt = "GRT",
  Hbar = "HBAR",
  Hnt = "HNT",
  Hot = "HOT",
  Icx = "ICX",
  Iost = "IOST",
  Iota = "IOTA",
  Key = "KEY",
  Klay = "KLAY",
  Knc = "KNC",
  Kp3R = "KP3R",
  Ksm = "KSM",
  Link = "LINK",
  Lrc = "LRC",
  Lsk = "LSK",
  Ltc = "LTC",
  Luna = "LUNA",
  Mana = "MANA",
  Matic = "MATIC",
  Mda = "MDA",
  Mkr = "MKR",
  Mln = "MLN",
  Movr = "MOVR",
  Near = "NEAR",
  Neo = "NEO",
  Null = "NULL",
  Null2 = "NULL2",
  Ogn = "OGN",
  Omg = "OMG",
  One = "ONE",
  Ont = "ONT",
  Pax = "PAX",
  Paxg = "PAXG",
  Poly = "POLY",
  Qkc = "QKC",
  Qnt = "QNT",
  Qtum = "QTUM",
  Rare = "RARE",
  Ray = "RAY",
  Rbn = "RBN",
  Ren = "REN",
  Rlc = "RLC",
  Rose = "ROSE",
  Rune = "RUNE",
  Rvn = "RVN",
  Sand = "SAND",
  Shib = "SHIB",
  Snt = "SNT",
  Snx = "SNX",
  Sol = "SOL",
  Srm = "SRM",
  Stx = "STX",
  Sushi = "SUSHI",
  Sxp = "SXP",
  Theta = "THETA",
  Trb = "TRB",
  Trx = "TRX",
  Tusd = "TUSD",
  Uni = "UNI",
  Usd = "USD",
  Usdc = "USDC",
  Usdt = "USDT",
  Vet = "VET",
  Waves = "WAVES",
  Waxp = "WAXP",
  Wbtc = "WBTC",
  Xbt = "XBT",
  Xem = "XEM",
  Xlm = "XLM",
  Xmr = "XMR",
  Xrp = "XRP",
  Xtz = "XTZ",
  Xvg = "XVG",
  Yfi = "YFI",
  Yfii = "YFII",
  Zec = "ZEC",
  Zil = "ZIL",
  Zrx = "ZRX",
}

export enum CurrencyPair {
  AaveBtc = "AAVE_BTC",
  AaveUsd = "AAVE_USD",
  AaveUsdt = "AAVE_USDT",
  AchUsd = "ACH_USD",
  AdaBtc = "ADA_BTC",
  AdaEth = "ADA_ETH",
  AdaEur = "ADA_EUR",
  AdaUsd = "ADA_USD",
  AdaUsdc = "ADA_USDC",
  AdaUsdt = "ADA_USDT",
  AdausdPerp = "ADAUSD_PERP",
  AlcxUsd = "ALCX_USD",
  AlcxUsdt = "ALCX_USDT",
  AlgoBtc = "ALGO_BTC",
  AlgoUsd = "ALGO_USD",
  AlgoUsdt = "ALGO_USDT",
  AliceUsdt = "ALICE_USDT",
  AmpUsd = "AMP_USD",
  AnkrUsdt = "ANKR_USDT",
  AtomUsd = "ATOM_USD",
  AtomUsdt = "ATOM_USDT",
  AvaxBtc = "AVAX_BTC",
  AvaxEur = "AVAX_EUR",
  AvaxUsd = "AVAX_USD",
  AvaxUsdc = "AVAX_USDC",
  AvaxUsdt = "AVAX_USDT",
  AxsUsdt = "AXS_USDT",
  BalEur = "BAL_EUR",
  BalUsd = "BAL_USD",
  BalUsdt = "BAL_USDT",
  BandBtc = "BAND_BTC",
  BatUsdt = "BAT_USDT",
  BchBtc = "BCH_BTC",
  BchUsdt = "BCH_USDT",
  BchusdPerp = "BCHUSD_PERP",
  BnbBtc = "BNB_BTC",
  BnbBusd = "BNB_BUSD",
  BnbEth = "BNB_ETH",
  BnbUsdt = "BNB_USDT",
  BnbusdPerp = "BNBUSD_PERP",
  BondUsd = "BOND_USD",
  BrdBtc = "BRD_BTC",
  BtcEur = "BTC_EUR",
  BtcGbp = "BTC_GBP",
  BtcPax = "BTC_PAX",
  BtcTusd = "BTC_TUSD",
  BtcUsd = "BTC_USD",
  BtcUsdc = "BTC_USDC",
  BtcUsdt = "BTC_USDT",
  Btcusd = "BTCUSD",
  BtcusdPerp = "BTCUSD_PERP",
  BtsBtc = "BTS_BTC",
  BttBtc = "BTT_BTC",
  BttUsdt = "BTT_USDT",
  BusdUsdt = "BUSD_USDT",
  CakeBtc = "CAKE_BTC",
  CakeUsdt = "CAKE_USDT",
  CelrBnb = "CELR_BNB",
  CelrBtc = "CELR_BTC",
  CelrUsdt = "CELR_USDT",
  CgldUsd = "CGLD_USD",
  ChzBtc = "CHZ_BTC",
  ChzUsdt = "CHZ_USDT",
  CompEur = "COMP_EUR",
  CompUsd = "COMP_USD",
  CompUsdt = "COMP_USDT",
  CroBtc = "CRO_BTC",
  CroUsdc = "CRO_USDC",
  CroUsdt = "CRO_USDT",
  CrvBtc = "CRV_BTC",
  CrvEur = "CRV_EUR",
  CrvUsd = "CRV_USD",
  CrvUsdc = "CRV_USDC",
  CrvUsdt = "CRV_USDT",
  CtsiBtc = "CTSI_BTC",
  CvcUsdc = "CVC_USDC",
  CvxUsdt = "CVX_USDT",
  DashBtc = "DASH_BTC",
  DashUsdt = "DASH_USDT",
  DataBtc = "DATA_BTC",
  DentUsdt = "DENT_USDT",
  DogeBtc = "DOGE_BTC",
  DogeUsdt = "DOGE_USDT",
  DogeusdPerp = "DOGEUSD_PERP",
  DotBtc = "DOT_BTC",
  DotUsd = "DOT_USD",
  DotUsdc = "DOT_USDC",
  DotUsdt = "DOT_USDT",
  DotusdPerp = "DOTUSD_PERP",
  EgldBtc = "EGLD_BTC",
  EgldEur = "EGLD_EUR",
  EgldUsdt = "EGLD_USDT",
  EnjBtc = "ENJ_BTC",
  EnjUsdt = "ENJ_USDT",
  EosBnb = "EOS_BNB",
  EosBtc = "EOS_BTC",
  EosEth = "EOS_ETH",
  EosUsdt = "EOS_USDT",
  Eosusd = "EOSUSD",
  EtcBtc = "ETC_BTC",
  EtcUsdt = "ETC_USDT",
  EthBtc = "ETH_BTC",
  EthEur = "ETH_EUR",
  EthGbp = "ETH_GBP",
  EthUsd = "ETH_USD",
  EthUsdc = "ETH_USDC",
  EthUsdt = "ETH_USDT",
  Ethusd = "ETHUSD",
  EthusdPerp = "ETHUSD_PERP",
  EurUsdt = "EUR_USDT",
  EvxBtc = "EVX_BTC",
  EvxEth = "EVX_ETH",
  FarmUsd = "FARM_USD",
  FetBtc = "FET_BTC",
  FetUsdt = "FET_USDT",
  FilUsd = "FIL_USD",
  FilUsdt = "FIL_USDT",
  FlmUsdt = "FLM_USDT",
  FlowBtc = "FLOW_BTC",
  FlowEth = "FLOW_ETH",
  FlowUsd = "FLOW_USD",
  FlowUsdt = "FLOW_USDT",
  FtmBtc = "FTM_BTC",
  FtmUsdc = "FTM_USDC",
  FtmUsdt = "FTM_USDT",
  FttBtc = "FTT_BTC",
  FttUsdt = "FTT_USDT",
  FxsUsdc = "FXS_USDC",
  FxsUsdt = "FXS_USDT",
  GalaBtc = "GALA_BTC",
  GalaUsdc = "GALA_USDC",
  GalaUsdt = "GALA_USDT",
  GasBtc = "GAS_BTC",
  GbpUsdt = "GBP_USDT",
  GnoEur = "GNO_EUR",
  GnoUsd = "GNO_USD",
  GnoUsdt = "GNO_USDT",
  GntBtc = "GNT_BTC",
  GrtUsd = "GRT_USD",
  GrtUsdt = "GRT_USDT",
  HbarUsdt = "HBAR_USDT",
  HntUsdt = "HNT_USDT",
  HotUsdt = "HOT_USDT",
  IcxBtc = "ICX_BTC",
  IcxUsdt = "ICX_USDT",
  IostBtc = "IOST_BTC",
  IostEth = "IOST_ETH",
  IostUsdt = "IOST_USDT",
  IotaBtc = "IOTA_BTC",
  IotaUsdt = "IOTA_USDT",
  KeyUsdt = "KEY_USDT",
  KlayUsdt = "KLAY_USDT",
  KncUsd = "KNC_USD",
  Kp3RUsdt = "KP3R_USDT",
  KsmBtc = "KSM_BTC",
  KsmUsdt = "KSM_USDT",
  LinkBtc = "LINK_BTC",
  LinkEth = "LINK_ETH",
  LinkEur = "LINK_EUR",
  LinkGbp = "LINK_GBP",
  LinkUsd = "LINK_USD",
  LinkUsdc = "LINK_USDC",
  LinkUsdt = "LINK_USDT",
  LinkusdPerp = "LINKUSD_PERP",
  LrcUsdt = "LRC_USDT",
  LskUsdt = "LSK_USDT",
  LtcBtc = "LTC_BTC",
  LtcEur = "LTC_EUR",
  LtcGbp = "LTC_GBP",
  LtcUsd = "LTC_USD",
  LtcUsdt = "LTC_USDT",
  LtcusdPerp = "LTCUSD_PERP",
  LunaBtc = "LUNA_BTC",
  LunaEur = "LUNA_EUR",
  LunaUsdc = "LUNA_USDC",
  LunaUsdt = "LUNA_USDT",
  ManaBtc = "MANA_BTC",
  ManaUsdc = "MANA_USDC",
  MaticBtc = "MATIC_BTC",
  MaticUsd = "MATIC_USD",
  MaticUsdt = "MATIC_USDT",
  MdaBtc = "MDA_BTC",
  MkrEur = "MKR_EUR",
  MkrUsd = "MKR_USD",
  MkrUsdt = "MKR_USDT",
  MlnUsd = "MLN_USD",
  MovrUsd = "MOVR_USD",
  NearUsdt = "NEAR_USDT",
  NeoBtc = "NEO_BTC",
  NeoUsdt = "NEO_USDT",
  NullNull = "NULL_NULL",
  OgnUsdt = "OGN_USDT",
  OmgBtc = "OMG_BTC",
  OneUsdt = "ONE_USDT",
  OntBtc = "ONT_BTC",
  OntUsdt = "ONT_USDT",
  PaxUsdt = "PAX_USDT",
  PaxgBtc = "PAXG_BTC",
  PaxgUsdt = "PAXG_USDT",
  PolyBtc = "POLY_BTC",
  QkcBtc = "QKC_BTC",
  QntBtc = "QNT_BTC",
  QntUsdt = "QNT_USDT",
  QtumUsdt = "QTUM_USDT",
  RareUsdt = "RARE_USDT",
  RayUsd = "RAY_USD",
  RbnUsd = "RBN_USD",
  RenBtc = "REN_BTC",
  RlcBtc = "RLC_BTC",
  RlcUsd = "RLC_USD",
  RoseUsdt = "ROSE_USDT",
  RuneUsdt = "RUNE_USDT",
  RvnBnb = "RVN_BNB",
  RvnBtc = "RVN_BTC",
  RvnUsdt = "RVN_USDT",
  SandUsdt = "SAND_USDT",
  ShibUsd = "SHIB_USD",
  SntBtc = "SNT_BTC",
  SntEth = "SNT_ETH",
  SnxEur = "SNX_EUR",
  SnxUsd = "SNX_USD",
  SnxUsdt = "SNX_USDT",
  SolBtc = "SOL_BTC",
  SolEur = "SOL_EUR",
  SolUsd = "SOL_USD",
  SolUsdc = "SOL_USDC",
  SolUsdt = "SOL_USDT",
  SrmUsdt = "SRM_USDT",
  StxUsdt = "STX_USDT",
  SushiUsdt = "SUSHI_USDT",
  SxpBtc = "SXP_BTC",
  SxpUsdt = "SXP_USDT",
  ThetaUsdt = "THETA_USDT",
  TrbBtc = "TRB_BTC",
  TrxBtc = "TRX_BTC",
  TrxUsdt = "TRX_USDT",
  TrxusdPerp = "TRXUSD_PERP",
  TusdUsdt = "TUSD_USDT",
  UniBtc = "UNI_BTC",
  UniUsd = "UNI_USD",
  UniUsdt = "UNI_USDT",
  UsdcUsdt = "USDC_USDT",
  VetBtc = "VET_BTC",
  VetEth = "VET_ETH",
  VetUsdc = "VET_USDC",
  VetUsdt = "VET_USDT",
  WavesBtc = "WAVES_BTC",
  WaxpUsdt = "WAXP_USDT",
  WbtcBtc = "WBTC_BTC",
  WbtcEth = "WBTC_ETH",
  Xbtusd = "XBTUSD",
  XemUsdt = "XEM_USDT",
  XlmBtc = "XLM_BTC",
  XlmUsd = "XLM_USD",
  XlmUsdt = "XLM_USDT",
  XlmusdPerp = "XLMUSD_PERP",
  XmrBtc = "XMR_BTC",
  XmrUsdt = "XMR_USDT",
  XrpBtc = "XRP_BTC",
  XrpEur = "XRP_EUR",
  XrpGbp = "XRP_GBP",
  XrpUsd = "XRP_USD",
  XrpUsdt = "XRP_USDT",
  Xrpusd = "XRPUSD",
  XrpusdPerp = "XRPUSD_PERP",
  XtzUsd = "XTZ_USD",
  XtzUsdt = "XTZ_USDT",
  XvgBtc = "XVG_BTC",
  YfiBtc = "YFI_BTC",
  YfiUsd = "YFI_USD",
  YfiUsdt = "YFI_USDT",
  YfiiUsdt = "YFII_USDT",
  ZecBtc = "ZEC_BTC",
  ZecEur = "ZEC_EUR",
  ZecUsd = "ZEC_USD",
  ZecUsdt = "ZEC_USDT",
  ZilBtc = "ZIL_BTC",
  ZrxBtc = "ZRX_BTC",
  ZrxEur = "ZRX_EUR",
  ZrxUsd = "ZRX_USD",
  ZrxUsdt = "ZRX_USDT",
}

export type CurrencyPairDetails = {
  __typename?: "CurrencyPairDetails";
  base: Currency;
  exchange: Exchange;
  id: Scalars["ID"];
  orderRules: OrderRules;
  pair: CurrencyPair;
  positionCurrency: Currency;
  quote: Currency;
  settleCurrency: Currency;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type DailyChart = {
  __typename?: "DailyChart";
  firstDay?: Maybe<Scalars["Date"]>;
  values?: Maybe<Array<Maybe<Scalars["Decimal"]>>>;
};

export type DecreaseExecutionAllocationInput = {
  decrement: Scalars["Int"];
  executionId: Scalars["ID"];
  idempotencyKey: Scalars["ID"];
};

export enum DirectPaymentAccountType {
  CryptocurrencyWallet = "CRYPTOCURRENCY_WALLET",
}

export type DirectPaymentConnection = {
  __typename?: "DirectPaymentConnection";
  edges?: Maybe<Array<Maybe<DirectPaymentConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DirectPaymentConnectionEdge = {
  __typename?: "DirectPaymentConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<DirectPaymentRequest>;
};

export type DirectPaymentRequest = {
  __typename?: "DirectPaymentRequest";
  id: Scalars["ID"];
  requestAmount: Scalars["Money"];
  review?: Maybe<DirectPaymentReview>;
  status: DirectPaymentRequestStatus;
  user: User;
};

export type DirectPaymentRequestFilter = {
  includeStatus?: InputMaybe<Array<DirectPaymentRequestStatus>>;
};

export enum DirectPaymentRequestStatus {
  Received = "RECEIVED",
  Reviewed = "REVIEWED",
}

export type DirectPaymentReview = {
  __typename?: "DirectPaymentReview";
  decision: DirectPaymentReviewDecision;
  reviewerComment: Scalars["String"];
  txInfo?: Maybe<DirectPaymentTxInfo>;
};

export enum DirectPaymentReviewDecision {
  Approved = "APPROVED",
  Denied = "DENIED",
}

export type DirectPaymentReviewInput = {
  decision: DirectPaymentReviewDecision;
  reviewerComment: Scalars["String"];
  txInfo?: InputMaybe<DirectPaymentTxInfoInput>;
};

export type DirectPaymentTxInfo = {
  __typename?: "DirectPaymentTxInfo";
  account: Scalars["String"];
  accountType: DirectPaymentAccountType;
  amountReceived: Scalars["Money"];
  timeReceived: Scalars["DateTime"];
  txId: Scalars["String"];
};

export type DirectPaymentTxInfoInput = {
  account: Scalars["String"];
  accountType: DirectPaymentAccountType;
  amountReceived: Scalars["Money"];
  timeReceived: Scalars["DateTime"];
  txId: Scalars["String"];
};

export type Entitlement = {
  id: Scalars["ID"];
  referenceId: Scalars["String"];
  type: Scalars["String"];
};

export enum Exchange {
  Binance = "BINANCE",
  BinanceCoinFutures = "BINANCE_COIN_FUTURES",
  BinanceFutures = "BINANCE_FUTURES",
  Bitmex = "BITMEX",
  Bybit = "BYBIT",
  BybitLinear = "BYBIT_LINEAR",
  Coinbase = "COINBASE",
  CryptoDotCom = "CRYPTO_DOT_COM",
  HuobiCoinSwaps = "HUOBI_COIN_SWAPS",
  Kraken = "KRAKEN",
  Nash = "NASH",
  Forex = "FOREX",
}

export type ExchangeDetails = {
  __typename?: "ExchangeDetails";
  currencyPairs: Array<Maybe<CurrencyPairDetails>>;
  id: Exchange;
  /** For backward compatibility */
  isMargin: Scalars["Boolean"];
  supportsMargin: Scalars["Boolean"];
};

export type ExchangePairTypeStuckExecution = {
  __typename?: "ExchangePairTypeStuckExecution";
  avgSecondsWithoutUpdate: Scalars["Decimal"];
  count: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  currencyPair: CurrencyPair;
  exchange: Exchange;
  type: ExecutionType;
};

export type Execution = {
  __typename?: "Execution";
  algorithmSettings?: Maybe<Scalars["String"]>;
  allocation: Scalars["Decimal"];
  analysis?: Maybe<Analysis>;
  /** @deprecated No longer supported */
  analysisWithRange?: Maybe<Analysis>;
  autoRebalance?: Maybe<Scalars["Boolean"]>;
  buyActionTime?: Maybe<SignalActionTime>;
  buyType?: Maybe<OrderType>;
  candleSize: Scalars["Resolution"];
  candleType?: Maybe<CandleType>;
  createdAt?: Maybe<Scalars["Instant"]>;
  credential?: Maybe<Credential>;
  currencyPair: CurrencyPair;
  currencyPairDetails?: Maybe<CurrencyPairDetails>;
  endedAt?: Maybe<Scalars["DateTime"]>;
  error?: Maybe<Scalars["String"]>;
  exchange: Exchange;
  favorite?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  isArchived?: Maybe<Scalars["Boolean"]>;
  isBasket?: Maybe<Scalars["Boolean"]>;
  isPack: Scalars["Boolean"];
  leverage: Scalars["Decimal"];
  leverageShort: Scalars["Decimal"];
  logs?: Maybe<Array<Maybe<ExecutionLog>>>;
  logsConnection?: Maybe<ExecutionLogConnection>;
  lookbackDays?: Maybe<Scalars["Int"]>;
  maxBuy?: Maybe<Scalars["Decimal"]>;
  /**
   * for executions that are subscriptions of a syndication
   * @deprecated No longer supported
   */
  measurementLogs?: Maybe<Array<Maybe<ExecutionMeasurementLog>>>;
  measurements?: Maybe<Measurements>;
  minProfit?: Maybe<Scalars["Decimal"]>;
  multiCoinCurrency?: Maybe<Currency>;
  multiCoinPackExecutions?: Maybe<
    Array<Maybe<MultiCoinPackCurrencyPercentage>>
  >;
  multivariantIterationId?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  orders?: Maybe<OrderConnection>;
  owner?: Maybe<PublicUser>;
  packExecutions?: Maybe<ExecutionConnection>;
  packExecutionsCount?: Maybe<Scalars["Int"]>;
  packPercentage?: Maybe<Scalars["Decimal"]>;
  performanceMode: ExecutionPerformanceMode;
  plotDefinition?: Maybe<ScriptPlotDefinition>;
  plotPalettes?: Maybe<Array<Maybe<ScriptPlotPalette>>>;
  preloadedScript?: Maybe<PreloadedScript>;
  rangeEnd?: Maybe<Scalars["Date"]>;
  rangeStart?: Maybe<Scalars["Date"]>;
  realizedProfitsLog?: Maybe<Array<Maybe<RealizedProfitsLog>>>;
  runNumber?: Maybe<Scalars["String"]>;
  runtimeSeconds?: Maybe<Scalars["Int"]>;
  scriptDetails?: Maybe<ScriptDetails>;
  scriptVersionSequence?: Maybe<Scalars["Int"]>;
  sellActionTime?: Maybe<SignalActionTime>;
  sellType?: Maybe<OrderType>;
  shareToken?: Maybe<Scalars["String"]>;
  stage?: Maybe<ExecutionStage>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status: ExecutionStatus;
  stopLossPercentage?: Maybe<Scalars["Decimal"]>;
  stopLossTrailing?: Maybe<Scalars["Boolean"]>;
  subscription?: Maybe<SharedExecution>;
  subscriptionExecutions?: Maybe<ExecutionConnection>;
  subscriptionExecutionsCount?: Maybe<Scalars["Int"]>;
  subscriptions?: Maybe<Array<Maybe<SharedExecution>>>;
  subscriptionsCount?: Maybe<ResultSize>;
  syndication?: Maybe<PublicSyndication>;
  syndicationId?: Maybe<Scalars["ID"]>;
  syndicationMeasurements?: Maybe<SyndicationMeasurements>;
  syndicationSettings?: Maybe<SyndicationSettings>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  takeProfitPercentage?: Maybe<Scalars["Decimal"]>;
  takeProfitTrailingPercentage?: Maybe<Scalars["Decimal"]>;
  /** @deprecated replaced by tradesConnection */
  trades?: Maybe<Array<Maybe<Trade>>>;
  tradesConnection?: Maybe<TradeConnection>;
  type: ExecutionType;
};

export type ExecutionAnalysisArgs = {
  rangeDays?: InputMaybe<Scalars["Int"]>;
};

export type ExecutionAnalysisWithRangeArgs = {
  rangeDays?: InputMaybe<Scalars["Int"]>;
};

export type ExecutionLogsArgs = {
  query?: InputMaybe<PageQuery>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ExecutionLogsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ExecutionOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ExecutionPackExecutionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ExecutionListFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExecutionSort>;
};

export type ExecutionPackExecutionsCountArgs = {
  filters?: InputMaybe<ExecutionListFilters>;
};

export type ExecutionSubscriptionArgs = {
  id: Scalars["ID"];
};

export type ExecutionSubscriptionExecutionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ExecutionListFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExecutionSort>;
};

export type ExecutionSubscriptionExecutionsCountArgs = {
  filters?: InputMaybe<ExecutionListFilters>;
};

export type ExecutionSubscriptionsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type ExecutionSubscriptionsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
};

export type ExecutionTradesArgs = {
  query?: InputMaybe<PageQuery>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ExecutionTradesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum ExecutionColumn {
  AbsoluteProfit = "ABSOLUTE_PROFIT",
  Allocation = "ALLOCATION",
  AllocationFeePerc = "ALLOCATION_FEE_PERC",
  Archived = "ARCHIVED",
  AutoRebalance = "AUTO_REBALANCE",
  AvgLoseMonth = "AVG_LOSE_MONTH",
  AvgMonthlyProfit = "AVG_MONTHLY_PROFIT",
  AvgPositionPrice = "AVG_POSITION_PRICE",
  AvgWinMonth = "AVG_WIN_MONTH",
  Balance = "BALANCE",
  BuyHoldRatio = "BUY_HOLD_RATIO",
  BuyHoldReturn = "BUY_HOLD_RETURN",
  CandleSize = "CANDLE_SIZE",
  CompoundDailyReturns = "COMPOUND_DAILY_RETURNS",
  ConsistencyScore = "CONSISTENCY_SCORE",
  CreationDate = "CREATION_DATE",
  CreatorName = "CREATOR_NAME",
  CurrencyPair = "CURRENCY_PAIR",
  EndedAt = "ENDED_AT",
  EndedAtDate = "ENDED_AT_DATE",
  Exchange = "EXCHANGE",
  ExecutionNumber = "EXECUTION_NUMBER",
  Favorite = "FAVORITE",
  IsBasket = "IS_BASKET",
  IsMultiCoinPack = "IS_MULTI_COIN_PACK",
  IsPack = "IS_PACK",
  IsPreloadedScript = "IS_PRELOADED_SCRIPT",
  IsSubscription = "IS_SUBSCRIPTION",
  Leverage = "LEVERAGE",
  LeverageShort = "LEVERAGE_SHORT",
  MaxDrawdown = "MAX_DRAWDOWN",
  MinAllocation = "MIN_ALLOCATION",
  MonthlyFeeUsd = "MONTHLY_FEE_USD",
  MultiCoinPack = "MULTI_COIN_PACK",
  Name = "NAME",
  NumberOfTrades = "NUMBER_OF_TRADES",
  PercBuyHoldRatio = "PERC_BUY_HOLD_RATIO",
  PercProfitableMonths = "PERC_PROFITABLE_MONTHS",
  PercentProfitableTrades = "PERCENT_PROFITABLE_TRADES",
  PositionAbsoluteProfit = "POSITION_ABSOLUTE_PROFIT",
  PositionAmount = "POSITION_AMOUNT",
  PositionProfitLoss = "POSITION_PROFIT_LOSS",
  PreloadedScript = "PRELOADED_SCRIPT",
  ProfitFactor = "PROFIT_FACTOR",
  Profitability = "PROFITABILITY",
  RiskScore = "RISK_SCORE",
  ScriptName = "SCRIPT_NAME",
  SharpeRatio = "SHARPE_RATIO",
  SortinoRatio = "SORTINO_RATIO",
  StartedAt = "STARTED_AT",
  StartedAtDate = "STARTED_AT_DATE",
  Status = "STATUS",
  SubscriptionStatus = "SUBSCRIPTION_STATUS",
  SuccessFeePerc = "SUCCESS_FEE_PERC",
  SyndicationId = "SYNDICATION_ID",
  TagId = "TAG_ID",
  TotalAllocated = "TOTAL_ALLOCATED",
  TotalRealizedGain = "TOTAL_REALIZED_GAIN",
  TotalRealizedLoss = "TOTAL_REALIZED_LOSS",
  Type = "TYPE",
  VisibleSyndication = "VISIBLE_SYNDICATION",
}

export type ExecutionConnection = {
  __typename?: "ExecutionConnection";
  edges?: Maybe<Array<Maybe<ExecutionConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExecutionConnectionEdge = {
  __typename?: "ExecutionConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Execution>;
};

export type ExecutionCreation = {
  algorithmSettings: Scalars["String"];
  /** Below are type-specific settings, must be present if creating that type */
  backtestSettings?: InputMaybe<BacktestSettings>;
  buyActionTime?: InputMaybe<SignalActionTime>;
  candleSize: Scalars["Resolution"];
  candleType: CandleType;
  currencyPair: CurrencyPair;
  exchange: Exchange;
  executionRunNumber?: InputMaybe<Scalars["Int"]>;
  initialCapital: Scalars["Decimal"];
  leverage?: InputMaybe<Scalars["Decimal"]>;
  leverageShort?: InputMaybe<Scalars["Decimal"]>;
  lookbackDays: Scalars["Int"];
  maxBuy: Scalars["Decimal"];
  minProfit: Scalars["Decimal"];
  multivariantSourceId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  performanceMode?: InputMaybe<ExecutionPerformanceMode>;
  preloadedScript?: InputMaybe<PreloadedScript>;
  scriptRevisionId: Scalars["ID"];
  sellActionTime?: InputMaybe<SignalActionTime>;
  stopLossPercentage: Scalars["Decimal"];
  stopLossTrailing: Scalars["Boolean"];
  strategySettings?: InputMaybe<StrategySettings>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  takeProfitPercentage: Scalars["Decimal"];
  takeProfitTrailingPercentage: Scalars["Decimal"];
  type: ExecutionType;
};

export type ExecutionFieldComparison = {
  field1: ExecutionColumn;
  field2: ExecutionColumn;
  filterType: FilterType;
};

export type ExecutionFilter = {
  filterKey: ExecutionColumn;
  filterType: FilterType;
  value: Scalars["String"];
};

export type ExecutionListFilters = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  fieldComparisons?: InputMaybe<Array<InputMaybe<ExecutionFieldComparison>>>;
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  stage?: InputMaybe<ExecutionStage>;
  types?: InputMaybe<Array<InputMaybe<ExecutionType>>>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type ExecutionLog = {
  __typename?: "ExecutionLog";
  id: Scalars["ID"];
  time?: Maybe<Scalars["DateTime"]>;
  /** We keep is as string for now, perhaps switch to enum later */
  type?: Maybe<Scalars["String"]>;
};

export type ExecutionLogConnection = {
  __typename?: "ExecutionLogConnection";
  edges?: Maybe<Array<Maybe<ExecutionLogConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExecutionLogConnectionEdge = {
  __typename?: "ExecutionLogConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<ExecutionLog>;
};

export type ExecutionMeasurementLog = {
  __typename?: "ExecutionMeasurementLog";
  absoluteProfit: Scalars["Decimal"];
  executionId: Scalars["Int"];
  initialAllocation: Scalars["Decimal"];
  initialAllocationPlusReturns: Scalars["Decimal"];
  logDate: Scalars["Date"];
  percentageLiveGains: Scalars["Decimal"];
  realizedLiveUsd?: Maybe<Scalars["Decimal"]>;
  totalValueUsd?: Maybe<Scalars["Decimal"]>;
};

export enum ExecutionPerformanceMode {
  Accuracy = "ACCURACY",
  AccuracyFull = "ACCURACY_FULL",
  Speed = "SPEED",
}

export type ExecutionRealizedProfits = {
  __typename?: "ExecutionRealizedProfits";
  /** @deprecated No longer supported */
  absoluteProfit?: Maybe<Scalars["Decimal"]>;
  /** @deprecated No longer supported */
  allocation?: Maybe<Scalars["Decimal"]>;
  assetsUnderManagement?: Maybe<Scalars["Decimal"]>;
  /** @deprecated No longer supported */
  profitability?: Maybe<Scalars["Decimal"]>;
  /** @deprecated No longer supported */
  settleCurrency?: Maybe<Currency>;
  totalAllocation?: Maybe<Scalars["Decimal"]>;
  totalAllocationCarryForward?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitability?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitabilityCarryForward?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfits?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitsCarryForward?: Maybe<Scalars["Decimal"]>;
  /** @deprecated No longer supported */
  totalValue?: Maybe<Scalars["Decimal"]>;
};

export type ExecutionSort = {
  sortDirection: SortDirection;
  sortKey: ExecutionColumn;
};

export enum ExecutionStage {
  Candidates = "CANDIDATES",
  ReleaseCandidates = "RELEASE_CANDIDATES",
}

export enum ExecutionStatus {
  Cancelled = "CANCELLED",
  Deleting = "DELETING",
  Ended = "ENDED",
  Failed = "FAILED",
  Liquidated = "LIQUIDATED",
  Pending = "PENDING",
  Running = "RUNNING",
  Stopping = "STOPPING",
  StoppingClosemarket = "STOPPING_CLOSEMARKET",
  StoppingNextclose = "STOPPING_NEXTCLOSE",
}

export type ExecutionStatusCount = {
  __typename?: "ExecutionStatusCount";
  count: Scalars["Int"];
  status: ExecutionStatus;
};

export enum ExecutionType {
  Backtest = "BACKTEST",
  Live = "LIVE",
  Papertrade = "PAPERTRADE",
  Preview = "PREVIEW",
  Syndication = "SYNDICATION",
  SyndicationBacktest = "SYNDICATION_BACKTEST",
}

export type FillInfo = {
  __typename?: "FillInfo";
  color: ColorInput;
  editable?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  input1: FillInputId;
  input2: FillInputId;
  inputType: PlotType;
  title?: Maybe<Scalars["String"]>;
  transparency: Scalars["Int"];
};

export type FillInputId = {
  __typename?: "FillInputId";
  str: Scalars["String"];
  type: PlotType;
};

export type FilterPreset = {
  __typename?: "FilterPreset";
  filters: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum FilterType {
  Contains = "CONTAINS",
  Equals = "EQUALS",
  GreaterThan = "GREATER_THAN",
  GreaterThanDays = "GREATER_THAN_DAYS",
  GreaterThanOrEqual = "GREATER_THAN_OR_EQUAL",
  IsFalse = "IS_FALSE",
  IsTrue = "IS_TRUE",
  LessThan = "LESS_THAN",
  LessThanDays = "LESS_THAN_DAYS",
  LessThanOrEqual = "LESS_THAN_OR_EQUAL",
  NotContains = "NOT_CONTAINS",
  NotEquals = "NOT_EQUALS",
}

export type FixedAmountInput = {
  amount: Scalars["Decimal"];
};

export type GettingStarted = {
  __typename?: "GettingStarted";
  addedCredential?: Maybe<Scalars["Boolean"]>;
  createdExperiment?: Maybe<Scalars["Boolean"]>;
  createdMultivariant?: Maybe<Scalars["Boolean"]>;
  launchedLive?: Maybe<Scalars["Boolean"]>;
  openedEditor?: Maybe<Scalars["Boolean"]>;
  promotedCandidate?: Maybe<Scalars["Boolean"]>;
  promotedExperiment?: Maybe<Scalars["Boolean"]>;
};

export type GrantSubscriptionToUser = {
  productId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type HLineInfo = {
  __typename?: "HLineInfo";
  color: ColorInput;
  editable?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  lineStyle: HLineStyle;
  lineWidth: Scalars["Int"];
  title: Scalars["String"];
  yVal: Scalars["Float"];
};

export enum HLineStyle {
  Dashed = "DASHED",
  Dotted = "DOTTED",
  Solid = "SOLID",
}

export type IncreaseExecutionAllocationInput = {
  executionId: Scalars["ID"];
  idempotencyKey: Scalars["ID"];
  increment: Scalars["Int"];
};

export enum InputType {
  Const = "CONST",
  Dynamic = "DYNAMIC",
}

export type Invoice = {
  __typename?: "Invoice";
  balance: Scalars["Decimal"];
  createdAt: Scalars["Instant"];
  currency: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  dueByTime?: Maybe<Scalars["DateTime"]>;
  expirationTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  items: Array<Maybe<InvoiceItem>>;
  mayCreatePayment: Scalars["Boolean"];
  payments: Array<Maybe<InvoicePayment>>;
  pending: Scalars["Decimal"];
  periodEnd?: Maybe<Scalars["Instant"]>;
  periodStart?: Maybe<Scalars["Instant"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  status: InvoiceStatus;
  statusReason?: Maybe<Scalars["String"]>;
  total: Scalars["Decimal"];
  totalPaid: Scalars["Decimal"];
  user: User;
};

export type InvoiceConnection = {
  __typename?: "InvoiceConnection";
  edges?: Maybe<Array<Maybe<InvoiceConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type InvoiceConnectionEdge = {
  __typename?: "InvoiceConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Invoice>;
};

export type InvoiceCreation = {
  definition: InvoiceDefinitionInput;
  userId: Scalars["ID"];
};

export enum InvoiceCurrency {
  Usd = "USD",
}

export type InvoiceDefinition = {
  __typename?: "InvoiceDefinition";
  currency: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  items: Array<Maybe<InvoiceItem>>;
  periodEnd?: Maybe<Scalars["Instant"]>;
  periodStart?: Maybe<Scalars["Instant"]>;
  shortDescription?: Maybe<Scalars["String"]>;
};

export type InvoiceDefinitionInput = {
  currency: InvoiceCurrency;
  description?: InputMaybe<Scalars["String"]>;
  items: Array<InputMaybe<InvoiceItemInput>>;
  reviewThresholds?: InputMaybe<InvoiceReviewThreshold>;
  shortDescription?: InputMaybe<Scalars["String"]>;
};

export type InvoiceEvent = {
  __typename?: "InvoiceEvent";
  invoiceId: Scalars["ID"];
  status: InvoiceStatus;
};

export type InvoiceFilter = {
  associatedWith?: InputMaybe<LineItemAssociationQuery>;
  excludeStatus?: InputMaybe<Array<InvoiceStatus>>;
  includeStatus?: InputMaybe<Array<InvoiceStatus>>;
};

export type InvoiceItem = {
  __typename?: "InvoiceItem";
  amount?: Maybe<Scalars["Decimal"]>;
  associations?: Maybe<Array<InvoiceItemAssociation>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  invoiceId: Scalars["ID"];
  itemType?: Maybe<Scalars["String"]>;
  product?: Maybe<Product>;
  successFeeLineItemMetadata?: Maybe<SuccessFeeLineItemMetadata>;
};

export type InvoiceItemAssociation = BillingSubscription | Product;

export type InvoiceItemInput = {
  amount: AmountInput;
  associations?: InputMaybe<Array<InputMaybe<LineItemAssociationInput>>>;
  description: Scalars["String"];
  type: InvoiceLineItemType;
};

export enum InvoiceLineItemType {
  AdjustmentCredit = "ADJUSTMENT_CREDIT",
  AdjustmentDebit = "ADJUSTMENT_DEBIT",
  Credit = "CREDIT",
  Fee = "FEE",
  PromoCredit = "PROMO_CREDIT",
  Refund = "REFUND",
  Tax = "TAX",
  Usage = "USAGE",
}

export type InvoicePayment = {
  __typename?: "InvoicePayment";
  amount: Scalars["Decimal"];
  externalId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  invoiceId: Scalars["ID"];
  metadata?: Maybe<PaymentMetadata>;
  paymentProvider: Scalars["String"];
  refunds: Array<InvoicePaymentRefund>;
  status: Scalars["String"];
  statusReason?: Maybe<Scalars["String"]>;
};

export type InvoicePaymentRefund = {
  __typename?: "InvoicePaymentRefund";
  amount: Scalars["Decimal"];
  id: Scalars["ID"];
  reason: Scalars["String"];
};

export enum InvoiceReviewThreshold {
  Hard = "HARD",
  Soft = "SOFT",
}

export enum InvoiceStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Expired = "EXPIRED",
  Forgiven = "FORGIVEN",
  Open = "OPEN",
  Overdue = "OVERDUE",
  Paid = "PAID",
  PendingManualApproval = "PENDING_MANUAL_APPROVAL",
  Rejected = "REJECTED",
}

export type LeverageInfo = {
  __typename?: "LeverageInfo";
  avgLeverage?: Maybe<Scalars["Decimal"]>;
  maxLeverage?: Maybe<Scalars["Decimal"]>;
  minLeverage?: Maybe<Scalars["Decimal"]>;
};

export type LineItemAssociationInput = {
  id: Scalars["String"];
  type: LineItemAssociationTypeInput;
};

export type LineItemAssociationQuery = {
  id: Scalars["ID"];
  type?: InputMaybe<AssociationType>;
};

export enum LineItemAssociationTypeInput {
  Execution = "EXECUTION",
  Product = "PRODUCT",
  Subscription = "SUBSCRIPTION",
}

export type LoadSuccess = {
  __typename?: "LoadSuccess";
  id: Scalars["ID"];
  success: Scalars["Boolean"];
};

export enum Locale {
  De = "DE",
  En = "EN",
  Es = "ES",
  Fr = "FR",
  Id = "ID",
  It = "IT",
  Ja = "JA",
  Ko = "KO",
  Nl = "NL",
  Pl = "PL",
  Pt = "PT",
  Ru = "RU",
  Tl = "TL",
  Tr = "TR",
  Uk = "UK",
  ZhCn = "ZH_CN",
  ZhTw = "ZH_TW",
}

export type MailingPreference = {
  __typename?: "MailingPreference";
  status?: Maybe<Scalars["String"]>;
  userType?: Maybe<UserType>;
};

export type Measurements = {
  __typename?: "Measurements";
  absoluteProfit?: Maybe<Scalars["Decimal"]>;
  availableFunds?: Maybe<Scalars["Decimal"]>;
  avgBarsInTrade?: Maybe<Scalars["Int"]>;
  avgLoseMonth?: Maybe<Scalars["Decimal"]>;
  avgMonthlyProfit?: Maybe<Scalars["Decimal"]>;
  avgPositionPrice?: Maybe<Scalars["Decimal"]>;
  avgTradePrice?: Maybe<Scalars["Decimal"]>;
  avgWinMonth?: Maybe<Scalars["Decimal"]>;
  balance?: Maybe<Scalars["Decimal"]>;
  buyCommission?: Maybe<Scalars["Decimal"]>;
  buyHoldRatio?: Maybe<Scalars["Decimal"]>;
  buyHoldReturn?: Maybe<Scalars["Decimal"]>;
  commissionValue?: Maybe<Scalars["Decimal"]>;
  consistencyScore?: Maybe<Scalars["Int"]>;
  lastTick?: Maybe<Scalars["DateTime"]>;
  maxDrawdown?: Maybe<Scalars["Decimal"]>;
  numberOfTrades?: Maybe<Scalars["Int"]>;
  percBuyHoldRatio?: Maybe<Scalars["Decimal"]>;
  percentProfitableTrades?: Maybe<Scalars["Decimal"]>;
  percProfitableMonths?: Maybe<Scalars["Decimal"]>;
  positionAbsoluteProfit?: Maybe<Scalars["Decimal"]>;
  positionAmount?: Maybe<Scalars["Decimal"]>;
  positionProfitLoss?: Maybe<Scalars["Decimal"]>;
  positionState?: Maybe<Scalars["String"]>;
  profitability?: Maybe<Scalars["Decimal"]>;
  profitFactor?: Maybe<Scalars["Decimal"]>;
  riskScore?: Maybe<Scalars["Decimal"]>;
  sellCommission?: Maybe<Scalars["Decimal"]>;
  sharpeRatio?: Maybe<Scalars["Decimal"]>;
  sortinoRatio?: Maybe<Scalars["Decimal"]>;
  totalRealizedGain?: Maybe<Scalars["Decimal"]>;
  totalRealizedLoss?: Maybe<Scalars["Decimal"]>;
  totalValue?: Maybe<Scalars["Decimal"]>;
};

export type MobileAppMetadata = {
  __typename?: "MobileAppMetadata";
  androidPlayStoreLink: Scalars["String"];
  iosAppStoreLink: Scalars["String"];
  minRequiredVersion: Scalars["String"];
  minSuggestedVersion: Scalars["String"];
};

export type MonthlyChart = {
  __typename?: "MonthlyChart";
  firstMonth?: Maybe<Scalars["String"]>;
  values?: Maybe<Array<Maybe<Scalars["Decimal"]>>>;
};

export type MultiCoinPackCurrencyPercentage = {
  __typename?: "MultiCoinPackCurrencyPercentage";
  currency: Currency;
  percentage: Scalars["Decimal"];
};

export type Multivariant = {
  __typename?: "Multivariant";
  algorithmSettings: Scalars["String"];
  allocation?: Maybe<Scalars["Decimal"]>;
  buyActionTime?: Maybe<Scalars["MVTParam"]>;
  candleSize?: Maybe<Scalars["MVTParam"]>;
  candleType?: Maybe<CandleType>;
  canResume?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  currencyPair: CurrencyPair;
  currencyPairDetails?: Maybe<CurrencyPairDetails>;
  endedAt?: Maybe<Scalars["DateTime"]>;
  exchange: Exchange;
  executions?: Maybe<Array<Maybe<Execution>>>;
  executionsCount?: Maybe<ResultSize>;
  favorite?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  leverage?: Maybe<Scalars["MVTParam"]>;
  leverageShort?: Maybe<Scalars["MVTParam"]>;
  lookbackDays: Scalars["Int"];
  maxBuy?: Maybe<Scalars["MVTParam"]>;
  minProfit?: Maybe<Scalars["MVTParam"]>;
  multipleValueFields?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  performanceMode: ExecutionPerformanceMode;
  progress?: Maybe<Array<Maybe<ExecutionStatusCount>>>;
  rangeEnd?: Maybe<Scalars["Date"]>;
  rangeStart?: Maybe<Scalars["Date"]>;
  scriptDetails: ScriptDetails;
  scriptVersionSequence?: Maybe<Scalars["Int"]>;
  sellActionTime?: Maybe<Scalars["MVTParam"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<ExecutionStatus>;
  stopLossPercentage?: Maybe<Scalars["MVTParam"]>;
  stopLossTrailing?: Maybe<Scalars["Boolean"]>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  takeProfitPercentage?: Maybe<Scalars["MVTParam"]>;
  takeProfitTrailingPercentage?: Maybe<Scalars["MVTParam"]>;
  tests?: Maybe<ExecutionConnection>;
  testsCount?: Maybe<Scalars["Int"]>;
  totalCombinationsCount: Scalars["Int"];
};

export type MultivariantExecutionsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type MultivariantExecutionsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
};

export type MultivariantTestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ExecutionListFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExecutionSort>;
};

export type MultivariantTestsCountArgs = {
  filters?: InputMaybe<ExecutionListFilters>;
};

export enum MultivariantColumn {
  Allocation = "ALLOCATION",
  CreationDate = "CREATION_DATE",
  CurrencyPair = "CURRENCY_PAIR",
  Exchange = "EXCHANGE",
  ExecutionNumber = "EXECUTION_NUMBER",
  Favorite = "FAVORITE",
  Name = "NAME",
  ScriptName = "SCRIPT_NAME",
  Status = "STATUS",
  TagId = "TAG_ID",
}

export type MultivariantConnection = {
  __typename?: "MultivariantConnection";
  edges?: Maybe<Array<Maybe<MultivariantConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MultivariantConnectionEdge = {
  __typename?: "MultivariantConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Multivariant>;
};

export type MultivariantCreation = {
  algorithmSettings?: InputMaybe<Scalars["String"]>;
  buyActionTime?: InputMaybe<Scalars["MVTParam"]>;
  candleSize?: InputMaybe<Scalars["MVTParam"]>;
  candleType?: InputMaybe<CandleType>;
  currencyPair: CurrencyPair;
  endDate?: InputMaybe<Scalars["Date"]>;
  exchange: Exchange;
  initialCapital?: InputMaybe<Scalars["Decimal"]>;
  leverage?: InputMaybe<Scalars["MVTParam"]>;
  leverageShort?: InputMaybe<Scalars["MVTParam"]>;
  lookbackDays?: InputMaybe<Scalars["Int"]>;
  maxBuy?: InputMaybe<Scalars["MVTParam"]>;
  minProfit?: InputMaybe<Scalars["MVTParam"]>;
  name?: InputMaybe<Scalars["String"]>;
  performanceMode?: InputMaybe<ExecutionPerformanceMode>;
  scriptRevisionId: Scalars["ID"];
  sellActionTime?: InputMaybe<Scalars["MVTParam"]>;
  startDate: Scalars["Date"];
  stopLossPercentage?: InputMaybe<Scalars["MVTParam"]>;
  stopLossTrailing?: InputMaybe<Scalars["Boolean"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  takeProfitPercentage?: InputMaybe<Scalars["MVTParam"]>;
  takeProfitTrailingPercentage?: InputMaybe<Scalars["MVTParam"]>;
};

export type MultivariantFilter = {
  filterKey: MultivariantColumn;
  filterType: FilterType;
  value: Scalars["String"];
};

export type MultivariantSort = {
  sortDirection: SortDirection;
  sortKey: MultivariantColumn;
};

export type MultivariantValidationResult = {
  __typename?: "MultivariantValidationResult";
  totalCombinationsCount: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  abandonSubscriptionCancellation: Scalars["Boolean"];
  addCompletedTour?: Maybe<AddCompletedTourPayload>;
  addInvoiceLineItem?: Maybe<Scalars["Boolean"]>;
  addSuccessFeePeakValueOverride?: Maybe<Scalars["Boolean"]>;
  addSyndicationUpdate?: Maybe<Scalars["Boolean"]>;
  /** Watchlists */
  addToWatchlist?: Maybe<WatchlistItem>;
  addUserToPaymentProviderAllowList: Scalars["Boolean"];
  addUserToSyndicationAllowList?: Maybe<Scalars["Boolean"]>;
  adminChangeProductBaseCharge?: Maybe<Scalars["Boolean"]>;
  adminChangeSubscriptionPaymentProvider: Scalars["Boolean"];
  /** Only used to switch between free <-> community until payments are implemented */
  adminChangeTier?: Maybe<User>;
  adminCreatePayment: InvoicePayment;
  adminDitchSubscription: Scalars["Boolean"];
  /** Executions - Admin */
  adminReloadExecution?: Maybe<Scalars["Boolean"]>;
  adminRemediateUnrevokedEntitlements?: Maybe<Scalars["Boolean"]>;
  adminRestartSubscriptionAfterNonPayment?: Maybe<Scalars["Boolean"]>;
  adminStopExecutions?: Maybe<AdminStopExecutionsPayload>;
  adminUpdateBotIsBasket?: Maybe<AdminUpdateBotIsBasketPayload>;
  adminUpdateBotVisibility?: Maybe<AdminUpdateBotVisibilityPayload>;
  approveAllInvoices: Scalars["Boolean"];
  approveInvoice: Scalars["Boolean"];
  cancelInvoice: Scalars["Boolean"];
  changeSubscriptionPaymentProvider: Scalars["Boolean"];
  checkCheckoutSessionId: Scalars["Boolean"];
  closeExecutionPosition?: Maybe<Execution>;
  convertToPapertrade?: Maybe<Execution>;
  convertToSyndication?: Maybe<Execution>;
  copyExecutionFromMultivariant?: Maybe<Execution>;
  /** Admin */
  createAnnouncement?: Maybe<Scalars["Boolean"]>;
  /** Billing */
  createBillingUser: Scalars["Boolean"];
  /** Columns Presets */
  createColumnsPreset?: Maybe<ColumnsPreset>;
  /** Credentials */
  createCredential?: Maybe<Credential>;
  /** Executions */
  createExecution?: Maybe<Execution>;
  createExecutionTag?: Maybe<Execution>;
  /** Filter Presets */
  createFilterPreset?: Maybe<FilterPreset>;
  createInvoice: Scalars["ID"];
  createMultivariant?: Maybe<Multivariant>;
  createMultivariantTag?: Maybe<Multivariant>;
  /** Packs */
  createPack?: Maybe<Execution>;
  createPromoterProfile?: Maybe<Promoter>;
  /** Scripts */
  createScript?: Maybe<Script>;
  createScriptTag?: Maybe<Script>;
  /** Shorten Link */
  createShortLink?: Maybe<CreateShortLinkPayload>;
  /** Syndications */
  createSyndicationSubscription?: Maybe<Execution>;
  /** Tags */
  createTag?: Maybe<Tag>;
  deactivateAnnouncement?: Maybe<Scalars["Boolean"]>;
  decreaseExecutionAllocation?: Maybe<Scalars["Boolean"]>;
  deleteColumnsPreset?: Maybe<Scalars["Boolean"]>;
  deleteCredential?: Maybe<Credential>;
  deleteExecution?: Maybe<Scalars["Boolean"]>;
  deleteExecutions?: Maybe<Scalars["Boolean"]>;
  deleteExecutionTag?: Maybe<Execution>;
  deleteFilterPreset?: Maybe<Scalars["Boolean"]>;
  deleteMultivariant?: Maybe<Multivariant>;
  deleteMultivariants: Array<Multivariant>;
  deleteMultivariantTag?: Maybe<Multivariant>;
  deleteScript?: Maybe<Scalars["Boolean"]>;
  deleteScriptTag?: Maybe<Script>;
  deleteSimulation?: Maybe<Scalars["Boolean"]>;
  deleteSimulations?: Maybe<Scalars["Boolean"]>;
  deleteTag?: Maybe<Scalars["Boolean"]>;
  dryRunCreateRenewalInvoice: InvoiceDefinition;
  extendInvoiceDueByTime: Scalars["Boolean"];
  forgiveInvoice: Scalars["Boolean"];
  grantSubscriptionToUser: Scalars["Boolean"];
  increaseExecutionAllocation?: Maybe<Scalars["Boolean"]>;
  incrementScriptTimesCopied?: Maybe<Script>;
  invitedToSyndicationAllowList?: Maybe<Scalars["Boolean"]>;
  loadAllInvoices: Array<Maybe<LoadSuccess>>;
  loadAllProducts: Array<Maybe<LoadSuccess>>;
  loadAllUsers: Array<Maybe<LoadSuccess>>;
  manuallyCompletePayment: Scalars["Boolean"];
  manuallyExpirePayment: Scalars["Boolean"];
  manuallyMarkPaymentRequestAsFailed: Scalars["Boolean"];
  manuallyVoidPayment: Scalars["Boolean"];
  markRefundRequestFailed?: Maybe<Scalars["Boolean"]>;
  markRenewalProcessFailed?: Maybe<Scalars["Boolean"]>;
  markSubscriptionProcessFailed?: Maybe<Scalars["Boolean"]>;
  modifySyndicationUpdate?: Maybe<Scalars["Boolean"]>;
  modifySyndicationUpdateAudience?: Maybe<Scalars["Boolean"]>;
  overrideSubscriptionRenewalTerminalFailure?: Maybe<Scalars["Boolean"]>;
  payRemainingInvoiceBalance: PayRemainingInvoiceBalanceResult;
  pinSyndicationUpdate?: Maybe<Scalars["Boolean"]>;
  refreshCredentialAssets?: Maybe<Credential>;
  refundPayment: Scalars["Boolean"];
  rejectAllInvoices: Scalars["Boolean"];
  rejectInvoice: Scalars["Boolean"];
  removeFromWatchlist?: Maybe<WatchlistItem>;
  removeInvoiceLineItem?: Maybe<Scalars["Boolean"]>;
  removeSyndicationUpdate?: Maybe<Scalars["Boolean"]>;
  removeUserFromPaymentProviderAllowList: Scalars["Boolean"];
  removeUserFromSyndicationAllowList?: Maybe<Scalars["Boolean"]>;
  renameColumnsPreset?: Maybe<ColumnsPreset>;
  renameFilterPreset?: Maybe<FilterPreset>;
  renameScript?: Maybe<Script>;
  restartExecution?: Maybe<Execution>;
  resumeMultivariant?: Maybe<Multivariant>;
  reviewDirectPayment: Scalars["Boolean"];
  saveUserPreferences: UserOnboardingPreferences;
  setInvoiceExpirationTime: Scalars["Boolean"];
  stopExecution?: Maybe<Execution>;
  stopExecutions?: Maybe<StopExecutionsPayload>;
  /** Multivariants */
  stopMultivariant?: Maybe<Multivariant>;
  subscribeToSyndication?: Maybe<SubscribeToSyndicationResult>;
  tempChangeTier: User;
  tempTokenizeCreditCard: PaymentSource;
  tokenizeCheckoutToken: PaymentSource;
  unarchiveExecution?: Maybe<Scalars["Boolean"]>;
  unarchiveExecutions?: Maybe<Scalars["Boolean"]>;
  unfinalizePayment?: Maybe<Scalars["Boolean"]>;
  unsubscribeFromSyndication?: Maybe<BillingSubscription>;
  updateColumnsPreset?: Maybe<ColumnsPreset>;
  updateExecutionFavorite?: Maybe<Execution>;
  updateExecutionNotes?: Maybe<Execution>;
  updateExecutionSharing?: Maybe<Execution>;
  updateExecutionStage?: Maybe<Execution>;
  updateFilterPreset?: Maybe<FilterPreset>;
  updateMultivariantFavorite?: Maybe<Multivariant>;
  updateScript?: Maybe<Script>;
  updateScriptFavorite?: Maybe<Script>;
  updateScriptNotes?: Maybe<Script>;
  updateScriptPublicStatus?: Maybe<Script>;
  updateSyndicationAccessControl?: Maybe<Execution>;
  updateSyndicationSettings?: Maybe<Execution>;
  /** @deprecated replaced by updateSyndicationAccessControl */
  updateSyndicationSettingsVisibility?: Maybe<Execution>;
  updateSyndicationSharing?: Maybe<Execution>;
  updateTag?: Maybe<Tag>;
  /** Deprecated */
  updateUserGeneralInfo: User;
  updateUserLanguage?: Maybe<UpdateUserLanguagePayload>;
  updateUserMailType: User;
  updateUserWelcomed: User;
  updateWatchlistItem?: Maybe<WatchlistItem>;
  validateMultivariant?: Maybe<MultivariantValidationResult>;
  welcomedUser: User;
};

export type MutationAbandonSubscriptionCancellationArgs = {
  subscriptionId: Scalars["ID"];
};

export type MutationAddCompletedTourArgs = {
  in: AddCompletedTourInput;
};

export type MutationAddInvoiceLineItemArgs = {
  invoiceId: Scalars["ID"];
  item: InvoiceItemInput;
};

export type MutationAddSuccessFeePeakValueOverrideArgs = {
  executionId: Scalars["ID"];
  value: Scalars["Decimal"];
};

export type MutationAddSyndicationUpdateArgs = {
  audience: SyndicationUpdateAudience;
  id: Scalars["ID"];
  update: Scalars["String"];
};

export type MutationAddToWatchlistArgs = {
  fields?: InputMaybe<WatchlistAssetFields>;
  id: Scalars["ID"];
  type: WatchlistType;
};

export type MutationAddUserToPaymentProviderAllowListArgs = {
  paymentProvider: PaymentProvider;
  userId: Scalars["ID"];
};

export type MutationAddUserToSyndicationAllowListArgs = {
  id: Scalars["ID"];
  userNickname: Scalars["String"];
};

export type MutationAdminChangeProductBaseChargeArgs = {
  in: ChangeProductBaseCharge;
};

export type MutationAdminChangeSubscriptionPaymentProviderArgs = {
  paymentProvider: PaymentProvider;
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAdminChangeTierArgs = {
  newTier: UserTier;
  userId: Scalars["ID"];
};

export type MutationAdminCreatePaymentArgs = {
  invoiceId: Scalars["ID"];
  paymentProvider: PaymentProvider;
};

export type MutationAdminDitchSubscriptionArgs = {
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAdminReloadExecutionArgs = {
  id: Scalars["ID"];
};

export type MutationAdminRemediateUnrevokedEntitlementsArgs = {
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAdminRestartSubscriptionAfterNonPaymentArgs = {
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAdminStopExecutionsArgs = {
  in: AdminStopExecutionsInput;
};

export type MutationAdminUpdateBotIsBasketArgs = {
  in: AdminUpdateBotIsBasketInput;
};

export type MutationAdminUpdateBotVisibilityArgs = {
  in: AdminUpdateBotVisibilityInput;
};

export type MutationApproveInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationCancelInvoiceArgs = {
  invoiceId: Scalars["ID"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type MutationChangeSubscriptionPaymentProviderArgs = {
  paymentProvider: PaymentProvider;
  subscriptionId: Scalars["ID"];
};

export type MutationCheckCheckoutSessionIdArgs = {
  sessionId: Scalars["ID"];
};

export type MutationCloseExecutionPositionArgs = {
  id: Scalars["ID"];
};

export type MutationConvertToPapertradeArgs = {
  syndicationId: Scalars["ID"];
};

export type MutationConvertToSyndicationArgs = {
  in?: InputMaybe<SyndicationCreation>;
};

export type MutationCopyExecutionFromMultivariantArgs = {
  id: Scalars["ID"];
};

export type MutationCreateAnnouncementArgs = {
  in: AnnouncementCreation;
};

export type MutationCreateBillingUserArgs = {
  userId: Scalars["ID"];
};

export type MutationCreateColumnsPresetArgs = {
  columns?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateCredentialArgs = {
  in?: InputMaybe<CredentialCreation>;
};

export type MutationCreateExecutionArgs = {
  in?: InputMaybe<ExecutionCreation>;
};

export type MutationCreateExecutionTagArgs = {
  executionId: Scalars["ID"];
  tagId: Scalars["ID"];
};

export type MutationCreateFilterPresetArgs = {
  filters?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateInvoiceArgs = {
  in: InvoiceCreation;
};

export type MutationCreateMultivariantArgs = {
  in?: InputMaybe<MultivariantCreation>;
};

export type MutationCreateMultivariantTagArgs = {
  multivariantId: Scalars["ID"];
  tagId: Scalars["ID"];
};

export type MutationCreatePackArgs = {
  in?: InputMaybe<PackCreation>;
};

export type MutationCreatePromoterProfileArgs = {
  refId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateScriptArgs = {
  in?: InputMaybe<ScriptCreation>;
};

export type MutationCreateScriptTagArgs = {
  scriptId: Scalars["ID"];
  tagId: Scalars["ID"];
};

export type MutationCreateShortLinkArgs = {
  link: CreateShortLinkInput;
};

export type MutationCreateSyndicationSubscriptionArgs = {
  in?: InputMaybe<SyndicationSubscriptionCreation>;
};

export type MutationCreateTagArgs = {
  color?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationDecreaseExecutionAllocationArgs = {
  in: DecreaseExecutionAllocationInput;
};

export type MutationDeleteColumnsPresetArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCredentialArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteExecutionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteExecutionsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type MutationDeleteExecutionTagArgs = {
  executionId: Scalars["ID"];
  tagId: Scalars["ID"];
};

export type MutationDeleteFilterPresetArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteMultivariantArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteMultivariantsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type MutationDeleteMultivariantTagArgs = {
  multivariantId: Scalars["ID"];
  tagId: Scalars["ID"];
};

export type MutationDeleteScriptArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteScriptTagArgs = {
  scriptId: Scalars["ID"];
  tagId: Scalars["ID"];
};

export type MutationDeleteSimulationArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteSimulationsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type MutationDeleteTagArgs = {
  id: Scalars["ID"];
};

export type MutationDryRunCreateRenewalInvoiceArgs = {
  cancellation?: Scalars["Boolean"];
  cycleSequentialId?: InputMaybe<Scalars["Int"]>;
  subscriptionId: Scalars["ID"];
};

export type MutationExtendInvoiceDueByTimeArgs = {
  extension: Scalars["String"];
  invoiceId: Scalars["ID"];
};

export type MutationForgiveInvoiceArgs = {
  invoiceId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationGrantSubscriptionToUserArgs = {
  in: GrantSubscriptionToUser;
};

export type MutationIncreaseExecutionAllocationArgs = {
  in: IncreaseExecutionAllocationInput;
};

export type MutationIncrementScriptTimesCopiedArgs = {
  id: Scalars["ID"];
};

export type MutationInvitedToSyndicationAllowListArgs = {
  shareToken: Scalars["ID"];
};

export type MutationManuallyCompletePaymentArgs = {
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationManuallyExpirePaymentArgs = {
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
};

export type MutationManuallyMarkPaymentRequestAsFailedArgs = {
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
};

export type MutationManuallyVoidPaymentArgs = {
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationMarkRefundRequestFailedArgs = {
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
  refundId: Scalars["ID"];
};

export type MutationMarkRenewalProcessFailedArgs = {
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationMarkSubscriptionProcessFailedArgs = {
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationModifySyndicationUpdateArgs = {
  id: Scalars["ID"];
  update?: InputMaybe<Scalars["String"]>;
  updateId: Scalars["ID"];
};

export type MutationModifySyndicationUpdateAudienceArgs = {
  audience: SyndicationUpdateAudience;
  id: Scalars["ID"];
  updateId: Scalars["ID"];
};

export type MutationOverrideSubscriptionRenewalTerminalFailureArgs = {
  subscriptionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationPayRemainingInvoiceBalanceArgs = {
  invoiceId: Scalars["ID"];
  paymentProvider: PaymentProvider;
};

export type MutationPinSyndicationUpdateArgs = {
  id: Scalars["ID"];
  placement: SyndicationUpdatePlacement;
  updateId: Scalars["ID"];
};

export type MutationRefreshCredentialAssetsArgs = {
  id: Scalars["ID"];
};

export type MutationRefundPaymentArgs = {
  amount?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationRejectAllInvoicesArgs = {
  reason: Scalars["String"];
};

export type MutationRejectInvoiceArgs = {
  invoiceId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationRemoveFromWatchlistArgs = {
  id: Scalars["ID"];
  type: WatchlistType;
};

export type MutationRemoveInvoiceLineItemArgs = {
  invoiceId: Scalars["ID"];
  itemId: Scalars["ID"];
};

export type MutationRemoveSyndicationUpdateArgs = {
  id: Scalars["ID"];
  updateId: Scalars["ID"];
};

export type MutationRemoveUserFromPaymentProviderAllowListArgs = {
  paymentProvider: PaymentProvider;
  userId: Scalars["ID"];
};

export type MutationRemoveUserFromSyndicationAllowListArgs = {
  id: Scalars["ID"];
  userNickname: Scalars["String"];
};

export type MutationRenameColumnsPresetArgs = {
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationRenameFilterPresetArgs = {
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationRenameScriptArgs = {
  in?: InputMaybe<ScriptRename>;
};

export type MutationRestartExecutionArgs = {
  id: Scalars["ID"];
};

export type MutationResumeMultivariantArgs = {
  id: Scalars["ID"];
};

export type MutationReviewDirectPaymentArgs = {
  paymentId: Scalars["ID"];
  review: DirectPaymentReviewInput;
};

export type MutationSaveUserPreferencesArgs = {
  preferences?: InputMaybe<UserOnboardingPreferencesInput>;
};

export type MutationSetInvoiceExpirationTimeArgs = {
  expirationTime?: InputMaybe<Scalars["DateTime"]>;
  invoiceId: Scalars["ID"];
};

export type MutationStopExecutionArgs = {
  id: Scalars["ID"];
  stopStatus?: InputMaybe<ExecutionStatus>;
};

export type MutationStopExecutionsArgs = {
  in: StopExecutionsInput;
};

export type MutationStopMultivariantArgs = {
  id: Scalars["ID"];
};

export type MutationSubscribeToSyndicationArgs = {
  id: Scalars["ID"];
  paymentProvider?: InputMaybe<PaymentProvider>;
};

export type MutationTempChangeTierArgs = {
  newTier: UserTier;
};

export type MutationTempTokenizeCreditCardArgs = {
  ccData: CreditCardData;
};

export type MutationTokenizeCheckoutTokenArgs = {
  token: Scalars["String"];
};

export type MutationUnarchiveExecutionArgs = {
  id: Scalars["ID"];
};

export type MutationUnarchiveExecutionsArgs = {
  id: Scalars["ID"];
};

export type MutationUnfinalizePaymentArgs = {
  invoiceId: Scalars["ID"];
  paymentId: Scalars["ID"];
};

export type MutationUnsubscribeFromSyndicationArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateColumnsPresetArgs = {
  columns?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type MutationUpdateExecutionFavoriteArgs = {
  favorite?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationUpdateExecutionNotesArgs = {
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateExecutionSharingArgs = {
  id: Scalars["ID"];
  share?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateExecutionStageArgs = {
  id: Scalars["ID"];
  stage?: InputMaybe<ExecutionStage>;
};

export type MutationUpdateFilterPresetArgs = {
  filters?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type MutationUpdateMultivariantFavoriteArgs = {
  favorite?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationUpdateScriptArgs = {
  in?: InputMaybe<ScriptUpdate>;
};

export type MutationUpdateScriptFavoriteArgs = {
  favorite?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationUpdateScriptNotesArgs = {
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateScriptPublicStatusArgs = {
  id: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateSyndicationAccessControlArgs = {
  accessControl: SyndicationAccessControl;
  id: Scalars["ID"];
};

export type MutationUpdateSyndicationSettingsArgs = {
  in?: InputMaybe<SyndicationCreation>;
};

export type MutationUpdateSyndicationSettingsVisibilityArgs = {
  id: Scalars["ID"];
  isVisible: Scalars["Boolean"];
};

export type MutationUpdateSyndicationSharingArgs = {
  id: Scalars["ID"];
  share: Scalars["Boolean"];
};

export type MutationUpdateTagArgs = {
  color?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateUserGeneralInfoArgs = {
  in?: InputMaybe<UserGeneralInfo>;
};

export type MutationUpdateUserLanguageArgs = {
  in: UpdateUserLanguageInput;
};

export type MutationUpdateUserMailTypeArgs = {
  preferredUserType: UserType;
};

export type MutationUpdateWatchlistItemArgs = {
  id: Scalars["ID"];
  rank: Scalars["Int"];
  type: WatchlistType;
};

export type MutationValidateMultivariantArgs = {
  in?: InputMaybe<MultivariantCreation>;
};

export type MutationWelcomedUserArgs = {
  preferredUserType: UserType;
};

export type NashLinkPaymentMetadata = PaymentMetadata & {
  __typename?: "NashLinkPaymentMetadata";
  paymentUrl: Scalars["String"];
  type: Scalars["String"];
};

/** An order placed as part of an execution */
export type Order = {
  __typename?: "Order";
  /** The total commission paid for the order (in BASE if a BUY, QUOTE if a SELL) */
  commissionPaid: Scalars["Decimal"];
  /** To make orders not rely on the exchange for the currency pair details (so multi-coin packs do not show the wrong details) */
  currencyPairDetails?: Maybe<CurrencyPairDetails>;
  /** The "effective" amount of the order placed after commission */
  effectiveAmount: Scalars["Decimal"];
  /** The "effective" price paid per BASE coin after commission */
  effectivePrice: Scalars["Decimal"];
  /** The "effective" value of the order after commission */
  effectiveValue: Scalars["Decimal"];
  /** Identifier referring to the order on the exchange */
  externalId?: Maybe<Scalars["ID"]>;
  /** The amount of this order that was filled by the exchange (in the pair's BASE currency) */
  filledAmount: Scalars["Decimal"];
  /** The price paid on the exchange in QUOTE per BASE coin */
  filledPrice: Scalars["Decimal"];
  /** The time this order was filled (or most recently filled if multiple fills) */
  filledTime?: Maybe<Scalars["DateTime"]>;
  /** The value of the order in QUOTE at the time of fill */
  filledValue: Scalars["Decimal"];
  /** Limit price in case this was a limit order */
  limitPrice?: Maybe<Scalars["Decimal"]>;
  /** The amount this order was placed for (in the pair's BASE currency) */
  placedAmount: Scalars["Decimal"];
  /** The time this order was placed */
  placedTime: Scalars["DateTime"];
  /** The side of this order (eg. buy or sell) */
  side: OrderSide;
  /** What triggered this order (eg. a signal, a stoploss trigger) */
  source: OrderSource;
  /** The status of this order (eg. open, filled, cancelled) */
  status: OrderStatus;
};

export type OrderConnection = {
  __typename?: "OrderConnection";
  edges?: Maybe<Array<Maybe<OrderConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrderConnectionEdge = {
  __typename?: "OrderConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Order>;
};

export type OrderRules = {
  __typename?: "OrderRules";
  maxOrderAmount: Scalars["Decimal"];
  minOrderAmount: Scalars["Decimal"];
  minOrderValue: Scalars["Decimal"];
};

export enum OrderSide {
  Buy = "BUY",
  Sell = "SELL",
}

export enum OrderSource {
  Liquidate = "LIQUIDATE",
  Manual = "MANUAL",
  Revert = "REVERT",
  Signal = "SIGNAL",
  SignalRetried = "SIGNAL_RETRIED",
  Stoploss = "STOPLOSS",
  StoplossAbs = "STOPLOSS_ABS",
  TakeProfit = "TAKE_PROFIT",
  TakeProfitAbs = "TAKE_PROFIT_ABS",
  TrailingStoploss = "TRAILING_STOPLOSS",
  TrailingTakeProfit = "TRAILING_TAKE_PROFIT",
  TrailingTakeProfitAbs = "TRAILING_TAKE_PROFIT_ABS",
}

export enum OrderStatus {
  Cancelled = "CANCELLED",
  Filled = "FILLED",
  Open = "OPEN",
  PartiallyFilled = "PARTIALLY_FILLED",
  Rejected = "REJECTED",
  Reverted = "REVERTED",
  Skipped = "SKIPPED",
  Unsubmitted = "UNSUBMITTED",
}

export enum OrderType {
  Limit = "LIMIT",
  Market = "MARKET",
}

export type PackCreation = {
  autoRebalance?: InputMaybe<Scalars["Boolean"]>;
  backtestEndDate?: InputMaybe<Scalars["Date"]>;
  backtestStartDate?: InputMaybe<Scalars["Date"]>;
  credentialId?: InputMaybe<Scalars["ID"]>;
  currencyPair: CurrencyPair;
  exchange: Exchange;
  executions?: InputMaybe<Array<InputMaybe<PackExecution>>>;
  initialCapital: Scalars["Decimal"];
  multiCoinCurrency?: InputMaybe<Currency>;
  name: Scalars["String"];
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type: ExecutionType;
};

export type PackExecution = {
  id: Scalars["ID"];
  percentage: Scalars["Decimal"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

/** GraphQL Does not support input unions */
export type PageQuery = {
  fromId?: InputMaybe<Scalars["ID"]>;
  numberOfResults: Scalars["Int"];
};

export type PaymentMetadata = {
  type: Scalars["String"];
};

export enum PaymentProvider {
  CheckoutCom = "CHECKOUT_COM",
  CheckoutComAlwaysDeny = "CHECKOUT_COM_ALWAYS_DENY",
  CheckoutComInvalidPayment = "CHECKOUT_COM_INVALID_PAYMENT",
  DirectPayment = "DIRECT_PAYMENT",
  DummyAlwaysFails = "DUMMY_ALWAYS_FAILS",
  DummyPaymentDenied = "DUMMY_PAYMENT_DENIED",
  Nashlink = "NASHLINK",
}

export type PaymentSource = {
  __typename?: "PaymentSource";
  id: Scalars["ID"];
  meta?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  status: PaymentSourceStatus;
  type: PaymentSourceType;
};

export enum PaymentSourceStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Failed = "FAILED",
}

export enum PaymentSourceType {
  CreditCard = "CREDIT_CARD",
}

export type PayRemainingInvoiceBalanceResult = {
  __typename?: "PayRemainingInvoiceBalanceResult";
  payment: InvoicePayment;
};

export enum Permission {
  AdminActions = "ADMIN_ACTIONS",
  BulkDataExport = "BULK_DATA_EXPORT",
  PrivilegedGroovyScripts = "PRIVILEGED_GROOVY_SCRIPTS",
  RunBacktest = "RUN_BACKTEST",
  RunBatchTest = "RUN_BATCH_TEST",
  RunLive = "RUN_LIVE",
  RunPaperTrade = "RUN_PAPER_TRADE",
  ShareLinks = "SHARE_LINKS",
  StrategyLicensing = "STRATEGY_LICENSING",
}

export type PhoneNumber = {
  countryCode: Scalars["String"];
  number: Scalars["String"];
};

export type PlotArrowInfo = {
  __typename?: "PlotArrowInfo";
  colorDown: ColorInput;
  colorUp: ColorInput;
  editable: Scalars["Boolean"];
  id: Scalars["ID"];
  maxHeight: Scalars["Int"];
  minHeight: Scalars["Int"];
  offset: Scalars["Int"];
  title: Scalars["String"];
  transparency: Scalars["Int"];
};

export type PlotCharInfo = {
  __typename?: "PlotCharInfo";
  char: Scalars["String"];
  color: ColorInput;
  editable: Scalars["Boolean"];
  id: Scalars["ID"];
  location: ShapePosition;
  offset: Scalars["Int"];
  size: ShapeSize;
  text?: Maybe<Scalars["String"]>;
  textColor?: Maybe<ColorInput>;
  title: Scalars["String"];
  transparency: Scalars["Int"];
};

export type PlotInfo = {
  __typename?: "PlotInfo";
  color: ColorInput;
  editable: Scalars["Boolean"];
  histBase: Scalars["Float"];
  id: Scalars["ID"];
  join: Scalars["Boolean"];
  lineStyle: PlotStyle;
  lineWidth: Scalars["Int"];
  offset: Scalars["Int"];
  title: Scalars["String"];
  trackPrice: Scalars["Boolean"];
  transparency: Scalars["Int"];
};

export type PlotShapeInfo = {
  __typename?: "PlotShapeInfo";
  color: ColorInput;
  editable: Scalars["Boolean"];
  id: Scalars["ID"];
  location: ShapePosition;
  offset: Scalars["Int"];
  size: ShapeSize;
  style: ShapeStyle;
  text?: Maybe<Scalars["String"]>;
  textColor?: Maybe<ColorInput>;
  title: Scalars["String"];
  transparency: Scalars["Int"];
};

export enum PlotStyle {
  Area = "AREA",
  Areabr = "AREABR",
  Circles = "CIRCLES",
  Columns = "COLUMNS",
  Cross = "CROSS",
  Histogram = "HISTOGRAM",
  Line = "LINE",
  Linebr = "LINEBR",
  Stepline = "STEPLINE",
}

export type PlotTick = {
  __typename?: "PlotTick";
  plotId: Scalars["String"];
  time: Scalars["Instant"];
  value: Scalars["String"];
};

export enum PlotType {
  Arrow = "ARROW",
  Bar = "BAR",
  Barcolor = "BARCOLOR",
  Bgcolor = "BGCOLOR",
  Candle = "CANDLE",
  Char = "CHAR",
  Fill = "FILL",
  Hline = "HLINE",
  Plot = "PLOT",
  Shape = "SHAPE",
}

export type Portfolio = {
  __typename?: "Portfolio";
  currency?: Maybe<PortfolioCurrency>;
  currentRealizedProfits?: Maybe<ExecutionRealizedProfits>;
  initialAllocation?: Maybe<Scalars["Decimal"]>;
  totalBalance?: Maybe<Scalars["Decimal"]>;
  totalInPosition?: Maybe<Scalars["Decimal"]>;
  totalInvested?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfit?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitPerc?: Maybe<Scalars["Decimal"]>;
  totalUnrealizedProfit?: Maybe<Scalars["Decimal"]>;
  totalUnrealizedProfitPerc?: Maybe<Scalars["Decimal"]>;
};

export enum PortfolioCurrency {
  Btc = "BTC",
  Usd = "USD",
}

export type PortfolioDailyLog = {
  __typename?: "PortfolioDailyLog";
  btcValue?: Maybe<Scalars["Decimal"]>;
  id: Scalars["String"];
  logDate?: Maybe<Scalars["Date"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usdValue?: Maybe<Scalars["Decimal"]>;
};

export type Position = {
  __typename?: "Position";
  char: Scalars["Int"];
  line: Scalars["Int"];
};

export enum PreloadedScript {
  GridBotLong = "GRID_BOT_LONG",
  GridBotLongAndShort = "GRID_BOT_LONG_AND_SHORT",
  GridBotShort = "GRID_BOT_SHORT",
  GridBotSpot = "GRID_BOT_SPOT",
  PriceBasedDca = "PRICE_BASED_DCA",
  SetupLong = "SETUP_LONG",
  SetupShort = "SETUP_SHORT",
  SetupSpot = "SETUP_SPOT",
  TemaDema = "TEMA_DEMA",
}

export type PresignedUrl = {
  __typename?: "PresignedUrl";
  key: Scalars["String"];
  url: Scalars["String"];
};

export type Product = {
  __typename?: "Product";
  currency: Scalars["String"];
  entitlements?: Maybe<Array<Entitlement>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  price: Scalars["Decimal"];
};

export type ProductSubscriptionEvent = {
  __typename?: "ProductSubscriptionEvent";
  status: SubscriptionStatus;
  subscriptionId: Scalars["ID"];
};

export type Promoter = {
  __typename?: "Promoter";
  currentBalance?: Maybe<PromoterBalance>;
  custId?: Maybe<Scalars["String"]>;
  defaultRefId?: Maybe<Scalars["String"]>;
  earningBalance?: Maybe<PromoterBalance>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  leads?: Maybe<Array<ReferralLead>>;
  note?: Maybe<Scalars["String"]>;
  paidBalance?: Maybe<PromoterBalance>;
  profile?: Maybe<PromoterProfile>;
  promotions?: Maybe<Array<Promotion>>;
};

export type PromoterBalance = {
  __typename?: "PromoterBalance";
  cash?: Maybe<Scalars["Int"]>;
  points?: Maybe<Scalars["Int"]>;
};

export type PromoterProfile = {
  __typename?: "PromoterProfile";
  description?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  lastName?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type PromoterReward = {
  __typename?: "PromoterReward";
  amount?: Maybe<Scalars["Int"]>;
  defaultPromoCode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Promotion = {
  __typename?: "Promotion";
  campaignId?: Maybe<Scalars["Int"]>;
  cancellationsCount?: Maybe<Scalars["Int"]>;
  currentPromotionReward?: Maybe<PromoterReward>;
  currentReferralReward?: Maybe<PromoterReward>;
  currentTargetReward?: Maybe<PromoterReward>;
  customersCount?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  leadsCount?: Maybe<Scalars["Int"]>;
  promoCode?: Maybe<Scalars["String"]>;
  promoterId?: Maybe<Scalars["Int"]>;
  referralLink?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  refundsCount?: Maybe<Scalars["Int"]>;
  refundsTotal?: Maybe<Scalars["Int"]>;
  salesCount?: Maybe<Scalars["Int"]>;
  salesTotal?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  visitorsCount?: Maybe<Scalars["Int"]>;
};

export type PublicScriptFilters = {
  author?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<ScriptLanguage>;
  name?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type PublicSyndication = {
  __typename?: "PublicSyndication";
  analysis?: Maybe<Analysis>;
  /** @deprecated replaced by analysis */
  analysisWithRange?: Maybe<Analysis>;
  autoRebalance?: Maybe<Scalars["Boolean"]>;
  backtestAnalysis?: Maybe<Analysis>;
  backtestMeasurements?: Maybe<SyndicationMeasurements>;
  backtestShareToken?: Maybe<Scalars["ID"]>;
  candleSize: Scalars["Resolution"];
  /** @deprecated replaced by trades */
  closedTrades?: Maybe<TradeConnection>;
  currencyPair?: Maybe<CurrencyPair>;
  currencyPairDetails?: Maybe<CurrencyPairDetails>;
  currentSubscriberRealizedProfits?: Maybe<ExecutionRealizedProfits>;
  exchange: Exchange;
  id: Scalars["ID"];
  isBasket?: Maybe<Scalars["Boolean"]>;
  leverageInfo?: Maybe<LeverageInfo>;
  measurements?: Maybe<SyndicationMeasurements>;
  multiCoinCurrency?: Maybe<Currency>;
  multiCoinPackExecutions?: Maybe<
    Array<Maybe<MultiCoinPackCurrencyPercentage>>
  >;
  name?: Maybe<Scalars["String"]>;
  owner: PublicUser;
  /**
   * TODO: Remove
   * @deprecated No longer supported
   */
  ownerNickname?: Maybe<Scalars["String"]>;
  performance?: Maybe<Scalars["Decimal"]>;
  pinnedUpdate?: Maybe<SyndicationUpdate>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  subExecutionsCount?: Maybe<Scalars["Int"]>;
  subscriberCount?: Maybe<Scalars["Int"]>;
  subscriberRealizedProfitsLog?: Maybe<Array<Maybe<RealizedProfitsLog>>>;
  /** New */
  subscription?: Maybe<BillingSubscription>;
  /** @deprecated No longer supported */
  subscriptionStatus: SyndicationSubscriptionStatus;
  syndicationSettings?: Maybe<SyndicationSettings>;
  timesWatchlisted?: Maybe<Scalars["Int"]>;
  totalValueUsd?: Maybe<Scalars["Decimal"]>;
  trades?: Maybe<TradeConnection>;
  updates?: Maybe<SyndicationUpdateConnection>;
};

export type PublicSyndicationAnalysisArgs = {
  rangeDays?: InputMaybe<Scalars["Int"]>;
};

export type PublicSyndicationAnalysisWithRangeArgs = {
  rangeDays?: InputMaybe<Scalars["Int"]>;
};

export type PublicSyndicationClosedTradesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type PublicSyndicationPerformanceArgs = {
  rangeDays?: InputMaybe<Scalars["Int"]>;
};

export type PublicSyndicationTradesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type PublicSyndicationUpdatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type PublicSyndicationConnection = {
  __typename?: "PublicSyndicationConnection";
  edges?: Maybe<Array<Maybe<PublicSyndicationConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PublicSyndicationConnectionEdge = {
  __typename?: "PublicSyndicationConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<PublicSyndication>;
};

export type PublicUser = {
  __typename?: "PublicUser";
  activeSubscribersCount?: Maybe<Scalars["Int"]>;
  /** maybe use date of first papertrade / live run instead? */
  companyName?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discordUrl?: Maybe<Scalars["String"]>;
  exchanges?: Maybe<Array<Maybe<Exchange>>>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  linkedinUrl?: Maybe<Scalars["String"]>;
  mediumUrl?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  personalBlogUrl?: Maybe<Scalars["String"]>;
  personalWebsiteUrl?: Maybe<Scalars["String"]>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
  profitableSyndicationsCount?: Maybe<Scalars["Int"]>;
  scripts?: Maybe<Array<Maybe<Script>>>;
  scriptsCount?: Maybe<Scalars["Int"]>;
  subscriberCount?: Maybe<Scalars["Int"]>;
  syndications?: Maybe<Array<Maybe<PublicSyndication>>>;
  syndicationsCount?: Maybe<ResultSize>;
  telegramUrl?: Maybe<Scalars["String"]>;
  timesWatchlisted?: Maybe<Scalars["Int"]>;
  totalAllocated?: Maybe<Scalars["Decimal"]>;
  totalSyndicationsTrades?: Maybe<Scalars["Int"]>;
  totalValueUsd?: Maybe<Scalars["Decimal"]>;
  tradingSince: Scalars["DateTime"];
  twitterUrl?: Maybe<Scalars["String"]>;
  unprofitableSyndicationsCount?: Maybe<Scalars["Int"]>;
};

export type PublicUserScriptsArgs = {
  query: PageQuery;
};

export type PublicUserScriptsCountArgs = {
  filters?: InputMaybe<PublicUserScriptsFilters>;
};

export type PublicUserSyndicationsArgs = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type PublicUserSyndicationsCountArgs = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
};

export type PublicUserConnection = {
  __typename?: "PublicUserConnection";
  edges?: Maybe<Array<Maybe<PublicUserConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PublicUserConnectionEdge = {
  __typename?: "PublicUserConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<PublicUser>;
};

export type PublicUserScriptsFilters = {
  language?: InputMaybe<ScriptLanguage>;
};

export type QuantityInput = {
  amount: Scalars["Decimal"];
  unit: Scalars["String"];
};

export type Range = {
  __typename?: "Range";
  end: Position;
  start: Position;
};

export type RateAmountInput = {
  quantity: Scalars["Decimal"];
  rate: RateInput;
};

export type RateInput = {
  price: Scalars["Decimal"];
  quantity: QuantityInput;
};

export type RealizedProfitsLog = {
  __typename?: "RealizedProfitsLog";
  assetsUnderManagement?: Maybe<Scalars["Decimal"]>;
  logDate?: Maybe<Scalars["Date"]>;
  totalAllocation?: Maybe<Scalars["Decimal"]>;
  totalAllocationCarryForward?: Maybe<Scalars["Decimal"]>;
  totalAllocationInDollars?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitability?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitabilityCarryForward?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfits?: Maybe<Scalars["Decimal"]>;
  totalRealizedProfitsCarryForward?: Maybe<Scalars["Decimal"]>;
};

export type RecaptchaInfo = {
  __typename?: "RecaptchaInfo";
  challengeTimestamp?: Maybe<Scalars["DateTime"]>;
  hostname?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ReferralLead = {
  __typename?: "ReferralLead";
  customerSince?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  planName?: Maybe<Scalars["String"]>;
  registrationDate?: Maybe<Scalars["DateTime"]>;
  state?: Maybe<Scalars["String"]>;
  suspicion?: Maybe<Scalars["String"]>;
};

export type ResultSize = {
  __typename?: "ResultSize";
  count?: Maybe<Scalars["Int"]>;
};

export type RootQuery = {
  __typename?: "RootQuery";
  adminGetBot?: Maybe<PublicSyndication>;
  adminGetUser: User;
  allInvoices?: Maybe<Array<Invoice>>;
  allSubscriptions?: Maybe<BillingSubscriptionConnection>;
  /** Announcements */
  announcement?: Maybe<Announcement>;
  appInfo: ApplicationInfo;
  billingPaymentProviderAllowList?: Maybe<BillingPaymentProviderAllowListConnection>;
  columnsPresets?: Maybe<Array<Maybe<ColumnsPreset>>>;
  creators?: Maybe<PublicUserConnection>;
  creatorsCount?: Maybe<Scalars["Int"]>;
  credentials?: Maybe<Array<Maybe<Credential>>>;
  directPaymentRequests?: Maybe<DirectPaymentConnection>;
  exchange?: Maybe<ExchangeDetails>;
  /** Exchange */
  exchanges?: Maybe<Array<Maybe<ExchangeDetails>>>;
  /** Executions */
  execution?: Maybe<Execution>;
  executions?: Maybe<Array<Maybe<Execution>>>;
  executionsCount?: Maybe<ResultSize>;
  failedExecutions?: Maybe<ExecutionConnection>;
  /** User Assets */
  filterPresets?: Maybe<Array<Maybe<FilterPreset>>>;
  gettingStarted?: Maybe<GettingStarted>;
  invoices?: Maybe<Array<Invoice>>;
  /** User Info */
  me: User;
  mobileAppMetadata?: Maybe<MobileAppMetadata>;
  mostPopularCreators?: Maybe<PublicUserConnection>;
  mostPopularSyndications?: Maybe<PublicSyndicationConnection>;
  /** Batches */
  multivariant?: Maybe<Multivariant>;
  /** @deprecated replaced by User.batchTests */
  multivariants?: Maybe<Array<Maybe<Multivariant>>>;
  /** @deprecated replaced by User.batchTestsCount */
  multivariantsCount?: Maybe<ResultSize>;
  packExecutions?: Maybe<Array<Maybe<Execution>>>;
  /** Billing */
  paymentSource?: Maybe<PaymentSource>;
  portfolioDailyLog?: Maybe<Array<Maybe<PortfolioDailyLog>>>;
  presignedUploadUrl?: Maybe<PresignedUrl>;
  privateSyndication?: Maybe<PublicSyndication>;
  publicBots?: Maybe<PublicSyndicationConnection>;
  publicBotsCount?: Maybe<Scalars["Int"]>;
  publicProfile?: Maybe<PublicUser>;
  /** @deprecated replaced by creators */
  publicProfiles?: Maybe<Array<Maybe<PublicUser>>>;
  /** @deprecated replaced by creatorsCount */
  publicProfilesCount?: Maybe<Scalars["Int"]>;
  publicScriptList?: Maybe<ScriptsConnection>;
  publicScriptListCount?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced by publicScriptList */
  publicScripts?: Maybe<Array<Maybe<Script>>>;
  /** @deprecated replaced by publicScriptsListCount */
  publicScriptsCount?: Maybe<Scalars["Int"]>;
  publicSyndication?: Maybe<PublicSyndication>;
  /** @deprecated replaced by publicSyndicationBots */
  publicSyndications?: Maybe<Array<Maybe<PublicSyndication>>>;
  /** @deprecated replaced by publicSyndicationBotsCount */
  publicSyndicationsCount?: Maybe<ResultSize>;
  /** Public / Profiles */
  publicUser?: Maybe<PublicUser>;
  script?: Maybe<Script>;
  /**
   * Scripts
   * @deprecated replaced by User.scripts
   */
  scripts?: Maybe<Array<Maybe<Script>>>;
  /** @deprecated replaced by User.scriptsCount */
  scriptsCount?: Maybe<Scalars["Int"]>;
  sharedExecution?: Maybe<SharedExecution>;
  simulations?: Maybe<Array<Maybe<Execution>>>;
  simulationsCount?: Maybe<ResultSize>;
  strategies?: Maybe<Array<Maybe<Execution>>>;
  strategiesCount?: Maybe<ResultSize>;
  /** Internal problems queries */
  stuckExecutions?: Maybe<Array<Maybe<ExchangePairTypeStuckExecution>>>;
  subscriptions?: Maybe<Array<BillingSubscription>>;
  syndications?: Maybe<Array<Maybe<Execution>>>;
  syndicationsCount?: Maybe<ResultSize>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  verifyRecaptcha?: Maybe<RecaptchaInfo>;
};

export type RootQueryAdminGetBotArgs = {
  id: Scalars["ID"];
};

export type RootQueryAdminGetUserArgs = {
  userId: Scalars["ID"];
};

export type RootQueryAllInvoicesArgs = {
  associatedWith?: InputMaybe<LineItemAssociationQuery>;
  excludeStatus?: InputMaybe<Array<InvoiceStatus>>;
  includeStatus?: InputMaybe<Array<InvoiceStatus>>;
  query?: InputMaybe<AllInvoicesQuery>;
};

export type RootQueryAllSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<AllSubscriptionsQuery>;
};

export type RootQueryBillingPaymentProviderAllowListArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  paymentProvider?: InputMaybe<PaymentProvider>;
};

export type RootQueryCreatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<CreatorFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<UserSort>;
};

export type RootQueryCreatorsCountArgs = {
  filters?: InputMaybe<CreatorFilters>;
};

export type RootQueryCredentialsArgs = {
  exchange?: InputMaybe<Exchange>;
};

export type RootQueryDirectPaymentRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DirectPaymentRequestFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryExchangeArgs = {
  id: Exchange;
};

export type RootQueryExecutionArgs = {
  id: Scalars["ID"];
};

export type RootQueryExecutionsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  stage?: InputMaybe<ExecutionStage>;
};

export type RootQueryExecutionsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  stage?: InputMaybe<ExecutionStage>;
};

export type RootQueryFailedExecutionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryInvoicesArgs = {
  associatedWith?: InputMaybe<LineItemAssociationQuery>;
  excludeStatus?: InputMaybe<Array<InvoiceStatus>>;
  includeStatus?: InputMaybe<Array<InvoiceStatus>>;
};

export type RootQueryMostPopularCreatorsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryMostPopularSyndicationsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryMultivariantArgs = {
  id: Scalars["ID"];
};

export type RootQueryMultivariantsArgs = {
  filters?: InputMaybe<Array<InputMaybe<MultivariantFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<MultivariantSort>;
};

export type RootQueryMultivariantsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<MultivariantFilter>>>;
};

export type RootQueryPackExecutionsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  packId: Scalars["ID"];
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type RootQueryPaymentSourceArgs = {
  type?: InputMaybe<PaymentSourceType>;
};

export type RootQueryPortfolioDailyLogArgs = {
  range?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryPresignedUploadUrlArgs = {
  contentLength?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<Scalars["String"]>;
};

export type RootQueryPrivateSyndicationArgs = {
  shareToken: Scalars["ID"];
};

export type RootQueryPublicBotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ExecutionListFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExecutionSort>;
};

export type RootQueryPublicBotsCountArgs = {
  filters?: InputMaybe<ExecutionListFilters>;
};

export type RootQueryPublicProfileArgs = {
  userId: Scalars["String"];
};

export type RootQueryPublicProfilesArgs = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  executionFilters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  filters?: InputMaybe<Array<InputMaybe<UserFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<UserSort>;
};

export type RootQueryPublicProfilesCountArgs = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  executionFilters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  filters?: InputMaybe<Array<InputMaybe<UserFilter>>>;
};

export type RootQueryPublicScriptListArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<PublicScriptFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ScriptSort>;
};

export type RootQueryPublicScriptListCountArgs = {
  filters?: InputMaybe<PublicScriptFilters>;
};

export type RootQueryPublicScriptsArgs = {
  filters?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<ScriptLanguage>;
  query?: InputMaybe<PageQuery>;
  sort?: InputMaybe<ScriptSort>;
};

export type RootQueryPublicScriptsCountArgs = {
  filters?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<ScriptLanguage>;
};

export type RootQueryPublicSyndicationArgs = {
  id: Scalars["ID"];
};

export type RootQueryPublicSyndicationsArgs = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  fieldComparisons?: InputMaybe<Array<InputMaybe<ExecutionFieldComparison>>>;
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryPublicSyndicationsCountArgs = {
  currencyPairs?: InputMaybe<Array<InputMaybe<CurrencyPair>>>;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>>>;
  fieldComparisons?: InputMaybe<Array<InputMaybe<ExecutionFieldComparison>>>;
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryPublicUserArgs = {
  nickname: Scalars["String"];
};

export type RootQueryScriptArgs = {
  id: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
};

export type RootQueryScriptsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ScriptFilter>>>;
  language?: InputMaybe<ScriptLanguage>;
  query?: InputMaybe<PageQuery>;
  sort?: InputMaybe<ScriptSort>;
};

export type RootQueryScriptsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ScriptFilter>>>;
  language?: InputMaybe<ScriptLanguage>;
};

export type RootQuerySharedExecutionArgs = {
  shareToken: Scalars["ID"];
};

export type RootQuerySimulationsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type RootQuerySimulationsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
};

export type RootQueryStrategiesArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type RootQueryStrategiesCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
};

export type RootQuerySubscriptionsArgs = {
  excludeStatus?: InputMaybe<Array<SubscriptionStatus>>;
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  includeStatus?: InputMaybe<Array<SubscriptionStatus>>;
};

export type RootQuerySyndicationsArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
};

export type RootQuerySyndicationsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<ExecutionFilter>>>;
};

export type RootQueryVerifyRecaptchaArgs = {
  token: Scalars["String"];
};

export type Script = {
  __typename?: "Script";
  /** @deprecated No longer supported */
  author?: Maybe<Scalars["String"]>;
  /** NOTE: This can be deleted once the frontend removes references to it */
  favorite?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  isOwner: Scalars["Boolean"];
  isPublic: Scalars["Boolean"];
  isUsed?: Maybe<Scalars["Boolean"]>;
  language: ScriptLanguage;
  latest: ScriptRevision;
  latestValid?: Maybe<ScriptRevision>;
  latestVersionNumber?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  ownerInfo?: Maybe<ScriptOwnerInfo>;
  preloadedScript?: Maybe<PreloadedScript>;
  preview?: Maybe<Execution>;
  revision: ScriptRevision;
  revisions: Array<Maybe<ScriptRevision>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  timesCopied?: Maybe<Scalars["Int"]>;
};

export type ScriptRevisionArgs = {
  id: Scalars["ID"];
};

export enum ScriptColumn {
  Author = "AUTHOR",
  Favorite = "FAVORITE",
  IsPublic = "IS_PUBLIC",
  Language = "LANGUAGE",
  Name = "NAME",
  TagId = "TAG_ID",
  TimesCopied = "TIMES_COPIED",
  UpdatedAt = "UPDATED_AT",
  Version = "VERSION",
}

export type ScriptCreation = {
  body: Scalars["String"];
  language: ScriptLanguage;
  name: Scalars["String"];
};

export type ScriptDetails = {
  __typename?: "ScriptDetails";
  body: Scalars["String"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  isLatest?: Maybe<Scalars["Boolean"]>;
  language: ScriptLanguage;
  name: Scalars["String"];
  parameters: Array<Maybe<ScriptParameterDetails>>;
  plotDefinition: ScriptPlotDefinition;
  preloadedScript?: Maybe<PreloadedScript>;
  revisionId: Scalars["ID"];
  scriptId: Scalars["ID"];
  version?: Maybe<Scalars["Int"]>;
};

export type ScriptError = {
  __typename?: "ScriptError";
  message: Scalars["String"];
  range: Range;
};

export type ScriptFilter = {
  filterKey: ScriptColumn;
  filterType: FilterType;
  value: Scalars["String"];
};

export enum ScriptLanguage {
  Bytecode = "BYTECODE",
  Groovy = "GROOVY",
  Groovyjr = "GROOVYJR",
  Pine = "PINE",
}

export type ScriptOwnerInfo = {
  __typename?: "ScriptOwnerInfo";
  author?: Maybe<Scalars["String"]>;
  isTunedTeam?: Maybe<Scalars["Boolean"]>;
  nickname?: Maybe<Scalars["String"]>;
};

export type ScriptParameterDetails = {
  __typename?: "ScriptParameterDetails";
  defaultValue?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  fieldName: Scalars["String"];
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  readableName?: Maybe<Scalars["String"]>;
  valueType: ScriptParameterType;
};

export enum ScriptParameterType {
  Boolean = "BOOLEAN",
  Color = "COLOR",
  Decimal = "DECIMAL",
  Integer = "INTEGER",
  Resolution = "RESOLUTION",
  String = "STRING",
}

export type ScriptPlotDefinition = {
  __typename?: "ScriptPlotDefinition";
  groups: Array<Maybe<ScriptPlotGroup>>;
};

export type ScriptPlotGroup = {
  __typename?: "ScriptPlotGroup";
  arrows: Array<Maybe<PlotArrowInfo>>;
  barColor?: Maybe<BarColorInfo>;
  bgColor?: Maybe<BgColorInfo>;
  chars: Array<Maybe<PlotCharInfo>>;
  fills: Array<Maybe<FillInfo>>;
  hLines: Array<Maybe<HLineInfo>>;
  id: Scalars["ID"];
  isOverlay: Scalars["Boolean"];
  plots: Array<Maybe<PlotInfo>>;
  shapes: Array<Maybe<PlotShapeInfo>>;
};

export type ScriptPlotPalette = {
  __typename?: "ScriptPlotPalette";
  colors: Array<Maybe<Scalars["String"]>>;
  plotId: Scalars["ID"];
};

export type ScriptRename = {
  name: Scalars["String"];
  scriptId: Scalars["ID"];
};

export type ScriptRevision = {
  __typename?: "ScriptRevision";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  errors: Array<Maybe<ScriptError>>;
  id: Scalars["ID"];
  infos: Array<Maybe<ScriptError>>;
  parameters: Array<Maybe<ScriptParameterDetails>>;
  plotDefinition: ScriptPlotDefinition;
  status: ScriptRevisionStatus;
  updatedAt: Scalars["DateTime"];
  warnings: Array<Maybe<ScriptError>>;
};

export enum ScriptRevisionStatus {
  Invalid = "INVALID",
  Processing = "PROCESSING",
  Valid = "VALID",
}

export type ScriptsConnection = {
  __typename?: "ScriptsConnection";
  edges?: Maybe<Array<Maybe<ScriptsConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ScriptsConnectionEdge = {
  __typename?: "ScriptsConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Script>;
};

export type ScriptSort = {
  sortDirection: SortDirection;
  sortKey: ScriptColumn;
};

export type ScriptUpdate = {
  body: Scalars["String"];
  scriptId: Scalars["ID"];
};

export enum ScriptVisibility {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export enum ShapePosition {
  Abovebar = "ABOVEBAR",
  Absolute = "ABSOLUTE",
  Belowbar = "BELOWBAR",
  Bottom = "BOTTOM",
  Top = "TOP",
}

export enum ShapeSize {
  Auto = "AUTO",
  Huge = "HUGE",
  Large = "LARGE",
  Normal = "NORMAL",
  Small = "SMALL",
  Tiny = "TINY",
}

export enum ShapeStyle {
  Arrowdown = "ARROWDOWN",
  Arrowup = "ARROWUP",
  Circle = "CIRCLE",
  Cross = "CROSS",
  Diamond = "DIAMOND",
  Flag = "FLAG",
  Labeldown = "LABELDOWN",
  Labelup = "LABELUP",
  Square = "SQUARE",
  Triangledown = "TRIANGLEDOWN",
  Triangleup = "TRIANGLEUP",
  Xcross = "XCROSS",
}

export type SharedExecution = {
  __typename?: "SharedExecution";
  allocation: Scalars["Decimal"];
  analysis?: Maybe<Analysis>;
  candleSize: Scalars["Resolution"];
  /** only get the name and not the entire PublicSyndication for SharedExecution since it shouldn't have that much info */
  currencyPair: CurrencyPair;
  currencyPairDetails?: Maybe<CurrencyPairDetails>;
  endedAt?: Maybe<Scalars["DateTime"]>;
  exchange: Exchange;
  id?: Maybe<Scalars["ID"]>;
  measurements?: Maybe<Measurements>;
  multiCoinCurrency?: Maybe<Currency>;
  name?: Maybe<Scalars["String"]>;
  ownerNickname?: Maybe<Scalars["String"]>;
  performanceMode: ExecutionPerformanceMode;
  rangeEnd?: Maybe<Scalars["Date"]>;
  rangeStart?: Maybe<Scalars["Date"]>;
  realizedProfitsLog?: Maybe<Array<Maybe<RealizedProfitsLog>>>;
  scriptName?: Maybe<Scalars["String"]>;
  shareToken: Scalars["ID"];
  startedAt?: Maybe<Scalars["DateTime"]>;
  status: ExecutionStatus;
  subscriptionStatus?: Maybe<SyndicationSubscriptionStatus>;
  syndicationName?: Maybe<Scalars["String"]>;
  /** @deprecated replaced by tradesConnection */
  trades?: Maybe<Array<Maybe<Trade>>>;
  tradesConnection?: Maybe<TradeConnection>;
  type: ExecutionType;
};

export type SharedExecutionAnalysisArgs = {
  rangeDays?: InputMaybe<Scalars["Int"]>;
};

export type SharedExecutionTradesArgs = {
  query?: InputMaybe<PageQuery>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type SharedExecutionTradesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum SignalActionTime {
  CandleClose = "CANDLE_CLOSE",
  Realtime = "REALTIME",
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type StopExecutionsInput = {
  ids: Array<Scalars["ID"]>;
  stopStatus: ExecutionStatus;
};

export type StopExecutionsPayload = {
  __typename?: "StopExecutionsPayload";
  executions: Array<Execution>;
};

export type StrategySettings = {
  buyType: OrderType;
  credentialId: Scalars["ID"];
  sellType: OrderType;
};

export type SubscribeToSyndicationResult = {
  __typename?: "SubscribeToSyndicationResult";
  id: Scalars["ID"];
  /** TODO Remove me */
  initialInvoiceId: Scalars["ID"];
  invoice?: Maybe<Invoice>;
};

export type Subscription = {
  __typename?: "Subscription";
  adminStats?: Maybe<AdminStats>;
  announcement?: Maybe<Announcement>;
  backtestProgress?: Maybe<Scalars["Int"]>;
  candle?: Maybe<Candle>;
  color?: Maybe<ColorChange>;
  execution?: Maybe<Execution>;
  invoiceEvents?: Maybe<InvoiceEvent>;
  multivariant?: Maybe<Multivariant>;
  order?: Maybe<Order>;
  plot?: Maybe<PlotTick>;
  portfolio?: Maybe<Portfolio>;
  productSubscriptionEvents?: Maybe<ProductSubscriptionEvent>;
  scriptRevision?: Maybe<ScriptRevision>;
  syndicationUpdate?: Maybe<SyndicationUpdate>;
  trade?: Maybe<Trade>;
  userSubscription?: Maybe<UserSubscriptionStatusUpdate>;
};

export type SubscriptionBacktestProgressArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type SubscriptionCandleArgs = {
  candleSize: Scalars["Resolution"];
  exchange: Exchange;
  pair: CurrencyPair;
};

export type SubscriptionColorArgs = {
  executionId: Scalars["ID"];
};

export type SubscriptionExecutionArgs = {
  ids: Array<InputMaybe<Scalars["ID"]>>;
};

export type SubscriptionMultivariantArgs = {
  ids: Array<InputMaybe<Scalars["ID"]>>;
};

export type SubscriptionOrderArgs = {
  executionId: Scalars["ID"];
};

export type SubscriptionPlotArgs = {
  executionId: Scalars["ID"];
};

export type SubscriptionPortfolioArgs = {
  currency?: InputMaybe<PortfolioCurrency>;
};

export type SubscriptionScriptRevisionArgs = {
  scriptRevisionId: Scalars["ID"];
};

export type SubscriptionSyndicationUpdateArgs = {
  syndicationId: Scalars["ID"];
};

export type SubscriptionTradeArgs = {
  executionId: Scalars["ID"];
  sortDirection?: InputMaybe<SortDirection>;
};

export type SubscriptionUserSubscriptionArgs = {
  subscriptionId: Scalars["ID"];
};

export type SubscriptionCreationFailure = {
  __typename?: "SubscriptionCreationFailure";
  reason: SubscriptionCreationFailureReason;
};

export enum SubscriptionCreationFailureReason {
  InternalError = "INTERNAL_ERROR",
  PaymentCancelled = "PAYMENT_CANCELLED",
  PaymentDenied = "PAYMENT_DENIED",
  PaymentExpired = "PAYMENT_EXPIRED",
  PaymentFailed = "PAYMENT_FAILED",
}

export type SubscriptionRenewalFailure = {
  __typename?: "SubscriptionRenewalFailure";
  reason: SubscriptionRenewalFailureReason;
};

export enum SubscriptionRenewalFailureReason {
  InternalError = "INTERNAL_ERROR",
  InvoiceExpired = "INVOICE_EXPIRED",
}

export enum SubscriptionRenewalProcessStatus {
  InProgress = "IN_PROGRESS",
  None = "NONE",
}

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Ditched = "DITCHED",
  Expired = "EXPIRED",
  InitialFulfillmentFailure = "INITIAL_FULFILLMENT_FAILURE",
  InitialPaymentCancelled = "INITIAL_PAYMENT_CANCELLED",
  InitialPaymentDenied = "INITIAL_PAYMENT_DENIED",
  InitialPaymentExpired = "INITIAL_PAYMENT_EXPIRED",
  InitialPaymentFailed = "INITIAL_PAYMENT_FAILED",
  InitialPaymentPending = "INITIAL_PAYMENT_PENDING",
  InitialRequested = "INITIAL_REQUESTED",
  InternalFailure = "INTERNAL_FAILURE",
  NextCycleCancellationPending = "NEXT_CYCLE_CANCELLATION_PENDING",
  RebillFailedInvoiceExpired = "REBILL_FAILED_INVOICE_EXPIRED",
  RebillFailedInvoiceRejected = "REBILL_FAILED_INVOICE_REJECTED",
  RebillInternalFailure = "REBILL_INTERNAL_FAILURE",
  RebillPending = "REBILL_PENDING",
  RebillTerminalInternalFailure = "REBILL_TERMINAL_INTERNAL_FAILURE",
  UserCancelled = "USER_CANCELLED",
}

/**
 * Deprecated
 * TODO Remove
 */
export enum SubscriptionUserStatus {
  NoRenewal = "NO_RENEWAL",
  Subscribed = "SUBSCRIBED",
  Unsubscribed = "UNSUBSCRIBED",
}

export type SuccessFeeLineItemMetadata = {
  __typename?: "SuccessFeeLineItemMetadata";
  newTotalValuePeak?: Maybe<Scalars["Decimal"]>;
  previousTotalValuePeak?: Maybe<Scalars["Decimal"]>;
  totalValueDeltaUsd?: Maybe<Scalars["Decimal"]>;
};

export enum SyndicationAccessControl {
  AllowAllUsers = "ALLOW_ALL_USERS",
  UseAllowList = "USE_ALLOW_LIST",
}

export enum SyndicationBillingPeriod {
  FiveMinutes = "FIVE_MINUTES",
  OneDay = "ONE_DAY",
  OneMonth = "ONE_MONTH",
  TenMinutes = "TEN_MINUTES",
  TwoMinutes = "TWO_MINUTES",
}

export type SyndicationCreation = {
  accessControl?: InputMaybe<SyndicationAccessControl>;
  allocationFeePerc?: InputMaybe<Scalars["Decimal"]>;
  billingPeriod?: InputMaybe<SyndicationBillingPeriod>;
  description?: InputMaybe<Scalars["String"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]>;
  maxAllocation: Scalars["Decimal"];
  maxSubscriberAllocation?: InputMaybe<Scalars["Decimal"]>;
  minAllocation: Scalars["Decimal"];
  /** TODO: Deprecated, remove */
  monthlyFeeUsd?: InputMaybe<Scalars["Decimal"]>;
  name: Scalars["String"];
  packId: Scalars["Int"];
  recurringFeeUsd?: InputMaybe<Scalars["Decimal"]>;
  renewalGracePeriod?: InputMaybe<Scalars["String"]>;
  successFeePerc?: InputMaybe<Scalars["Decimal"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type SyndicationEntitlement = Entitlement & {
  __typename?: "SyndicationEntitlement";
  currentAllocation?: Maybe<Scalars["Decimal"]>;
  executions?: Maybe<ExecutionConnection>;
  id: Scalars["ID"];
  referenceId: Scalars["String"];
  syndication: PublicSyndication;
  type: Scalars["String"];
};

export type SyndicationEntitlementExecutionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type SyndicationMeasurements = {
  __typename?: "SyndicationMeasurements";
  absoluteProfit?: Maybe<Scalars["Decimal"]>;
  availableFunds?: Maybe<Scalars["Decimal"]>;
  avgBarsInTrade?: Maybe<Scalars["Int"]>;
  avgLoseMonth?: Maybe<Scalars["Decimal"]>;
  avgMonthlyProfit?: Maybe<Scalars["Decimal"]>;
  avgPositionPrice?: Maybe<Scalars["Decimal"]>;
  avgTradePrice?: Maybe<Scalars["Decimal"]>;
  avgWinMonth?: Maybe<Scalars["Decimal"]>;
  balance?: Maybe<Scalars["Decimal"]>;
  buyCommission?: Maybe<Scalars["Decimal"]>;
  buyHoldRatio?: Maybe<Scalars["Decimal"]>;
  buyHoldReturn?: Maybe<Scalars["Decimal"]>;
  commissionValue?: Maybe<Scalars["Decimal"]>;
  consistencyScore?: Maybe<Scalars["Int"]>;
  lastTick?: Maybe<Scalars["DateTime"]>;
  maxDrawdown?: Maybe<Scalars["Decimal"]>;
  numberOfTrades?: Maybe<Scalars["Int"]>;
  percBuyHoldRatio?: Maybe<Scalars["Decimal"]>;
  percentProfitableTrades?: Maybe<Scalars["Decimal"]>;
  percProfitableMonths?: Maybe<Scalars["Decimal"]>;
  positionAbsoluteProfit?: Maybe<Scalars["Decimal"]>;
  positionAmount?: Maybe<Scalars["Decimal"]>;
  positionProfitLoss?: Maybe<Scalars["Decimal"]>;
  profitability?: Maybe<Scalars["Decimal"]>;
  profitFactor?: Maybe<Scalars["Decimal"]>;
  riskScore?: Maybe<Scalars["Decimal"]>;
  sellCommission?: Maybe<Scalars["Decimal"]>;
  sharpeRatio?: Maybe<Scalars["Decimal"]>;
  sortinoRatio?: Maybe<Scalars["Decimal"]>;
  subscriptionAllocation?: Maybe<Scalars["Decimal"]>;
  subscriptionCount?: Maybe<Scalars["Int"]>;
  totalRealizedGain?: Maybe<Scalars["Decimal"]>;
  totalRealizedLoss?: Maybe<Scalars["Decimal"]>;
  totalValue?: Maybe<Scalars["Decimal"]>;
};

export type SyndicationSettings = {
  __typename?: "SyndicationSettings";
  accessControl?: Maybe<SyndicationAccessControl>;
  activeSubscribersCount?: Maybe<Scalars["Int"]>;
  allocationFeePerc?: Maybe<Scalars["Decimal"]>;
  allowList?: Maybe<Array<Maybe<AllowListInfo>>>;
  availableAllocation?: Maybe<Scalars["Decimal"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isVisible?: Maybe<Scalars["Boolean"]>;
  maxAllocation: Scalars["Decimal"];
  maxSubscriberAllocation?: Maybe<Scalars["Decimal"]>;
  minAllocation: Scalars["Decimal"];
  monthlyFeeUsd?: Maybe<Scalars["Decimal"]>;
  productId?: Maybe<Scalars["ID"]>;
  shareToken?: Maybe<Scalars["ID"]>;
  successFeePerc?: Maybe<Scalars["Decimal"]>;
  visibility?: Maybe<BotVisibility>;
};

export type SyndicationSubscriptionCreation = {
  credentialId?: InputMaybe<Scalars["ID"]>;
  initialCapital: Scalars["Decimal"];
  syndicationId: Scalars["ID"];
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type: ExecutionType;
};

/** TODO: Remove */
export enum SyndicationSubscriptionStatus {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  NotSubscribed = "NOT_SUBSCRIBED",
  Pending = "PENDING",
}

export type SyndicationUpdate = {
  __typename?: "SyndicationUpdate";
  audience?: Maybe<SyndicationUpdateAudience>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  placement?: Maybe<SyndicationUpdatePlacement>;
  syndicationId?: Maybe<Scalars["ID"]>;
  update?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum SyndicationUpdateAudience {
  Everyone = "EVERYONE",
  SubscribersOnly = "SUBSCRIBERS_ONLY",
}

export type SyndicationUpdateConnection = {
  __typename?: "SyndicationUpdateConnection";
  edges?: Maybe<Array<Maybe<SyndicationUpdateConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SyndicationUpdateConnectionEdge = {
  __typename?: "SyndicationUpdateConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<SyndicationUpdate>;
};

export enum SyndicationUpdatePlacement {
  List = "LIST",
  Pinned = "PINNED",
}

export type SyndicationWatchlistItem = WatchlistItem & {
  __typename?: "SyndicationWatchlistItem";
  rank: Scalars["Int"];
  syndication?: Maybe<PublicSyndication>;
  syndicationId: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type Tag = {
  __typename?: "Tag";
  color: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Trade = {
  __typename?: "Trade";
  absoluteProfit: Scalars["Decimal"];
  /** To make orders not rely on the exchange for the currency pair details (so multi-coin packs do not show the wrong details) */
  currencyPairDetails?: Maybe<CurrencyPairDetails>;
  fundingFee?: Maybe<Scalars["Decimal"]>;
  id: Scalars["ID"];
  isClosed: Scalars["Boolean"];
  isProfitable: Scalars["Boolean"];
  orders: Array<Maybe<Order>>;
  profitPercentage: Scalars["Decimal"];
};

export type TradeConnection = {
  __typename?: "TradeConnection";
  edges?: Maybe<Array<Maybe<TradeConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TradeConnectionEdge = {
  __typename?: "TradeConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Trade>;
};

export type UpdateUserLanguageInput = {
  language: Locale;
};

export type UpdateUserLanguagePayload = {
  __typename?: "UpdateUserLanguagePayload";
  user?: Maybe<User>;
};

export type User = {
  __typename?: "User";
  availablePaymentProviders?: Maybe<Array<PaymentProvider>>;
  batchTests?: Maybe<MultivariantConnection>;
  batchTestsCount?: Maybe<Scalars["Int"]>;
  companyName?: Maybe<Scalars["String"]>;
  completedTours?: Maybe<Array<Maybe<UserTourType>>>;
  country?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discordUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  executions?: Maybe<ExecutionConnection>;
  executionsCount?: Maybe<Scalars["Int"]>;
  features?: Maybe<UserFeatureValues>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  invoices?: Maybe<InvoiceConnection>;
  language?: Maybe<Locale>;
  lastName: Scalars["String"];
  linkedinUrl?: Maybe<Scalars["String"]>;
  mailingPreference?: Maybe<MailingPreference>;
  mediumUrl?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  outstandingInvoiceCount?: Maybe<Scalars["Int"]>;
  permissions: Array<Maybe<Permission>>;
  personalBlogUrl?: Maybe<Scalars["String"]>;
  personalWebsiteUrl?: Maybe<Scalars["String"]>;
  portfolioRealizedProfitsLog?: Maybe<Array<Maybe<RealizedProfitsLog>>>;
  preferences?: Maybe<UserOnboardingPreferences>;
  preloadedScript?: Maybe<Script>;
  profilePhotoS3Key?: Maybe<Scalars["String"]>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
  promoterDetails?: Maybe<Promoter>;
  registrationDate: Scalars["DateTime"];
  scripts?: Maybe<ScriptsConnection>;
  scriptsCount?: Maybe<Scalars["Int"]>;
  subscriptions?: Maybe<BillingSubscriptionConnection>;
  telegramUrl?: Maybe<Scalars["String"]>;
  termsAccepted: Scalars["Boolean"];
  tier?: Maybe<UserTier>;
  tierParameters?: Maybe<UserTierParameters>;
  twitterUrl?: Maybe<Scalars["String"]>;
  watchlist?: Maybe<WatchlistConnection>;
  welcomed: Scalars["Boolean"];
};

export type UserBatchTestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<Array<InputMaybe<MultivariantFilter>>>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MultivariantSort>;
};

export type UserBatchTestsCountArgs = {
  filters?: InputMaybe<Array<InputMaybe<MultivariantFilter>>>;
};

export type UserExecutionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ExecutionListFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExecutionSort>;
};

export type UserExecutionsCountArgs = {
  filters?: InputMaybe<ExecutionListFilters>;
};

export type UserInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<InvoiceFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type UserPreloadedScriptArgs = {
  type: PreloadedScript;
};

export type UserScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<UserScriptFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ScriptSort>;
};

export type UserScriptsCountArgs = {
  filters?: InputMaybe<UserScriptFilters>;
};

export type UserSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<BillingSubscriptionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type UserWatchlistArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<WatchlistFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export enum UserColumn {
  BotsCount = "BOTS_COUNT",
  DisplayName = "DISPLAY_NAME",
  Exchanges = "EXCHANGES",
  Nickname = "NICKNAME",
  RegistrationDate = "REGISTRATION_DATE",
  ScriptsCount = "SCRIPTS_COUNT",
  TotalAllocated = "TOTAL_ALLOCATED",
  TotalTrades = "TOTAL_TRADES",
}

export type UserFeatureValues = {
  __typename?: "UserFeatureValues";
  runSyndicationPapertrades?: Maybe<Scalars["Boolean"]>;
  useStrategyLicensing?: Maybe<Scalars["Boolean"]>;
  canDownloadTrades?: Maybe<Scalars["Boolean"]>;
  readMarketData?: Maybe<Scalars["Boolean"]>;
  writePineScript?: Maybe<Scalars["Boolean"]>;
  writeTunedScript?: Maybe<Scalars["Boolean"]>;
  readDocumentation?: Maybe<Scalars["Boolean"]>;
  useMinimumProfit?: Maybe<Scalars["Boolean"]>;
  useStopLoss?: Maybe<Scalars["Boolean"]>;
  useTakeProfit?: Maybe<Scalars["Boolean"]>;
  concurrentBatchTests?: Maybe<Scalars["Int"]>;
  batchTestMaxSizeAccuracy?: Maybe<Scalars["Int"]>;
  batchTestMaxSizeCandlesAmount?: Maybe<Scalars["Int"]>;
  paperTradesCount?: Maybe<Scalars["Int"]>;
  updateExecutionAllocation?: Maybe<Scalars["Boolean"]>;
};

export type UserFilter = {
  filterKey: UserColumn;
  filterType: FilterType;
  value: Scalars["String"];
};

export type UserGeneralInfo = {
  companyName: Scalars["String"];
  country: Scalars["String"];
  description: Scalars["String"];
  discordUrl?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  linkedinUrl?: InputMaybe<Scalars["String"]>;
  mediumUrl?: InputMaybe<Scalars["String"]>;
  nickname: Scalars["String"];
  personalBlogUrl?: InputMaybe<Scalars["String"]>;
  personalWebsiteUrl?: InputMaybe<Scalars["String"]>;
  telegramUrl?: InputMaybe<Scalars["String"]>;
  twitterUrl?: InputMaybe<Scalars["String"]>;
};

export type UserOnboardingPreferences = {
  __typename?: "UserOnboardingPreferences";
  currencies?: Maybe<Array<Currency>>;
  currencyPairs?: Maybe<Array<CurrencyPair>>;
  exchanges?: Maybe<Array<Exchange>>;
  risk?: Maybe<UserRiskPreference>;
};

export type UserOnboardingPreferencesInput = {
  currencies?: InputMaybe<Array<Currency>>;
  currencyPairs?: InputMaybe<Array<CurrencyPair>>;
  exchanges?: InputMaybe<Array<Exchange>>;
  risk?: InputMaybe<UserRiskPreference>;
};

export enum UserRiskPreference {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type UserScriptFilters = {
  favorite?: InputMaybe<Scalars["Boolean"]>;
  includedTagIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  language?: InputMaybe<ScriptLanguage>;
  name?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<ScriptVisibility>;
};

export type UserSort = {
  sortDirection: SortDirection;
  sortKey: UserColumn;
};

export type UserSubscriptionStatusUpdate = {
  __typename?: "UserSubscriptionStatusUpdate";
  status: Scalars["String"];
  subscriptionId: Scalars["ID"];
};

export enum UserTier {
  Admin = "ADMIN",
  Community = "COMMUNITY",
  CommunityUnlimited = "COMMUNITY_UNLIMITED",
  Free = "FREE",
  Partner = "PARTNER",
  Performance = "PERFORMANCE",
  Privileged = "PRIVILEGED",
  Subscriber = "SUBSCRIBER",
  Suspended = "SUSPENDED",
  Tester = "TESTER",
}

export type UserTierParameters = {
  __typename?: "UserTierParameters";
  concurrentBatchTests: Scalars["Int"];
  batchTestMaxCandlesAmount: Scalars["Int"];
  paperTradesCount: Scalars["Int"];
};

export enum UserTourType {
  Setups = "SETUPS",
}

export enum UserType {
  All = "ALL",
  Creator = "CREATOR",
  Investor = "INVESTOR",
  None = "NONE",
}

export type WatchlistAssetFields = {
  assetName?: InputMaybe<Scalars["String"]>;
  assetSymbol?: InputMaybe<Scalars["String"]>;
  assetType?: InputMaybe<AssetType>;
};

export type WatchlistConnection = {
  __typename?: "WatchlistConnection";
  edges?: Maybe<Array<Maybe<WatchlistConnectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type WatchlistConnectionEdge = {
  __typename?: "WatchlistConnectionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<WatchlistItem>;
};

export type WatchlistFilter = {
  type?: InputMaybe<WatchlistType>;
};

export type WatchlistItem = {
  rank: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export enum WatchlistType {
  Asset = "ASSET",
  Creator = "CREATOR",
  Syndication = "SYNDICATION",
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: "__EnumValue";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  isDeprecated: Scalars["Boolean"];
  deprecationReason?: Maybe<Scalars["String"]>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: "__Field";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars["Boolean"];
  deprecationReason?: Maybe<Scalars["String"]>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: "__InputValue";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars["String"]>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name and description, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: "__Type";
  kind: __TypeKind;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name and description, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name and description, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars["Boolean"]>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = "SCALAR",
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = "OBJECT",
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = "INTERFACE",
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = "UNION",
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = "ENUM",
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = "INPUT_OBJECT",
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = "LIST",
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = "NON_NULL",
}

export type AdminStatsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type AdminStatsSubscription = {
  __typename?: "Subscription";
  adminStats?:
    | {
        __typename?: "AdminStats";
        queues?:
          | Array<
              | {
                  __typename?: "AdminQueueStats";
                  name: string;
                  consumersCount?: number | null | undefined;
                  taskCount?: number | null | undefined;
                  inProgressTaskCount?: number | null | undefined;
                  tasksCompletedPerSecond?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AdminQueueStatsFragment = {
  __typename?: "AdminQueueStats";
  name: string;
  consumersCount?: number | null | undefined;
  taskCount?: number | null | undefined;
  inProgressTaskCount?: number | null | undefined;
  tasksCompletedPerSecond?: number | null | undefined;
};

export type BotsDetailsPanelQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BotsDetailsPanelQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        exchange: Exchange;
        name?: string | null | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              description?: string | null | undefined;
              id: string;
              maxAllocation: any;
              maxSubscriberAllocation?: any | null | undefined;
              minAllocation: any;
              monthlyFeeUsd?: any | null | undefined;
              allocationFeePerc?: any | null | undefined;
              successFeePerc?: any | null | undefined;
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
        owner: {
          __typename?: "PublicUser";
          id: string;
          firstName: string;
          lastName: string;
          companyName?: string | null | undefined;
          nickname?: string | null | undefined;
          profilePhotoUrl?: string | null | undefined;
        };
        multiCoinPackExecutions?:
          | Array<
              | {
                  __typename?: "MultiCoinPackCurrencyPercentage";
                  currency: Currency;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              settleCurrency: Currency;
              quote: Currency;
              positionCurrency: Currency;
              id: string;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "SyndicationMeasurements";
              subscriptionAllocation?: any | null | undefined;
            }
          | null
          | undefined;
        subscription?:
          | {
              __typename?: "BillingSubscription";
              id: string;
              statusDetails?:
                | {
                    __typename?: "BillingSubscriptionStatusDetails";
                    mayUseEntitlements: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotsSubpageQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<ExecutionListFilters>;
}>;

export type BotsSubpageQuery = {
  __typename?: "RootQuery";
  publicBotsCount?: number | null | undefined;
  publicBots?:
    | {
        __typename?: "PublicSyndicationConnection";
        edges?:
          | Array<
              | {
                  __typename?: "PublicSyndicationConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "PublicSyndication";
                        id: string;
                        name?: string | null | undefined;
                        exchange: Exchange;
                        currencyPair?: CurrencyPair | null | undefined;
                        startedAt?: string | null | undefined;
                        candleSize: string;
                        multiCoinCurrency?: Currency | null | undefined;
                        autoRebalance?: boolean | null | undefined;
                        owner: {
                          __typename?: "PublicUser";
                          firstName: string;
                          lastName: string;
                          companyName?: string | null | undefined;
                          nickname?: string | null | undefined;
                        };
                        currencyPairDetails?:
                          | {
                              __typename?: "CurrencyPairDetails";
                              id: string;
                              exchange: Exchange;
                              pair: CurrencyPair;
                              base: Currency;
                              quote: Currency;
                              settleCurrency: Currency;
                              positionCurrency: Currency;
                            }
                          | null
                          | undefined;
                        syndicationSettings?:
                          | {
                              __typename?: "SyndicationSettings";
                              id: string;
                              maxAllocation: any;
                              minAllocation: any;
                              monthlyFeeUsd?: any | null | undefined;
                              allocationFeePerc?: any | null | undefined;
                              successFeePerc?: any | null | undefined;
                              description?: string | null | undefined;
                              maxSubscriberAllocation?: any | null | undefined;
                            }
                          | null
                          | undefined;
                        measurements?:
                          | {
                              __typename?: "SyndicationMeasurements";
                              maxDrawdown?: any | null | undefined;
                              profitability?: any | null | undefined;
                              avgMonthlyProfit?: any | null | undefined;
                              percProfitableMonths?: any | null | undefined;
                              riskScore?: any | null | undefined;
                              subscriptionAllocation?: any | null | undefined;
                              numberOfTrades?: number | null | undefined;
                              percentProfitableTrades?: any | null | undefined;
                              sharpeRatio?: any | null | undefined;
                              sortinoRatio?: any | null | undefined;
                              consistencyScore?: number | null | undefined;
                              availableFunds?: any | null | undefined;
                              totalValue?: any | null | undefined;
                              buyCommission?: any | null | undefined;
                              sellCommission?: any | null | undefined;
                              commissionValue?: any | null | undefined;
                              avgBarsInTrade?: number | null | undefined;
                              profitFactor?: any | null | undefined;
                              buyHoldReturn?: any | null | undefined;
                              avgTradePrice?: any | null | undefined;
                              lastTick?: string | null | undefined;
                              avgWinMonth?: any | null | undefined;
                              avgLoseMonth?: any | null | undefined;
                            }
                          | null
                          | undefined;
                        leverageInfo?:
                          | {
                              __typename?: "LeverageInfo";
                              maxLeverage?: any | null | undefined;
                              minLeverage?: any | null | undefined;
                              avgLeverage?: any | null | undefined;
                            }
                          | null
                          | undefined;
                        multiCoinPackExecutions?:
                          | Array<
                              | {
                                  __typename?: "MultiCoinPackCurrencyPercentage";
                                  currency: Currency;
                                  percentage: any;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                        backtestMeasurements?:
                          | {
                              __typename?: "SyndicationMeasurements";
                              maxDrawdown?: any | null | undefined;
                              profitability?: any | null | undefined;
                              avgMonthlyProfit?: any | null | undefined;
                              percProfitableMonths?: any | null | undefined;
                              riskScore?: any | null | undefined;
                              subscriptionAllocation?: any | null | undefined;
                              numberOfTrades?: number | null | undefined;
                              percentProfitableTrades?: any | null | undefined;
                              sharpeRatio?: any | null | undefined;
                              sortinoRatio?: any | null | undefined;
                              consistencyScore?: number | null | undefined;
                              availableFunds?: any | null | undefined;
                              totalValue?: any | null | undefined;
                              buyCommission?: any | null | undefined;
                              sellCommission?: any | null | undefined;
                              commissionValue?: any | null | undefined;
                              avgBarsInTrade?: number | null | undefined;
                              profitFactor?: any | null | undefined;
                              buyHoldReturn?: any | null | undefined;
                              avgTradePrice?: any | null | undefined;
                              lastTick?: string | null | undefined;
                              avgWinMonth?: any | null | undefined;
                              avgLoseMonth?: any | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | { __typename?: "PageInfo"; hasNextPage: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotsTable_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair?: CurrencyPair | null | undefined;
  startedAt?: string | null | undefined;
  candleSize: string;
  multiCoinCurrency?: Currency | null | undefined;
  autoRebalance?: boolean | null | undefined;
  owner: {
    __typename?: "PublicUser";
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
  };
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        id: string;
        maxAllocation: any;
        minAllocation: any;
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        description?: string | null | undefined;
        maxSubscriberAllocation?: any | null | undefined;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        maxDrawdown?: any | null | undefined;
        profitability?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        riskScore?: any | null | undefined;
        subscriptionAllocation?: any | null | undefined;
        numberOfTrades?: number | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        consistencyScore?: number | null | undefined;
        availableFunds?: any | null | undefined;
        totalValue?: any | null | undefined;
        buyCommission?: any | null | undefined;
        sellCommission?: any | null | undefined;
        commissionValue?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
      }
    | null
    | undefined;
  leverageInfo?:
    | {
        __typename?: "LeverageInfo";
        maxLeverage?: any | null | undefined;
        minLeverage?: any | null | undefined;
        avgLeverage?: any | null | undefined;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | {
            __typename?: "MultiCoinPackCurrencyPercentage";
            currency: Currency;
            percentage: any;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  backtestMeasurements?:
    | {
        __typename?: "SyndicationMeasurements";
        maxDrawdown?: any | null | undefined;
        profitability?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        riskScore?: any | null | undefined;
        subscriptionAllocation?: any | null | undefined;
        numberOfTrades?: number | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        consistencyScore?: number | null | undefined;
        availableFunds?: any | null | undefined;
        totalValue?: any | null | undefined;
        buyCommission?: any | null | undefined;
        sellCommission?: any | null | undefined;
        commissionValue?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
      }
    | null
    | undefined;
};

export type BotsTradesPanelQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type BotsTradesPanelQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        exchange: Exchange;
        trades?:
          | {
              __typename?: "TradeConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "TradeConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Trade";
                              id: string;
                              absoluteProfit: any;
                              profitPercentage: any;
                              isClosed: boolean;
                              fundingFee?: any | null | undefined;
                              orders: Array<
                                | {
                                    __typename?: "Order";
                                    externalId?: string | null | undefined;
                                    source: OrderSource;
                                    status: OrderStatus;
                                    side: OrderSide;
                                    placedTime: string;
                                    placedAmount: any;
                                    filledTime?: string | null | undefined;
                                    commissionPaid: any;
                                    limitPrice?: any | null | undefined;
                                    filledAmount: any;
                                    filledPrice: any;
                                    filledValue: any;
                                    effectiveAmount: any;
                                    effectivePrice: any;
                                    effectiveValue: any;
                                    currencyPairDetails?:
                                      | {
                                          __typename?: "CurrencyPairDetails";
                                          id: string;
                                          exchange: Exchange;
                                          pair: CurrencyPair;
                                          base: Currency;
                                          quote: Currency;
                                          settleCurrency: Currency;
                                          positionCurrency: Currency;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "SyndicationMeasurements";
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotsUpdatesPanelQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type BotsUpdatesPanelQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        owner: {
          __typename?: "PublicUser";
          id: string;
          firstName: string;
          lastName: string;
          companyName?: string | null | undefined;
          nickname?: string | null | undefined;
          profilePhotoUrl?: string | null | undefined;
        };
        pinnedUpdate?:
          | {
              __typename?: "SyndicationUpdate";
              id: string;
              update?: string | null | undefined;
              syndicationId?: string | null | undefined;
              createdAt?: string | null | undefined;
              updatedAt?: string | null | undefined;
              placement?: SyndicationUpdatePlacement | null | undefined;
              audience?: SyndicationUpdateAudience | null | undefined;
            }
          | null
          | undefined;
        updates?:
          | {
              __typename?: "SyndicationUpdateConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "SyndicationUpdateConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "SyndicationUpdate";
                              id: string;
                              update?: string | null | undefined;
                              syndicationId?: string | null | undefined;
                              createdAt?: string | null | undefined;
                              updatedAt?: string | null | undefined;
                              placement?:
                                | SyndicationUpdatePlacement
                                | null
                                | undefined;
                              audience?:
                                | SyndicationUpdateAudience
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotAdditionalInfoQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BotAdditionalInfoQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        autoRebalance?: boolean | null | undefined;
        subExecutionsCount?: number | null | undefined;
        analysis?:
          | {
              __typename?: "Analysis";
              avgEquityPeakDays?: any | null | undefined;
            }
          | null
          | undefined;
        leverageInfo?:
          | {
              __typename?: "LeverageInfo";
              avgLeverage?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotAllocation_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  currencyPairDetails?:
    | { __typename?: "CurrencyPairDetails"; settleCurrency: Currency }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        subscriptionAllocation?: any | null | undefined;
      }
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        id: string;
        maxAllocation: any;
        maxSubscriberAllocation?: any | null | undefined;
        minAllocation: any;
      }
    | null
    | undefined;
};

export type BotAssets_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  multiCoinPackExecutions?:
    | Array<
        | { __typename?: "MultiCoinPackCurrencyPercentage"; currency: Currency }
        | null
        | undefined
      >
    | null
    | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        settleCurrency: Currency;
        quote: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
};

export type BotFeeContainer_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
      }
    | null
    | undefined;
};

export type BotHeaderInfo_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  name?: string | null | undefined;
  owner: {
    __typename?: "PublicUser";
    id: string;
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
  };
};

export type BotSubscribeButton_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  owner: {
    __typename?: "PublicUser";
    id: string;
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
  };
  subscription?:
    | {
        __typename?: "BillingSubscription";
        id: string;
        statusDetails?:
          | {
              __typename?: "BillingSubscriptionStatusDetails";
              mayUseEntitlements: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        settleCurrency: Currency;
        quote: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | { __typename?: "MultiCoinPackCurrencyPercentage"; currency: Currency }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type BotSubscriptionModal_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        settleCurrency: Currency;
        quote: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | { __typename?: "MultiCoinPackCurrencyPercentage"; currency: Currency }
        | null
        | undefined
      >
    | null
    | undefined;
  owner: {
    __typename?: "PublicUser";
    id: string;
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
  };
  subscription?:
    | {
        __typename?: "BillingSubscription";
        id: string;
        statusDetails?:
          | {
              __typename?: "BillingSubscriptionStatusDetails";
              mayUseEntitlements: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotsDescription_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        description?: string | null | undefined;
      }
    | null
    | undefined;
};

export type BotOrdersCell_OrdersFragment = {
  __typename?: "Order";
  externalId?: string | null | undefined;
  source: OrderSource;
  status: OrderStatus;
  side: OrderSide;
  placedTime: string;
  placedAmount: any;
  filledTime?: string | null | undefined;
  commissionPaid: any;
  limitPrice?: any | null | undefined;
  filledAmount: any;
  filledPrice: any;
  filledValue: any;
  effectiveAmount: any;
  effectivePrice: any;
  effectiveValue: any;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
};

export type BotTradesRow_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  exchange: Exchange;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        positionProfitLoss?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
      }
    | null
    | undefined;
};

export type BotTradesRow_TradeFragment = {
  __typename?: "Trade";
  id: string;
  absoluteProfit: any;
  profitPercentage: any;
  isClosed: boolean;
  fundingFee?: any | null | undefined;
  orders: Array<
    | {
        __typename?: "Order";
        externalId?: string | null | undefined;
        source: OrderSource;
        status: OrderStatus;
        side: OrderSide;
        placedTime: string;
        placedAmount: any;
        filledTime?: string | null | undefined;
        commissionPaid: any;
        limitPrice?: any | null | undefined;
        filledAmount: any;
        filledPrice: any;
        filledValue: any;
        effectiveAmount: any;
        effectivePrice: any;
        effectiveValue: any;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
};

export type BotTradesTable_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  exchange: Exchange;
  trades?:
    | {
        __typename?: "TradeConnection";
        edges?:
          | Array<
              | {
                  __typename?: "TradeConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "Trade";
                        id: string;
                        absoluteProfit: any;
                        profitPercentage: any;
                        isClosed: boolean;
                        fundingFee?: any | null | undefined;
                        orders: Array<
                          | {
                              __typename?: "Order";
                              externalId?: string | null | undefined;
                              source: OrderSource;
                              status: OrderStatus;
                              side: OrderSide;
                              placedTime: string;
                              placedAmount: any;
                              filledTime?: string | null | undefined;
                              commissionPaid: any;
                              limitPrice?: any | null | undefined;
                              filledAmount: any;
                              filledPrice: any;
                              filledValue: any;
                              effectiveAmount: any;
                              effectivePrice: any;
                              effectiveValue: any;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >;
                        currencyPairDetails?:
                          | {
                              __typename?: "CurrencyPairDetails";
                              id: string;
                              exchange: Exchange;
                              pair: CurrencyPair;
                              base: Currency;
                              quote: Currency;
                              settleCurrency: Currency;
                              positionCurrency: Currency;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | { __typename?: "PageInfo"; hasNextPage: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        positionProfitLoss?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
      }
    | null
    | undefined;
};

export type BotUpdate_UpdateFragment = {
  __typename?: "SyndicationUpdate";
  id: string;
  update?: string | null | undefined;
  syndicationId?: string | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
  placement?: SyndicationUpdatePlacement | null | undefined;
  audience?: SyndicationUpdateAudience | null | undefined;
};

export type BotUpdateHeader_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  firstName: string;
  lastName: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
};

export type BotUpdateHeader_UpdateFragment = {
  __typename?: "SyndicationUpdate";
  id: string;
  syndicationId?: string | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
  placement?: SyndicationUpdatePlacement | null | undefined;
  audience?: SyndicationUpdateAudience | null | undefined;
};

export type BotUpdates_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  owner: {
    __typename?: "PublicUser";
    id: string;
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
  };
  pinnedUpdate?:
    | {
        __typename?: "SyndicationUpdate";
        id: string;
        update?: string | null | undefined;
        syndicationId?: string | null | undefined;
        createdAt?: string | null | undefined;
        updatedAt?: string | null | undefined;
        placement?: SyndicationUpdatePlacement | null | undefined;
        audience?: SyndicationUpdateAudience | null | undefined;
      }
    | null
    | undefined;
  updates?:
    | {
        __typename?: "SyndicationUpdateConnection";
        edges?:
          | Array<
              | {
                  __typename?: "SyndicationUpdateConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "SyndicationUpdate";
                        id: string;
                        update?: string | null | undefined;
                        syndicationId?: string | null | undefined;
                        createdAt?: string | null | undefined;
                        updatedAt?: string | null | undefined;
                        placement?:
                          | SyndicationUpdatePlacement
                          | null
                          | undefined;
                        audience?: SyndicationUpdateAudience | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | { __typename?: "PageInfo"; hasNextPage: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetControlValuesQueryVariables = Exact<{ [key: string]: never }>;

export type GetControlValuesQuery = {
  __typename?: "RootQuery";
  exchanges?:
    | Array<
        | {
            __typename?: "ExchangeDetails";
            id: Exchange;
            currencyPairs: Array<
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined
            >;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  candleTypes?:
    | {
        __typename?: "__Type";
        enumValues?:
          | Array<{ __typename?: "__EnumValue"; name: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FormContextProviderQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<UserScriptFilters>;
}>;

export type FormContextProviderQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    scripts?:
      | {
          __typename?: "ScriptsConnection";
          edges?:
            | Array<
                | {
                    __typename?: "ScriptsConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "Script";
                          id: string;
                          isOwner: boolean;
                          language: ScriptLanguage;
                          name: string;
                          favorite?: boolean | null | undefined;
                          notes?: string | null | undefined;
                          isPublic: boolean;
                          latestVersionNumber?: number | null | undefined;
                          timesCopied?: number | null | undefined;
                          preloadedScript?: PreloadedScript | null | undefined;
                          latest: {
                            __typename?: "ScriptRevision";
                            id: string;
                            createdAt: string;
                            updatedAt: string;
                          };
                          latestValid?:
                            | {
                                __typename?: "ScriptRevision";
                                id: string;
                                status: ScriptRevisionStatus;
                                parameters: Array<
                                  | {
                                      __typename?: "ScriptParameterDetails";
                                      fieldName: string;
                                      valueType: ScriptParameterType;
                                      readableName?: string | null | undefined;
                                      description?: string | null | undefined;
                                      defaultValue?: string | null | undefined;
                                      options?:
                                        | Array<string | null | undefined>
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined
                                >;
                              }
                            | null
                            | undefined;
                          tags?:
                            | Array<
                                | {
                                    __typename?: "Tag";
                                    id: string;
                                    name: string;
                                    color: string;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                          ownerInfo?:
                            | {
                                __typename?: "ScriptOwnerInfo";
                                author?: string | null | undefined;
                                nickname?: string | null | undefined;
                                isTunedTeam?: boolean | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type NewFormDataProviderQueryVariables = Exact<{
  type: PreloadedScript;
}>;

export type NewFormDataProviderQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    preloadedScript?:
      | {
          __typename?: "Script";
          id: string;
          name: string;
          language: ScriptLanguage;
          latest: {
            __typename?: "ScriptRevision";
            id: string;
            status: ScriptRevisionStatus;
            parameters: Array<
              | {
                  __typename?: "ScriptParameterDetails";
                  fieldName: string;
                  valueType: ScriptParameterType;
                  readableName?: string | null | undefined;
                  description?: string | null | undefined;
                  defaultValue?: string | null | undefined;
                  options?: Array<string | null | undefined> | null | undefined;
                }
              | null
              | undefined
            >;
          };
        }
      | null
      | undefined;
  };
};

export type ComposerGeneralPanel_ScriptFragment = {
  __typename?: "Script";
  id: string;
  isOwner: boolean;
  language: ScriptLanguage;
  name: string;
  favorite?: boolean | null | undefined;
  notes?: string | null | undefined;
  isPublic: boolean;
  latestVersionNumber?: number | null | undefined;
  timesCopied?: number | null | undefined;
  preloadedScript?: PreloadedScript | null | undefined;
  latest: {
    __typename?: "ScriptRevision";
    id: string;
    createdAt: string;
    updatedAt: string;
  };
  latestValid?:
    | {
        __typename?: "ScriptRevision";
        id: string;
        status: ScriptRevisionStatus;
        parameters: Array<
          | {
              __typename?: "ScriptParameterDetails";
              fieldName: string;
              valueType: ScriptParameterType;
              readableName?: string | null | undefined;
              description?: string | null | undefined;
              defaultValue?: string | null | undefined;
              options?: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
  ownerInfo?:
    | {
        __typename?: "ScriptOwnerInfo";
        author?: string | null | undefined;
        nickname?: string | null | undefined;
        isTunedTeam?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type ExecutionSubscriptionNameQueryVariables = Exact<{
  botId: Scalars["ID"];
  executionId: Scalars["ID"];
}>;

export type ExecutionSubscriptionNameQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        subscription?:
          | {
              __typename?: "SharedExecution";
              shareToken: string;
              id?: string | null | undefined;
              ownerNickname?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ExecutionSubscriptionStatusInfoQueryVariables = Exact<{
  botId: Scalars["ID"];
  executionId: Scalars["ID"];
}>;

export type ExecutionSubscriptionStatusInfoQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        subscription?:
          | {
              __typename?: "SharedExecution";
              shareToken: string;
              id?: string | null | undefined;
              subscriptionStatus?:
                | SyndicationSubscriptionStatus
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ExecutionsInfiniteTable_ExecutionFragment = {
  __typename?: "Execution";
  id: string;
  name?: string | null | undefined;
  isPack: boolean;
  packPercentage?: any | null | undefined;
  status: ExecutionStatus;
  candleType?: CandleType | null | undefined;
  type: ExecutionType;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  multiCoinCurrency?: Currency | null | undefined;
  candleSize?: string;
  favorite?: boolean | null | undefined;
  error?: string | null | undefined;
  runtimeSeconds?: number | null | undefined;
  shareToken?: string | null | undefined;
  algorithmSettings?: string | null | undefined;
  createdAt?: string | null | undefined;
  leverage: any;
  leverageShort: any;
  runNumber?: string | null | undefined;
  multivariantIterationId?: number | null | undefined;
  scriptVersionSequence?: number | null | undefined;
  allocation?: any;
  rangeStart?: string | null | undefined;
  startedAt?: string | null | undefined;
  rangeEnd?: string | null | undefined;
  endedAt?: string | null | undefined;
  autoRebalance?: boolean | null | undefined;
  stage?: ExecutionStage | null | undefined;
  syndicationId?: string | null | undefined;
  stopLossPercentage?: any | null | undefined;
  stopLossTrailing?: boolean | null | undefined;
  takeProfitPercentage?: any | null | undefined;
  takeProfitTrailingPercentage?: any | null | undefined;
  notes?: string | null | undefined;
  preloadedScript?: PreloadedScript | null | undefined;
  maxBuy?: any | null | undefined;
  packExecutionsCount?: number | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | {
            __typename?: "MultiCoinPackCurrencyPercentage";
            currency: Currency;
            percentage: any;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        absoluteProfit?: any | null | undefined;
        numberOfTrades?: number | null | undefined;
        maxDrawdown?: any | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        profitability?: any | null | undefined;
        avgPositionPrice?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        positionAmount?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
        positionProfitLoss?: any | null | undefined;
        balance?: any | null | undefined;
        riskScore?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        profitFactor?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        positionState?: string | null | undefined;
        buyHoldReturn?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        totalRealizedGain?: any | null | undefined;
        totalRealizedLoss?: any | null | undefined;
        consistencyScore?: number | null | undefined;
      }
    | null
    | undefined;
  scriptDetails?:
    | {
        __typename?: "ScriptDetails";
        id: string;
        scriptId: string;
        revisionId: string;
        name: string;
        version?: number | null | undefined;
        isLatest?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        parameters: Array<
          | {
              __typename?: "ScriptParameterDetails";
              fieldName: string;
              readableName?: string | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        maxAllocation: any;
        minAllocation: any;
        description?: string | null | undefined;
        isVisible?: boolean | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
        shareToken?: string | null | undefined;
        activeSubscribersCount?: number | null | undefined;
        allowList?:
          | Array<
              | {
                  __typename?: "AllowListInfo";
                  nickname: string;
                  canRemove: boolean;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  syndication?:
    | { __typename?: "PublicSyndication"; name?: string | null | undefined }
    | null
    | undefined;
  owner?:
    | { __typename?: "PublicUser"; nickname?: string | null | undefined }
    | null
    | undefined;
};

export type ExecutionsInnerTable_ExecutionFragment = {
  __typename?: "Execution";
  id: string;
  isPack: boolean;
  syndicationId?: string | null | undefined;
  status: ExecutionStatus;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  multiCoinCurrency?: Currency | null | undefined;
  leverage: any;
  leverageShort: any;
  currencyPairDetails?:
    | { __typename?: "CurrencyPairDetails"; settleCurrency: Currency }
    | null
    | undefined;
};

export type ExecutionsSubRowsQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<ExecutionListFilters>;
  useCandleSize: Scalars["Boolean"];
  useAbsoluteProfit: Scalars["Boolean"];
  useNumberOfTrades: Scalars["Boolean"];
  useMaxDrawdown: Scalars["Boolean"];
  usePercentProfitableTrades: Scalars["Boolean"];
  useProfitability: Scalars["Boolean"];
  useAvgPositionPrice: Scalars["Boolean"];
  useAvgMonthlyProfit: Scalars["Boolean"];
  useAvgWinMonth: Scalars["Boolean"];
  useAvgLoseMonth: Scalars["Boolean"];
  usePercProfitableMonths: Scalars["Boolean"];
  usePositionAmount: Scalars["Boolean"];
  usePositionAbsoluteProfit: Scalars["Boolean"];
  usePositionProfitLoss: Scalars["Boolean"];
  useBalance: Scalars["Boolean"];
  useRiskScore: Scalars["Boolean"];
  useBuyHoldReturn: Scalars["Boolean"];
  useSharpeRatio: Scalars["Boolean"];
  useSortinoRatio: Scalars["Boolean"];
  useTotalRealizedGain: Scalars["Boolean"];
  useTotalRealizedLoss: Scalars["Boolean"];
  useConsistencyScore: Scalars["Boolean"];
  useScriptVersion: Scalars["Boolean"];
  useScriptName: Scalars["Boolean"];
  useCreatedAt: Scalars["Boolean"];
  useTags: Scalars["Boolean"];
  useAllocation: Scalars["Boolean"];
  useStartedAt: Scalars["Boolean"];
  useEndedAt: Scalars["Boolean"];
  useAutoRebalance: Scalars["Boolean"];
  isBot: Scalars["Boolean"];
  useActiveSubscribersCount: Scalars["Boolean"];
}>;

export type ExecutionsSubRowsQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        packExecutions?:
          | {
              __typename?: "ExecutionConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "ExecutionConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Execution";
                              id: string;
                              name?: string | null | undefined;
                              isPack: boolean;
                              packPercentage?: any | null | undefined;
                              status: ExecutionStatus;
                              candleType?: CandleType | null | undefined;
                              type: ExecutionType;
                              exchange: Exchange;
                              currencyPair: CurrencyPair;
                              multiCoinCurrency?: Currency | null | undefined;
                              candleSize?: string;
                              favorite?: boolean | null | undefined;
                              error?: string | null | undefined;
                              runtimeSeconds?: number | null | undefined;
                              shareToken?: string | null | undefined;
                              algorithmSettings?: string | null | undefined;
                              createdAt?: string | null | undefined;
                              leverage: any;
                              leverageShort: any;
                              runNumber?: string | null | undefined;
                              multivariantIterationId?:
                                | number
                                | null
                                | undefined;
                              scriptVersionSequence?: number | null | undefined;
                              allocation?: any;
                              rangeStart?: string | null | undefined;
                              startedAt?: string | null | undefined;
                              rangeEnd?: string | null | undefined;
                              endedAt?: string | null | undefined;
                              autoRebalance?: boolean | null | undefined;
                              stage?: ExecutionStage | null | undefined;
                              syndicationId?: string | null | undefined;
                              stopLossPercentage?: any | null | undefined;
                              stopLossTrailing?: boolean | null | undefined;
                              takeProfitPercentage?: any | null | undefined;
                              takeProfitTrailingPercentage?:
                                | any
                                | null
                                | undefined;
                              notes?: string | null | undefined;
                              preloadedScript?:
                                | PreloadedScript
                                | null
                                | undefined;
                              maxBuy?: any | null | undefined;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                              multiCoinPackExecutions?:
                                | Array<
                                    | {
                                        __typename?: "MultiCoinPackCurrencyPercentage";
                                        currency: Currency;
                                        percentage: any;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              measurements?:
                                | {
                                    __typename?: "Measurements";
                                    absoluteProfit?: any | null | undefined;
                                    numberOfTrades?: number | null | undefined;
                                    maxDrawdown?: any | null | undefined;
                                    percentProfitableTrades?:
                                      | any
                                      | null
                                      | undefined;
                                    profitability?: any | null | undefined;
                                    avgPositionPrice?: any | null | undefined;
                                    avgMonthlyProfit?: any | null | undefined;
                                    avgWinMonth?: any | null | undefined;
                                    avgLoseMonth?: any | null | undefined;
                                    percProfitableMonths?:
                                      | any
                                      | null
                                      | undefined;
                                    positionAmount?: any | null | undefined;
                                    positionAbsoluteProfit?:
                                      | any
                                      | null
                                      | undefined;
                                    positionProfitLoss?: any | null | undefined;
                                    balance?: any | null | undefined;
                                    riskScore?: any | null | undefined;
                                    avgBarsInTrade?: number | null | undefined;
                                    profitFactor?: any | null | undefined;
                                    avgTradePrice?: any | null | undefined;
                                    lastTick?: string | null | undefined;
                                    positionState?: string | null | undefined;
                                    buyHoldReturn?: any | null | undefined;
                                    sharpeRatio?: any | null | undefined;
                                    sortinoRatio?: any | null | undefined;
                                    totalRealizedGain?: any | null | undefined;
                                    totalRealizedLoss?: any | null | undefined;
                                    consistencyScore?:
                                      | number
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              scriptDetails?:
                                | {
                                    __typename?: "ScriptDetails";
                                    id: string;
                                    scriptId: string;
                                    revisionId: string;
                                    name: string;
                                    version?: number | null | undefined;
                                    isLatest?: boolean | null | undefined;
                                    createdAt?: string | null | undefined;
                                    parameters: Array<
                                      | {
                                          __typename?: "ScriptParameterDetails";
                                          fieldName: string;
                                          readableName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined
                                    >;
                                  }
                                | null
                                | undefined;
                              tags?:
                                | Array<
                                    | {
                                        __typename?: "Tag";
                                        id: string;
                                        name: string;
                                        color: string;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              syndicationSettings?:
                                | {
                                    __typename?: "SyndicationSettings";
                                    maxAllocation: any;
                                    minAllocation: any;
                                    description?: string | null | undefined;
                                    isVisible?: boolean | null | undefined;
                                    accessControl?:
                                      | SyndicationAccessControl
                                      | null
                                      | undefined;
                                    shareToken?: string | null | undefined;
                                    activeSubscribersCount?:
                                      | number
                                      | null
                                      | undefined;
                                    allowList?:
                                      | Array<
                                          | {
                                              __typename?: "AllowListInfo";
                                              nickname: string;
                                              canRemove: boolean;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              syndication?:
                                | {
                                    __typename?: "PublicSyndication";
                                    name?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              owner?:
                                | {
                                    __typename?: "PublicUser";
                                    nickname?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FilterListQueryVariables = Exact<{ [key: string]: never }>;

export type FilterListQuery = {
  __typename?: "RootQuery";
  filterPresets?:
    | Array<
        | {
            __typename?: "FilterPreset";
            id: string;
            name: string;
            filters: string;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type SaveFilters_FilterPresetFragment = {
  __typename?: "FilterPreset";
  id: string;
  name: string;
  filters: string;
};

export type ExecutionLogsQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type ExecutionLogsQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        logsConnection?:
          | {
              __typename?: "ExecutionLogConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "ExecutionLogConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "ExecutionLog";
                              id: string;
                              type?: string | null | undefined;
                              time?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ExecutionLogsTable_LogFragment = {
  __typename?: "ExecutionLog";
  id: string;
  type?: string | null | undefined;
  time?: string | null | undefined;
};

export type GuestPanelQueryVariables = Exact<{
  shareToken: Scalars["ID"];
}>;

export type GuestPanelQuery = {
  __typename?: "RootQuery";
  sharedExecution?:
    | {
        __typename?: "SharedExecution";
        shareToken: string;
        name?: string | null | undefined;
        scriptName?: string | null | undefined;
        syndicationName?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        candleSize: string;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PortfolioSubscriptionSubscriptionVariables = Exact<{
  currency?: InputMaybe<PortfolioCurrency>;
}>;

export type PortfolioSubscriptionSubscription = {
  __typename?: "Subscription";
  portfolio?:
    | {
        __typename?: "Portfolio";
        currency?: PortfolioCurrency | null | undefined;
        totalBalance?: any | null | undefined;
        totalInvested?: any | null | undefined;
        totalInPosition?: any | null | undefined;
        totalUnrealizedProfit?: any | null | undefined;
        totalUnrealizedProfitPerc?: any | null | undefined;
        totalRealizedProfit?: any | null | undefined;
        totalRealizedProfitPerc?: any | null | undefined;
      }
    | null
    | undefined;
};

export type MultivariantsRow_MultivariantFragment = {
  __typename?: "Multivariant";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  candleSize?: string | null | undefined;
  candleType?: CandleType | null | undefined;
  maxBuy?: string | null | undefined;
  minProfit?: string | null | undefined;
  stopLossPercentage?: string | null | undefined;
  stopLossTrailing?: boolean | null | undefined;
  takeProfitPercentage?: string | null | undefined;
  takeProfitTrailingPercentage?: string | null | undefined;
  algorithmSettings: string;
  status?: ExecutionStatus | null | undefined;
  totalCombinationsCount: number;
  canResume?: boolean | null | undefined;
  createdAt?: string | null | undefined;
  favorite?: boolean | null | undefined;
  scriptVersionSequence?: number | null | undefined;
  allocation?: any | null | undefined;
  performanceMode: ExecutionPerformanceMode;
  leverage?: string | null | undefined;
  leverageShort?: string | null | undefined;
  lookbackDays: number;
  buyActionTime?: string | null | undefined;
  sellActionTime?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  scriptDetails: {
    __typename?: "ScriptDetails";
    id: string;
    scriptId: string;
    revisionId: string;
    name: string;
    language: ScriptLanguage;
    version?: number | null | undefined;
    isLatest?: boolean | null | undefined;
    createdAt?: string | null | undefined;
    parameters: Array<
      | {
          __typename?: "ScriptParameterDetails";
          readableName?: string | null | undefined;
          fieldName: string;
        }
      | null
      | undefined
    >;
  };
  progress?:
    | Array<
        | {
            __typename?: "ExecutionStatusCount";
            status: ExecutionStatus;
            count: number;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type MultivariantsTable_MultivariantFragment = {
  __typename?: "Multivariant";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  candleSize?: string | null | undefined;
  candleType?: CandleType | null | undefined;
  maxBuy?: string | null | undefined;
  minProfit?: string | null | undefined;
  stopLossPercentage?: string | null | undefined;
  stopLossTrailing?: boolean | null | undefined;
  takeProfitPercentage?: string | null | undefined;
  takeProfitTrailingPercentage?: string | null | undefined;
  algorithmSettings: string;
  status?: ExecutionStatus | null | undefined;
  totalCombinationsCount: number;
  canResume?: boolean | null | undefined;
  createdAt?: string | null | undefined;
  favorite?: boolean | null | undefined;
  scriptVersionSequence?: number | null | undefined;
  allocation?: any | null | undefined;
  performanceMode: ExecutionPerformanceMode;
  leverage?: string | null | undefined;
  leverageShort?: string | null | undefined;
  lookbackDays: number;
  buyActionTime?: string | null | undefined;
  sellActionTime?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  scriptDetails: {
    __typename?: "ScriptDetails";
    id: string;
    scriptId: string;
    revisionId: string;
    name: string;
    language: ScriptLanguage;
    version?: number | null | undefined;
    isLatest?: boolean | null | undefined;
    createdAt?: string | null | undefined;
    parameters: Array<
      | {
          __typename?: "ScriptParameterDetails";
          readableName?: string | null | undefined;
          fieldName: string;
        }
      | null
      | undefined
    >;
  };
  progress?:
    | Array<
        | {
            __typename?: "ExecutionStatusCount";
            status: ExecutionStatus;
            count: number;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PublicScriptsSubpageQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ScriptSort>;
  filters?: InputMaybe<PublicScriptFilters>;
}>;

export type PublicScriptsSubpageQuery = {
  __typename?: "RootQuery";
  publicScriptListCount?: number | null | undefined;
  publicScriptList?:
    | {
        __typename?: "ScriptsConnection";
        edges?:
          | Array<
              | {
                  __typename?: "ScriptsConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "Script";
                        id: string;
                        isOwner: boolean;
                        language: ScriptLanguage;
                        name: string;
                        favorite?: boolean | null | undefined;
                        notes?: string | null | undefined;
                        isPublic: boolean;
                        latestVersionNumber?: number | null | undefined;
                        timesCopied?: number | null | undefined;
                        preloadedScript?: PreloadedScript | null | undefined;
                        latest: {
                          __typename?: "ScriptRevision";
                          id: string;
                          createdAt: string;
                          updatedAt: string;
                        };
                        latestValid?:
                          | {
                              __typename?: "ScriptRevision";
                              id: string;
                              status: ScriptRevisionStatus;
                              parameters: Array<
                                | {
                                    __typename?: "ScriptParameterDetails";
                                    fieldName: string;
                                    valueType: ScriptParameterType;
                                    readableName?: string | null | undefined;
                                    description?: string | null | undefined;
                                    defaultValue?: string | null | undefined;
                                    options?:
                                      | Array<string | null | undefined>
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >;
                            }
                          | null
                          | undefined;
                        tags?:
                          | Array<
                              | {
                                  __typename?: "Tag";
                                  id: string;
                                  name: string;
                                  color: string;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                        ownerInfo?:
                          | {
                              __typename?: "ScriptOwnerInfo";
                              author?: string | null | undefined;
                              nickname?: string | null | undefined;
                              isTunedTeam?: boolean | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | { __typename?: "PageInfo"; hasNextPage: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ScriptsSidePanelQueryVariables = Exact<{
  id: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ScriptsSidePanelQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        id: string;
        language: ScriptLanguage;
        name: string;
        isPublic: boolean;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
        };
      }
    | null
    | undefined;
};

export type ScriptsTable_ScriptFragment = {
  __typename?: "Script";
  id: string;
  isOwner: boolean;
  language: ScriptLanguage;
  name: string;
  favorite?: boolean | null | undefined;
  notes?: string | null | undefined;
  isPublic: boolean;
  latestVersionNumber?: number | null | undefined;
  timesCopied?: number | null | undefined;
  preloadedScript?: PreloadedScript | null | undefined;
  latest: {
    __typename?: "ScriptRevision";
    id: string;
    createdAt: string;
    updatedAt: string;
  };
  latestValid?:
    | {
        __typename?: "ScriptRevision";
        id: string;
        status: ScriptRevisionStatus;
        parameters: Array<
          | {
              __typename?: "ScriptParameterDetails";
              fieldName: string;
              valueType: ScriptParameterType;
              readableName?: string | null | undefined;
              description?: string | null | undefined;
              defaultValue?: string | null | undefined;
              options?: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
  ownerInfo?:
    | {
        __typename?: "ScriptOwnerInfo";
        author?: string | null | undefined;
        nickname?: string | null | undefined;
        isTunedTeam?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type UserScriptsSubpageQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ScriptSort>;
  filters?: InputMaybe<UserScriptFilters>;
}>;

export type UserScriptsSubpageQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    scriptsCount?: number | null | undefined;
    scripts?:
      | {
          __typename?: "ScriptsConnection";
          edges?:
            | Array<
                | {
                    __typename?: "ScriptsConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "Script";
                          id: string;
                          isOwner: boolean;
                          language: ScriptLanguage;
                          name: string;
                          favorite?: boolean | null | undefined;
                          notes?: string | null | undefined;
                          isPublic: boolean;
                          latestVersionNumber?: number | null | undefined;
                          timesCopied?: number | null | undefined;
                          preloadedScript?: PreloadedScript | null | undefined;
                          latest: {
                            __typename?: "ScriptRevision";
                            id: string;
                            createdAt: string;
                            updatedAt: string;
                          };
                          latestValid?:
                            | {
                                __typename?: "ScriptRevision";
                                id: string;
                                status: ScriptRevisionStatus;
                                parameters: Array<
                                  | {
                                      __typename?: "ScriptParameterDetails";
                                      fieldName: string;
                                      valueType: ScriptParameterType;
                                      readableName?: string | null | undefined;
                                      description?: string | null | undefined;
                                      defaultValue?: string | null | undefined;
                                      options?:
                                        | Array<string | null | undefined>
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined
                                >;
                              }
                            | null
                            | undefined;
                          tags?:
                            | Array<
                                | {
                                    __typename?: "Tag";
                                    id: string;
                                    name: string;
                                    color: string;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                          ownerInfo?:
                            | {
                                __typename?: "ScriptOwnerInfo";
                                author?: string | null | undefined;
                                nickname?: string | null | undefined;
                                isTunedTeam?: boolean | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type MostPopularTradersSubpageQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
}>;

export type MostPopularTradersSubpageQuery = {
  __typename?: "RootQuery";
  mostPopularCreators?:
    | {
        __typename?: "PublicUserConnection";
        edges?:
          | Array<
              | {
                  __typename?: "PublicUserConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "PublicUser";
                        id: string;
                        exchanges?:
                          | Array<Exchange | null | undefined>
                          | null
                          | undefined;
                        firstName: string;
                        lastName: string;
                        companyName?: string | null | undefined;
                        nickname?: string | null | undefined;
                        profilePhotoUrl?: string | null | undefined;
                        scriptsCount?: number | null | undefined;
                        tradingSince: string;
                        syndicationsCount?:
                          | {
                              __typename?: "ResultSize";
                              count?: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TraderProfilePanelQueryVariables = Exact<{
  nickname: Scalars["String"];
}>;

export type TraderProfilePanelQuery = {
  __typename?: "RootQuery";
  publicUser?:
    | {
        __typename?: "PublicUser";
        id: string;
        nickname?: string | null | undefined;
        firstName: string;
        lastName: string;
        tradingSince: string;
        companyName?: string | null | undefined;
        country?: string | null | undefined;
        profilePhotoUrl?: string | null | undefined;
        description?: string | null | undefined;
        exchanges?: Array<Exchange | null | undefined> | null | undefined;
        twitterUrl?: string | null | undefined;
        discordUrl?: string | null | undefined;
        telegramUrl?: string | null | undefined;
        mediumUrl?: string | null | undefined;
        linkedinUrl?: string | null | undefined;
        personalBlogUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

export type TraderRow_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  exchanges?: Array<Exchange | null | undefined> | null | undefined;
  firstName: string;
  lastName: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
  scriptsCount?: number | null | undefined;
  tradingSince: string;
  syndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type TradersSubpageQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<CreatorFilters>;
  sort?: InputMaybe<UserSort>;
}>;

export type TradersSubpageQuery = {
  __typename?: "RootQuery";
  creatorsCount?: number | null | undefined;
  creators?:
    | {
        __typename?: "PublicUserConnection";
        edges?:
          | Array<
              | {
                  __typename?: "PublicUserConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "PublicUser";
                        id: string;
                        exchanges?:
                          | Array<Exchange | null | undefined>
                          | null
                          | undefined;
                        firstName: string;
                        lastName: string;
                        companyName?: string | null | undefined;
                        nickname?: string | null | undefined;
                        profilePhotoUrl?: string | null | undefined;
                        scriptsCount?: number | null | undefined;
                        tradingSince: string;
                        syndicationsCount?:
                          | {
                              __typename?: "ResultSize";
                              count?: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | { __typename?: "PageInfo"; hasNextPage: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TradersTable_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  exchanges?: Array<Exchange | null | undefined> | null | undefined;
  firstName: string;
  lastName: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
  scriptsCount?: number | null | undefined;
  tradingSince: string;
  syndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type TraderBotsCountField_PublicUserFragment = {
  __typename?: "PublicUser";
  syndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type TraderExchangesField_PublicUserFragment = {
  __typename?: "PublicUser";
  exchanges?: Array<Exchange | null | undefined> | null | undefined;
};

export type TraderNameField_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  firstName: string;
  lastName: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
};

export type TraderRegistrationDateField_PublicUserFragment = {
  __typename?: "PublicUser";
  tradingSince: string;
};

export type TraderScriptsCountField_PublicUserFragment = {
  __typename?: "PublicUser";
  scriptsCount?: number | null | undefined;
};

export type TraderTotalAllocatedFieldQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type TraderTotalAllocatedFieldQuery = {
  __typename?: "RootQuery";
  publicProfile?:
    | {
        __typename?: "PublicUser";
        id: string;
        totalAllocated?: any | null | undefined;
      }
    | null
    | undefined;
};

export type TraderTotalTradesFieldQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type TraderTotalTradesFieldQuery = {
  __typename?: "RootQuery";
  publicProfile?:
    | {
        __typename?: "PublicUser";
        id: string;
        totalSyndicationsTrades?: number | null | undefined;
      }
    | null
    | undefined;
};

export type TraderBotsAndScriptsQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type TraderBotsAndScriptsQuery = {
  __typename?: "RootQuery";
  publicProfile?:
    | {
        __typename?: "PublicUser";
        id: string;
        profitableSyndicationsCount?: number | null | undefined;
        unprofitableSyndicationsCount?: number | null | undefined;
        tunedScriptsCount?: number | null | undefined;
        pineScriptsCount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type TraderDescription_PublicUserFragment = {
  __typename?: "PublicUser";
  description?: string | null | undefined;
};

export type TraderExchanges_PublicUserFragment = {
  __typename?: "PublicUser";
  exchanges?: Array<Exchange | null | undefined> | null | undefined;
};

export type TraderFooterButtons_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
};

export type TraderHeaderInfo_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  firstName: string;
  lastName: string;
  tradingSince: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  country?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
};

export type TraderLinks_PublicUserFragment = {
  __typename?: "PublicUser";
  twitterUrl?: string | null | undefined;
  discordUrl?: string | null | undefined;
  telegramUrl?: string | null | undefined;
  mediumUrl?: string | null | undefined;
  linkedinUrl?: string | null | undefined;
  personalBlogUrl?: string | null | undefined;
};

export type ExecutionTrades_ExecutionFragment = {
  __typename?: "Execution";
  id: string;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  syndicationId?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        numberOfTrades?: number | null | undefined;
        positionProfitLoss?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
      }
    | null
    | undefined;
};

export type ExecutionTrades_SharedExecutionFragment = {
  __typename?: "SharedExecution";
  shareToken: string;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  multiCoinCurrency?: Currency | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        numberOfTrades?: number | null | undefined;
        positionProfitLoss?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
      }
    | null
    | undefined;
};

export type TradesTable_TradeFragment = {
  __typename?: "Trade";
  id: string;
  absoluteProfit: any;
  profitPercentage: any;
  isClosed: boolean;
  isProfitable: boolean;
  fundingFee?: any | null | undefined;
  orders: Array<
    | {
        __typename?: "Order";
        externalId?: string | null | undefined;
        source: OrderSource;
        status: OrderStatus;
        side: OrderSide;
        placedTime: string;
        placedAmount: any;
        filledTime?: string | null | undefined;
        commissionPaid: any;
        limitPrice?: any | null | undefined;
        filledAmount: any;
        filledPrice: any;
        filledValue: any;
        effectiveAmount: any;
        effectivePrice: any;
        effectiveValue: any;
      }
    | null
    | undefined
  >;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
};

export type GettingStartedContextQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GettingStartedContextQuery = {
  __typename?: "RootQuery";
  gettingStarted?:
    | {
        __typename?: "GettingStarted";
        openedEditor?: boolean | null | undefined;
        createdExperiment?: boolean | null | undefined;
        createdMultivariant?: boolean | null | undefined;
        promotedExperiment?: boolean | null | undefined;
        promotedCandidate?: boolean | null | undefined;
        addedCredential?: boolean | null | undefined;
        launchedLive?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type CreateShortLinkMutationVariables = Exact<{
  in: CreateShortLinkInput;
}>;

export type CreateShortLinkMutation = {
  __typename?: "Mutation";
  createShortLink?:
    | { __typename?: "CreateShortLinkPayload"; url?: string | null | undefined }
    | null
    | undefined;
};

export type TourContextQueryVariables = Exact<{ [key: string]: never }>;

export type TourContextQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    id: string;
    completedTours?: Array<UserTourType | null | undefined> | null | undefined;
  };
};

export type TradersListContextProvider_PublicUserFragment = {
  __typename?: "PublicUser";
  id: string;
  exchanges?: Array<Exchange | null | undefined> | null | undefined;
  firstName: string;
  lastName: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
  scriptsCount?: number | null | undefined;
  tradingSince: string;
  syndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type ExecutionContextDeprecatedQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<ExecutionListFilters>;
}>;

export type ExecutionContextDeprecatedQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    executionsCount?: number | null | undefined;
    executions?:
      | {
          __typename?: "ExecutionConnection";
          edges?:
            | Array<
                | {
                    __typename?: "ExecutionConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "Execution";
                          id: string;
                          isPack: boolean;
                          syndicationId?: string | null | undefined;
                          status: ExecutionStatus;
                          exchange: Exchange;
                          currencyPair: CurrencyPair;
                          multiCoinCurrency?: Currency | null | undefined;
                          leverage: any;
                          leverageShort: any;
                          currencyPairDetails?:
                            | {
                                __typename?: "CurrencyPairDetails";
                                settleCurrency: Currency;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type ExecutionContextQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<ExecutionListFilters>;
  useCandleSize: Scalars["Boolean"];
  useAbsoluteProfit: Scalars["Boolean"];
  useNumberOfTrades: Scalars["Boolean"];
  useMaxDrawdown: Scalars["Boolean"];
  usePercentProfitableTrades: Scalars["Boolean"];
  useProfitability: Scalars["Boolean"];
  useAvgPositionPrice: Scalars["Boolean"];
  useAvgMonthlyProfit: Scalars["Boolean"];
  useAvgWinMonth: Scalars["Boolean"];
  useAvgLoseMonth: Scalars["Boolean"];
  usePercProfitableMonths: Scalars["Boolean"];
  usePositionAmount: Scalars["Boolean"];
  usePositionAbsoluteProfit: Scalars["Boolean"];
  usePositionProfitLoss: Scalars["Boolean"];
  useBalance: Scalars["Boolean"];
  useRiskScore: Scalars["Boolean"];
  useBuyHoldReturn: Scalars["Boolean"];
  useSharpeRatio: Scalars["Boolean"];
  useSortinoRatio: Scalars["Boolean"];
  useTotalRealizedGain: Scalars["Boolean"];
  useTotalRealizedLoss: Scalars["Boolean"];
  useConsistencyScore: Scalars["Boolean"];
  useScriptVersion: Scalars["Boolean"];
  useScriptName: Scalars["Boolean"];
  useCreatedAt: Scalars["Boolean"];
  useTags: Scalars["Boolean"];
  useAllocation: Scalars["Boolean"];
  useStartedAt: Scalars["Boolean"];
  useEndedAt: Scalars["Boolean"];
  useAutoRebalance: Scalars["Boolean"];
  isBot: Scalars["Boolean"];
  useActiveSubscribersCount: Scalars["Boolean"];
}>;

export type ExecutionContextQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    executionsCount?: number | null | undefined;
    executions?:
      | {
          __typename?: "ExecutionConnection";
          edges?:
            | Array<
                | {
                    __typename?: "ExecutionConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "Execution";
                          id: string;
                          name?: string | null | undefined;
                          isPack: boolean;
                          packPercentage?: any | null | undefined;
                          status: ExecutionStatus;
                          candleType?: CandleType | null | undefined;
                          type: ExecutionType;
                          exchange: Exchange;
                          currencyPair: CurrencyPair;
                          multiCoinCurrency?: Currency | null | undefined;
                          candleSize?: string;
                          favorite?: boolean | null | undefined;
                          error?: string | null | undefined;
                          runtimeSeconds?: number | null | undefined;
                          shareToken?: string | null | undefined;
                          algorithmSettings?: string | null | undefined;
                          createdAt?: string | null | undefined;
                          leverage: any;
                          leverageShort: any;
                          runNumber?: string | null | undefined;
                          multivariantIterationId?: number | null | undefined;
                          scriptVersionSequence?: number | null | undefined;
                          allocation?: any;
                          rangeStart?: string | null | undefined;
                          startedAt?: string | null | undefined;
                          rangeEnd?: string | null | undefined;
                          endedAt?: string | null | undefined;
                          autoRebalance?: boolean | null | undefined;
                          stage?: ExecutionStage | null | undefined;
                          syndicationId?: string | null | undefined;
                          stopLossPercentage?: any | null | undefined;
                          stopLossTrailing?: boolean | null | undefined;
                          takeProfitPercentage?: any | null | undefined;
                          takeProfitTrailingPercentage?: any | null | undefined;
                          notes?: string | null | undefined;
                          preloadedScript?: PreloadedScript | null | undefined;
                          maxBuy?: any | null | undefined;
                          packExecutionsCount?: number | null | undefined;
                          currencyPairDetails?:
                            | {
                                __typename?: "CurrencyPairDetails";
                                id: string;
                                exchange: Exchange;
                                pair: CurrencyPair;
                                base: Currency;
                                quote: Currency;
                                settleCurrency: Currency;
                                positionCurrency: Currency;
                              }
                            | null
                            | undefined;
                          multiCoinPackExecutions?:
                            | Array<
                                | {
                                    __typename?: "MultiCoinPackCurrencyPercentage";
                                    currency: Currency;
                                    percentage: any;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                          measurements?:
                            | {
                                __typename?: "Measurements";
                                absoluteProfit?: any | null | undefined;
                                numberOfTrades?: number | null | undefined;
                                maxDrawdown?: any | null | undefined;
                                percentProfitableTrades?:
                                  | any
                                  | null
                                  | undefined;
                                profitability?: any | null | undefined;
                                avgPositionPrice?: any | null | undefined;
                                avgMonthlyProfit?: any | null | undefined;
                                avgWinMonth?: any | null | undefined;
                                avgLoseMonth?: any | null | undefined;
                                percProfitableMonths?: any | null | undefined;
                                positionAmount?: any | null | undefined;
                                positionAbsoluteProfit?: any | null | undefined;
                                positionProfitLoss?: any | null | undefined;
                                balance?: any | null | undefined;
                                riskScore?: any | null | undefined;
                                avgBarsInTrade?: number | null | undefined;
                                profitFactor?: any | null | undefined;
                                avgTradePrice?: any | null | undefined;
                                lastTick?: string | null | undefined;
                                positionState?: string | null | undefined;
                                buyHoldReturn?: any | null | undefined;
                                sharpeRatio?: any | null | undefined;
                                sortinoRatio?: any | null | undefined;
                                totalRealizedGain?: any | null | undefined;
                                totalRealizedLoss?: any | null | undefined;
                                consistencyScore?: number | null | undefined;
                              }
                            | null
                            | undefined;
                          scriptDetails?:
                            | {
                                __typename?: "ScriptDetails";
                                id: string;
                                scriptId: string;
                                revisionId: string;
                                name: string;
                                version?: number | null | undefined;
                                isLatest?: boolean | null | undefined;
                                createdAt?: string | null | undefined;
                                parameters: Array<
                                  | {
                                      __typename?: "ScriptParameterDetails";
                                      fieldName: string;
                                      readableName?: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >;
                              }
                            | null
                            | undefined;
                          tags?:
                            | Array<
                                | {
                                    __typename?: "Tag";
                                    id: string;
                                    name: string;
                                    color: string;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                          syndicationSettings?:
                            | {
                                __typename?: "SyndicationSettings";
                                maxAllocation: any;
                                minAllocation: any;
                                description?: string | null | undefined;
                                isVisible?: boolean | null | undefined;
                                accessControl?:
                                  | SyndicationAccessControl
                                  | null
                                  | undefined;
                                shareToken?: string | null | undefined;
                                activeSubscribersCount?:
                                  | number
                                  | null
                                  | undefined;
                                allowList?:
                                  | Array<
                                      | {
                                          __typename?: "AllowListInfo";
                                          nickname: string;
                                          canRemove: boolean;
                                        }
                                      | null
                                      | undefined
                                    >
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          syndication?:
                            | {
                                __typename?: "PublicSyndication";
                                name?: string | null | undefined;
                              }
                            | null
                            | undefined;
                          owner?:
                            | {
                                __typename?: "PublicUser";
                                nickname?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type MultivariantColumnContextProviderQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<
    Array<InputMaybe<MultivariantFilter>> | InputMaybe<MultivariantFilter>
  >;
  sort?: InputMaybe<MultivariantSort>;
}>;

export type MultivariantColumnContextProviderQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    batchTestsCount?: number | null | undefined;
    batchTests?:
      | {
          __typename?: "MultivariantConnection";
          edges?:
            | Array<
                | {
                    __typename?: "MultivariantConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "Multivariant";
                          id: string;
                          name?: string | null | undefined;
                          exchange: Exchange;
                          currencyPair: CurrencyPair;
                          candleSize?: string | null | undefined;
                          candleType?: CandleType | null | undefined;
                          maxBuy?: string | null | undefined;
                          minProfit?: string | null | undefined;
                          stopLossPercentage?: string | null | undefined;
                          stopLossTrailing?: boolean | null | undefined;
                          takeProfitPercentage?: string | null | undefined;
                          takeProfitTrailingPercentage?:
                            | string
                            | null
                            | undefined;
                          algorithmSettings: string;
                          status?: ExecutionStatus | null | undefined;
                          totalCombinationsCount: number;
                          canResume?: boolean | null | undefined;
                          createdAt?: string | null | undefined;
                          favorite?: boolean | null | undefined;
                          scriptVersionSequence?: number | null | undefined;
                          allocation?: any | null | undefined;
                          performanceMode: ExecutionPerformanceMode;
                          leverage?: string | null | undefined;
                          leverageShort?: string | null | undefined;
                          lookbackDays: number;
                          buyActionTime?: string | null | undefined;
                          sellActionTime?: string | null | undefined;
                          currencyPairDetails?:
                            | {
                                __typename?: "CurrencyPairDetails";
                                id: string;
                                exchange: Exchange;
                                pair: CurrencyPair;
                                base: Currency;
                                quote: Currency;
                                settleCurrency: Currency;
                                positionCurrency: Currency;
                              }
                            | null
                            | undefined;
                          scriptDetails: {
                            __typename?: "ScriptDetails";
                            id: string;
                            scriptId: string;
                            revisionId: string;
                            name: string;
                            language: ScriptLanguage;
                            version?: number | null | undefined;
                            isLatest?: boolean | null | undefined;
                            createdAt?: string | null | undefined;
                            parameters: Array<
                              | {
                                  __typename?: "ScriptParameterDetails";
                                  readableName?: string | null | undefined;
                                  fieldName: string;
                                }
                              | null
                              | undefined
                            >;
                          };
                          progress?:
                            | Array<
                                | {
                                    __typename?: "ExecutionStatusCount";
                                    status: ExecutionStatus;
                                    count: number;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                          tags?:
                            | Array<
                                | {
                                    __typename?: "Tag";
                                    id: string;
                                    name: string;
                                    color: string;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type MultivariantExecutionContextProviderDeprecatedQueryVariables =
  Exact<{
    id: Scalars["ID"];
    first?: InputMaybe<Scalars["Int"]>;
    after?: InputMaybe<Scalars["String"]>;
    sort?: InputMaybe<ExecutionSort>;
    filters?: InputMaybe<ExecutionListFilters>;
  }>;

export type MultivariantExecutionContextProviderDeprecatedQuery = {
  __typename?: "RootQuery";
  multivariant?:
    | {
        __typename?: "Multivariant";
        testsCount?: number | null | undefined;
        tests?:
          | {
              __typename?: "ExecutionConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "ExecutionConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Execution";
                              id: string;
                              isPack: boolean;
                              syndicationId?: string | null | undefined;
                              status: ExecutionStatus;
                              exchange: Exchange;
                              currencyPair: CurrencyPair;
                              multiCoinCurrency?: Currency | null | undefined;
                              leverage: any;
                              leverageShort: any;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    settleCurrency: Currency;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MultivariantExecutionContextProviderQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<ExecutionListFilters>;
  useCandleSize: Scalars["Boolean"];
  useAbsoluteProfit: Scalars["Boolean"];
  useNumberOfTrades: Scalars["Boolean"];
  useMaxDrawdown: Scalars["Boolean"];
  usePercentProfitableTrades: Scalars["Boolean"];
  useProfitability: Scalars["Boolean"];
  useAvgPositionPrice: Scalars["Boolean"];
  useAvgMonthlyProfit: Scalars["Boolean"];
  useAvgWinMonth: Scalars["Boolean"];
  useAvgLoseMonth: Scalars["Boolean"];
  usePercProfitableMonths: Scalars["Boolean"];
  usePositionAmount: Scalars["Boolean"];
  usePositionAbsoluteProfit: Scalars["Boolean"];
  usePositionProfitLoss: Scalars["Boolean"];
  useBalance: Scalars["Boolean"];
  useRiskScore: Scalars["Boolean"];
  useBuyHoldReturn: Scalars["Boolean"];
  useSharpeRatio: Scalars["Boolean"];
  useSortinoRatio: Scalars["Boolean"];
  useTotalRealizedGain: Scalars["Boolean"];
  useTotalRealizedLoss: Scalars["Boolean"];
  useConsistencyScore: Scalars["Boolean"];
  useScriptVersion: Scalars["Boolean"];
  useScriptName: Scalars["Boolean"];
  useCreatedAt: Scalars["Boolean"];
  useTags: Scalars["Boolean"];
  useAllocation: Scalars["Boolean"];
  useStartedAt: Scalars["Boolean"];
  useEndedAt: Scalars["Boolean"];
  useAutoRebalance: Scalars["Boolean"];
  isBot: Scalars["Boolean"];
  useActiveSubscribersCount: Scalars["Boolean"];
}>;

export type MultivariantExecutionContextProviderQuery = {
  __typename?: "RootQuery";
  multivariant?:
    | {
        __typename?: "Multivariant";
        testsCount?: number | null | undefined;
        tests?:
          | {
              __typename?: "ExecutionConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "ExecutionConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Execution";
                              id: string;
                              name?: string | null | undefined;
                              isPack: boolean;
                              packPercentage?: any | null | undefined;
                              status: ExecutionStatus;
                              candleType?: CandleType | null | undefined;
                              type: ExecutionType;
                              exchange: Exchange;
                              currencyPair: CurrencyPair;
                              multiCoinCurrency?: Currency | null | undefined;
                              candleSize?: string;
                              favorite?: boolean | null | undefined;
                              error?: string | null | undefined;
                              runtimeSeconds?: number | null | undefined;
                              shareToken?: string | null | undefined;
                              algorithmSettings?: string | null | undefined;
                              createdAt?: string | null | undefined;
                              leverage: any;
                              leverageShort: any;
                              runNumber?: string | null | undefined;
                              multivariantIterationId?:
                                | number
                                | null
                                | undefined;
                              scriptVersionSequence?: number | null | undefined;
                              allocation?: any;
                              rangeStart?: string | null | undefined;
                              startedAt?: string | null | undefined;
                              rangeEnd?: string | null | undefined;
                              endedAt?: string | null | undefined;
                              autoRebalance?: boolean | null | undefined;
                              stage?: ExecutionStage | null | undefined;
                              syndicationId?: string | null | undefined;
                              stopLossPercentage?: any | null | undefined;
                              stopLossTrailing?: boolean | null | undefined;
                              takeProfitPercentage?: any | null | undefined;
                              takeProfitTrailingPercentage?:
                                | any
                                | null
                                | undefined;
                              notes?: string | null | undefined;
                              preloadedScript?:
                                | PreloadedScript
                                | null
                                | undefined;
                              maxBuy?: any | null | undefined;
                              packExecutionsCount?: number | null | undefined;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                              multiCoinPackExecutions?:
                                | Array<
                                    | {
                                        __typename?: "MultiCoinPackCurrencyPercentage";
                                        currency: Currency;
                                        percentage: any;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              measurements?:
                                | {
                                    __typename?: "Measurements";
                                    absoluteProfit?: any | null | undefined;
                                    numberOfTrades?: number | null | undefined;
                                    maxDrawdown?: any | null | undefined;
                                    percentProfitableTrades?:
                                      | any
                                      | null
                                      | undefined;
                                    profitability?: any | null | undefined;
                                    avgPositionPrice?: any | null | undefined;
                                    avgMonthlyProfit?: any | null | undefined;
                                    avgWinMonth?: any | null | undefined;
                                    avgLoseMonth?: any | null | undefined;
                                    percProfitableMonths?:
                                      | any
                                      | null
                                      | undefined;
                                    positionAmount?: any | null | undefined;
                                    positionAbsoluteProfit?:
                                      | any
                                      | null
                                      | undefined;
                                    positionProfitLoss?: any | null | undefined;
                                    balance?: any | null | undefined;
                                    riskScore?: any | null | undefined;
                                    avgBarsInTrade?: number | null | undefined;
                                    profitFactor?: any | null | undefined;
                                    avgTradePrice?: any | null | undefined;
                                    lastTick?: string | null | undefined;
                                    positionState?: string | null | undefined;
                                    buyHoldReturn?: any | null | undefined;
                                    sharpeRatio?: any | null | undefined;
                                    sortinoRatio?: any | null | undefined;
                                    totalRealizedGain?: any | null | undefined;
                                    totalRealizedLoss?: any | null | undefined;
                                    consistencyScore?:
                                      | number
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              scriptDetails?:
                                | {
                                    __typename?: "ScriptDetails";
                                    id: string;
                                    scriptId: string;
                                    revisionId: string;
                                    name: string;
                                    version?: number | null | undefined;
                                    isLatest?: boolean | null | undefined;
                                    createdAt?: string | null | undefined;
                                    parameters: Array<
                                      | {
                                          __typename?: "ScriptParameterDetails";
                                          fieldName: string;
                                          readableName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined
                                    >;
                                  }
                                | null
                                | undefined;
                              tags?:
                                | Array<
                                    | {
                                        __typename?: "Tag";
                                        id: string;
                                        name: string;
                                        color: string;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              syndicationSettings?:
                                | {
                                    __typename?: "SyndicationSettings";
                                    maxAllocation: any;
                                    minAllocation: any;
                                    description?: string | null | undefined;
                                    isVisible?: boolean | null | undefined;
                                    accessControl?:
                                      | SyndicationAccessControl
                                      | null
                                      | undefined;
                                    shareToken?: string | null | undefined;
                                    activeSubscribersCount?:
                                      | number
                                      | null
                                      | undefined;
                                    allowList?:
                                      | Array<
                                          | {
                                              __typename?: "AllowListInfo";
                                              nickname: string;
                                              canRemove: boolean;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              syndication?:
                                | {
                                    __typename?: "PublicSyndication";
                                    name?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              owner?:
                                | {
                                    __typename?: "PublicUser";
                                    nickname?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SyndicationSubscriptionsContextProviderDeprecatedQueryVariables =
  Exact<{
    id: Scalars["ID"];
    first?: InputMaybe<Scalars["Int"]>;
    after?: InputMaybe<Scalars["String"]>;
    sort?: InputMaybe<ExecutionSort>;
    filters?: InputMaybe<ExecutionListFilters>;
  }>;

export type SyndicationSubscriptionsContextProviderDeprecatedQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        subscriptionExecutionsCount?: number | null | undefined;
        subscriptionExecutions?:
          | {
              __typename?: "ExecutionConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "ExecutionConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | { __typename?: "Execution"; id: string }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SyndicationSubscriptionsContextProviderQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<ExecutionListFilters>;
  useCandleSize: Scalars["Boolean"];
  useAbsoluteProfit: Scalars["Boolean"];
  useNumberOfTrades: Scalars["Boolean"];
  useMaxDrawdown: Scalars["Boolean"];
  usePercentProfitableTrades: Scalars["Boolean"];
  useProfitability: Scalars["Boolean"];
  useAvgPositionPrice: Scalars["Boolean"];
  useAvgMonthlyProfit: Scalars["Boolean"];
  useAvgWinMonth: Scalars["Boolean"];
  useAvgLoseMonth: Scalars["Boolean"];
  usePercProfitableMonths: Scalars["Boolean"];
  usePositionAmount: Scalars["Boolean"];
  usePositionAbsoluteProfit: Scalars["Boolean"];
  usePositionProfitLoss: Scalars["Boolean"];
  useBalance: Scalars["Boolean"];
  useRiskScore: Scalars["Boolean"];
  useBuyHoldReturn: Scalars["Boolean"];
  useSharpeRatio: Scalars["Boolean"];
  useSortinoRatio: Scalars["Boolean"];
  useTotalRealizedGain: Scalars["Boolean"];
  useTotalRealizedLoss: Scalars["Boolean"];
  useConsistencyScore: Scalars["Boolean"];
  useScriptVersion: Scalars["Boolean"];
  useScriptName: Scalars["Boolean"];
  useCreatedAt: Scalars["Boolean"];
  useTags: Scalars["Boolean"];
  useAllocation: Scalars["Boolean"];
  useStartedAt: Scalars["Boolean"];
  useEndedAt: Scalars["Boolean"];
  useAutoRebalance: Scalars["Boolean"];
  isBot: Scalars["Boolean"];
  useActiveSubscribersCount: Scalars["Boolean"];
}>;

export type SyndicationSubscriptionsContextProviderQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        name?: string | null | undefined;
        subscriptionExecutionsCount?: number | null | undefined;
        subscriptionExecutions?:
          | {
              __typename?: "ExecutionConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "ExecutionConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Execution";
                              id: string;
                              name?: string | null | undefined;
                              isPack: boolean;
                              packPercentage?: any | null | undefined;
                              status: ExecutionStatus;
                              candleType?: CandleType | null | undefined;
                              type: ExecutionType;
                              exchange: Exchange;
                              currencyPair: CurrencyPair;
                              multiCoinCurrency?: Currency | null | undefined;
                              candleSize?: string;
                              favorite?: boolean | null | undefined;
                              error?: string | null | undefined;
                              runtimeSeconds?: number | null | undefined;
                              shareToken?: string | null | undefined;
                              algorithmSettings?: string | null | undefined;
                              createdAt?: string | null | undefined;
                              leverage: any;
                              leverageShort: any;
                              runNumber?: string | null | undefined;
                              multivariantIterationId?:
                                | number
                                | null
                                | undefined;
                              scriptVersionSequence?: number | null | undefined;
                              allocation?: any;
                              rangeStart?: string | null | undefined;
                              startedAt?: string | null | undefined;
                              rangeEnd?: string | null | undefined;
                              endedAt?: string | null | undefined;
                              autoRebalance?: boolean | null | undefined;
                              stage?: ExecutionStage | null | undefined;
                              syndicationId?: string | null | undefined;
                              stopLossPercentage?: any | null | undefined;
                              stopLossTrailing?: boolean | null | undefined;
                              takeProfitPercentage?: any | null | undefined;
                              takeProfitTrailingPercentage?:
                                | any
                                | null
                                | undefined;
                              notes?: string | null | undefined;
                              preloadedScript?:
                                | PreloadedScript
                                | null
                                | undefined;
                              maxBuy?: any | null | undefined;
                              packExecutionsCount?: number | null | undefined;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                              multiCoinPackExecutions?:
                                | Array<
                                    | {
                                        __typename?: "MultiCoinPackCurrencyPercentage";
                                        currency: Currency;
                                        percentage: any;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              measurements?:
                                | {
                                    __typename?: "Measurements";
                                    absoluteProfit?: any | null | undefined;
                                    numberOfTrades?: number | null | undefined;
                                    maxDrawdown?: any | null | undefined;
                                    percentProfitableTrades?:
                                      | any
                                      | null
                                      | undefined;
                                    profitability?: any | null | undefined;
                                    avgPositionPrice?: any | null | undefined;
                                    avgMonthlyProfit?: any | null | undefined;
                                    avgWinMonth?: any | null | undefined;
                                    avgLoseMonth?: any | null | undefined;
                                    percProfitableMonths?:
                                      | any
                                      | null
                                      | undefined;
                                    positionAmount?: any | null | undefined;
                                    positionAbsoluteProfit?:
                                      | any
                                      | null
                                      | undefined;
                                    positionProfitLoss?: any | null | undefined;
                                    balance?: any | null | undefined;
                                    riskScore?: any | null | undefined;
                                    avgBarsInTrade?: number | null | undefined;
                                    profitFactor?: any | null | undefined;
                                    avgTradePrice?: any | null | undefined;
                                    lastTick?: string | null | undefined;
                                    positionState?: string | null | undefined;
                                    buyHoldReturn?: any | null | undefined;
                                    sharpeRatio?: any | null | undefined;
                                    sortinoRatio?: any | null | undefined;
                                    totalRealizedGain?: any | null | undefined;
                                    totalRealizedLoss?: any | null | undefined;
                                    consistencyScore?:
                                      | number
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              scriptDetails?:
                                | {
                                    __typename?: "ScriptDetails";
                                    id: string;
                                    scriptId: string;
                                    revisionId: string;
                                    name: string;
                                    version?: number | null | undefined;
                                    isLatest?: boolean | null | undefined;
                                    createdAt?: string | null | undefined;
                                    parameters: Array<
                                      | {
                                          __typename?: "ScriptParameterDetails";
                                          fieldName: string;
                                          readableName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined
                                    >;
                                  }
                                | null
                                | undefined;
                              tags?:
                                | Array<
                                    | {
                                        __typename?: "Tag";
                                        id: string;
                                        name: string;
                                        color: string;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              syndicationSettings?:
                                | {
                                    __typename?: "SyndicationSettings";
                                    maxAllocation: any;
                                    minAllocation: any;
                                    description?: string | null | undefined;
                                    isVisible?: boolean | null | undefined;
                                    accessControl?:
                                      | SyndicationAccessControl
                                      | null
                                      | undefined;
                                    shareToken?: string | null | undefined;
                                    activeSubscribersCount?:
                                      | number
                                      | null
                                      | undefined;
                                    allowList?:
                                      | Array<
                                          | {
                                              __typename?: "AllowListInfo";
                                              nickname: string;
                                              canRemove: boolean;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              syndication?:
                                | {
                                    __typename?: "PublicSyndication";
                                    name?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              owner?:
                                | {
                                    __typename?: "PublicUser";
                                    nickname?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GuestTradesContextProviderQueryVariables = Exact<{
  shareToken: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type GuestTradesContextProviderQuery = {
  __typename?: "RootQuery";
  sharedExecution?:
    | {
        __typename?: "SharedExecution";
        shareToken: string;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        tradesConnection?:
          | {
              __typename?: "TradeConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "TradeConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Trade";
                              id: string;
                              absoluteProfit: any;
                              profitPercentage: any;
                              isClosed: boolean;
                              isProfitable: boolean;
                              fundingFee?: any | null | undefined;
                              orders: Array<
                                | {
                                    __typename?: "Order";
                                    externalId?: string | null | undefined;
                                    source: OrderSource;
                                    status: OrderStatus;
                                    side: OrderSide;
                                    placedTime: string;
                                    placedAmount: any;
                                    filledTime?: string | null | undefined;
                                    commissionPaid: any;
                                    limitPrice?: any | null | undefined;
                                    filledAmount: any;
                                    filledPrice: any;
                                    filledValue: any;
                                    effectiveAmount: any;
                                    effectivePrice: any;
                                    effectiveValue: any;
                                  }
                                | null
                                | undefined
                              >;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              numberOfTrades?: number | null | undefined;
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TradesContextProviderQueryVariables = Exact<{
  executionId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type TradesContextProviderQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        syndicationId?: string | null | undefined;
        tradesConnection?:
          | {
              __typename?: "TradeConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "TradeConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Trade";
                              id: string;
                              absoluteProfit: any;
                              profitPercentage: any;
                              isClosed: boolean;
                              isProfitable: boolean;
                              fundingFee?: any | null | undefined;
                              orders: Array<
                                | {
                                    __typename?: "Order";
                                    externalId?: string | null | undefined;
                                    source: OrderSource;
                                    status: OrderStatus;
                                    side: OrderSide;
                                    placedTime: string;
                                    placedAmount: any;
                                    filledTime?: string | null | undefined;
                                    commissionPaid: any;
                                    limitPrice?: any | null | undefined;
                                    filledAmount: any;
                                    filledPrice: any;
                                    filledValue: any;
                                    effectiveAmount: any;
                                    effectivePrice: any;
                                    effectiveValue: any;
                                  }
                                | null
                                | undefined
                              >;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              numberOfTrades?: number | null | undefined;
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotSubscriptionFlowModal_PublicSyndicationFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        settleCurrency: Currency;
        quote: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | { __typename?: "MultiCoinPackCurrencyPercentage"; currency: Currency }
        | null
        | undefined
      >
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  owner: {
    __typename?: "PublicUser";
    id: string;
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
  };
  subscription?:
    | {
        __typename?: "BillingSubscription";
        id: string;
        statusDetails?:
          | {
              __typename?: "BillingSubscriptionStatusDetails";
              mayUseEntitlements: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AssetDataFragment = {
  __typename?: "Asset";
  currency: string;
  amount: any;
};

export type AssetDetailsDataFragment = {
  __typename?: "AssetDetails";
  updatedAt?: string | null | undefined;
  free: Array<
    { __typename?: "Asset"; currency: string; amount: any } | null | undefined
  >;
};

export type UserDataFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  termsAccepted: boolean;
  welcomed: boolean;
  tier?: UserTier | null | undefined;
  permissions: Array<Permission | null | undefined>;
  registrationDate: string;
  companyName?: string | null | undefined;
  nickname?: string | null | undefined;
  country?: string | null | undefined;
  description?: string | null | undefined;
  twitterUrl?: string | null | undefined;
  discordUrl?: string | null | undefined;
  telegramUrl?: string | null | undefined;
  mediumUrl?: string | null | undefined;
  linkedinUrl?: string | null | undefined;
  personalBlogUrl?: string | null | undefined;
  personalWebsiteUrl?: string | null | undefined;
  profilePhotoS3Key?: string | null | undefined;
  profilePhotoUrl?: string | null | undefined;
  outstandingInvoiceCount?: number | null | undefined;
  availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
  tierParameters?:
    | {
        __typename?: "UserTierParameters";
        concurrentBatchTests: number;
        paperTradesCount: number;
        batchTestMaxSizeAccuracy: number;
        batchTestMaxCandlesAmount: number;
      }
    | null
    | undefined;
  mailingPreference?:
    | {
        __typename?: "MailingPreference";
        status?: string | null | undefined;
        userType?: UserType | null | undefined;
      }
    | null
    | undefined;
};

export type CredentialDataFragment = {
  __typename?: "Credential";
  id: string;
  name: string;
  exchange: Exchange;
  maskedApiKey: string;
  status: CredentialStatus;
};

export type ScriptParameterFullDataFragment = {
  __typename?: "ScriptParameterDetails";
  fieldName: string;
  valueType: ScriptParameterType;
  readableName?: string | null | undefined;
  description?: string | null | undefined;
  defaultValue?: string | null | undefined;
  options?: Array<string | null | undefined> | null | undefined;
};

export type PlotInfoDataFragment = {
  __typename?: "PlotInfo";
  id: string;
  title: string;
  lineWidth: number;
  lineStyle: PlotStyle;
  transparency: number;
  trackPrice: boolean;
  histBase: number;
  offset: number;
  editable: boolean;
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
};

export type PlotArrowInfoDataFragment = {
  __typename?: "PlotArrowInfo";
  id: string;
  title: string;
  transparency: number;
  offset: number;
  minHeight: number;
  maxHeight: number;
  editable: boolean;
  colorUp: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
  colorDown: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
};

export type PlotCharInfoDataFragment = {
  __typename?: "PlotCharInfo";
  id: string;
  title: string;
  char: string;
  location: ShapePosition;
  transparency: number;
  offset: number;
  text?: string | null | undefined;
  size: ShapeSize;
  editable: boolean;
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
  textColor?:
    | {
        __typename?: "ColorInput";
        type: InputType;
        value?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PlotShapeInfoDataFragment = {
  __typename?: "PlotShapeInfo";
  id: string;
  title: string;
  style: ShapeStyle;
  location: ShapePosition;
  transparency: number;
  offset: number;
  text?: string | null | undefined;
  size: ShapeSize;
  editable: boolean;
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
  textColor?:
    | {
        __typename?: "ColorInput";
        type: InputType;
        value?: string | null | undefined;
      }
    | null
    | undefined;
};

export type FillInfoDataFragment = {
  __typename?: "FillInfo";
  id: string;
  inputType: PlotType;
  transparency: number;
  title?: string | null | undefined;
  editable?: boolean | null | undefined;
  input1: { __typename?: "FillInputId"; str: string };
  input2: { __typename?: "FillInputId"; str: string };
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
};

export type HLineInfoDataFragment = {
  __typename?: "HLineInfo";
  id: string;
  title: string;
  yVal: number;
  lineWidth: number;
  lineStyle: HLineStyle;
  editable?: boolean | null | undefined;
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
};

export type BgColorInfoDataFragment = {
  __typename?: "BGColorInfo";
  transparency: number;
  offset?: number | null | undefined;
  title?: string | null | undefined;
  editable?: boolean | null | undefined;
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
};

export type BarColorInfoDataFragment = {
  __typename?: "BarColorInfo";
  offset?: number | null | undefined;
  title?: string | null | undefined;
  editable?: boolean | null | undefined;
  color: {
    __typename?: "ColorInput";
    type: InputType;
    value?: string | null | undefined;
  };
};

export type PlotGroupFullDataFragment = {
  __typename?: "ScriptPlotGroup";
  id: string;
  isOverlay: boolean;
  plots: Array<
    | {
        __typename?: "PlotInfo";
        id: string;
        title: string;
        lineWidth: number;
        lineStyle: PlotStyle;
        transparency: number;
        trackPrice: boolean;
        histBase: number;
        offset: number;
        editable: boolean;
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
      }
    | null
    | undefined
  >;
  arrows: Array<
    | {
        __typename?: "PlotArrowInfo";
        id: string;
        title: string;
        transparency: number;
        offset: number;
        minHeight: number;
        maxHeight: number;
        editable: boolean;
        colorUp: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
        colorDown: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
      }
    | null
    | undefined
  >;
  chars: Array<
    | {
        __typename?: "PlotCharInfo";
        id: string;
        title: string;
        char: string;
        location: ShapePosition;
        transparency: number;
        offset: number;
        text?: string | null | undefined;
        size: ShapeSize;
        editable: boolean;
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
        textColor?:
          | {
              __typename?: "ColorInput";
              type: InputType;
              value?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  shapes: Array<
    | {
        __typename?: "PlotShapeInfo";
        id: string;
        title: string;
        style: ShapeStyle;
        location: ShapePosition;
        transparency: number;
        offset: number;
        text?: string | null | undefined;
        size: ShapeSize;
        editable: boolean;
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
        textColor?:
          | {
              __typename?: "ColorInput";
              type: InputType;
              value?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  fills: Array<
    | {
        __typename?: "FillInfo";
        id: string;
        inputType: PlotType;
        transparency: number;
        title?: string | null | undefined;
        editable?: boolean | null | undefined;
        input1: { __typename?: "FillInputId"; str: string };
        input2: { __typename?: "FillInputId"; str: string };
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
      }
    | null
    | undefined
  >;
  hLines: Array<
    | {
        __typename?: "HLineInfo";
        id: string;
        title: string;
        yVal: number;
        lineWidth: number;
        lineStyle: HLineStyle;
        editable?: boolean | null | undefined;
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
      }
    | null
    | undefined
  >;
  bgColor?:
    | {
        __typename?: "BGColorInfo";
        transparency: number;
        offset?: number | null | undefined;
        title?: string | null | undefined;
        editable?: boolean | null | undefined;
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
      }
    | null
    | undefined;
  barColor?:
    | {
        __typename?: "BarColorInfo";
        offset?: number | null | undefined;
        title?: string | null | undefined;
        editable?: boolean | null | undefined;
        color: {
          __typename?: "ColorInput";
          type: InputType;
          value?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type PlotDefinitionDataFragment = {
  __typename?: "ScriptPlotDefinition";
  groups: Array<
    | {
        __typename?: "ScriptPlotGroup";
        id: string;
        isOverlay: boolean;
        plots: Array<
          | {
              __typename?: "PlotInfo";
              id: string;
              title: string;
              lineWidth: number;
              lineStyle: PlotStyle;
              transparency: number;
              trackPrice: boolean;
              histBase: number;
              offset: number;
              editable: boolean;
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
            }
          | null
          | undefined
        >;
        arrows: Array<
          | {
              __typename?: "PlotArrowInfo";
              id: string;
              title: string;
              transparency: number;
              offset: number;
              minHeight: number;
              maxHeight: number;
              editable: boolean;
              colorUp: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
              colorDown: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
            }
          | null
          | undefined
        >;
        chars: Array<
          | {
              __typename?: "PlotCharInfo";
              id: string;
              title: string;
              char: string;
              location: ShapePosition;
              transparency: number;
              offset: number;
              text?: string | null | undefined;
              size: ShapeSize;
              editable: boolean;
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
              textColor?:
                | {
                    __typename?: "ColorInput";
                    type: InputType;
                    value?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        shapes: Array<
          | {
              __typename?: "PlotShapeInfo";
              id: string;
              title: string;
              style: ShapeStyle;
              location: ShapePosition;
              transparency: number;
              offset: number;
              text?: string | null | undefined;
              size: ShapeSize;
              editable: boolean;
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
              textColor?:
                | {
                    __typename?: "ColorInput";
                    type: InputType;
                    value?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        fills: Array<
          | {
              __typename?: "FillInfo";
              id: string;
              inputType: PlotType;
              transparency: number;
              title?: string | null | undefined;
              editable?: boolean | null | undefined;
              input1: { __typename?: "FillInputId"; str: string };
              input2: { __typename?: "FillInputId"; str: string };
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
            }
          | null
          | undefined
        >;
        hLines: Array<
          | {
              __typename?: "HLineInfo";
              id: string;
              title: string;
              yVal: number;
              lineWidth: number;
              lineStyle: HLineStyle;
              editable?: boolean | null | undefined;
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
            }
          | null
          | undefined
        >;
        bgColor?:
          | {
              __typename?: "BGColorInfo";
              transparency: number;
              offset?: number | null | undefined;
              title?: string | null | undefined;
              editable?: boolean | null | undefined;
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
            }
          | null
          | undefined;
        barColor?:
          | {
              __typename?: "BarColorInfo";
              offset?: number | null | undefined;
              title?: string | null | undefined;
              editable?: boolean | null | undefined;
              color: {
                __typename?: "ColorInput";
                type: InputType;
                value?: string | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type ScriptErrorDataFragment = {
  __typename?: "ScriptError";
  message: string;
  range: {
    __typename?: "Range";
    start: { __typename?: "Position"; line: number; char: number };
    end: { __typename?: "Position"; line: number; char: number };
  };
};

export type ScriptRevisionDataFragment = {
  __typename?: "ScriptRevision";
  id: string;
  status: ScriptRevisionStatus;
  parameters: Array<
    | {
        __typename?: "ScriptParameterDetails";
        fieldName: string;
        valueType: ScriptParameterType;
        readableName?: string | null | undefined;
        description?: string | null | undefined;
        defaultValue?: string | null | undefined;
        options?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined
  >;
  plotDefinition: {
    __typename?: "ScriptPlotDefinition";
    groups: Array<
      | {
          __typename?: "ScriptPlotGroup";
          id: string;
          isOverlay: boolean;
          plots: Array<
            | {
                __typename?: "PlotInfo";
                id: string;
                title: string;
                lineWidth: number;
                lineStyle: PlotStyle;
                transparency: number;
                trackPrice: boolean;
                histBase: number;
                offset: number;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          arrows: Array<
            | {
                __typename?: "PlotArrowInfo";
                id: string;
                title: string;
                transparency: number;
                offset: number;
                minHeight: number;
                maxHeight: number;
                editable: boolean;
                colorUp: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                colorDown: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          chars: Array<
            | {
                __typename?: "PlotCharInfo";
                id: string;
                title: string;
                char: string;
                location: ShapePosition;
                transparency: number;
                offset: number;
                text?: string | null | undefined;
                size: ShapeSize;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                textColor?:
                  | {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
          shapes: Array<
            | {
                __typename?: "PlotShapeInfo";
                id: string;
                title: string;
                style: ShapeStyle;
                location: ShapePosition;
                transparency: number;
                offset: number;
                text?: string | null | undefined;
                size: ShapeSize;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                textColor?:
                  | {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
          fills: Array<
            | {
                __typename?: "FillInfo";
                id: string;
                inputType: PlotType;
                transparency: number;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                input1: { __typename?: "FillInputId"; str: string };
                input2: { __typename?: "FillInputId"; str: string };
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          hLines: Array<
            | {
                __typename?: "HLineInfo";
                id: string;
                title: string;
                yVal: number;
                lineWidth: number;
                lineStyle: HLineStyle;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          bgColor?:
            | {
                __typename?: "BGColorInfo";
                transparency: number;
                offset?: number | null | undefined;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined;
          barColor?:
            | {
                __typename?: "BarColorInfo";
                offset?: number | null | undefined;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
  errors: Array<
    | {
        __typename?: "ScriptError";
        message: string;
        range: {
          __typename?: "Range";
          start: { __typename?: "Position"; line: number; char: number };
          end: { __typename?: "Position"; line: number; char: number };
        };
      }
    | null
    | undefined
  >;
  warnings: Array<
    | {
        __typename?: "ScriptError";
        message: string;
        range: {
          __typename?: "Range";
          start: { __typename?: "Position"; line: number; char: number };
          end: { __typename?: "Position"; line: number; char: number };
        };
      }
    | null
    | undefined
  >;
  infos: Array<
    | {
        __typename?: "ScriptError";
        message: string;
        range: {
          __typename?: "Range";
          start: { __typename?: "Position"; line: number; char: number };
          end: { __typename?: "Position"; line: number; char: number };
        };
      }
    | null
    | undefined
  >;
};

export type ScriptRevisionFullDataFragment = {
  __typename?: "ScriptRevision";
  id: string;
  body: string;
  status: ScriptRevisionStatus;
  createdAt: string;
  updatedAt: string;
  parameters: Array<
    | {
        __typename?: "ScriptParameterDetails";
        fieldName: string;
        valueType: ScriptParameterType;
        readableName?: string | null | undefined;
        description?: string | null | undefined;
        defaultValue?: string | null | undefined;
        options?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined
  >;
  plotDefinition: {
    __typename?: "ScriptPlotDefinition";
    groups: Array<
      | {
          __typename?: "ScriptPlotGroup";
          id: string;
          isOverlay: boolean;
          plots: Array<
            | {
                __typename?: "PlotInfo";
                id: string;
                title: string;
                lineWidth: number;
                lineStyle: PlotStyle;
                transparency: number;
                trackPrice: boolean;
                histBase: number;
                offset: number;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          arrows: Array<
            | {
                __typename?: "PlotArrowInfo";
                id: string;
                title: string;
                transparency: number;
                offset: number;
                minHeight: number;
                maxHeight: number;
                editable: boolean;
                colorUp: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                colorDown: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          chars: Array<
            | {
                __typename?: "PlotCharInfo";
                id: string;
                title: string;
                char: string;
                location: ShapePosition;
                transparency: number;
                offset: number;
                text?: string | null | undefined;
                size: ShapeSize;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                textColor?:
                  | {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
          shapes: Array<
            | {
                __typename?: "PlotShapeInfo";
                id: string;
                title: string;
                style: ShapeStyle;
                location: ShapePosition;
                transparency: number;
                offset: number;
                text?: string | null | undefined;
                size: ShapeSize;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                textColor?:
                  | {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
          fills: Array<
            | {
                __typename?: "FillInfo";
                id: string;
                inputType: PlotType;
                transparency: number;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                input1: { __typename?: "FillInputId"; str: string };
                input2: { __typename?: "FillInputId"; str: string };
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          hLines: Array<
            | {
                __typename?: "HLineInfo";
                id: string;
                title: string;
                yVal: number;
                lineWidth: number;
                lineStyle: HLineStyle;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          bgColor?:
            | {
                __typename?: "BGColorInfo";
                transparency: number;
                offset?: number | null | undefined;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined;
          barColor?:
            | {
                __typename?: "BarColorInfo";
                offset?: number | null | undefined;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
  errors: Array<
    | {
        __typename?: "ScriptError";
        message: string;
        range: {
          __typename?: "Range";
          start: { __typename?: "Position"; line: number; char: number };
          end: { __typename?: "Position"; line: number; char: number };
        };
      }
    | null
    | undefined
  >;
  warnings: Array<
    | {
        __typename?: "ScriptError";
        message: string;
        range: {
          __typename?: "Range";
          start: { __typename?: "Position"; line: number; char: number };
          end: { __typename?: "Position"; line: number; char: number };
        };
      }
    | null
    | undefined
  >;
  infos: Array<
    | {
        __typename?: "ScriptError";
        message: string;
        range: {
          __typename?: "Range";
          start: { __typename?: "Position"; line: number; char: number };
          end: { __typename?: "Position"; line: number; char: number };
        };
      }
    | null
    | undefined
  >;
};

export type ScriptRevisionDateDataFragment = {
  __typename?: "ScriptRevision";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type ScriptMainDataFragment = {
  __typename?: "Script";
  id: string;
  name: string;
  language: ScriptLanguage;
};

export type ScriptListDataFragment = {
  __typename?: "Script";
  id: string;
  isOwner: boolean;
  name: string;
  language: ScriptLanguage;
  favorite?: boolean | null | undefined;
  notes?: string | null | undefined;
  isPublic: boolean;
  latestVersionNumber?: number | null | undefined;
  timesCopied?: number | null | undefined;
  isUsed?: boolean | null | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
  ownerInfo?:
    | {
        __typename?: "ScriptOwnerInfo";
        author?: string | null | undefined;
        nickname?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ScriptDetailsMainDataFragment = {
  __typename?: "ScriptDetails";
  id: string;
  scriptId: string;
  revisionId: string;
  name: string;
  language: ScriptLanguage;
  version?: number | null | undefined;
  isLatest?: boolean | null | undefined;
  createdAt?: string | null | undefined;
};

export type ScriptDetailsFullDataFragment = {
  __typename?: "ScriptDetails";
  id: string;
  scriptId: string;
  revisionId: string;
  name: string;
  language: ScriptLanguage;
  body: string;
  parameters: Array<
    | {
        __typename?: "ScriptParameterDetails";
        fieldName: string;
        readableName?: string | null | undefined;
        valueType: ScriptParameterType;
        defaultValue?: string | null | undefined;
      }
    | null
    | undefined
  >;
  plotDefinition: {
    __typename?: "ScriptPlotDefinition";
    groups: Array<
      | {
          __typename?: "ScriptPlotGroup";
          id: string;
          isOverlay: boolean;
          plots: Array<
            | {
                __typename?: "PlotInfo";
                id: string;
                title: string;
                lineWidth: number;
                lineStyle: PlotStyle;
                transparency: number;
                trackPrice: boolean;
                histBase: number;
                offset: number;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          arrows: Array<
            | {
                __typename?: "PlotArrowInfo";
                id: string;
                title: string;
                transparency: number;
                offset: number;
                minHeight: number;
                maxHeight: number;
                editable: boolean;
                colorUp: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                colorDown: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          chars: Array<
            | {
                __typename?: "PlotCharInfo";
                id: string;
                title: string;
                char: string;
                location: ShapePosition;
                transparency: number;
                offset: number;
                text?: string | null | undefined;
                size: ShapeSize;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                textColor?:
                  | {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
          shapes: Array<
            | {
                __typename?: "PlotShapeInfo";
                id: string;
                title: string;
                style: ShapeStyle;
                location: ShapePosition;
                transparency: number;
                offset: number;
                text?: string | null | undefined;
                size: ShapeSize;
                editable: boolean;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
                textColor?:
                  | {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
          fills: Array<
            | {
                __typename?: "FillInfo";
                id: string;
                inputType: PlotType;
                transparency: number;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                input1: { __typename?: "FillInputId"; str: string };
                input2: { __typename?: "FillInputId"; str: string };
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          hLines: Array<
            | {
                __typename?: "HLineInfo";
                id: string;
                title: string;
                yVal: number;
                lineWidth: number;
                lineStyle: HLineStyle;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined
          >;
          bgColor?:
            | {
                __typename?: "BGColorInfo";
                transparency: number;
                offset?: number | null | undefined;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined;
          barColor?:
            | {
                __typename?: "BarColorInfo";
                offset?: number | null | undefined;
                title?: string | null | undefined;
                editable?: boolean | null | undefined;
                color: {
                  __typename?: "ColorInput";
                  type: InputType;
                  value?: string | null | undefined;
                };
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type MeasurementDataFragment = {
  __typename?: "Measurements";
  absoluteProfit?: any | null | undefined;
  avgBarsInTrade?: number | null | undefined;
  numberOfTrades?: number | null | undefined;
  maxDrawdown?: any | null | undefined;
  percentProfitableTrades?: any | null | undefined;
  profitability?: any | null | undefined;
  profitFactor?: any | null | undefined;
  buyHoldRatio?: any | null | undefined;
  buyHoldReturn?: any | null | undefined;
  avgTradePrice?: any | null | undefined;
  avgPositionPrice?: any | null | undefined;
  lastTick?: string | null | undefined;
  positionProfitLoss?: any | null | undefined;
  avgMonthlyProfit?: any | null | undefined;
  avgWinMonth?: any | null | undefined;
  avgLoseMonth?: any | null | undefined;
  percProfitableMonths?: any | null | undefined;
  positionAbsoluteProfit?: any | null | undefined;
  positionAmount?: any | null | undefined;
  balance?: any | null | undefined;
  riskScore?: any | null | undefined;
  sharpeRatio?: any | null | undefined;
  sortinoRatio?: any | null | undefined;
  totalRealizedGain?: any | null | undefined;
  totalRealizedLoss?: any | null | undefined;
  consistencyScore?: number | null | undefined;
};

export type TradesMeasurementDataFragment = {
  __typename?: "Measurements";
  numberOfTrades?: number | null | undefined;
  positionProfitLoss?: any | null | undefined;
  positionAbsoluteProfit?: any | null | undefined;
};

export type OrderMainDataFragment = {
  __typename?: "Order";
  externalId?: string | null | undefined;
  source: OrderSource;
  status: OrderStatus;
  side: OrderSide;
  placedTime: string;
  placedAmount: any;
  filledTime?: string | null | undefined;
  commissionPaid: any;
  limitPrice?: any | null | undefined;
};

export type OrderPreCommissionDataFragment = {
  __typename?: "Order";
  filledAmount: any;
  filledPrice: any;
  filledValue: any;
};

export type OrderPostCommissionDataFragment = {
  __typename?: "Order";
  effectiveAmount: any;
  effectivePrice: any;
  effectiveValue: any;
};

export type TradeDataFragment = {
  __typename?: "Trade";
  id: string;
  absoluteProfit: any;
  profitPercentage: any;
  isClosed: boolean;
  isProfitable: boolean;
  fundingFee?: any | null | undefined;
  orders: Array<
    | {
        __typename?: "Order";
        externalId?: string | null | undefined;
        source: OrderSource;
        status: OrderStatus;
        side: OrderSide;
        placedTime: string;
        placedAmount: any;
        filledTime?: string | null | undefined;
        commissionPaid: any;
        limitPrice?: any | null | undefined;
        filledAmount: any;
        filledPrice: any;
        filledValue: any;
        effectiveAmount: any;
        effectivePrice: any;
        effectiveValue: any;
      }
    | null
    | undefined
  >;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
};

export type ExecutionLogDataFragment = {
  __typename?: "ExecutionLog";
  id: string;
  type?: string | null | undefined;
  time?: string | null | undefined;
};

export type ExecutionControlDataFragment = {
  __typename?: "Execution";
  id: string;
  name?: string | null | undefined;
  type: ExecutionType;
  status: ExecutionStatus;
  currencyPair: CurrencyPair;
  multiCoinCurrency?: Currency | null | undefined;
  exchange: Exchange;
  candleSize: string;
  candleType?: CandleType | null | undefined;
  rangeStart?: string | null | undefined;
  rangeEnd?: string | null | undefined;
  startedAt?: string | null | undefined;
  endedAt?: string | null | undefined;
  runNumber?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  plotDefinition?:
    | {
        __typename?: "ScriptPlotDefinition";
        groups: Array<
          | {
              __typename?: "ScriptPlotGroup";
              id: string;
              isOverlay: boolean;
              plots: Array<
                | {
                    __typename?: "PlotInfo";
                    id: string;
                    title: string;
                    lineWidth: number;
                    lineStyle: PlotStyle;
                    transparency: number;
                    trackPrice: boolean;
                    histBase: number;
                    offset: number;
                    editable: boolean;
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                  }
                | null
                | undefined
              >;
              arrows: Array<
                | {
                    __typename?: "PlotArrowInfo";
                    id: string;
                    title: string;
                    transparency: number;
                    offset: number;
                    minHeight: number;
                    maxHeight: number;
                    editable: boolean;
                    colorUp: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                    colorDown: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                  }
                | null
                | undefined
              >;
              chars: Array<
                | {
                    __typename?: "PlotCharInfo";
                    id: string;
                    title: string;
                    char: string;
                    location: ShapePosition;
                    transparency: number;
                    offset: number;
                    text?: string | null | undefined;
                    size: ShapeSize;
                    editable: boolean;
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                    textColor?:
                      | {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              shapes: Array<
                | {
                    __typename?: "PlotShapeInfo";
                    id: string;
                    title: string;
                    style: ShapeStyle;
                    location: ShapePosition;
                    transparency: number;
                    offset: number;
                    text?: string | null | undefined;
                    size: ShapeSize;
                    editable: boolean;
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                    textColor?:
                      | {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              fills: Array<
                | {
                    __typename?: "FillInfo";
                    id: string;
                    inputType: PlotType;
                    transparency: number;
                    title?: string | null | undefined;
                    editable?: boolean | null | undefined;
                    input1: { __typename?: "FillInputId"; str: string };
                    input2: { __typename?: "FillInputId"; str: string };
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                  }
                | null
                | undefined
              >;
              hLines: Array<
                | {
                    __typename?: "HLineInfo";
                    id: string;
                    title: string;
                    yVal: number;
                    lineWidth: number;
                    lineStyle: HLineStyle;
                    editable?: boolean | null | undefined;
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                  }
                | null
                | undefined
              >;
              bgColor?:
                | {
                    __typename?: "BGColorInfo";
                    transparency: number;
                    offset?: number | null | undefined;
                    title?: string | null | undefined;
                    editable?: boolean | null | undefined;
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                  }
                | null
                | undefined;
              barColor?:
                | {
                    __typename?: "BarColorInfo";
                    offset?: number | null | undefined;
                    title?: string | null | undefined;
                    editable?: boolean | null | undefined;
                    color: {
                      __typename?: "ColorInput";
                      type: InputType;
                      value?: string | null | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
  plotPalettes?:
    | Array<
        | {
            __typename?: "ScriptPlotPalette";
            plotId: string;
            colors: Array<string | null | undefined>;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type MultivariantListDataFragment = {
  __typename?: "Multivariant";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  candleSize?: string | null | undefined;
  candleType?: CandleType | null | undefined;
  maxBuy?: string | null | undefined;
  minProfit?: string | null | undefined;
  stopLossPercentage?: string | null | undefined;
  stopLossTrailing?: boolean | null | undefined;
  takeProfitPercentage?: string | null | undefined;
  takeProfitTrailingPercentage?: string | null | undefined;
  algorithmSettings: string;
  status?: ExecutionStatus | null | undefined;
  totalCombinationsCount: number;
  canResume?: boolean | null | undefined;
  createdAt?: string | null | undefined;
  favorite?: boolean | null | undefined;
  scriptVersionSequence?: number | null | undefined;
  allocation?: any | null | undefined;
  performanceMode: ExecutionPerformanceMode;
  leverage?: string | null | undefined;
  leverageShort?: string | null | undefined;
  lookbackDays: number;
  buyActionTime?: string | null | undefined;
  sellActionTime?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  scriptDetails: {
    __typename?: "ScriptDetails";
    id: string;
    scriptId: string;
    revisionId: string;
    name: string;
    language: ScriptLanguage;
    version?: number | null | undefined;
    isLatest?: boolean | null | undefined;
    createdAt?: string | null | undefined;
    parameters: Array<
      | {
          __typename?: "ScriptParameterDetails";
          readableName?: string | null | undefined;
          fieldName: string;
        }
      | null
      | undefined
    >;
  };
  progress?:
    | Array<
        | {
            __typename?: "ExecutionStatusCount";
            status: ExecutionStatus;
            count: number;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type MultivariantDetailsDataFragment = {
  __typename?: "Multivariant";
  id: string;
  lookbackDays: number;
  rangeStart?: string | null | undefined;
  rangeEnd?: string | null | undefined;
  buyActionTime?: string | null | undefined;
  sellActionTime?: string | null | undefined;
  allocation?: any | null | undefined;
  leverage?: string | null | undefined;
  leverageShort?: string | null | undefined;
  createdAt?: string | null | undefined;
};

export type MultivariantComposerDataFragment = {
  __typename?: "Multivariant";
  id: string;
  multipleValueFields?: Array<string | null | undefined> | null | undefined;
};

export type SharedExecutionDataFragment = {
  __typename?: "SharedExecution";
  shareToken: string;
  name?: string | null | undefined;
  syndicationName?: string | null | undefined;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  multiCoinCurrency?: Currency | null | undefined;
  candleSize: string;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        absoluteProfit?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        numberOfTrades?: number | null | undefined;
        maxDrawdown?: any | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        profitability?: any | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldRatio?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        avgPositionPrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        positionProfitLoss?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
        positionAmount?: any | null | undefined;
        balance?: any | null | undefined;
        riskScore?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        totalRealizedGain?: any | null | undefined;
        totalRealizedLoss?: any | null | undefined;
        consistencyScore?: number | null | undefined;
      }
    | null
    | undefined;
};

export type ExecutionSubscriptionDataFragment = {
  __typename?: "Execution";
  id: string;
  status: ExecutionStatus;
  measurements?:
    | {
        __typename?: "Measurements";
        absoluteProfit?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        numberOfTrades?: number | null | undefined;
        maxDrawdown?: any | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        profitability?: any | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldRatio?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        avgPositionPrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        positionProfitLoss?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
        positionAmount?: any | null | undefined;
        balance?: any | null | undefined;
        riskScore?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        totalRealizedGain?: any | null | undefined;
        totalRealizedLoss?: any | null | undefined;
        consistencyScore?: number | null | undefined;
      }
    | null
    | undefined;
};

export type ExecutionListDataFragment = {
  __typename?: "Execution";
  id: string;
  name?: string | null | undefined;
  type: ExecutionType;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  candleSize: string;
  candleType?: CandleType | null | undefined;
  status: ExecutionStatus;
  favorite?: boolean | null | undefined;
  error?: string | null | undefined;
  runtimeSeconds?: number | null | undefined;
  shareToken?: string | null | undefined;
  algorithmSettings?: string | null | undefined;
  createdAt?: string | null | undefined;
  leverage: any;
  leverageShort: any;
  multivariantIterationId?: number | null | undefined;
  scriptVersionSequence?: number | null | undefined;
  runNumber?: string | null | undefined;
  allocation: any;
  rangeStart?: string | null | undefined;
  rangeEnd?: string | null | undefined;
  isPack: boolean;
  stage?: ExecutionStage | null | undefined;
  syndicationId?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        absoluteProfit?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        numberOfTrades?: number | null | undefined;
        maxDrawdown?: any | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        profitability?: any | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldRatio?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        avgPositionPrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        positionProfitLoss?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
        positionAmount?: any | null | undefined;
        balance?: any | null | undefined;
        riskScore?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        totalRealizedGain?: any | null | undefined;
        totalRealizedLoss?: any | null | undefined;
        consistencyScore?: number | null | undefined;
      }
    | null
    | undefined;
  scriptDetails?:
    | {
        __typename?: "ScriptDetails";
        id: string;
        scriptId: string;
        revisionId: string;
        name: string;
        language: ScriptLanguage;
        version?: number | null | undefined;
        isLatest?: boolean | null | undefined;
        createdAt?: string | null | undefined;
      }
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
};

export type ExecutionPreviewDataFragment = {
  __typename?: "Execution";
  id: string;
  type: ExecutionType;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  candleSize: string;
  candleType?: CandleType | null | undefined;
  status: ExecutionStatus;
  error?: string | null | undefined;
  algorithmSettings?: string | null | undefined;
  createdAt?: string | null | undefined;
  leverage: any;
  leverageShort: any;
  scriptVersionSequence?: number | null | undefined;
  runNumber?: string | null | undefined;
  allocation: any;
  rangeStart?: string | null | undefined;
  rangeEnd?: string | null | undefined;
  stage?: ExecutionStage | null | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        absoluteProfit?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        numberOfTrades?: number | null | undefined;
        maxDrawdown?: any | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        profitability?: any | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldRatio?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        avgPositionPrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        positionProfitLoss?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
        positionAmount?: any | null | undefined;
        balance?: any | null | undefined;
        riskScore?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        totalRealizedGain?: any | null | undefined;
        totalRealizedLoss?: any | null | undefined;
        consistencyScore?: number | null | undefined;
      }
    | null
    | undefined;
  scriptDetails?:
    | {
        __typename?: "ScriptDetails";
        id: string;
        scriptId: string;
        revisionId: string;
        name: string;
        language: ScriptLanguage;
        version?: number | null | undefined;
        isLatest?: boolean | null | undefined;
        createdAt?: string | null | undefined;
      }
    | null
    | undefined;
};

export type MultivariantExecutionsDataFragment = {
  __typename?: "Multivariant";
  id: string;
  executions?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            name?: string | null | undefined;
            type: ExecutionType;
            exchange: Exchange;
            currencyPair: CurrencyPair;
            candleSize: string;
            candleType?: CandleType | null | undefined;
            status: ExecutionStatus;
            favorite?: boolean | null | undefined;
            error?: string | null | undefined;
            runtimeSeconds?: number | null | undefined;
            shareToken?: string | null | undefined;
            algorithmSettings?: string | null | undefined;
            createdAt?: string | null | undefined;
            leverage: any;
            leverageShort: any;
            multivariantIterationId?: number | null | undefined;
            scriptVersionSequence?: number | null | undefined;
            runNumber?: string | null | undefined;
            allocation: any;
            rangeStart?: string | null | undefined;
            rangeEnd?: string | null | undefined;
            isPack: boolean;
            stage?: ExecutionStage | null | undefined;
            syndicationId?: string | null | undefined;
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
            measurements?:
              | {
                  __typename?: "Measurements";
                  absoluteProfit?: any | null | undefined;
                  avgBarsInTrade?: number | null | undefined;
                  numberOfTrades?: number | null | undefined;
                  maxDrawdown?: any | null | undefined;
                  percentProfitableTrades?: any | null | undefined;
                  profitability?: any | null | undefined;
                  profitFactor?: any | null | undefined;
                  buyHoldRatio?: any | null | undefined;
                  buyHoldReturn?: any | null | undefined;
                  avgTradePrice?: any | null | undefined;
                  avgPositionPrice?: any | null | undefined;
                  lastTick?: string | null | undefined;
                  positionProfitLoss?: any | null | undefined;
                  avgMonthlyProfit?: any | null | undefined;
                  avgWinMonth?: any | null | undefined;
                  avgLoseMonth?: any | null | undefined;
                  percProfitableMonths?: any | null | undefined;
                  positionAbsoluteProfit?: any | null | undefined;
                  positionAmount?: any | null | undefined;
                  balance?: any | null | undefined;
                  riskScore?: any | null | undefined;
                  sharpeRatio?: any | null | undefined;
                  sortinoRatio?: any | null | undefined;
                  totalRealizedGain?: any | null | undefined;
                  totalRealizedLoss?: any | null | undefined;
                  consistencyScore?: number | null | undefined;
                }
              | null
              | undefined;
            scriptDetails?:
              | {
                  __typename?: "ScriptDetails";
                  id: string;
                  scriptId: string;
                  revisionId: string;
                  name: string;
                  language: ScriptLanguage;
                  version?: number | null | undefined;
                  isLatest?: boolean | null | undefined;
                  createdAt?: string | null | undefined;
                }
              | null
              | undefined;
            tags?:
              | Array<
                  | {
                      __typename?: "Tag";
                      id: string;
                      name: string;
                      color: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            syndicationSettings?:
              | {
                  __typename?: "SyndicationSettings";
                  accessControl?: SyndicationAccessControl | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  executionsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type ExecutionDetailsDataFragment = {
  __typename?: "Execution";
  id: string;
  name?: string | null | undefined;
  currencyPair: CurrencyPair;
  multiCoinCurrency?: Currency | null | undefined;
  candleSize: string;
  candleType?: CandleType | null | undefined;
  allocation: any;
  type: ExecutionType;
  exchange: Exchange;
  status: ExecutionStatus;
  rangeStart?: string | null | undefined;
  rangeEnd?: string | null | undefined;
  maxBuy?: any | null | undefined;
  minProfit?: any | null | undefined;
  stopLossPercentage?: any | null | undefined;
  stopLossTrailing?: boolean | null | undefined;
  takeProfitPercentage?: any | null | undefined;
  takeProfitTrailingPercentage?: any | null | undefined;
  buyActionTime?: SignalActionTime | null | undefined;
  sellActionTime?: SignalActionTime | null | undefined;
  buyType?: OrderType | null | undefined;
  sellType?: OrderType | null | undefined;
  algorithmSettings?: string | null | undefined;
  lookbackDays?: number | null | undefined;
  leverage: any;
  leverageShort: any;
  scriptVersionSequence?: number | null | undefined;
  runNumber?: string | null | undefined;
  multivariantIterationId?: number | null | undefined;
  error?: string | null | undefined;
  syndicationId?: string | null | undefined;
  performanceMode: ExecutionPerformanceMode;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | {
            __typename?: "MultiCoinPackCurrencyPercentage";
            currency: Currency;
            percentage: any;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "Measurements";
        absoluteProfit?: any | null | undefined;
        avgBarsInTrade?: number | null | undefined;
        numberOfTrades?: number | null | undefined;
        maxDrawdown?: any | null | undefined;
        percentProfitableTrades?: any | null | undefined;
        profitability?: any | null | undefined;
        profitFactor?: any | null | undefined;
        buyHoldRatio?: any | null | undefined;
        buyHoldReturn?: any | null | undefined;
        avgTradePrice?: any | null | undefined;
        avgPositionPrice?: any | null | undefined;
        lastTick?: string | null | undefined;
        positionProfitLoss?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        avgWinMonth?: any | null | undefined;
        avgLoseMonth?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        positionAbsoluteProfit?: any | null | undefined;
        positionAmount?: any | null | undefined;
        balance?: any | null | undefined;
        riskScore?: any | null | undefined;
        sharpeRatio?: any | null | undefined;
        sortinoRatio?: any | null | undefined;
        totalRealizedGain?: any | null | undefined;
        totalRealizedLoss?: any | null | undefined;
        consistencyScore?: number | null | undefined;
      }
    | null
    | undefined;
  scriptDetails?:
    | {
        __typename?: "ScriptDetails";
        id: string;
        scriptId: string;
        revisionId: string;
        name: string;
        language: ScriptLanguage;
        body: string;
        parameters: Array<
          | {
              __typename?: "ScriptParameterDetails";
              fieldName: string;
              readableName?: string | null | undefined;
              valueType: ScriptParameterType;
              defaultValue?: string | null | undefined;
            }
          | null
          | undefined
        >;
        plotDefinition: {
          __typename?: "ScriptPlotDefinition";
          groups: Array<
            | {
                __typename?: "ScriptPlotGroup";
                id: string;
                isOverlay: boolean;
                plots: Array<
                  | {
                      __typename?: "PlotInfo";
                      id: string;
                      title: string;
                      lineWidth: number;
                      lineStyle: PlotStyle;
                      transparency: number;
                      trackPrice: boolean;
                      histBase: number;
                      offset: number;
                      editable: boolean;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                arrows: Array<
                  | {
                      __typename?: "PlotArrowInfo";
                      id: string;
                      title: string;
                      transparency: number;
                      offset: number;
                      minHeight: number;
                      maxHeight: number;
                      editable: boolean;
                      colorUp: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                      colorDown: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                chars: Array<
                  | {
                      __typename?: "PlotCharInfo";
                      id: string;
                      title: string;
                      char: string;
                      location: ShapePosition;
                      transparency: number;
                      offset: number;
                      text?: string | null | undefined;
                      size: ShapeSize;
                      editable: boolean;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                      textColor?:
                        | {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
                shapes: Array<
                  | {
                      __typename?: "PlotShapeInfo";
                      id: string;
                      title: string;
                      style: ShapeStyle;
                      location: ShapePosition;
                      transparency: number;
                      offset: number;
                      text?: string | null | undefined;
                      size: ShapeSize;
                      editable: boolean;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                      textColor?:
                        | {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
                fills: Array<
                  | {
                      __typename?: "FillInfo";
                      id: string;
                      inputType: PlotType;
                      transparency: number;
                      title?: string | null | undefined;
                      editable?: boolean | null | undefined;
                      input1: { __typename?: "FillInputId"; str: string };
                      input2: { __typename?: "FillInputId"; str: string };
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                hLines: Array<
                  | {
                      __typename?: "HLineInfo";
                      id: string;
                      title: string;
                      yVal: number;
                      lineWidth: number;
                      lineStyle: HLineStyle;
                      editable?: boolean | null | undefined;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                bgColor?:
                  | {
                      __typename?: "BGColorInfo";
                      transparency: number;
                      offset?: number | null | undefined;
                      title?: string | null | undefined;
                      editable?: boolean | null | undefined;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined;
                barColor?:
                  | {
                      __typename?: "BarColorInfo";
                      offset?: number | null | undefined;
                      title?: string | null | undefined;
                      editable?: boolean | null | undefined;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
  syndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
      }
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        description?: string | null | undefined;
        maxAllocation: any;
        minAllocation: any;
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        maxSubscriberAllocation?: any | null | undefined;
        availableAllocation?: any | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  owner?:
    | {
        __typename?: "PublicUser";
        companyName?: string | null | undefined;
        nickname?: string | null | undefined;
        firstName: string;
        lastName: string;
      }
    | null
    | undefined;
};

export type OrderRulesDataFragment = {
  __typename?: "OrderRules";
  minOrderAmount: any;
  maxOrderAmount: any;
  minOrderValue: any;
};

export type CurrencyPairDetailsDataFragment = {
  __typename?: "CurrencyPairDetails";
  id: string;
  exchange: Exchange;
  pair: CurrencyPair;
  base: Currency;
  quote: Currency;
  settleCurrency: Currency;
  positionCurrency: Currency;
};

export type PublicSyndicationDataFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair?: CurrencyPair | null | undefined;
  multiCoinCurrency?: Currency | null | undefined;
  multiCoinPackExecutions?:
    | Array<
        | {
            __typename?: "MultiCoinPackCurrencyPercentage";
            currency: Currency;
            percentage: any;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        id: string;
        maxAllocation: any;
        minAllocation: any;
        description?: string | null | undefined;
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        maxSubscriberAllocation?: any | null | undefined;
        availableAllocation?: any | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        subscriptionAllocation?: any | null | undefined;
      }
    | null
    | undefined;
  owner: {
    __typename?: "PublicUser";
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    firstName: string;
    lastName: string;
  };
  leverageInfo?:
    | { __typename?: "LeverageInfo"; maxLeverage?: any | null | undefined }
    | null
    | undefined;
};

export type PublicSyndicationListDataFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair?: CurrencyPair | null | undefined;
  multiCoinCurrency?: Currency | null | undefined;
  startedAt?: string | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | {
            __typename?: "MultiCoinPackCurrencyPercentage";
            currency: Currency;
            percentage: any;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        id: string;
        maxAllocation: any;
        minAllocation: any;
        description?: string | null | undefined;
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        maxDrawdown?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        riskScore?: any | null | undefined;
        subscriptionCount?: number | null | undefined;
        subscriptionAllocation?: any | null | undefined;
        profitability?: any | null | undefined;
      }
    | null
    | undefined;
};

export type AnalysisDataFragment = {
  __typename?: "Analysis";
  bestMonth?: any | null | undefined;
  worstMonth?: any | null | undefined;
  avgEquityPeakDays?: any | null | undefined;
  realizedProfitPerc?: any | null | undefined;
  dailyProfits?:
    | {
        __typename?: "DailyChart";
        firstDay?: string | null | undefined;
        values?: Array<any | null | undefined> | null | undefined;
      }
    | null
    | undefined;
  monthlyProfits?:
    | {
        __typename?: "MonthlyChart";
        firstMonth?: string | null | undefined;
        values?: Array<any | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type PublicSyndicationDetailsDataFragment = {
  __typename?: "PublicSyndication";
  id: string;
  name?: string | null | undefined;
  exchange: Exchange;
  currencyPair?: CurrencyPair | null | undefined;
  multiCoinCurrency?: Currency | null | undefined;
  startedAt?: string | null | undefined;
  performance?: any | null | undefined;
  currencyPairDetails?:
    | {
        __typename?: "CurrencyPairDetails";
        id: string;
        exchange: Exchange;
        pair: CurrencyPair;
        base: Currency;
        quote: Currency;
        settleCurrency: Currency;
        positionCurrency: Currency;
      }
    | null
    | undefined;
  multiCoinPackExecutions?:
    | Array<
        | {
            __typename?: "MultiCoinPackCurrencyPercentage";
            currency: Currency;
            percentage: any;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  syndicationSettings?:
    | {
        __typename?: "SyndicationSettings";
        id: string;
        maxAllocation: any;
        maxSubscriberAllocation?: any | null | undefined;
        availableAllocation?: any | null | undefined;
        minAllocation: any;
        description?: string | null | undefined;
        monthlyFeeUsd?: any | null | undefined;
        allocationFeePerc?: any | null | undefined;
        successFeePerc?: any | null | undefined;
        productId?: string | null | undefined;
        accessControl?: SyndicationAccessControl | null | undefined;
      }
    | null
    | undefined;
  measurements?:
    | {
        __typename?: "SyndicationMeasurements";
        maxDrawdown?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        riskScore?: any | null | undefined;
        subscriptionCount?: number | null | undefined;
        subscriptionAllocation?: any | null | undefined;
      }
    | null
    | undefined;
  backtestMeasurements?:
    | {
        __typename?: "SyndicationMeasurements";
        maxDrawdown?: any | null | undefined;
        avgMonthlyProfit?: any | null | undefined;
        percProfitableMonths?: any | null | undefined;
        riskScore?: any | null | undefined;
      }
    | null
    | undefined;
  backtestAnalysis?:
    | {
        __typename?: "Analysis";
        bestMonth?: any | null | undefined;
        worstMonth?: any | null | undefined;
        avgEquityPeakDays?: any | null | undefined;
        realizedProfitPerc?: any | null | undefined;
        dailyProfits?:
          | {
              __typename?: "DailyChart";
              firstDay?: string | null | undefined;
              values?: Array<any | null | undefined> | null | undefined;
            }
          | null
          | undefined;
        monthlyProfits?:
          | {
              __typename?: "MonthlyChart";
              firstMonth?: string | null | undefined;
              values?: Array<any | null | undefined> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  leverageInfo?:
    | {
        __typename?: "LeverageInfo";
        maxLeverage?: any | null | undefined;
        minLeverage?: any | null | undefined;
        avgLeverage?: any | null | undefined;
      }
    | null
    | undefined;
  subscription?:
    | {
        __typename?: "BillingSubscription";
        id: string;
        statusDetails?:
          | {
              __typename?: "BillingSubscriptionStatusDetails";
              mayUnsubscribe: boolean;
              mayRevokeUnsubscription: boolean;
              mayUseEntitlements: boolean;
              hasPaymentPending: boolean;
              hasCreationFailed: boolean;
              hasLatestRenewalFailed: boolean;
              willBeCancelledAtEndOfCycle: boolean;
              userHasUnsubscribed: boolean;
              creationFailure?:
                | {
                    __typename?: "SubscriptionCreationFailure";
                    reason: SubscriptionCreationFailureReason;
                  }
                | null
                | undefined;
              latestRenewalFailure?:
                | {
                    __typename?: "SubscriptionRenewalFailure";
                    reason: SubscriptionRenewalFailureReason;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  owner: {
    __typename?: "PublicUser";
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
  };
};

export type SyndicationUpdateFragment = {
  __typename?: "SyndicationUpdate";
  id: string;
  syndicationId?: string | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
  placement?: SyndicationUpdatePlacement | null | undefined;
  audience?: SyndicationUpdateAudience | null | undefined;
  update?: string | null | undefined;
};

export type PromotionDataFragment = {
  __typename?: "Promotion";
  id?: number | null | undefined;
  status?: string | null | undefined;
  refId?: string | null | undefined;
  promoCode?: string | null | undefined;
  promoterId?: number | null | undefined;
  campaignId?: number | null | undefined;
  referralLink?: string | null | undefined;
};

export type ReferralLeadDataFragment = {
  __typename?: "ReferralLead";
  id?: number | null | undefined;
  state?: string | null | undefined;
  email?: string | null | undefined;
  customerSince?: string | null | undefined;
  planName?: string | null | undefined;
  suspicion?: string | null | undefined;
  registrationDate?: string | null | undefined;
};

export type PromoterDataFragment = {
  __typename?: "Promoter";
  id?: number | null | undefined;
  custId?: string | null | undefined;
  email?: string | null | undefined;
  defaultRefId?: string | null | undefined;
  promotions?:
    | Array<{
        __typename?: "Promotion";
        id?: number | null | undefined;
        status?: string | null | undefined;
        refId?: string | null | undefined;
        promoCode?: string | null | undefined;
        promoterId?: number | null | undefined;
        campaignId?: number | null | undefined;
        referralLink?: string | null | undefined;
      }>
    | null
    | undefined;
  leads?:
    | Array<{
        __typename?: "ReferralLead";
        id?: number | null | undefined;
        state?: string | null | undefined;
        email?: string | null | undefined;
        customerSince?: string | null | undefined;
        planName?: string | null | undefined;
        suspicion?: string | null | undefined;
        registrationDate?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type UserPromoterDataFragment = {
  __typename?: "User";
  id: string;
  promoterDetails?:
    | {
        __typename?: "Promoter";
        id?: number | null | undefined;
        custId?: string | null | undefined;
        email?: string | null | undefined;
        defaultRefId?: string | null | undefined;
        promotions?:
          | Array<{
              __typename?: "Promotion";
              id?: number | null | undefined;
              status?: string | null | undefined;
              refId?: string | null | undefined;
              promoCode?: string | null | undefined;
              promoterId?: number | null | undefined;
              campaignId?: number | null | undefined;
              referralLink?: string | null | undefined;
            }>
          | null
          | undefined;
        leads?:
          | Array<{
              __typename?: "ReferralLead";
              id?: number | null | undefined;
              state?: string | null | undefined;
              email?: string | null | undefined;
              customerSince?: string | null | undefined;
              planName?: string | null | undefined;
              suspicion?: string | null | undefined;
              registrationDate?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PaymentSourceDataFragment = {
  __typename?: "PaymentSource";
  id: string;
  type: PaymentSourceType;
  status: PaymentSourceStatus;
  name: string;
  meta?: string | null | undefined;
};

export type ProductDataFragment = {
  __typename?: "Product";
  id: string;
  name: string;
  price: any;
  currency: string;
  entitlements?:
    | Array<{
        __typename?: "SyndicationEntitlement";
        type: string;
        referenceId: string;
        syndication: {
          __typename?: "PublicSyndication";
          id: string;
          name?: string | null | undefined;
          currencyPair?: CurrencyPair | null | undefined;
          startedAt?: string | null | undefined;
          owner: {
            __typename?: "PublicUser";
            firstName: string;
            lastName: string;
            companyName?: string | null | undefined;
            nickname?: string | null | undefined;
          };
          currencyPairDetails?:
            | {
                __typename?: "CurrencyPairDetails";
                id: string;
                exchange: Exchange;
                pair: CurrencyPair;
                base: Currency;
                quote: Currency;
                settleCurrency: Currency;
                positionCurrency: Currency;
              }
            | null
            | undefined;
          syndicationSettings?:
            | {
                __typename?: "SyndicationSettings";
                id: string;
                monthlyFeeUsd?: any | null | undefined;
                allocationFeePerc?: any | null | undefined;
                successFeePerc?: any | null | undefined;
                accessControl?: SyndicationAccessControl | null | undefined;
              }
            | null
            | undefined;
        };
      }>
    | null
    | undefined;
};

export type BillingSubscriptionDataFragment = {
  __typename?: "BillingSubscription";
  id: string;
  nextBillingTime?: string | null | undefined;
  statusDetails?:
    | {
        __typename?: "BillingSubscriptionStatusDetails";
        mayUnsubscribe: boolean;
        mayRevokeUnsubscription: boolean;
        mayUseEntitlements: boolean;
        hasPaymentPending: boolean;
        hasCreationFailed: boolean;
        hasLatestRenewalFailed: boolean;
        willBeCancelledAtEndOfCycle: boolean;
        userHasUnsubscribed: boolean;
        creationFailure?:
          | {
              __typename?: "SubscriptionCreationFailure";
              reason: SubscriptionCreationFailureReason;
            }
          | null
          | undefined;
        latestRenewalFailure?:
          | {
              __typename?: "SubscriptionRenewalFailure";
              reason: SubscriptionRenewalFailureReason;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  product: {
    __typename?: "Product";
    id: string;
    name: string;
    price: any;
    currency: string;
    entitlements?:
      | Array<{
          __typename?: "SyndicationEntitlement";
          type: string;
          referenceId: string;
          syndication: {
            __typename?: "PublicSyndication";
            id: string;
            name?: string | null | undefined;
            currencyPair?: CurrencyPair | null | undefined;
            startedAt?: string | null | undefined;
            owner: {
              __typename?: "PublicUser";
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              nickname?: string | null | undefined;
            };
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
            syndicationSettings?:
              | {
                  __typename?: "SyndicationSettings";
                  id: string;
                  monthlyFeeUsd?: any | null | undefined;
                  allocationFeePerc?: any | null | undefined;
                  successFeePerc?: any | null | undefined;
                  accessControl?: SyndicationAccessControl | null | undefined;
                }
              | null
              | undefined;
          };
        }>
      | null
      | undefined;
  };
};

export type InvoiceItemFragment = {
  __typename?: "InvoiceItem";
  id: string;
  itemType?: string | null | undefined;
  amount?: any | null | undefined;
  description?: string | null | undefined;
  associations?:
    | Array<
        | {
            __typename?: "BillingSubscription";
            id: string;
            startTime?: string | null | undefined;
            endTime?: string | null | undefined;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            price: any;
            currency: string;
            entitlements?:
              | Array<{
                  __typename?: "SyndicationEntitlement";
                  type: string;
                  referenceId: string;
                  syndication: {
                    __typename?: "PublicSyndication";
                    id: string;
                    name?: string | null | undefined;
                    currencyPair?: CurrencyPair | null | undefined;
                    startedAt?: string | null | undefined;
                    owner: {
                      __typename?: "PublicUser";
                      firstName: string;
                      lastName: string;
                      companyName?: string | null | undefined;
                      nickname?: string | null | undefined;
                    };
                    currencyPairDetails?:
                      | {
                          __typename?: "CurrencyPairDetails";
                          id: string;
                          exchange: Exchange;
                          pair: CurrencyPair;
                          base: Currency;
                          quote: Currency;
                          settleCurrency: Currency;
                          positionCurrency: Currency;
                        }
                      | null
                      | undefined;
                    syndicationSettings?:
                      | {
                          __typename?: "SyndicationSettings";
                          id: string;
                          monthlyFeeUsd?: any | null | undefined;
                          allocationFeePerc?: any | null | undefined;
                          successFeePerc?: any | null | undefined;
                          accessControl?:
                            | SyndicationAccessControl
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  };
                }>
              | null
              | undefined;
          }
      >
    | null
    | undefined;
  successFeeLineItemMetadata?:
    | {
        __typename?: "SuccessFeeLineItemMetadata";
        previousTotalValuePeak?: any | null | undefined;
        newTotalValuePeak?: any | null | undefined;
        totalValueDeltaUsd?: any | null | undefined;
      }
    | null
    | undefined;
};

export type InvoicePaymentFragment = {
  __typename?: "InvoicePayment";
  id: string;
  paymentProvider: string;
  status: string;
  statusReason?: string | null | undefined;
  amount: any;
  externalId?: string | null | undefined;
  metadata?:
    | { __typename?: "CheckoutPaymentMetadata"; redirectLink3DS2: string }
    | { __typename?: "NashLinkPaymentMetadata"; paymentUrl: string }
    | null
    | undefined;
  refunds: Array<{
    __typename?: "InvoicePaymentRefund";
    id: string;
    amount: any;
    reason: string;
  }>;
};

export type InvoiceFragment = {
  __typename?: "Invoice";
  id: string;
  createdAt: string;
  total: any;
  totalPaid: any;
  status: InvoiceStatus;
  currency: string;
  pending: any;
  balance: any;
  periodStart?: string | null | undefined;
  periodEnd?: string | null | undefined;
  mayCreatePayment: boolean;
  payments: Array<
    | {
        __typename?: "InvoicePayment";
        id: string;
        paymentProvider: string;
        status: string;
        statusReason?: string | null | undefined;
        amount: any;
        externalId?: string | null | undefined;
        metadata?:
          | { __typename?: "CheckoutPaymentMetadata"; redirectLink3DS2: string }
          | { __typename?: "NashLinkPaymentMetadata"; paymentUrl: string }
          | null
          | undefined;
        refunds: Array<{
          __typename?: "InvoicePaymentRefund";
          id: string;
          amount: any;
          reason: string;
        }>;
      }
    | null
    | undefined
  >;
  items: Array<
    | {
        __typename?: "InvoiceItem";
        id: string;
        itemType?: string | null | undefined;
        amount?: any | null | undefined;
        description?: string | null | undefined;
        associations?:
          | Array<
              | {
                  __typename?: "BillingSubscription";
                  id: string;
                  startTime?: string | null | undefined;
                  endTime?: string | null | undefined;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  price: any;
                  currency: string;
                  entitlements?:
                    | Array<{
                        __typename?: "SyndicationEntitlement";
                        type: string;
                        referenceId: string;
                        syndication: {
                          __typename?: "PublicSyndication";
                          id: string;
                          name?: string | null | undefined;
                          currencyPair?: CurrencyPair | null | undefined;
                          startedAt?: string | null | undefined;
                          owner: {
                            __typename?: "PublicUser";
                            firstName: string;
                            lastName: string;
                            companyName?: string | null | undefined;
                            nickname?: string | null | undefined;
                          };
                          currencyPairDetails?:
                            | {
                                __typename?: "CurrencyPairDetails";
                                id: string;
                                exchange: Exchange;
                                pair: CurrencyPair;
                                base: Currency;
                                quote: Currency;
                                settleCurrency: Currency;
                                positionCurrency: Currency;
                              }
                            | null
                            | undefined;
                          syndicationSettings?:
                            | {
                                __typename?: "SyndicationSettings";
                                id: string;
                                monthlyFeeUsd?: any | null | undefined;
                                allocationFeePerc?: any | null | undefined;
                                successFeePerc?: any | null | undefined;
                                accessControl?:
                                  | SyndicationAccessControl
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                        };
                      }>
                    | null
                    | undefined;
                }
            >
          | null
          | undefined;
        successFeeLineItemMetadata?:
          | {
              __typename?: "SuccessFeeLineItemMetadata";
              previousTotalValuePeak?: any | null | undefined;
              newTotalValuePeak?: any | null | undefined;
              totalValueDeltaUsd?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type CreateScriptMutationVariables = Exact<{
  language: ScriptLanguage;
  name: Scalars["String"];
  body: Scalars["String"];
}>;

export type CreateScriptMutation = {
  __typename?: "Mutation";
  createScript?:
    | {
        __typename?: "Script";
        id: string;
        name: string;
        language: ScriptLanguage;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
          createdAt: string;
          updatedAt: string;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type UpdateScriptMutationVariables = Exact<{
  scriptId: Scalars["ID"];
  body: Scalars["String"];
}>;

export type UpdateScriptMutation = {
  __typename?: "Mutation";
  updateScript?:
    | {
        __typename?: "Script";
        id: string;
        name: string;
        language: ScriptLanguage;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
          createdAt: string;
          updatedAt: string;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
        revisions: Array<
          | {
              __typename?: "ScriptRevision";
              id: string;
              body: string;
              status: ScriptRevisionStatus;
              createdAt: string;
              updatedAt: string;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    valueType: ScriptParameterType;
                    readableName?: string | null | undefined;
                    description?: string | null | undefined;
                    defaultValue?: string | null | undefined;
                    options?:
                      | Array<string | null | undefined>
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
              errors: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              warnings: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              infos: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type UpdateScriptFavoriteMutationVariables = Exact<{
  scriptId: Scalars["ID"];
  favorite: Scalars["Boolean"];
}>;

export type UpdateScriptFavoriteMutation = {
  __typename?: "Mutation";
  updateScriptFavorite?:
    | {
        __typename?: "Script";
        id: string;
        isOwner: boolean;
        name: string;
        language: ScriptLanguage;
        favorite?: boolean | null | undefined;
        notes?: string | null | undefined;
        isPublic: boolean;
        latestVersionNumber?: number | null | undefined;
        timesCopied?: number | null | undefined;
        isUsed?: boolean | null | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        ownerInfo?:
          | {
              __typename?: "ScriptOwnerInfo";
              author?: string | null | undefined;
              nickname?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RenameScriptMutationVariables = Exact<{
  scriptId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type RenameScriptMutation = {
  __typename?: "Mutation";
  renameScript?:
    | { __typename?: "Script"; id: string; name: string }
    | null
    | undefined;
};

export type CreateMultivariantMutationVariables = Exact<{
  in: MultivariantCreation;
}>;

export type CreateMultivariantMutation = {
  __typename?: "Mutation";
  createMultivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ValidateMultivariantMutationVariables = Exact<{
  in: MultivariantCreation;
}>;

export type ValidateMultivariantMutation = {
  __typename?: "Mutation";
  validateMultivariant?:
    | {
        __typename?: "MultivariantValidationResult";
        totalCombinationsCount: number;
      }
    | null
    | undefined;
};

export type StopMultivariantMutationVariables = Exact<{
  multivariantId: Scalars["ID"];
}>;

export type StopMultivariantMutation = {
  __typename?: "Mutation";
  stopMultivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ResumeMultivariantMutationVariables = Exact<{
  multivariantId: Scalars["ID"];
}>;

export type ResumeMultivariantMutation = {
  __typename?: "Mutation";
  resumeMultivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateMultivariantFavoriteMutationVariables = Exact<{
  id: Scalars["ID"];
  favorite?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateMultivariantFavoriteMutation = {
  __typename?: "Mutation";
  updateMultivariantFavorite?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteMultivariantMutationVariables = Exact<{
  multivariantId: Scalars["ID"];
}>;

export type DeleteMultivariantMutation = {
  __typename?: "Mutation";
  deleteMultivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteMultivariantsMutationVariables = Exact<{
  multivariantIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteMultivariantsMutation = {
  __typename?: "Mutation";
  deleteMultivariants: Array<{
    __typename?: "Multivariant";
    id: string;
    name?: string | null | undefined;
    exchange: Exchange;
    currencyPair: CurrencyPair;
    candleSize?: string | null | undefined;
    candleType?: CandleType | null | undefined;
    maxBuy?: string | null | undefined;
    minProfit?: string | null | undefined;
    stopLossPercentage?: string | null | undefined;
    stopLossTrailing?: boolean | null | undefined;
    takeProfitPercentage?: string | null | undefined;
    takeProfitTrailingPercentage?: string | null | undefined;
    algorithmSettings: string;
    status?: ExecutionStatus | null | undefined;
    totalCombinationsCount: number;
    canResume?: boolean | null | undefined;
    createdAt?: string | null | undefined;
    favorite?: boolean | null | undefined;
    scriptVersionSequence?: number | null | undefined;
    allocation?: any | null | undefined;
    performanceMode: ExecutionPerformanceMode;
    leverage?: string | null | undefined;
    leverageShort?: string | null | undefined;
    lookbackDays: number;
    buyActionTime?: string | null | undefined;
    sellActionTime?: string | null | undefined;
    currencyPairDetails?:
      | {
          __typename?: "CurrencyPairDetails";
          id: string;
          exchange: Exchange;
          pair: CurrencyPair;
          base: Currency;
          quote: Currency;
          settleCurrency: Currency;
          positionCurrency: Currency;
        }
      | null
      | undefined;
    scriptDetails: {
      __typename?: "ScriptDetails";
      id: string;
      scriptId: string;
      revisionId: string;
      name: string;
      language: ScriptLanguage;
      version?: number | null | undefined;
      isLatest?: boolean | null | undefined;
      createdAt?: string | null | undefined;
      parameters: Array<
        | {
            __typename?: "ScriptParameterDetails";
            readableName?: string | null | undefined;
            fieldName: string;
          }
        | null
        | undefined
      >;
    };
    progress?:
      | Array<
          | {
              __typename?: "ExecutionStatusCount";
              status: ExecutionStatus;
              count: number;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    tags?:
      | Array<
          | { __typename?: "Tag"; id: string; name: string; color: string }
          | null
          | undefined
        >
      | null
      | undefined;
  }>;
};

export type CreateExecutionMutationVariables = Exact<{
  in: ExecutionCreation;
}>;

export type CreateExecutionMutation = {
  __typename?: "Mutation";
  createExecution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateExecutionFavoriteMutationVariables = Exact<{
  id: Scalars["ID"];
  favorite?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateExecutionFavoriteMutation = {
  __typename?: "Mutation";
  updateExecutionFavorite?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateExecutionSharingMutationVariables = Exact<{
  id: Scalars["ID"];
  share?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateExecutionSharingMutation = {
  __typename?: "Mutation";
  updateExecutionSharing?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateCredentialMutationVariables = Exact<{
  in: CredentialCreation;
}>;

export type CreateCredentialMutation = {
  __typename?: "Mutation";
  createCredential?:
    | {
        __typename?: "Credential";
        id: string;
        name: string;
        exchange: Exchange;
        maskedApiKey: string;
        status: CredentialStatus;
      }
    | null
    | undefined;
};

export type DeleteCredentialMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCredentialMutation = {
  __typename?: "Mutation";
  deleteCredential?:
    | {
        __typename?: "Credential";
        id: string;
        name: string;
        exchange: Exchange;
        maskedApiKey: string;
        status: CredentialStatus;
      }
    | null
    | undefined;
};

export type StopExecutionMutationVariables = Exact<{
  executionId: Scalars["ID"];
  stopStatus?: InputMaybe<ExecutionStatus>;
}>;

export type StopExecutionMutation = {
  __typename?: "Mutation";
  stopExecution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteScriptMutationVariables = Exact<{
  scriptId: Scalars["ID"];
}>;

export type DeleteScriptMutation = {
  __typename?: "Mutation";
  deleteScript?: boolean | null | undefined;
};

export type RestartExecutionMutationVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type RestartExecutionMutation = {
  __typename?: "Mutation";
  restartExecution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteSimulationMutationVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type DeleteSimulationMutation = {
  __typename?: "Mutation";
  deleteSimulation?: boolean | null | undefined;
};

export type DeleteSimulationsMutationVariables = Exact<{
  executionIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteSimulationsMutation = {
  __typename?: "Mutation";
  deleteSimulations?: boolean | null | undefined;
};

export type CreateFilterPresetMutationVariables = Exact<{
  name: Scalars["String"];
  filters: Scalars["String"];
}>;

export type CreateFilterPresetMutation = {
  __typename?: "Mutation";
  createFilterPreset?:
    | { __typename?: "FilterPreset"; id: string; name: string; filters: string }
    | null
    | undefined;
};

export type DeleteFilterPresetMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteFilterPresetMutation = {
  __typename?: "Mutation";
  deleteFilterPreset?: boolean | null | undefined;
};

export type RenameFilterPresetMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
}>;

export type RenameFilterPresetMutation = {
  __typename?: "Mutation";
  renameFilterPreset?:
    | { __typename?: "FilterPreset"; id: string; name: string; filters: string }
    | null
    | undefined;
};

export type UpdateFilterPresetMutationVariables = Exact<{
  id: Scalars["ID"];
  filters: Scalars["String"];
}>;

export type UpdateFilterPresetMutation = {
  __typename?: "Mutation";
  updateFilterPreset?:
    | { __typename?: "FilterPreset"; id: string; name: string; filters: string }
    | null
    | undefined;
};

export type CreateTagMutationVariables = Exact<{
  name: Scalars["String"];
  color: Scalars["String"];
}>;

export type CreateTagMutation = {
  __typename?: "Mutation";
  createTag?:
    | { __typename?: "Tag"; id: string; name: string; color: string }
    | null
    | undefined;
};

export type DeleteTagMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTagMutation = {
  __typename?: "Mutation";
  deleteTag?: boolean | null | undefined;
};

export type UpdateTagMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  color: Scalars["String"];
}>;

export type UpdateTagMutation = {
  __typename?: "Mutation";
  updateTag?:
    | { __typename?: "Tag"; id: string; name: string; color: string }
    | null
    | undefined;
};

export type CreateExecutionTagMutationVariables = Exact<{
  executionId: Scalars["ID"];
  tagId: Scalars["ID"];
}>;

export type CreateExecutionTagMutation = {
  __typename?: "Mutation";
  createExecutionTag?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteExecutionTagMutationVariables = Exact<{
  executionId: Scalars["ID"];
  tagId: Scalars["ID"];
}>;

export type DeleteExecutionTagMutation = {
  __typename?: "Mutation";
  deleteExecutionTag?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateMultivariantTagMutationVariables = Exact<{
  multivariantId: Scalars["ID"];
  tagId: Scalars["ID"];
}>;

export type CreateMultivariantTagMutation = {
  __typename?: "Mutation";
  createMultivariantTag?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteMultivariantTagMutationVariables = Exact<{
  multivariantId: Scalars["ID"];
  tagId: Scalars["ID"];
}>;

export type DeleteMultivariantTagMutation = {
  __typename?: "Mutation";
  deleteMultivariantTag?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateScriptTagMutationVariables = Exact<{
  scriptId: Scalars["ID"];
  tagId: Scalars["ID"];
}>;

export type CreateScriptTagMutation = {
  __typename?: "Mutation";
  createScriptTag?:
    | {
        __typename?: "Script";
        id: string;
        isOwner: boolean;
        name: string;
        language: ScriptLanguage;
        favorite?: boolean | null | undefined;
        notes?: string | null | undefined;
        isPublic: boolean;
        latestVersionNumber?: number | null | undefined;
        timesCopied?: number | null | undefined;
        isUsed?: boolean | null | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        ownerInfo?:
          | {
              __typename?: "ScriptOwnerInfo";
              author?: string | null | undefined;
              nickname?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteScriptTagMutationVariables = Exact<{
  scriptId: Scalars["ID"];
  tagId: Scalars["ID"];
}>;

export type DeleteScriptTagMutation = {
  __typename?: "Mutation";
  deleteScriptTag?:
    | {
        __typename?: "Script";
        id: string;
        isOwner: boolean;
        name: string;
        language: ScriptLanguage;
        favorite?: boolean | null | undefined;
        notes?: string | null | undefined;
        isPublic: boolean;
        latestVersionNumber?: number | null | undefined;
        timesCopied?: number | null | undefined;
        isUsed?: boolean | null | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        ownerInfo?:
          | {
              __typename?: "ScriptOwnerInfo";
              author?: string | null | undefined;
              nickname?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateScriptNotesMutationVariables = Exact<{
  scriptId: Scalars["ID"];
  notes: Scalars["String"];
}>;

export type UpdateScriptNotesMutation = {
  __typename?: "Mutation";
  updateScriptNotes?:
    | {
        __typename?: "Script";
        id: string;
        isOwner: boolean;
        name: string;
        language: ScriptLanguage;
        favorite?: boolean | null | undefined;
        notes?: string | null | undefined;
        isPublic: boolean;
        latestVersionNumber?: number | null | undefined;
        timesCopied?: number | null | undefined;
        isUsed?: boolean | null | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        ownerInfo?:
          | {
              __typename?: "ScriptOwnerInfo";
              author?: string | null | undefined;
              nickname?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TempChangeTierMutationVariables = Exact<{
  newTier: UserTier;
}>;

export type TempChangeTierMutation = {
  __typename?: "Mutation";
  tempChangeTier: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    termsAccepted: boolean;
    welcomed: boolean;
    tier?: UserTier | null | undefined;
    permissions: Array<Permission | null | undefined>;
    registrationDate: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    country?: string | null | undefined;
    description?: string | null | undefined;
    twitterUrl?: string | null | undefined;
    discordUrl?: string | null | undefined;
    telegramUrl?: string | null | undefined;
    mediumUrl?: string | null | undefined;
    linkedinUrl?: string | null | undefined;
    personalBlogUrl?: string | null | undefined;
    personalWebsiteUrl?: string | null | undefined;
    profilePhotoS3Key?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
    outstandingInvoiceCount?: number | null | undefined;
    availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
    tierParameters?:
      | {
          __typename?: "UserTierParameters";
          concurrentBatchTests: number;
          paperTradesCount: number;
          batchTestMaxSizeAccuracy: number;
          batchTestMaxCandlesAmount: number;
        }
      | null
      | undefined;
    mailingPreference?:
      | {
          __typename?: "MailingPreference";
          status?: string | null | undefined;
          userType?: UserType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateUserWelcomedMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateUserWelcomedMutation = {
  __typename?: "Mutation";
  updateUserWelcomed: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    termsAccepted: boolean;
    welcomed: boolean;
    tier?: UserTier | null | undefined;
    permissions: Array<Permission | null | undefined>;
    registrationDate: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    country?: string | null | undefined;
    description?: string | null | undefined;
    twitterUrl?: string | null | undefined;
    discordUrl?: string | null | undefined;
    telegramUrl?: string | null | undefined;
    mediumUrl?: string | null | undefined;
    linkedinUrl?: string | null | undefined;
    personalBlogUrl?: string | null | undefined;
    personalWebsiteUrl?: string | null | undefined;
    profilePhotoS3Key?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
    outstandingInvoiceCount?: number | null | undefined;
    availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
    tierParameters?:
      | {
          __typename?: "UserTierParameters";
          concurrentBatchTests: number;
          paperTradesCount: number;
          batchTestMaxSizeAccuracy: number;
          batchTestMaxCandlesAmount: number;
        }
      | null
      | undefined;
    mailingPreference?:
      | {
          __typename?: "MailingPreference";
          status?: string | null | undefined;
          userType?: UserType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type WelcomeUserMutationVariables = Exact<{
  preferredUserType: UserType;
}>;

export type WelcomeUserMutation = {
  __typename?: "Mutation";
  welcomedUser: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    termsAccepted: boolean;
    welcomed: boolean;
    tier?: UserTier | null | undefined;
    permissions: Array<Permission | null | undefined>;
    registrationDate: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    country?: string | null | undefined;
    description?: string | null | undefined;
    twitterUrl?: string | null | undefined;
    discordUrl?: string | null | undefined;
    telegramUrl?: string | null | undefined;
    mediumUrl?: string | null | undefined;
    linkedinUrl?: string | null | undefined;
    personalBlogUrl?: string | null | undefined;
    personalWebsiteUrl?: string | null | undefined;
    profilePhotoS3Key?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
    outstandingInvoiceCount?: number | null | undefined;
    availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
    tierParameters?:
      | {
          __typename?: "UserTierParameters";
          concurrentBatchTests: number;
          paperTradesCount: number;
          batchTestMaxSizeAccuracy: number;
          batchTestMaxCandlesAmount: number;
        }
      | null
      | undefined;
    mailingPreference?:
      | {
          __typename?: "MailingPreference";
          status?: string | null | undefined;
          userType?: UserType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type CreatePackMutationVariables = Exact<{
  in: PackCreation;
}>;

export type CreatePackMutation = {
  __typename?: "Mutation";
  createPack?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateUserGeneralInfoMutationVariables = Exact<{
  in?: InputMaybe<UserGeneralInfo>;
}>;

export type UpdateUserGeneralInfoMutation = {
  __typename?: "Mutation";
  updateUserGeneralInfo: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    termsAccepted: boolean;
    welcomed: boolean;
    tier?: UserTier | null | undefined;
    permissions: Array<Permission | null | undefined>;
    registrationDate: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    country?: string | null | undefined;
    description?: string | null | undefined;
    twitterUrl?: string | null | undefined;
    discordUrl?: string | null | undefined;
    telegramUrl?: string | null | undefined;
    mediumUrl?: string | null | undefined;
    linkedinUrl?: string | null | undefined;
    personalBlogUrl?: string | null | undefined;
    personalWebsiteUrl?: string | null | undefined;
    profilePhotoS3Key?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
    outstandingInvoiceCount?: number | null | undefined;
    availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
    tierParameters?:
      | {
          __typename?: "UserTierParameters";
          concurrentBatchTests: number;
          paperTradesCount: number;
          batchTestMaxSizeAccuracy: number;
          batchTestMaxCandlesAmount: number;
        }
      | null
      | undefined;
    mailingPreference?:
      | {
          __typename?: "MailingPreference";
          status?: string | null | undefined;
          userType?: UserType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateScriptPublicStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateScriptPublicStatusMutation = {
  __typename?: "Mutation";
  updateScriptPublicStatus?:
    | {
        __typename?: "Script";
        id: string;
        isOwner: boolean;
        name: string;
        language: ScriptLanguage;
        favorite?: boolean | null | undefined;
        notes?: string | null | undefined;
        isPublic: boolean;
        latestVersionNumber?: number | null | undefined;
        timesCopied?: number | null | undefined;
        isUsed?: boolean | null | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        ownerInfo?:
          | {
              __typename?: "ScriptOwnerInfo";
              author?: string | null | undefined;
              nickname?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IncrementScriptTimesCopiedMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IncrementScriptTimesCopiedMutation = {
  __typename?: "Mutation";
  incrementScriptTimesCopied?:
    | {
        __typename?: "Script";
        id: string;
        timesCopied?: number | null | undefined;
      }
    | null
    | undefined;
};

export type RefreshCredentialAssetsMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RefreshCredentialAssetsMutation = {
  __typename?: "Mutation";
  refreshCredentialAssets?:
    | {
        __typename?: "Credential";
        id: string;
        name: string;
        exchange: Exchange;
        maskedApiKey: string;
        status: CredentialStatus;
        assets: {
          __typename?: "AssetDetails";
          updatedAt?: string | null | undefined;
          total: Array<
            | {
                __typename?: "Asset";
                usdValue?: any | null | undefined;
                btcValue?: any | null | undefined;
                currency: string;
                amount: any;
              }
            | null
            | undefined
          >;
          free: Array<
            | { __typename?: "Asset"; currency: string; amount: any }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type UpdateExecutionStageMutationVariables = Exact<{
  executionId: Scalars["ID"];
  stage?: InputMaybe<ExecutionStage>;
}>;

export type UpdateExecutionStageMutation = {
  __typename?: "Mutation";
  updateExecutionStage?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteExecutionMutationVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type DeleteExecutionMutation = {
  __typename?: "Mutation";
  deleteExecution?: boolean | null | undefined;
};

export type DeleteExecutionsMutationVariables = Exact<{
  executionIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteExecutionsMutation = {
  __typename?: "Mutation";
  deleteExecutions?: boolean | null | undefined;
};

export type CreateSyndicationSubscriptionMutationVariables = Exact<{
  in: SyndicationSubscriptionCreation;
}>;

export type CreateSyndicationSubscriptionMutation = {
  __typename?: "Mutation";
  createSyndicationSubscription?:
    | { __typename?: "Execution"; id: string }
    | null
    | undefined;
};

export type CopyExecutionFromMultivariantMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CopyExecutionFromMultivariantMutation = {
  __typename?: "Mutation";
  copyExecutionFromMultivariant?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ConvertToSyndicationMutationVariables = Exact<{
  in?: InputMaybe<SyndicationCreation>;
}>;

export type ConvertToSyndicationMutation = {
  __typename?: "Mutation";
  convertToSyndication?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ConvertToPapertradeMutationVariables = Exact<{
  syndicationId: Scalars["ID"];
}>;

export type ConvertToPapertradeMutation = {
  __typename?: "Mutation";
  convertToPapertrade?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CloseExecutionPositionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CloseExecutionPositionMutation = {
  __typename?: "Mutation";
  closeExecutionPosition?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateSyndicationSettingsMutationVariables = Exact<{
  in?: InputMaybe<SyndicationCreation>;
}>;

export type UpdateSyndicationSettingsMutation = {
  __typename?: "Mutation";
  updateSyndicationSettings?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateSyndicationAccessControlMutationVariables = Exact<{
  id: Scalars["ID"];
  accessControl: SyndicationAccessControl;
}>;

export type UpdateSyndicationAccessControlMutation = {
  __typename?: "Mutation";
  updateSyndicationAccessControl?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UnarchiveExecutionMutationVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type UnarchiveExecutionMutation = {
  __typename?: "Mutation";
  unarchiveExecution?: boolean | null | undefined;
};

export type CreateColumnsPresetMutationVariables = Exact<{
  name: Scalars["String"];
  columns: Scalars["String"];
}>;

export type CreateColumnsPresetMutation = {
  __typename?: "Mutation";
  createColumnsPreset?:
    | {
        __typename?: "ColumnsPreset";
        id: string;
        name: string;
        columns: string;
      }
    | null
    | undefined;
};

export type DeleteColumnsPresetMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteColumnsPresetMutation = {
  __typename?: "Mutation";
  deleteColumnsPreset?: boolean | null | undefined;
};

export type RenameColumnsPresetMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
}>;

export type RenameColumnsPresetMutation = {
  __typename?: "Mutation";
  renameColumnsPreset?:
    | {
        __typename?: "ColumnsPreset";
        id: string;
        name: string;
        columns: string;
      }
    | null
    | undefined;
};

export type UpdateColumnsPresetMutationVariables = Exact<{
  id: Scalars["ID"];
  columns: Scalars["String"];
}>;

export type UpdateColumnsPresetMutation = {
  __typename?: "Mutation";
  updateColumnsPreset?:
    | {
        __typename?: "ColumnsPreset";
        id: string;
        name: string;
        columns: string;
      }
    | null
    | undefined;
};

export type CreateAnnouncementMutationVariables = Exact<{
  in: AnnouncementCreation;
}>;

export type CreateAnnouncementMutation = {
  __typename?: "Mutation";
  createAnnouncement?: boolean | null | undefined;
};

export type DeactivateAnnouncementMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeactivateAnnouncementMutation = {
  __typename?: "Mutation";
  deactivateAnnouncement?: boolean | null | undefined;
};

export type UpdateExecutionNotesMutationVariables = Exact<{
  executionId: Scalars["ID"];
  notes: Scalars["String"];
}>;

export type UpdateExecutionNotesMutation = {
  __typename?: "Mutation";
  updateExecutionNotes?:
    | {
        __typename?: "Execution";
        id: string;
        notes?: string | null | undefined;
      }
    | null
    | undefined;
};

export type AddUserToSyndicationAllowListMutationVariables = Exact<{
  id: Scalars["ID"];
  userNickname: Scalars["String"];
}>;

export type AddUserToSyndicationAllowListMutation = {
  __typename?: "Mutation";
  addUserToSyndicationAllowList?: boolean | null | undefined;
};

export type RemoveUserFromSyndicationAllowListMutationVariables = Exact<{
  id: Scalars["ID"];
  userNickname: Scalars["String"];
}>;

export type RemoveUserFromSyndicationAllowListMutation = {
  __typename?: "Mutation";
  removeUserFromSyndicationAllowList?: boolean | null | undefined;
};

export type UpdateSyndicationSharingMutationVariables = Exact<{
  id: Scalars["ID"];
  share: Scalars["Boolean"];
}>;

export type UpdateSyndicationSharingMutation = {
  __typename?: "Mutation";
  updateSyndicationSharing?:
    | {
        __typename?: "Execution";
        id: string;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              shareToken?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type InvitedToSyndicationAllowListMutationVariables = Exact<{
  shareToken: Scalars["ID"];
}>;

export type InvitedToSyndicationAllowListMutation = {
  __typename?: "Mutation";
  invitedToSyndicationAllowList?: boolean | null | undefined;
};

export type AddSyndicationUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  update: Scalars["String"];
  audience: SyndicationUpdateAudience;
}>;

export type AddSyndicationUpdateMutation = {
  __typename?: "Mutation";
  addSyndicationUpdate?: boolean | null | undefined;
};

export type RemoveSyndicationUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  updateId: Scalars["ID"];
}>;

export type RemoveSyndicationUpdateMutation = {
  __typename?: "Mutation";
  removeSyndicationUpdate?: boolean | null | undefined;
};

export type PinSyndicationUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  updateId: Scalars["ID"];
  placement: SyndicationUpdatePlacement;
}>;

export type PinSyndicationUpdateMutation = {
  __typename?: "Mutation";
  pinSyndicationUpdate?: boolean | null | undefined;
};

export type ModifySyndicationUpdateAudienceMutationVariables = Exact<{
  id: Scalars["ID"];
  updateId: Scalars["ID"];
  audience: SyndicationUpdateAudience;
}>;

export type ModifySyndicationUpdateAudienceMutation = {
  __typename?: "Mutation";
  modifySyndicationUpdateAudience?: boolean | null | undefined;
};

export type ModifySyndicationUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  updateId: Scalars["ID"];
  update: Scalars["String"];
}>;

export type ModifySyndicationUpdateMutation = {
  __typename?: "Mutation";
  modifySyndicationUpdate?: boolean | null | undefined;
};

export type UpdateUserMailTypeMutationVariables = Exact<{
  preferredUserType: UserType;
}>;

export type UpdateUserMailTypeMutation = {
  __typename?: "Mutation";
  updateUserMailType: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    termsAccepted: boolean;
    welcomed: boolean;
    tier?: UserTier | null | undefined;
    permissions: Array<Permission | null | undefined>;
    registrationDate: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    country?: string | null | undefined;
    description?: string | null | undefined;
    twitterUrl?: string | null | undefined;
    discordUrl?: string | null | undefined;
    telegramUrl?: string | null | undefined;
    mediumUrl?: string | null | undefined;
    linkedinUrl?: string | null | undefined;
    personalBlogUrl?: string | null | undefined;
    personalWebsiteUrl?: string | null | undefined;
    profilePhotoS3Key?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
    outstandingInvoiceCount?: number | null | undefined;
    availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
    tierParameters?:
      | {
          __typename?: "UserTierParameters";
          concurrentBatchTests: number;
          batchTestMaxSizeAccuracy: number;
          batchTestMaxCandlesAmount: number;
          paperTradesCount: number;
        }
      | null
      | undefined;
    mailingPreference?:
      | {
          __typename?: "MailingPreference";
          status?: string | null | undefined;
          userType?: UserType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type CreatePromoterProfileMutationVariables = Exact<{
  refId?: InputMaybe<Scalars["String"]>;
}>;

export type CreatePromoterProfileMutation = {
  __typename?: "Mutation";
  createPromoterProfile?:
    | {
        __typename?: "Promoter";
        id?: number | null | undefined;
        custId?: string | null | undefined;
        email?: string | null | undefined;
        defaultRefId?: string | null | undefined;
        promotions?:
          | Array<{
              __typename?: "Promotion";
              id?: number | null | undefined;
              status?: string | null | undefined;
              refId?: string | null | undefined;
              promoCode?: string | null | undefined;
              promoterId?: number | null | undefined;
              campaignId?: number | null | undefined;
              referralLink?: string | null | undefined;
            }>
          | null
          | undefined;
        leads?:
          | Array<{
              __typename?: "ReferralLead";
              id?: number | null | undefined;
              state?: string | null | undefined;
              email?: string | null | undefined;
              customerSince?: string | null | undefined;
              planName?: string | null | undefined;
              suspicion?: string | null | undefined;
              registrationDate?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateUserLanguageMutationVariables = Exact<{
  input: UpdateUserLanguageInput;
}>;

export type UpdateUserLanguageMutation = {
  __typename?: "Mutation";
  updateUserLanguage?:
    | {
        __typename?: "UpdateUserLanguagePayload";
        user?:
          | {
              __typename?: "User";
              id: string;
              language?: Locale | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type StopExecutionsMutationVariables = Exact<{
  in: StopExecutionsInput;
}>;

export type StopExecutionsMutation = {
  __typename?: "Mutation";
  stopExecutions?:
    | {
        __typename?: "StopExecutionsPayload";
        executions: Array<{
          __typename?: "Execution";
          id: string;
          status: ExecutionStatus;
        }>;
      }
    | null
    | undefined;
};

export type AddCompletedTourMutationVariables = Exact<{
  in: AddCompletedTourInput;
}>;

export type AddCompletedTourMutation = {
  __typename?: "Mutation";
  addCompletedTour?:
    | {
        __typename?: "AddCompletedTourPayload";
        user?:
          | {
              __typename?: "User";
              id: string;
              completedTours?:
                | Array<UserTourType | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TokenizeCheckoutTokenMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type TokenizeCheckoutTokenMutation = {
  __typename?: "Mutation";
  source: {
    __typename?: "PaymentSource";
    id: string;
    type: PaymentSourceType;
    status: PaymentSourceStatus;
    name: string;
    meta?: string | null | undefined;
  };
};

export type SubscribeToSyndicationMutationVariables = Exact<{
  id: Scalars["ID"];
  paymentProvider?: InputMaybe<PaymentProvider>;
}>;

export type SubscribeToSyndicationMutation = {
  __typename?: "Mutation";
  subscribeToSyndication?:
    | {
        __typename?: "SubscribeToSyndicationResult";
        id: string;
        invoice?:
          | {
              __typename?: "Invoice";
              id: string;
              payments: Array<
                | {
                    __typename?: "InvoicePayment";
                    id: string;
                    paymentProvider: string;
                    status: string;
                    statusReason?: string | null | undefined;
                    amount: any;
                    externalId?: string | null | undefined;
                    metadata?:
                      | {
                          __typename?: "CheckoutPaymentMetadata";
                          redirectLink3DS2: string;
                        }
                      | {
                          __typename?: "NashLinkPaymentMetadata";
                          paymentUrl: string;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UnsubscribeFromSyndicationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnsubscribeFromSyndicationMutation = {
  __typename?: "Mutation";
  unsubscribeFromSyndication?:
    | {
        __typename?: "BillingSubscription";
        id: string;
        nextBillingTime?: string | null | undefined;
        statusDetails?:
          | {
              __typename?: "BillingSubscriptionStatusDetails";
              mayUnsubscribe: boolean;
              mayRevokeUnsubscription: boolean;
              mayUseEntitlements: boolean;
              hasPaymentPending: boolean;
              hasCreationFailed: boolean;
              hasLatestRenewalFailed: boolean;
              willBeCancelledAtEndOfCycle: boolean;
              userHasUnsubscribed: boolean;
              creationFailure?:
                | {
                    __typename?: "SubscriptionCreationFailure";
                    reason: SubscriptionCreationFailureReason;
                  }
                | null
                | undefined;
              latestRenewalFailure?:
                | {
                    __typename?: "SubscriptionRenewalFailure";
                    reason: SubscriptionRenewalFailureReason;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        product: {
          __typename?: "Product";
          id: string;
          name: string;
          price: any;
          currency: string;
          entitlements?:
            | Array<{
                __typename?: "SyndicationEntitlement";
                type: string;
                referenceId: string;
                syndication: {
                  __typename?: "PublicSyndication";
                  id: string;
                  name?: string | null | undefined;
                  currencyPair?: CurrencyPair | null | undefined;
                  startedAt?: string | null | undefined;
                  owner: {
                    __typename?: "PublicUser";
                    firstName: string;
                    lastName: string;
                    companyName?: string | null | undefined;
                    nickname?: string | null | undefined;
                  };
                  currencyPairDetails?:
                    | {
                        __typename?: "CurrencyPairDetails";
                        id: string;
                        exchange: Exchange;
                        pair: CurrencyPair;
                        base: Currency;
                        quote: Currency;
                        settleCurrency: Currency;
                        positionCurrency: Currency;
                      }
                    | null
                    | undefined;
                  syndicationSettings?:
                    | {
                        __typename?: "SyndicationSettings";
                        id: string;
                        monthlyFeeUsd?: any | null | undefined;
                        allocationFeePerc?: any | null | undefined;
                        successFeePerc?: any | null | undefined;
                        accessControl?:
                          | SyndicationAccessControl
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                };
              }>
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type AbandonSubscriptionCancellationMutationVariables = Exact<{
  subscriptionId: Scalars["ID"];
}>;

export type AbandonSubscriptionCancellationMutation = {
  __typename?: "Mutation";
  abandonSubscriptionCancellation: boolean;
};

export type PayRemainingInvoiceBalanceMutationVariables = Exact<{
  invoiceId: Scalars["ID"];
  paymentProvider: PaymentProvider;
}>;

export type PayRemainingInvoiceBalanceMutation = {
  __typename?: "Mutation";
  payRemainingInvoiceBalance: {
    __typename?: "PayRemainingInvoiceBalanceResult";
    payment: {
      __typename?: "InvoicePayment";
      id: string;
      paymentProvider: string;
      status: string;
      statusReason?: string | null | undefined;
      amount: any;
      externalId?: string | null | undefined;
      metadata?:
        | { __typename?: "CheckoutPaymentMetadata"; redirectLink3DS2: string }
        | { __typename?: "NashLinkPaymentMetadata"; paymentUrl: string }
        | null
        | undefined;
      refunds: Array<{
        __typename?: "InvoicePaymentRefund";
        id: string;
        amount: any;
        reason: string;
      }>;
    };
  };
};

export type CheckCheckoutSessionIdMutationVariables = Exact<{
  sessionId: Scalars["ID"];
}>;

export type CheckCheckoutSessionIdMutation = {
  __typename?: "Mutation";
  checkCheckoutSessionId: boolean;
};

export type GetMyDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyDetailsQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    termsAccepted: boolean;
    welcomed: boolean;
    tier?: UserTier | null | undefined;
    permissions: Array<Permission | null | undefined>;
    registrationDate: string;
    companyName?: string | null | undefined;
    nickname?: string | null | undefined;
    country?: string | null | undefined;
    description?: string | null | undefined;
    twitterUrl?: string | null | undefined;
    discordUrl?: string | null | undefined;
    telegramUrl?: string | null | undefined;
    mediumUrl?: string | null | undefined;
    linkedinUrl?: string | null | undefined;
    personalBlogUrl?: string | null | undefined;
    personalWebsiteUrl?: string | null | undefined;
    profilePhotoS3Key?: string | null | undefined;
    profilePhotoUrl?: string | null | undefined;
    outstandingInvoiceCount?: number | null | undefined;
    availablePaymentProviders?: Array<PaymentProvider> | null | undefined;
    tierParameters?:
      | {
          __typename?: "UserTierParameters";
          concurrentBatchTests: number;
          paperTradesCount: number;
          batchTestMaxSizeAccuracy: number;
          batchTestMaxCandlesAmount: number;
        }
      | null
      | undefined;
    mailingPreference?:
      | {
          __typename?: "MailingPreference";
          status?: string | null | undefined;
          userType?: UserType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type GetExchangeDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExchangeDetailsQuery = {
  __typename?: "RootQuery";
  exchanges?:
    | Array<
        | {
            __typename?: "ExchangeDetails";
            id: Exchange;
            isMargin: boolean;
            currencyPairs: Array<
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                  orderRules: {
                    __typename?: "OrderRules";
                    minOrderAmount: any;
                    maxOrderAmount: any;
                    minOrderValue: any;
                  };
                }
              | null
              | undefined
            >;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetScriptsQueryVariables = Exact<{ [key: string]: never }>;

export type GetScriptsQuery = {
  __typename?: "RootQuery";
  scripts?:
    | Array<
        | {
            __typename?: "Script";
            id: string;
            name: string;
            language: ScriptLanguage;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetScriptsListDataQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ScriptSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ScriptFilter>> | InputMaybe<ScriptFilter>
  >;
  language?: InputMaybe<ScriptLanguage>;
}>;

export type GetScriptsListDataQuery = {
  __typename?: "RootQuery";
  scripts?:
    | Array<
        | {
            __typename?: "Script";
            preloadedScript?: PreloadedScript | null | undefined;
            id: string;
            isOwner: boolean;
            name: string;
            language: ScriptLanguage;
            favorite?: boolean | null | undefined;
            notes?: string | null | undefined;
            isPublic: boolean;
            latestVersionNumber?: number | null | undefined;
            timesCopied?: number | null | undefined;
            isUsed?: boolean | null | undefined;
            latest: {
              __typename?: "ScriptRevision";
              id: string;
              createdAt: string;
              updatedAt: string;
            };
            tags?:
              | Array<
                  | {
                      __typename?: "Tag";
                      id: string;
                      name: string;
                      color: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            ownerInfo?:
              | {
                  __typename?: "ScriptOwnerInfo";
                  author?: string | null | undefined;
                  nickname?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetScriptsListCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<ScriptFilter>> | InputMaybe<ScriptFilter>
  >;
  language?: InputMaybe<ScriptLanguage>;
}>;

export type GetScriptsListCountQuery = {
  __typename?: "RootQuery";
  scriptsCount?: number | null | undefined;
};

export type GetPublicScriptsListDataQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ScriptSort>;
  filters?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<ScriptLanguage>;
}>;

export type GetPublicScriptsListDataQuery = {
  __typename?: "RootQuery";
  publicScripts?:
    | Array<
        | {
            __typename?: "Script";
            id: string;
            isOwner: boolean;
            name: string;
            language: ScriptLanguage;
            favorite?: boolean | null | undefined;
            notes?: string | null | undefined;
            isPublic: boolean;
            latestVersionNumber?: number | null | undefined;
            timesCopied?: number | null | undefined;
            isUsed?: boolean | null | undefined;
            latest: {
              __typename?: "ScriptRevision";
              id: string;
              createdAt: string;
              updatedAt: string;
            };
            ownerInfo?:
              | {
                  __typename?: "ScriptOwnerInfo";
                  author?: string | null | undefined;
                  nickname?: string | null | undefined;
                  isTunedTeam?: boolean | null | undefined;
                }
              | null
              | undefined;
            tags?:
              | Array<
                  | {
                      __typename?: "Tag";
                      id: string;
                      name: string;
                      color: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetPublicScriptsDataQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ScriptSort>;
  filters?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<ScriptLanguage>;
}>;

export type GetPublicScriptsDataQuery = {
  __typename?: "RootQuery";
  publicScripts?:
    | Array<
        | {
            __typename?: "Script";
            id: string;
            name: string;
            language: ScriptLanguage;
            timesCopied?: number | null | undefined;
            ownerInfo?:
              | {
                  __typename?: "ScriptOwnerInfo";
                  author?: string | null | undefined;
                  nickname?: string | null | undefined;
                }
              | null
              | undefined;
            latest: { __typename?: "ScriptRevision"; updatedAt: string };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetPublicScriptsListCountQueryVariables = Exact<{
  filters?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<ScriptLanguage>;
}>;

export type GetPublicScriptsListCountQuery = {
  __typename?: "RootQuery";
  publicScriptsCount?: number | null | undefined;
};

export type GetCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCredentialsQuery = {
  __typename?: "RootQuery";
  credentials?:
    | Array<
        | {
            __typename?: "Credential";
            id: string;
            name: string;
            exchange: Exchange;
            maskedApiKey: string;
            status: CredentialStatus;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetCredentialsWithFundsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCredentialsWithFundsQuery = {
  __typename?: "RootQuery";
  credentials?:
    | Array<
        | {
            __typename?: "Credential";
            id: string;
            name: string;
            exchange: Exchange;
            maskedApiKey: string;
            status: CredentialStatus;
            assets: {
              __typename?: "AssetDetails";
              updatedAt?: string | null | undefined;
              free: Array<
                | {
                    __typename?: "Asset";
                    usdValue?: any | null | undefined;
                    btcValue?: any | null | undefined;
                    currency: string;
                    amount: any;
                  }
                | null
                | undefined
              >;
              total: Array<
                | {
                    __typename?: "Asset";
                    usdValue?: any | null | undefined;
                    btcValue?: any | null | undefined;
                    currency: string;
                    amount: any;
                  }
                | null
                | undefined
              >;
              allocated: Array<
                | {
                    __typename?: "Asset";
                    usdValue?: any | null | undefined;
                    btcValue?: any | null | undefined;
                    currency: string;
                    amount: any;
                  }
                | null
                | undefined
              >;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetScriptsAndCredentialsQueryVariables = Exact<{
  exchange: Exchange;
}>;

export type GetScriptsAndCredentialsQuery = {
  __typename?: "RootQuery";
  credentials?:
    | Array<
        | {
            __typename?: "Credential";
            id: string;
            name: string;
            exchange: Exchange;
            maskedApiKey: string;
            status: CredentialStatus;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  scripts?:
    | Array<
        | {
            __typename?: "Script";
            id: string;
            name: string;
            language: ScriptLanguage;
            latest: {
              __typename?: "ScriptRevision";
              id: string;
              status: ScriptRevisionStatus;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    valueType: ScriptParameterType;
                    readableName?: string | null | undefined;
                    description?: string | null | undefined;
                    defaultValue?: string | null | undefined;
                    options?:
                      | Array<string | null | undefined>
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
              errors: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              warnings: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              infos: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetScriptDetailsWithRevisionQueryVariables = Exact<{
  scriptId: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
  revisionId: Scalars["ID"];
  useLatest: Scalars["Boolean"];
}>;

export type GetScriptDetailsWithRevisionQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        isUsed?: boolean | null | undefined;
        preloadedScript?: PreloadedScript | null | undefined;
        id: string;
        name: string;
        language: ScriptLanguage;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
          createdAt: string;
          updatedAt: string;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
        preview?: { __typename?: "Execution"; id: string } | null | undefined;
        revision?: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
          createdAt: string;
          updatedAt: string;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type GetScriptDetailsQueryVariables = Exact<{
  scriptId: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetScriptDetailsQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        isUsed?: boolean | null | undefined;
        preloadedScript?: PreloadedScript | null | undefined;
        id: string;
        name: string;
        language: ScriptLanguage;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
          createdAt: string;
          updatedAt: string;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
        preview?: { __typename?: "Execution"; id: string } | null | undefined;
        revisions: Array<
          | {
              __typename?: "ScriptRevision";
              id: string;
              body: string;
              status: ScriptRevisionStatus;
              createdAt: string;
              updatedAt: string;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    valueType: ScriptParameterType;
                    readableName?: string | null | undefined;
                    description?: string | null | undefined;
                    defaultValue?: string | null | undefined;
                    options?:
                      | Array<string | null | undefined>
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
              errors: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              warnings: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              infos: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetPublicScriptDetailsQueryVariables = Exact<{
  scriptId: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetPublicScriptDetailsQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        isUsed?: boolean | null | undefined;
        id: string;
        name: string;
        language: ScriptLanguage;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          body: string;
          status: ScriptRevisionStatus;
          createdAt: string;
          updatedAt: string;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
        preview?: { __typename?: "Execution"; id: string } | null | undefined;
        revisions: Array<
          | {
              __typename?: "ScriptRevision";
              id: string;
              body: string;
              status: ScriptRevisionStatus;
              createdAt: string;
              updatedAt: string;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    valueType: ScriptParameterType;
                    readableName?: string | null | undefined;
                    description?: string | null | undefined;
                    defaultValue?: string | null | undefined;
                    options?:
                      | Array<string | null | undefined>
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
              errors: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              warnings: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              infos: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetScriptNotesQueryVariables = Exact<{
  scriptId: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetScriptNotesQuery = {
  __typename?: "RootQuery";
  script?:
    | { __typename?: "Script"; id: string; notes?: string | null | undefined }
    | null
    | undefined;
};

export type GetScriptRevisionsQueryVariables = Exact<{
  scriptId: Scalars["ID"];
}>;

export type GetScriptRevisionsQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        revisions: Array<
          | {
              __typename?: "ScriptRevision";
              id: string;
              body: string;
              status: ScriptRevisionStatus;
              createdAt: string;
              updatedAt: string;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    valueType: ScriptParameterType;
                    readableName?: string | null | undefined;
                    description?: string | null | undefined;
                    defaultValue?: string | null | undefined;
                    options?:
                      | Array<string | null | undefined>
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
              errors: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              warnings: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
              infos: Array<
                | {
                    __typename?: "ScriptError";
                    message: string;
                    range: {
                      __typename?: "Range";
                      start: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                      end: {
                        __typename?: "Position";
                        line: number;
                        char: number;
                      };
                    };
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetScriptRevisionsDatesQueryVariables = Exact<{
  scriptId: Scalars["ID"];
}>;

export type GetScriptRevisionsDatesQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        revisions: Array<
          | {
              __typename?: "ScriptRevision";
              id: string;
              createdAt: string;
              updatedAt: string;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetLatestScriptRevisionIdQueryVariables = Exact<{
  scriptId: Scalars["ID"];
}>;

export type GetLatestScriptRevisionIdQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        id: string;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          status: ScriptRevisionStatus;
        };
      }
    | null
    | undefined;
};

export type GetLatestPublicScriptRevisionIdQueryVariables = Exact<{
  scriptId: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetLatestPublicScriptRevisionIdQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        id: string;
        latest: {
          __typename?: "ScriptRevision";
          id: string;
          status: ScriptRevisionStatus;
        };
      }
    | null
    | undefined;
};

export type GetScriptRevisionDetailsQueryVariables = Exact<{
  scriptId: Scalars["ID"];
  scriptRevisionId: Scalars["ID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetScriptRevisionDetailsQuery = {
  __typename?: "RootQuery";
  script?:
    | {
        __typename?: "Script";
        id: string;
        language: ScriptLanguage;
        revision: {
          __typename?: "ScriptRevision";
          id: string;
          status: ScriptRevisionStatus;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                fieldName: string;
                valueType: ScriptParameterType;
                readableName?: string | null | undefined;
                description?: string | null | undefined;
                defaultValue?: string | null | undefined;
                options?: Array<string | null | undefined> | null | undefined;
              }
            | null
            | undefined
          >;
          plotDefinition: {
            __typename?: "ScriptPlotDefinition";
            groups: Array<
              | {
                  __typename?: "ScriptPlotGroup";
                  id: string;
                  isOverlay: boolean;
                  plots: Array<
                    | {
                        __typename?: "PlotInfo";
                        id: string;
                        title: string;
                        lineWidth: number;
                        lineStyle: PlotStyle;
                        transparency: number;
                        trackPrice: boolean;
                        histBase: number;
                        offset: number;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  arrows: Array<
                    | {
                        __typename?: "PlotArrowInfo";
                        id: string;
                        title: string;
                        transparency: number;
                        offset: number;
                        minHeight: number;
                        maxHeight: number;
                        editable: boolean;
                        colorUp: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        colorDown: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  chars: Array<
                    | {
                        __typename?: "PlotCharInfo";
                        id: string;
                        title: string;
                        char: string;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  shapes: Array<
                    | {
                        __typename?: "PlotShapeInfo";
                        id: string;
                        title: string;
                        style: ShapeStyle;
                        location: ShapePosition;
                        transparency: number;
                        offset: number;
                        text?: string | null | undefined;
                        size: ShapeSize;
                        editable: boolean;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                        textColor?:
                          | {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  fills: Array<
                    | {
                        __typename?: "FillInfo";
                        id: string;
                        inputType: PlotType;
                        transparency: number;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        input1: { __typename?: "FillInputId"; str: string };
                        input2: { __typename?: "FillInputId"; str: string };
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  hLines: Array<
                    | {
                        __typename?: "HLineInfo";
                        id: string;
                        title: string;
                        yVal: number;
                        lineWidth: number;
                        lineStyle: HLineStyle;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >;
                  bgColor?:
                    | {
                        __typename?: "BGColorInfo";
                        transparency: number;
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  barColor?:
                    | {
                        __typename?: "BarColorInfo";
                        offset?: number | null | undefined;
                        title?: string | null | undefined;
                        editable?: boolean | null | undefined;
                        color: {
                          __typename?: "ColorInput";
                          type: InputType;
                          value?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
          errors: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          warnings: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
          infos: Array<
            | {
                __typename?: "ScriptError";
                message: string;
                range: {
                  __typename?: "Range";
                  start: {
                    __typename?: "Position";
                    line: number;
                    char: number;
                  };
                  end: { __typename?: "Position"; line: number; char: number };
                };
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type ValidateExecutionQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type ValidateExecutionQuery = {
  __typename?: "RootQuery";
  execution?:
    | { __typename?: "Execution"; id: string; type: ExecutionType }
    | null
    | undefined;
};

export type ExecutionPreviewSubscriptionSubscriptionVariables = Exact<{
  executionIds: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type ExecutionPreviewSubscriptionSubscription = {
  __typename?: "Subscription";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        error?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        stage?: ExecutionStage | null | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ExecutionSubscriptionSubscriptionVariables = Exact<{
  executionIds: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type ExecutionSubscriptionSubscription = {
  __typename?: "Subscription";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        status: ExecutionStatus;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionControlsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetExecutionControlsQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        status: ExecutionStatus;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        exchange: Exchange;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        startedAt?: string | null | undefined;
        endedAt?: string | null | undefined;
        runNumber?: string | null | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              revisionId: string;
              name: string;
              version?: number | null | undefined;
            }
          | null
          | undefined;
        syndication?:
          | {
              __typename?: "PublicSyndication";
              id: string;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        plotDefinition?:
          | {
              __typename?: "ScriptPlotDefinition";
              groups: Array<
                | {
                    __typename?: "ScriptPlotGroup";
                    id: string;
                    isOverlay: boolean;
                    plots: Array<
                      | {
                          __typename?: "PlotInfo";
                          id: string;
                          title: string;
                          lineWidth: number;
                          lineStyle: PlotStyle;
                          transparency: number;
                          trackPrice: boolean;
                          histBase: number;
                          offset: number;
                          editable: boolean;
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                        }
                      | null
                      | undefined
                    >;
                    arrows: Array<
                      | {
                          __typename?: "PlotArrowInfo";
                          id: string;
                          title: string;
                          transparency: number;
                          offset: number;
                          minHeight: number;
                          maxHeight: number;
                          editable: boolean;
                          colorUp: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                          colorDown: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                        }
                      | null
                      | undefined
                    >;
                    chars: Array<
                      | {
                          __typename?: "PlotCharInfo";
                          id: string;
                          title: string;
                          char: string;
                          location: ShapePosition;
                          transparency: number;
                          offset: number;
                          text?: string | null | undefined;
                          size: ShapeSize;
                          editable: boolean;
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                          textColor?:
                            | {
                                __typename?: "ColorInput";
                                type: InputType;
                                value?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >;
                    shapes: Array<
                      | {
                          __typename?: "PlotShapeInfo";
                          id: string;
                          title: string;
                          style: ShapeStyle;
                          location: ShapePosition;
                          transparency: number;
                          offset: number;
                          text?: string | null | undefined;
                          size: ShapeSize;
                          editable: boolean;
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                          textColor?:
                            | {
                                __typename?: "ColorInput";
                                type: InputType;
                                value?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >;
                    fills: Array<
                      | {
                          __typename?: "FillInfo";
                          id: string;
                          inputType: PlotType;
                          transparency: number;
                          title?: string | null | undefined;
                          editable?: boolean | null | undefined;
                          input1: { __typename?: "FillInputId"; str: string };
                          input2: { __typename?: "FillInputId"; str: string };
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                        }
                      | null
                      | undefined
                    >;
                    hLines: Array<
                      | {
                          __typename?: "HLineInfo";
                          id: string;
                          title: string;
                          yVal: number;
                          lineWidth: number;
                          lineStyle: HLineStyle;
                          editable?: boolean | null | undefined;
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                        }
                      | null
                      | undefined
                    >;
                    bgColor?:
                      | {
                          __typename?: "BGColorInfo";
                          transparency: number;
                          offset?: number | null | undefined;
                          title?: string | null | undefined;
                          editable?: boolean | null | undefined;
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                        }
                      | null
                      | undefined;
                    barColor?:
                      | {
                          __typename?: "BarColorInfo";
                          offset?: number | null | undefined;
                          title?: string | null | undefined;
                          editable?: boolean | null | undefined;
                          color: {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          };
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
        plotPalettes?:
          | Array<
              | {
                  __typename?: "ScriptPlotPalette";
                  plotId: string;
                  colors: Array<string | null | undefined>;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetBotControlsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetBotControlsQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
        currencyPair?: CurrencyPair | null | undefined;
        candleSize: string;
        multiCoinCurrency?: Currency | null | undefined;
        exchange: Exchange;
        startedAt?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionRowQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type GetExecutionRowQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        status: ExecutionStatus;
        favorite?: boolean | null | undefined;
        error?: string | null | undefined;
        runtimeSeconds?: number | null | undefined;
        shareToken?: string | null | undefined;
        algorithmSettings?: string | null | undefined;
        createdAt?: string | null | undefined;
        leverage: any;
        leverageShort: any;
        multivariantIterationId?: number | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        allocation: any;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        isPack: boolean;
        stage?: ExecutionStage | null | undefined;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSharedExecutionControlsQueryVariables = Exact<{
  shareToken: Scalars["ID"];
}>;

export type GetSharedExecutionControlsQuery = {
  __typename?: "RootQuery";
  sharedExecution?:
    | {
        __typename?: "SharedExecution";
        shareToken: string;
        name?: string | null | undefined;
        type: ExecutionType;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        exchange: Exchange;
        candleSize: string;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        startedAt?: string | null | undefined;
        endedAt?: string | null | undefined;
        scriptName?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionDetailsForCopyQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type GetExecutionDetailsForCopyQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        isPack: boolean;
        autoRebalance?: boolean | null | undefined;
        preloadedScript?: PreloadedScript | null | undefined;
        id: string;
        name?: string | null | undefined;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        allocation: any;
        type: ExecutionType;
        exchange: Exchange;
        status: ExecutionStatus;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        maxBuy?: any | null | undefined;
        minProfit?: any | null | undefined;
        stopLossPercentage?: any | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: any | null | undefined;
        takeProfitTrailingPercentage?: any | null | undefined;
        buyActionTime?: SignalActionTime | null | undefined;
        sellActionTime?: SignalActionTime | null | undefined;
        buyType?: OrderType | null | undefined;
        sellType?: OrderType | null | undefined;
        algorithmSettings?: string | null | undefined;
        lookbackDays?: number | null | undefined;
        leverage: any;
        leverageShort: any;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        multivariantIterationId?: number | null | undefined;
        error?: string | null | undefined;
        syndicationId?: string | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        credential?:
          | {
              __typename?: "Credential";
              id: string;
              assets: {
                __typename?: "AssetDetails";
                free: Array<
                  | { __typename?: "Asset"; currency: string; amount: any }
                  | null
                  | undefined
                >;
              };
            }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        multiCoinPackExecutions?:
          | Array<
              | {
                  __typename?: "MultiCoinPackCurrencyPercentage";
                  currency: Currency;
                  percentage: any;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              body: string;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    readableName?: string | null | undefined;
                    valueType: ScriptParameterType;
                    defaultValue?: string | null | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndication?:
          | {
              __typename?: "PublicSyndication";
              id: string;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              description?: string | null | undefined;
              maxAllocation: any;
              minAllocation: any;
              monthlyFeeUsd?: any | null | undefined;
              allocationFeePerc?: any | null | undefined;
              successFeePerc?: any | null | undefined;
              maxSubscriberAllocation?: any | null | undefined;
              availableAllocation?: any | null | undefined;
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
        owner?:
          | {
              __typename?: "PublicUser";
              companyName?: string | null | undefined;
              nickname?: string | null | undefined;
              firstName: string;
              lastName: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionDetailsQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type GetExecutionDetailsQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        candleSize: string;
        candleType?: CandleType | null | undefined;
        allocation: any;
        type: ExecutionType;
        exchange: Exchange;
        status: ExecutionStatus;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        maxBuy?: any | null | undefined;
        minProfit?: any | null | undefined;
        stopLossPercentage?: any | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: any | null | undefined;
        takeProfitTrailingPercentage?: any | null | undefined;
        buyActionTime?: SignalActionTime | null | undefined;
        sellActionTime?: SignalActionTime | null | undefined;
        buyType?: OrderType | null | undefined;
        sellType?: OrderType | null | undefined;
        algorithmSettings?: string | null | undefined;
        lookbackDays?: number | null | undefined;
        leverage: any;
        leverageShort: any;
        scriptVersionSequence?: number | null | undefined;
        runNumber?: string | null | undefined;
        multivariantIterationId?: number | null | undefined;
        error?: string | null | undefined;
        syndicationId?: string | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        multiCoinPackExecutions?:
          | Array<
              | {
                  __typename?: "MultiCoinPackCurrencyPercentage";
                  currency: Currency;
                  percentage: any;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              body: string;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    readableName?: string | null | undefined;
                    valueType: ScriptParameterType;
                    defaultValue?: string | null | undefined;
                  }
                | null
                | undefined
              >;
              plotDefinition: {
                __typename?: "ScriptPlotDefinition";
                groups: Array<
                  | {
                      __typename?: "ScriptPlotGroup";
                      id: string;
                      isOverlay: boolean;
                      plots: Array<
                        | {
                            __typename?: "PlotInfo";
                            id: string;
                            title: string;
                            lineWidth: number;
                            lineStyle: PlotStyle;
                            transparency: number;
                            trackPrice: boolean;
                            histBase: number;
                            offset: number;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      arrows: Array<
                        | {
                            __typename?: "PlotArrowInfo";
                            id: string;
                            title: string;
                            transparency: number;
                            offset: number;
                            minHeight: number;
                            maxHeight: number;
                            editable: boolean;
                            colorUp: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            colorDown: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      chars: Array<
                        | {
                            __typename?: "PlotCharInfo";
                            id: string;
                            title: string;
                            char: string;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      shapes: Array<
                        | {
                            __typename?: "PlotShapeInfo";
                            id: string;
                            title: string;
                            style: ShapeStyle;
                            location: ShapePosition;
                            transparency: number;
                            offset: number;
                            text?: string | null | undefined;
                            size: ShapeSize;
                            editable: boolean;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                            textColor?:
                              | {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >;
                      fills: Array<
                        | {
                            __typename?: "FillInfo";
                            id: string;
                            inputType: PlotType;
                            transparency: number;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            input1: { __typename?: "FillInputId"; str: string };
                            input2: { __typename?: "FillInputId"; str: string };
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      hLines: Array<
                        | {
                            __typename?: "HLineInfo";
                            id: string;
                            title: string;
                            yVal: number;
                            lineWidth: number;
                            lineStyle: HLineStyle;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined
                      >;
                      bgColor?:
                        | {
                            __typename?: "BGColorInfo";
                            transparency: number;
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                      barColor?:
                        | {
                            __typename?: "BarColorInfo";
                            offset?: number | null | undefined;
                            title?: string | null | undefined;
                            editable?: boolean | null | undefined;
                            color: {
                              __typename?: "ColorInput";
                              type: InputType;
                              value?: string | null | undefined;
                            };
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
              };
            }
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
        syndication?:
          | {
              __typename?: "PublicSyndication";
              id: string;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              description?: string | null | undefined;
              maxAllocation: any;
              minAllocation: any;
              monthlyFeeUsd?: any | null | undefined;
              allocationFeePerc?: any | null | undefined;
              successFeePerc?: any | null | undefined;
              maxSubscriberAllocation?: any | null | undefined;
              availableAllocation?: any | null | undefined;
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
        owner?:
          | {
              __typename?: "PublicUser";
              companyName?: string | null | undefined;
              nickname?: string | null | undefined;
              firstName: string;
              lastName: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionTradesQueryVariables = Exact<{
  executionId: Scalars["ID"];
  num: Scalars["Int"];
  sortDirection: SortDirection;
  from?: InputMaybe<Scalars["ID"]>;
}>;

export type GetExecutionTradesQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              numberOfTrades?: number | null | undefined;
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
        trades?:
          | Array<
              | {
                  __typename?: "Trade";
                  id: string;
                  absoluteProfit: any;
                  profitPercentage: any;
                  isClosed: boolean;
                  isProfitable: boolean;
                  fundingFee?: any | null | undefined;
                  orders: Array<
                    | {
                        __typename?: "Order";
                        externalId?: string | null | undefined;
                        source: OrderSource;
                        status: OrderStatus;
                        side: OrderSide;
                        placedTime: string;
                        placedAmount: any;
                        filledTime?: string | null | undefined;
                        commissionPaid: any;
                        limitPrice?: any | null | undefined;
                        filledAmount: any;
                        filledPrice: any;
                        filledValue: any;
                        effectiveAmount: any;
                        effectivePrice: any;
                        effectiveValue: any;
                      }
                    | null
                    | undefined
                  >;
                  currencyPairDetails?:
                    | {
                        __typename?: "CurrencyPairDetails";
                        id: string;
                        exchange: Exchange;
                        pair: CurrencyPair;
                        base: Currency;
                        quote: Currency;
                        settleCurrency: Currency;
                        positionCurrency: Currency;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionMeasurementsQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type GetExecutionMeasurementsQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        favorite?: boolean | null | undefined;
        id: string;
        measurements?:
          | {
              __typename?: "Measurements";
              numberOfTrades?: number | null | undefined;
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionLogQueryVariables = Exact<{
  executionId: Scalars["ID"];
  num: Scalars["Int"];
  sortDirection: SortDirection;
  from?: InputMaybe<Scalars["ID"]>;
}>;

export type GetExecutionLogQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        logs?:
          | Array<
              | {
                  __typename?: "ExecutionLog";
                  id: string;
                  type?: string | null | undefined;
                  time?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSharedExecutionTradesQueryVariables = Exact<{
  shareToken: Scalars["ID"];
  num: Scalars["Int"];
  sortDirection: SortDirection;
  from?: InputMaybe<Scalars["ID"]>;
}>;

export type GetSharedExecutionTradesQuery = {
  __typename?: "RootQuery";
  sharedExecution?:
    | {
        __typename?: "SharedExecution";
        shareToken: string;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              numberOfTrades?: number | null | undefined;
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
        trades?:
          | Array<
              | {
                  __typename?: "Trade";
                  id: string;
                  absoluteProfit: any;
                  profitPercentage: any;
                  isClosed: boolean;
                  isProfitable: boolean;
                  fundingFee?: any | null | undefined;
                  orders: Array<
                    | {
                        __typename?: "Order";
                        externalId?: string | null | undefined;
                        source: OrderSource;
                        status: OrderStatus;
                        side: OrderSide;
                        placedTime: string;
                        placedAmount: any;
                        filledTime?: string | null | undefined;
                        commissionPaid: any;
                        limitPrice?: any | null | undefined;
                        filledAmount: any;
                        filledPrice: any;
                        filledValue: any;
                        effectiveAmount: any;
                        effectivePrice: any;
                        effectiveValue: any;
                      }
                    | null
                    | undefined
                  >;
                  currencyPairDetails?:
                    | {
                        __typename?: "CurrencyPairDetails";
                        id: string;
                        exchange: Exchange;
                        pair: CurrencyPair;
                        base: Currency;
                        quote: Currency;
                        settleCurrency: Currency;
                        positionCurrency: Currency;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TradesSubscriptionSubscriptionVariables = Exact<{
  executionId: Scalars["ID"];
  sortDirection: SortDirection;
}>;

export type TradesSubscriptionSubscription = {
  __typename?: "Subscription";
  trade?:
    | {
        __typename?: "Trade";
        id: string;
        absoluteProfit: any;
        profitPercentage: any;
        isClosed: boolean;
        isProfitable: boolean;
        fundingFee?: any | null | undefined;
        orders: Array<
          | {
              __typename?: "Order";
              externalId?: string | null | undefined;
              source: OrderSource;
              status: OrderStatus;
              side: OrderSide;
              placedTime: string;
              placedAmount: any;
              filledTime?: string | null | undefined;
              commissionPaid: any;
              limitPrice?: any | null | undefined;
              filledAmount: any;
              filledPrice: any;
              filledValue: any;
              effectiveAmount: any;
              effectivePrice: any;
              effectiveValue: any;
            }
          | null
          | undefined
        >;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetStrategiesQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetStrategiesQuery = {
  __typename?: "RootQuery";
  strategies?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            isPack: boolean;
            type: ExecutionType;
            status: ExecutionStatus;
            exchange: Exchange;
            leverage: any;
            leverageShort: any;
            currencyPair: CurrencyPair;
            syndicationId?: string | null | undefined;
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
            scriptDetails?:
              | { __typename?: "ScriptDetails"; name: string }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetStrategiesInvestPageQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetStrategiesInvestPageQuery = {
  __typename?: "RootQuery";
  strategies?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            isPack: boolean;
            type: ExecutionType;
            status: ExecutionStatus;
            exchange: Exchange;
            leverage: any;
            leverageShort: any;
            currencyPair: CurrencyPair;
            syndicationId?: string | null | undefined;
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
            scriptDetails?:
              | { __typename?: "ScriptDetails"; name: string }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetStrategiesCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetStrategiesCountQuery = {
  __typename?: "RootQuery";
  strategiesCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type GetSharedExecutionQueryVariables = Exact<{
  shareToken: Scalars["ID"];
}>;

export type GetSharedExecutionQuery = {
  __typename?: "RootQuery";
  sharedExecution?:
    | {
        __typename?: "SharedExecution";
        scriptName?: string | null | undefined;
        shareToken: string;
        name?: string | null | undefined;
        syndicationName?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        candleSize: string;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              absoluteProfit?: any | null | undefined;
              avgBarsInTrade?: number | null | undefined;
              numberOfTrades?: number | null | undefined;
              maxDrawdown?: any | null | undefined;
              percentProfitableTrades?: any | null | undefined;
              profitability?: any | null | undefined;
              profitFactor?: any | null | undefined;
              buyHoldRatio?: any | null | undefined;
              buyHoldReturn?: any | null | undefined;
              avgTradePrice?: any | null | undefined;
              avgPositionPrice?: any | null | undefined;
              lastTick?: string | null | undefined;
              positionProfitLoss?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
              positionAmount?: any | null | undefined;
              balance?: any | null | undefined;
              riskScore?: any | null | undefined;
              sharpeRatio?: any | null | undefined;
              sortinoRatio?: any | null | undefined;
              totalRealizedGain?: any | null | undefined;
              totalRealizedLoss?: any | null | undefined;
              consistencyScore?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSimulationsQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetSimulationsQuery = {
  __typename?: "RootQuery";
  simulations?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            isPack: boolean;
            type: ExecutionType;
            exchange: Exchange;
            currencyPair: CurrencyPair;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetSimulationsCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetSimulationsCountQuery = {
  __typename?: "RootQuery";
  simulationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type GetMultivariantsQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<MultivariantSort>;
  filters?: InputMaybe<
    Array<InputMaybe<MultivariantFilter>> | InputMaybe<MultivariantFilter>
  >;
}>;

export type GetMultivariantsQuery = {
  __typename?: "RootQuery";
  multivariants?:
    | Array<
        | {
            __typename?: "Multivariant";
            id: string;
            name?: string | null | undefined;
            exchange: Exchange;
            currencyPair: CurrencyPair;
            candleSize?: string | null | undefined;
            candleType?: CandleType | null | undefined;
            maxBuy?: string | null | undefined;
            minProfit?: string | null | undefined;
            stopLossPercentage?: string | null | undefined;
            stopLossTrailing?: boolean | null | undefined;
            takeProfitPercentage?: string | null | undefined;
            takeProfitTrailingPercentage?: string | null | undefined;
            algorithmSettings: string;
            status?: ExecutionStatus | null | undefined;
            totalCombinationsCount: number;
            canResume?: boolean | null | undefined;
            createdAt?: string | null | undefined;
            favorite?: boolean | null | undefined;
            scriptVersionSequence?: number | null | undefined;
            allocation?: any | null | undefined;
            performanceMode: ExecutionPerformanceMode;
            leverage?: string | null | undefined;
            leverageShort?: string | null | undefined;
            lookbackDays: number;
            buyActionTime?: string | null | undefined;
            sellActionTime?: string | null | undefined;
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
            scriptDetails: {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              language: ScriptLanguage;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    readableName?: string | null | undefined;
                    fieldName: string;
                  }
                | null
                | undefined
              >;
            };
            progress?:
              | Array<
                  | {
                      __typename?: "ExecutionStatusCount";
                      status: ExecutionStatus;
                      count: number;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            tags?:
              | Array<
                  | {
                      __typename?: "Tag";
                      id: string;
                      name: string;
                      color: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetMultivariantsCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<MultivariantFilter>> | InputMaybe<MultivariantFilter>
  >;
}>;

export type GetMultivariantsCountQuery = {
  __typename?: "RootQuery";
  multivariantsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type MultivariantSubscriptionSubscriptionVariables = Exact<{
  multivariantIds: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type MultivariantSubscriptionSubscription = {
  __typename?: "Subscription";
  multivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ScriptRevisionSubscriptionSubscriptionVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ScriptRevisionSubscriptionSubscription = {
  __typename?: "Subscription";
  scriptRevision?:
    | {
        __typename?: "ScriptRevision";
        id: string;
        body: string;
        status: ScriptRevisionStatus;
        createdAt: string;
        updatedAt: string;
        parameters: Array<
          | {
              __typename?: "ScriptParameterDetails";
              fieldName: string;
              valueType: ScriptParameterType;
              readableName?: string | null | undefined;
              description?: string | null | undefined;
              defaultValue?: string | null | undefined;
              options?: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined
        >;
        plotDefinition: {
          __typename?: "ScriptPlotDefinition";
          groups: Array<
            | {
                __typename?: "ScriptPlotGroup";
                id: string;
                isOverlay: boolean;
                plots: Array<
                  | {
                      __typename?: "PlotInfo";
                      id: string;
                      title: string;
                      lineWidth: number;
                      lineStyle: PlotStyle;
                      transparency: number;
                      trackPrice: boolean;
                      histBase: number;
                      offset: number;
                      editable: boolean;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                arrows: Array<
                  | {
                      __typename?: "PlotArrowInfo";
                      id: string;
                      title: string;
                      transparency: number;
                      offset: number;
                      minHeight: number;
                      maxHeight: number;
                      editable: boolean;
                      colorUp: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                      colorDown: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                chars: Array<
                  | {
                      __typename?: "PlotCharInfo";
                      id: string;
                      title: string;
                      char: string;
                      location: ShapePosition;
                      transparency: number;
                      offset: number;
                      text?: string | null | undefined;
                      size: ShapeSize;
                      editable: boolean;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                      textColor?:
                        | {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
                shapes: Array<
                  | {
                      __typename?: "PlotShapeInfo";
                      id: string;
                      title: string;
                      style: ShapeStyle;
                      location: ShapePosition;
                      transparency: number;
                      offset: number;
                      text?: string | null | undefined;
                      size: ShapeSize;
                      editable: boolean;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                      textColor?:
                        | {
                            __typename?: "ColorInput";
                            type: InputType;
                            value?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
                fills: Array<
                  | {
                      __typename?: "FillInfo";
                      id: string;
                      inputType: PlotType;
                      transparency: number;
                      title?: string | null | undefined;
                      editable?: boolean | null | undefined;
                      input1: { __typename?: "FillInputId"; str: string };
                      input2: { __typename?: "FillInputId"; str: string };
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                hLines: Array<
                  | {
                      __typename?: "HLineInfo";
                      id: string;
                      title: string;
                      yVal: number;
                      lineWidth: number;
                      lineStyle: HLineStyle;
                      editable?: boolean | null | undefined;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined
                >;
                bgColor?:
                  | {
                      __typename?: "BGColorInfo";
                      transparency: number;
                      offset?: number | null | undefined;
                      title?: string | null | undefined;
                      editable?: boolean | null | undefined;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined;
                barColor?:
                  | {
                      __typename?: "BarColorInfo";
                      offset?: number | null | undefined;
                      title?: string | null | undefined;
                      editable?: boolean | null | undefined;
                      color: {
                        __typename?: "ColorInput";
                        type: InputType;
                        value?: string | null | undefined;
                      };
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
        };
        errors: Array<
          | {
              __typename?: "ScriptError";
              message: string;
              range: {
                __typename?: "Range";
                start: { __typename?: "Position"; line: number; char: number };
                end: { __typename?: "Position"; line: number; char: number };
              };
            }
          | null
          | undefined
        >;
        warnings: Array<
          | {
              __typename?: "ScriptError";
              message: string;
              range: {
                __typename?: "Range";
                start: { __typename?: "Position"; line: number; char: number };
                end: { __typename?: "Position"; line: number; char: number };
              };
            }
          | null
          | undefined
        >;
        infos: Array<
          | {
              __typename?: "ScriptError";
              message: string;
              range: {
                __typename?: "Range";
                start: { __typename?: "Position"; line: number; char: number };
                end: { __typename?: "Position"; line: number; char: number };
              };
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type CandleSubscriptionSubscriptionVariables = Exact<{
  exchange: Exchange;
  pair: CurrencyPair;
  candleSize: Scalars["Resolution"];
}>;

export type CandleSubscriptionSubscription = {
  __typename?: "Subscription";
  candle?:
    | {
        __typename?: "Candle";
        open: any;
        high: any;
        low: any;
        volume: any;
        close: any;
        startTime: string;
        endTime: string;
      }
    | null
    | undefined;
};

export type ExecutionOrderSubscriptionSubscriptionVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type ExecutionOrderSubscriptionSubscription = {
  __typename?: "Subscription";
  order?:
    | {
        __typename?: "Order";
        placedTime: string;
        side: OrderSide;
        placedAmount: any;
      }
    | null
    | undefined;
};

export type ExecutionOrderListSubscriptionSubscriptionVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type ExecutionOrderListSubscriptionSubscription = {
  __typename?: "Subscription";
  order?:
    | {
        __typename?: "Order";
        externalId?: string | null | undefined;
        source: OrderSource;
        status: OrderStatus;
        side: OrderSide;
        placedTime: string;
        placedAmount: any;
        filledTime?: string | null | undefined;
        commissionPaid: any;
        limitPrice?: any | null | undefined;
        filledAmount: any;
        filledPrice: any;
        filledValue: any;
        effectiveAmount: any;
        effectivePrice: any;
        effectiveValue: any;
      }
    | null
    | undefined;
};

export type ExecutionPlotSubscriptionSubscriptionVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type ExecutionPlotSubscriptionSubscription = {
  __typename?: "Subscription";
  plot?:
    | { __typename?: "PlotTick"; plotId: string; time: string; value: string }
    | null
    | undefined;
};

export type ExecutionColorSubscriptionSubscriptionVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type ExecutionColorSubscriptionSubscription = {
  __typename?: "Subscription";
  color?:
    | {
        __typename?: "ColorChange";
        plotId: string;
        time: string;
        value: string;
      }
    | null
    | undefined;
};

export type GetMultivariantDetailsQueryVariables = Exact<{
  multivariantId: Scalars["ID"];
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetMultivariantDetailsQuery = {
  __typename?: "RootQuery";
  multivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        candleSize?: string | null | undefined;
        candleType?: CandleType | null | undefined;
        maxBuy?: string | null | undefined;
        minProfit?: string | null | undefined;
        stopLossPercentage?: string | null | undefined;
        stopLossTrailing?: boolean | null | undefined;
        takeProfitPercentage?: string | null | undefined;
        takeProfitTrailingPercentage?: string | null | undefined;
        algorithmSettings: string;
        status?: ExecutionStatus | null | undefined;
        totalCombinationsCount: number;
        canResume?: boolean | null | undefined;
        createdAt?: string | null | undefined;
        favorite?: boolean | null | undefined;
        scriptVersionSequence?: number | null | undefined;
        allocation?: any | null | undefined;
        performanceMode: ExecutionPerformanceMode;
        leverage?: string | null | undefined;
        leverageShort?: string | null | undefined;
        lookbackDays: number;
        buyActionTime?: string | null | undefined;
        sellActionTime?: string | null | undefined;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        executionsCount?:
          | { __typename?: "ResultSize"; count?: number | null | undefined }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails: {
          __typename?: "ScriptDetails";
          id: string;
          scriptId: string;
          revisionId: string;
          name: string;
          language: ScriptLanguage;
          version?: number | null | undefined;
          isLatest?: boolean | null | undefined;
          createdAt?: string | null | undefined;
          parameters: Array<
            | {
                __typename?: "ScriptParameterDetails";
                readableName?: string | null | undefined;
                fieldName: string;
              }
            | null
            | undefined
          >;
        };
        progress?:
          | Array<
              | {
                  __typename?: "ExecutionStatusCount";
                  status: ExecutionStatus;
                  count: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        tags?:
          | Array<
              | { __typename?: "Tag"; id: string; name: string; color: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMultivariantComposerDataQueryVariables = Exact<{
  multivariantId: Scalars["ID"];
}>;

export type GetMultivariantComposerDataQuery = {
  __typename?: "RootQuery";
  multivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        multipleValueFields?:
          | Array<string | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMultivariantExecutionsQueryVariables = Exact<{
  multivariantId: Scalars["ID"];
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetMultivariantExecutionsQuery = {
  __typename?: "RootQuery";
  multivariant?:
    | {
        __typename?: "Multivariant";
        id: string;
        executions?:
          | Array<
              | {
                  __typename?: "Execution";
                  id: string;
                  name?: string | null | undefined;
                  type: ExecutionType;
                  exchange: Exchange;
                  currencyPair: CurrencyPair;
                  candleSize: string;
                  candleType?: CandleType | null | undefined;
                  status: ExecutionStatus;
                  favorite?: boolean | null | undefined;
                  error?: string | null | undefined;
                  runtimeSeconds?: number | null | undefined;
                  shareToken?: string | null | undefined;
                  algorithmSettings?: string | null | undefined;
                  createdAt?: string | null | undefined;
                  leverage: any;
                  leverageShort: any;
                  multivariantIterationId?: number | null | undefined;
                  scriptVersionSequence?: number | null | undefined;
                  runNumber?: string | null | undefined;
                  allocation: any;
                  rangeStart?: string | null | undefined;
                  rangeEnd?: string | null | undefined;
                  isPack: boolean;
                  stage?: ExecutionStage | null | undefined;
                  syndicationId?: string | null | undefined;
                  currencyPairDetails?:
                    | {
                        __typename?: "CurrencyPairDetails";
                        id: string;
                        exchange: Exchange;
                        pair: CurrencyPair;
                        base: Currency;
                        quote: Currency;
                        settleCurrency: Currency;
                        positionCurrency: Currency;
                      }
                    | null
                    | undefined;
                  measurements?:
                    | {
                        __typename?: "Measurements";
                        absoluteProfit?: any | null | undefined;
                        avgBarsInTrade?: number | null | undefined;
                        numberOfTrades?: number | null | undefined;
                        maxDrawdown?: any | null | undefined;
                        percentProfitableTrades?: any | null | undefined;
                        profitability?: any | null | undefined;
                        profitFactor?: any | null | undefined;
                        buyHoldRatio?: any | null | undefined;
                        buyHoldReturn?: any | null | undefined;
                        avgTradePrice?: any | null | undefined;
                        avgPositionPrice?: any | null | undefined;
                        lastTick?: string | null | undefined;
                        positionProfitLoss?: any | null | undefined;
                        avgMonthlyProfit?: any | null | undefined;
                        avgWinMonth?: any | null | undefined;
                        avgLoseMonth?: any | null | undefined;
                        percProfitableMonths?: any | null | undefined;
                        positionAbsoluteProfit?: any | null | undefined;
                        positionAmount?: any | null | undefined;
                        balance?: any | null | undefined;
                        riskScore?: any | null | undefined;
                        sharpeRatio?: any | null | undefined;
                        sortinoRatio?: any | null | undefined;
                        totalRealizedGain?: any | null | undefined;
                        totalRealizedLoss?: any | null | undefined;
                        consistencyScore?: number | null | undefined;
                      }
                    | null
                    | undefined;
                  scriptDetails?:
                    | {
                        __typename?: "ScriptDetails";
                        id: string;
                        scriptId: string;
                        revisionId: string;
                        name: string;
                        language: ScriptLanguage;
                        version?: number | null | undefined;
                        isLatest?: boolean | null | undefined;
                        createdAt?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  tags?:
                    | Array<
                        | {
                            __typename?: "Tag";
                            id: string;
                            name: string;
                            color: string;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  syndicationSettings?:
                    | {
                        __typename?: "SyndicationSettings";
                        accessControl?:
                          | SyndicationAccessControl
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        executionsCount?:
          | { __typename?: "ResultSize"; count?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateStrategyFormQueryVariables = Exact<{ [key: string]: never }>;

export type CreateStrategyFormQuery = {
  __typename?: "RootQuery";
  credentials?:
    | Array<
        | {
            __typename?: "Credential";
            id: string;
            name: string;
            exchange: Exchange;
            maskedApiKey: string;
            status: CredentialStatus;
            assets: {
              __typename?: "AssetDetails";
              free: Array<
                | { __typename?: "Asset"; currency: string; amount: any }
                | null
                | undefined
              >;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
  scripts?:
    | Array<
        | {
            __typename?: "Script";
            id: string;
            name: string;
            language: ScriptLanguage;
            latestValid?:
              | {
                  __typename?: "ScriptRevision";
                  id: string;
                  status: ScriptRevisionStatus;
                  parameters: Array<
                    | {
                        __typename?: "ScriptParameterDetails";
                        fieldName: string;
                        valueType: ScriptParameterType;
                        readableName?: string | null | undefined;
                        description?: string | null | undefined;
                        defaultValue?: string | null | undefined;
                        options?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  plotDefinition: {
                    __typename?: "ScriptPlotDefinition";
                    groups: Array<
                      | {
                          __typename?: "ScriptPlotGroup";
                          id: string;
                          isOverlay: boolean;
                          plots: Array<
                            | {
                                __typename?: "PlotInfo";
                                id: string;
                                title: string;
                                lineWidth: number;
                                lineStyle: PlotStyle;
                                transparency: number;
                                trackPrice: boolean;
                                histBase: number;
                                offset: number;
                                editable: boolean;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          arrows: Array<
                            | {
                                __typename?: "PlotArrowInfo";
                                id: string;
                                title: string;
                                transparency: number;
                                offset: number;
                                minHeight: number;
                                maxHeight: number;
                                editable: boolean;
                                colorUp: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                                colorDown: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          chars: Array<
                            | {
                                __typename?: "PlotCharInfo";
                                id: string;
                                title: string;
                                char: string;
                                location: ShapePosition;
                                transparency: number;
                                offset: number;
                                text?: string | null | undefined;
                                size: ShapeSize;
                                editable: boolean;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                                textColor?:
                                  | {
                                      __typename?: "ColorInput";
                                      type: InputType;
                                      value?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >;
                          shapes: Array<
                            | {
                                __typename?: "PlotShapeInfo";
                                id: string;
                                title: string;
                                style: ShapeStyle;
                                location: ShapePosition;
                                transparency: number;
                                offset: number;
                                text?: string | null | undefined;
                                size: ShapeSize;
                                editable: boolean;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                                textColor?:
                                  | {
                                      __typename?: "ColorInput";
                                      type: InputType;
                                      value?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >;
                          fills: Array<
                            | {
                                __typename?: "FillInfo";
                                id: string;
                                inputType: PlotType;
                                transparency: number;
                                title?: string | null | undefined;
                                editable?: boolean | null | undefined;
                                input1: {
                                  __typename?: "FillInputId";
                                  str: string;
                                };
                                input2: {
                                  __typename?: "FillInputId";
                                  str: string;
                                };
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          hLines: Array<
                            | {
                                __typename?: "HLineInfo";
                                id: string;
                                title: string;
                                yVal: number;
                                lineWidth: number;
                                lineStyle: HLineStyle;
                                editable?: boolean | null | undefined;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          bgColor?:
                            | {
                                __typename?: "BGColorInfo";
                                transparency: number;
                                offset?: number | null | undefined;
                                title?: string | null | undefined;
                                editable?: boolean | null | undefined;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined;
                          barColor?:
                            | {
                                __typename?: "BarColorInfo";
                                offset?: number | null | undefined;
                                title?: string | null | undefined;
                                editable?: boolean | null | undefined;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >;
                  };
                  errors: Array<
                    | {
                        __typename?: "ScriptError";
                        message: string;
                        range: {
                          __typename?: "Range";
                          start: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                          end: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                        };
                      }
                    | null
                    | undefined
                  >;
                  warnings: Array<
                    | {
                        __typename?: "ScriptError";
                        message: string;
                        range: {
                          __typename?: "Range";
                          start: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                          end: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                        };
                      }
                    | null
                    | undefined
                  >;
                  infos: Array<
                    | {
                        __typename?: "ScriptError";
                        message: string;
                        range: {
                          __typename?: "Range";
                          start: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                          end: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                        };
                      }
                    | null
                    | undefined
                  >;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CreateSimulationFormQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CreateSimulationFormQuery = {
  __typename?: "RootQuery";
  scripts?:
    | Array<
        | {
            __typename?: "Script";
            id: string;
            name: string;
            language: ScriptLanguage;
            latestValid?:
              | {
                  __typename?: "ScriptRevision";
                  id: string;
                  status: ScriptRevisionStatus;
                  parameters: Array<
                    | {
                        __typename?: "ScriptParameterDetails";
                        fieldName: string;
                        valueType: ScriptParameterType;
                        readableName?: string | null | undefined;
                        description?: string | null | undefined;
                        defaultValue?: string | null | undefined;
                        options?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >;
                  plotDefinition: {
                    __typename?: "ScriptPlotDefinition";
                    groups: Array<
                      | {
                          __typename?: "ScriptPlotGroup";
                          id: string;
                          isOverlay: boolean;
                          plots: Array<
                            | {
                                __typename?: "PlotInfo";
                                id: string;
                                title: string;
                                lineWidth: number;
                                lineStyle: PlotStyle;
                                transparency: number;
                                trackPrice: boolean;
                                histBase: number;
                                offset: number;
                                editable: boolean;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          arrows: Array<
                            | {
                                __typename?: "PlotArrowInfo";
                                id: string;
                                title: string;
                                transparency: number;
                                offset: number;
                                minHeight: number;
                                maxHeight: number;
                                editable: boolean;
                                colorUp: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                                colorDown: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          chars: Array<
                            | {
                                __typename?: "PlotCharInfo";
                                id: string;
                                title: string;
                                char: string;
                                location: ShapePosition;
                                transparency: number;
                                offset: number;
                                text?: string | null | undefined;
                                size: ShapeSize;
                                editable: boolean;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                                textColor?:
                                  | {
                                      __typename?: "ColorInput";
                                      type: InputType;
                                      value?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >;
                          shapes: Array<
                            | {
                                __typename?: "PlotShapeInfo";
                                id: string;
                                title: string;
                                style: ShapeStyle;
                                location: ShapePosition;
                                transparency: number;
                                offset: number;
                                text?: string | null | undefined;
                                size: ShapeSize;
                                editable: boolean;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                                textColor?:
                                  | {
                                      __typename?: "ColorInput";
                                      type: InputType;
                                      value?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >;
                          fills: Array<
                            | {
                                __typename?: "FillInfo";
                                id: string;
                                inputType: PlotType;
                                transparency: number;
                                title?: string | null | undefined;
                                editable?: boolean | null | undefined;
                                input1: {
                                  __typename?: "FillInputId";
                                  str: string;
                                };
                                input2: {
                                  __typename?: "FillInputId";
                                  str: string;
                                };
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          hLines: Array<
                            | {
                                __typename?: "HLineInfo";
                                id: string;
                                title: string;
                                yVal: number;
                                lineWidth: number;
                                lineStyle: HLineStyle;
                                editable?: boolean | null | undefined;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined
                          >;
                          bgColor?:
                            | {
                                __typename?: "BGColorInfo";
                                transparency: number;
                                offset?: number | null | undefined;
                                title?: string | null | undefined;
                                editable?: boolean | null | undefined;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined;
                          barColor?:
                            | {
                                __typename?: "BarColorInfo";
                                offset?: number | null | undefined;
                                title?: string | null | undefined;
                                editable?: boolean | null | undefined;
                                color: {
                                  __typename?: "ColorInput";
                                  type: InputType;
                                  value?: string | null | undefined;
                                };
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >;
                  };
                  errors: Array<
                    | {
                        __typename?: "ScriptError";
                        message: string;
                        range: {
                          __typename?: "Range";
                          start: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                          end: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                        };
                      }
                    | null
                    | undefined
                  >;
                  warnings: Array<
                    | {
                        __typename?: "ScriptError";
                        message: string;
                        range: {
                          __typename?: "Range";
                          start: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                          end: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                        };
                      }
                    | null
                    | undefined
                  >;
                  infos: Array<
                    | {
                        __typename?: "ScriptError";
                        message: string;
                        range: {
                          __typename?: "Range";
                          start: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                          end: {
                            __typename?: "Position";
                            line: number;
                            char: number;
                          };
                        };
                      }
                    | null
                    | undefined
                  >;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetMyTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyTagsQuery = {
  __typename?: "RootQuery";
  tags?:
    | Array<
        | { __typename?: "Tag"; id: string; name: string; color: string }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetExecutionInfoForPacksQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type GetExecutionInfoForPacksQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        multiCoinCurrency?: Currency | null | undefined;
        startedAt?: string | null | undefined;
        endedAt?: string | null | undefined;
        rangeStart?: string | null | undefined;
        rangeEnd?: string | null | undefined;
        runNumber?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              name: string;
              version?: number | null | undefined;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              profitability?: any | null | undefined;
              maxDrawdown?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              avgWinMonth?: any | null | undefined;
              avgLoseMonth?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPackExecutionsQueryVariables = Exact<{
  packId: Scalars["ID"];
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetPackExecutionsQuery = {
  __typename?: "RootQuery";
  packExecutions?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            type: ExecutionType;
            exchange: Exchange;
            currencyPair: CurrencyPair;
            packPercentage?: any | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetExecutionsQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  stage?: InputMaybe<ExecutionStage>;
}>;

export type GetExecutionsQuery = {
  __typename?: "RootQuery";
  executions?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            isPack: boolean;
            type: ExecutionType;
            status: ExecutionStatus;
            exchange: Exchange;
            leverage: any;
            leverageShort: any;
            currencyPair: CurrencyPair;
            syndicationId?: string | null | undefined;
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetExecutionsCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  stage?: InputMaybe<ExecutionStage>;
}>;

export type GetExecutionsCountQuery = {
  __typename?: "RootQuery";
  executionsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type GetSyndicationsQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetSyndicationsQuery = {
  __typename?: "RootQuery";
  syndications?:
    | Array<
        | {
            __typename?: "Execution";
            id: string;
            isPack: boolean;
            type: ExecutionType;
            exchange: Exchange;
            currencyPair: CurrencyPair;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetSyndicationsCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetSyndicationsCountQuery = {
  __typename?: "RootQuery";
  syndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type GetGettingStartedQueryVariables = Exact<{ [key: string]: never }>;

export type GetGettingStartedQuery = {
  __typename?: "RootQuery";
  gettingStarted?:
    | {
        __typename?: "GettingStarted";
        openedEditor?: boolean | null | undefined;
        createdExperiment?: boolean | null | undefined;
        createdMultivariant?: boolean | null | undefined;
        promotedExperiment?: boolean | null | undefined;
        promotedCandidate?: boolean | null | undefined;
        addedCredential?: boolean | null | undefined;
        launchedLive?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type GetPublicSyndicationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPublicSyndicationQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair?: CurrencyPair | null | undefined;
        multiCoinCurrency?: Currency | null | undefined;
        multiCoinPackExecutions?:
          | Array<
              | {
                  __typename?: "MultiCoinPackCurrencyPercentage";
                  currency: Currency;
                  percentage: any;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              id: string;
              maxAllocation: any;
              minAllocation: any;
              description?: string | null | undefined;
              monthlyFeeUsd?: any | null | undefined;
              allocationFeePerc?: any | null | undefined;
              successFeePerc?: any | null | undefined;
              maxSubscriberAllocation?: any | null | undefined;
              availableAllocation?: any | null | undefined;
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "SyndicationMeasurements";
              subscriptionAllocation?: any | null | undefined;
            }
          | null
          | undefined;
        owner: {
          __typename?: "PublicUser";
          companyName?: string | null | undefined;
          nickname?: string | null | undefined;
          firstName: string;
          lastName: string;
        };
        leverageInfo?:
          | {
              __typename?: "LeverageInfo";
              maxLeverage?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PresignedUploadUrlQueryVariables = Exact<{
  contentType?: InputMaybe<Scalars["String"]>;
  contentLength?: InputMaybe<Scalars["String"]>;
}>;

export type PresignedUploadUrlQuery = {
  __typename?: "RootQuery";
  presignedUploadUrl?:
    | { __typename?: "PresignedUrl"; url: string; key: string }
    | null
    | undefined;
};

export type GetPublicSyndicationDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPublicSyndicationDetailsQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair?: CurrencyPair | null | undefined;
        multiCoinCurrency?: Currency | null | undefined;
        startedAt?: string | null | undefined;
        performance?: any | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        multiCoinPackExecutions?:
          | Array<
              | {
                  __typename?: "MultiCoinPackCurrencyPercentage";
                  currency: Currency;
                  percentage: any;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              id: string;
              maxAllocation: any;
              maxSubscriberAllocation?: any | null | undefined;
              availableAllocation?: any | null | undefined;
              minAllocation: any;
              description?: string | null | undefined;
              monthlyFeeUsd?: any | null | undefined;
              allocationFeePerc?: any | null | undefined;
              successFeePerc?: any | null | undefined;
              productId?: string | null | undefined;
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "SyndicationMeasurements";
              maxDrawdown?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              riskScore?: any | null | undefined;
              subscriptionCount?: number | null | undefined;
              subscriptionAllocation?: any | null | undefined;
            }
          | null
          | undefined;
        backtestMeasurements?:
          | {
              __typename?: "SyndicationMeasurements";
              maxDrawdown?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              riskScore?: any | null | undefined;
            }
          | null
          | undefined;
        backtestAnalysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        leverageInfo?:
          | {
              __typename?: "LeverageInfo";
              maxLeverage?: any | null | undefined;
              minLeverage?: any | null | undefined;
              avgLeverage?: any | null | undefined;
            }
          | null
          | undefined;
        subscription?:
          | {
              __typename?: "BillingSubscription";
              id: string;
              statusDetails?:
                | {
                    __typename?: "BillingSubscriptionStatusDetails";
                    mayUnsubscribe: boolean;
                    mayRevokeUnsubscription: boolean;
                    mayUseEntitlements: boolean;
                    hasPaymentPending: boolean;
                    hasCreationFailed: boolean;
                    hasLatestRenewalFailed: boolean;
                    willBeCancelledAtEndOfCycle: boolean;
                    userHasUnsubscribed: boolean;
                    creationFailure?:
                      | {
                          __typename?: "SubscriptionCreationFailure";
                          reason: SubscriptionCreationFailureReason;
                        }
                      | null
                      | undefined;
                    latestRenewalFailure?:
                      | {
                          __typename?: "SubscriptionRenewalFailure";
                          reason: SubscriptionRenewalFailureReason;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        owner: {
          __typename?: "PublicUser";
          companyName?: string | null | undefined;
          nickname?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type GetPublicSyndicationAnalysisQueryVariables = Exact<{
  id: Scalars["ID"];
  rangeDays?: InputMaybe<Scalars["Int"]>;
  hasRange: Scalars["Boolean"];
}>;

export type GetPublicSyndicationAnalysisQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        analysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        analysisWithRange?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPublicSyndicationMetricsQueryVariables = Exact<{
  id: Scalars["ID"];
  rangeDays?: InputMaybe<Scalars["Int"]>;
}>;

export type GetPublicSyndicationMetricsQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        performance?: any | null | undefined;
        analysisWithRange?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPublicSyndicationUpdatesQueryVariables = Exact<{
  id: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetPublicSyndicationUpdatesQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        pinnedUpdate?:
          | {
              __typename?: "SyndicationUpdate";
              id: string;
              syndicationId?: string | null | undefined;
              createdAt?: string | null | undefined;
              updatedAt?: string | null | undefined;
              placement?: SyndicationUpdatePlacement | null | undefined;
              audience?: SyndicationUpdateAudience | null | undefined;
              update?: string | null | undefined;
            }
          | null
          | undefined;
        updates?:
          | {
              __typename?: "SyndicationUpdateConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "SyndicationUpdateConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "SyndicationUpdate";
                              id: string;
                              syndicationId?: string | null | undefined;
                              createdAt?: string | null | undefined;
                              updatedAt?: string | null | undefined;
                              placement?:
                                | SyndicationUpdatePlacement
                                | null
                                | undefined;
                              audience?:
                                | SyndicationUpdateAudience
                                | null
                                | undefined;
                              update?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPrivateSyndicationQueryVariables = Exact<{
  shareToken: Scalars["ID"];
}>;

export type GetPrivateSyndicationQuery = {
  __typename?: "RootQuery";
  privateSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
        exchange: Exchange;
        currencyPair?: CurrencyPair | null | undefined;
        multiCoinCurrency?: Currency | null | undefined;
        startedAt?: string | null | undefined;
        performance?: any | null | undefined;
        owner: {
          __typename?: "PublicUser";
          id: string;
          firstName: string;
          lastName: string;
          tradingSince: string;
          companyName?: string | null | undefined;
          nickname?: string | null | undefined;
          country?: string | null | undefined;
          description?: string | null | undefined;
          twitterUrl?: string | null | undefined;
          discordUrl?: string | null | undefined;
          telegramUrl?: string | null | undefined;
          mediumUrl?: string | null | undefined;
          linkedinUrl?: string | null | undefined;
          personalBlogUrl?: string | null | undefined;
          personalWebsiteUrl?: string | null | undefined;
          exchanges?: Array<Exchange | null | undefined> | null | undefined;
          profilePhotoUrl?: string | null | undefined;
        };
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        multiCoinPackExecutions?:
          | Array<
              | {
                  __typename?: "MultiCoinPackCurrencyPercentage";
                  currency: Currency;
                  percentage: any;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationSettings?:
          | {
              __typename?: "SyndicationSettings";
              id: string;
              maxAllocation: any;
              maxSubscriberAllocation?: any | null | undefined;
              availableAllocation?: any | null | undefined;
              minAllocation: any;
              description?: string | null | undefined;
              monthlyFeeUsd?: any | null | undefined;
              allocationFeePerc?: any | null | undefined;
              successFeePerc?: any | null | undefined;
              productId?: string | null | undefined;
              accessControl?: SyndicationAccessControl | null | undefined;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "SyndicationMeasurements";
              maxDrawdown?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              riskScore?: any | null | undefined;
              subscriptionCount?: number | null | undefined;
              subscriptionAllocation?: any | null | undefined;
            }
          | null
          | undefined;
        backtestMeasurements?:
          | {
              __typename?: "SyndicationMeasurements";
              maxDrawdown?: any | null | undefined;
              avgMonthlyProfit?: any | null | undefined;
              percProfitableMonths?: any | null | undefined;
              riskScore?: any | null | undefined;
            }
          | null
          | undefined;
        backtestAnalysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        leverageInfo?:
          | {
              __typename?: "LeverageInfo";
              maxLeverage?: any | null | undefined;
              minLeverage?: any | null | undefined;
              avgLeverage?: any | null | undefined;
            }
          | null
          | undefined;
        subscription?:
          | {
              __typename?: "BillingSubscription";
              id: string;
              statusDetails?:
                | {
                    __typename?: "BillingSubscriptionStatusDetails";
                    mayUnsubscribe: boolean;
                    mayRevokeUnsubscription: boolean;
                    mayUseEntitlements: boolean;
                    hasPaymentPending: boolean;
                    hasCreationFailed: boolean;
                    hasLatestRenewalFailed: boolean;
                    willBeCancelledAtEndOfCycle: boolean;
                    userHasUnsubscribed: boolean;
                    creationFailure?:
                      | {
                          __typename?: "SubscriptionCreationFailure";
                          reason: SubscriptionCreationFailureReason;
                        }
                      | null
                      | undefined;
                    latestRenewalFailure?:
                      | {
                          __typename?: "SubscriptionRenewalFailure";
                          reason: SubscriptionRenewalFailureReason;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPrivateSyndicationAnalysisQueryVariables = Exact<{
  shareToken: Scalars["ID"];
  rangeDays?: InputMaybe<Scalars["Int"]>;
  hasRange: Scalars["Boolean"];
}>;

export type GetPrivateSyndicationAnalysisQuery = {
  __typename?: "RootQuery";
  privateSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        analysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        analysisWithRange?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPublicUserQueryVariables = Exact<{
  nickname: Scalars["String"];
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetPublicUserQuery = {
  __typename?: "RootQuery";
  publicUser?:
    | {
        __typename?: "PublicUser";
        id: string;
        firstName: string;
        lastName: string;
        tradingSince: string;
        companyName?: string | null | undefined;
        nickname?: string | null | undefined;
        country?: string | null | undefined;
        description?: string | null | undefined;
        twitterUrl?: string | null | undefined;
        discordUrl?: string | null | undefined;
        telegramUrl?: string | null | undefined;
        mediumUrl?: string | null | undefined;
        linkedinUrl?: string | null | undefined;
        personalBlogUrl?: string | null | undefined;
        personalWebsiteUrl?: string | null | undefined;
        exchanges?: Array<Exchange | null | undefined> | null | undefined;
        profilePhotoUrl?: string | null | undefined;
        scriptsCount?: number | null | undefined;
        syndicationsCount?:
          | { __typename?: "ResultSize"; count?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPublicUserSyndicationListQueryVariables = Exact<{
  userId: Scalars["String"];
  num: Scalars["Int"];
  from?: InputMaybe<Scalars["ID"]>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  sort?: InputMaybe<ExecutionSort>;
}>;

export type GetPublicUserSyndicationListQuery = {
  __typename?: "RootQuery";
  publicProfile?:
    | {
        __typename?: "PublicUser";
        id: string;
        syndications?:
          | Array<
              | {
                  __typename?: "PublicSyndication";
                  id: string;
                  name?: string | null | undefined;
                  exchange: Exchange;
                  currencyPair?: CurrencyPair | null | undefined;
                  multiCoinCurrency?: Currency | null | undefined;
                  startedAt?: string | null | undefined;
                  analysis?:
                    | {
                        __typename?: "Analysis";
                        realizedProfitPerc?: any | null | undefined;
                      }
                    | null
                    | undefined;
                  currencyPairDetails?:
                    | {
                        __typename?: "CurrencyPairDetails";
                        id: string;
                        exchange: Exchange;
                        pair: CurrencyPair;
                        base: Currency;
                        quote: Currency;
                        settleCurrency: Currency;
                        positionCurrency: Currency;
                      }
                    | null
                    | undefined;
                  multiCoinPackExecutions?:
                    | Array<
                        | {
                            __typename?: "MultiCoinPackCurrencyPercentage";
                            currency: Currency;
                            percentage: any;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  syndicationSettings?:
                    | {
                        __typename?: "SyndicationSettings";
                        id: string;
                        maxAllocation: any;
                        minAllocation: any;
                        description?: string | null | undefined;
                        monthlyFeeUsd?: any | null | undefined;
                        allocationFeePerc?: any | null | undefined;
                        successFeePerc?: any | null | undefined;
                        accessControl?:
                          | SyndicationAccessControl
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  measurements?:
                    | {
                        __typename?: "SyndicationMeasurements";
                        maxDrawdown?: any | null | undefined;
                        avgMonthlyProfit?: any | null | undefined;
                        percProfitableMonths?: any | null | undefined;
                        riskScore?: any | null | undefined;
                        subscriptionCount?: number | null | undefined;
                        subscriptionAllocation?: any | null | undefined;
                        profitability?: any | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        syndicationsCount?:
          | { __typename?: "ResultSize"; count?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPublicUserScriptListQueryVariables = Exact<{
  userId: Scalars["String"];
  num: Scalars["Int"];
  from?: InputMaybe<Scalars["ID"]>;
}>;

export type GetPublicUserScriptListQuery = {
  __typename?: "RootQuery";
  publicProfile?:
    | {
        __typename?: "PublicUser";
        id: string;
        scriptsCount?: number | null | undefined;
        scripts?:
          | Array<
              | {
                  __typename?: "Script";
                  id: string;
                  name: string;
                  language: ScriptLanguage;
                  favorite?: boolean | null | undefined;
                  notes?: string | null | undefined;
                  isPublic: boolean;
                  latestVersionNumber?: number | null | undefined;
                  timesCopied?: number | null | undefined;
                  latest: {
                    __typename?: "ScriptRevision";
                    id: string;
                    createdAt: string;
                    updatedAt: string;
                  };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPublicSyndicationsQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>> | InputMaybe<Exchange>>;
}>;

export type GetPublicSyndicationsQuery = {
  __typename?: "RootQuery";
  publicSyndications?:
    | Array<
        | {
            __typename?: "PublicSyndication";
            id: string;
            name?: string | null | undefined;
            owner: {
              __typename?: "PublicUser";
              nickname?: string | null | undefined;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetPublicSyndicationsListQueryVariables = Exact<{
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>> | InputMaybe<Exchange>>;
  currencyPairs?: InputMaybe<
    Array<InputMaybe<CurrencyPair>> | InputMaybe<CurrencyPair>
  >;
  fieldComparisons?: InputMaybe<
    | Array<InputMaybe<ExecutionFieldComparison>>
    | InputMaybe<ExecutionFieldComparison>
  >;
}>;

export type GetPublicSyndicationsListQuery = {
  __typename?: "RootQuery";
  publicSyndications?:
    | Array<
        | {
            __typename?: "PublicSyndication";
            id: string;
            name?: string | null | undefined;
            exchange: Exchange;
            currencyPair?: CurrencyPair | null | undefined;
            multiCoinCurrency?: Currency | null | undefined;
            startedAt?: string | null | undefined;
            owner: {
              __typename?: "PublicUser";
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              nickname?: string | null | undefined;
            };
            currencyPairDetails?:
              | {
                  __typename?: "CurrencyPairDetails";
                  id: string;
                  exchange: Exchange;
                  pair: CurrencyPair;
                  base: Currency;
                  quote: Currency;
                  settleCurrency: Currency;
                  positionCurrency: Currency;
                }
              | null
              | undefined;
            multiCoinPackExecutions?:
              | Array<
                  | {
                      __typename?: "MultiCoinPackCurrencyPercentage";
                      currency: Currency;
                      percentage: any;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            syndicationSettings?:
              | {
                  __typename?: "SyndicationSettings";
                  id: string;
                  maxAllocation: any;
                  minAllocation: any;
                  description?: string | null | undefined;
                  monthlyFeeUsd?: any | null | undefined;
                  allocationFeePerc?: any | null | undefined;
                  successFeePerc?: any | null | undefined;
                  accessControl?: SyndicationAccessControl | null | undefined;
                }
              | null
              | undefined;
            measurements?:
              | {
                  __typename?: "SyndicationMeasurements";
                  maxDrawdown?: any | null | undefined;
                  avgMonthlyProfit?: any | null | undefined;
                  percProfitableMonths?: any | null | undefined;
                  riskScore?: any | null | undefined;
                  subscriptionCount?: number | null | undefined;
                  subscriptionAllocation?: any | null | undefined;
                  profitability?: any | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetPublicSyndicationsCountQueryVariables = Exact<{
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>> | InputMaybe<Exchange>>;
  currencyPairs?: InputMaybe<
    Array<InputMaybe<CurrencyPair>> | InputMaybe<CurrencyPair>
  >;
  fieldComparisons?: InputMaybe<
    | Array<InputMaybe<ExecutionFieldComparison>>
    | InputMaybe<ExecutionFieldComparison>
  >;
}>;

export type GetPublicSyndicationsCountQuery = {
  __typename?: "RootQuery";
  publicSyndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};

export type GetPublicUsersQueryVariables = Exact<{
  query: PageQuery;
  filters?: InputMaybe<Array<InputMaybe<UserFilter>> | InputMaybe<UserFilter>>;
}>;

export type GetPublicUsersQuery = {
  __typename?: "RootQuery";
  publicProfiles?:
    | Array<
        | {
            __typename?: "PublicUser";
            id: string;
            nickname?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetPublicUsersDataQueryVariables = Exact<{
  query: PageQuery;
  filters?: InputMaybe<Array<InputMaybe<UserFilter>> | InputMaybe<UserFilter>>;
  executionFilters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>> | InputMaybe<Exchange>>;
}>;

export type GetPublicUsersDataQuery = {
  __typename?: "RootQuery";
  publicProfiles?:
    | Array<
        | {
            __typename?: "PublicUser";
            id: string;
            nickname?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetPublicUsersCountQueryVariables = Exact<{
  filters?: InputMaybe<Array<InputMaybe<UserFilter>> | InputMaybe<UserFilter>>;
  executionFilters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
  exchanges?: InputMaybe<Array<InputMaybe<Exchange>> | InputMaybe<Exchange>>;
}>;

export type GetPublicUsersCountQuery = {
  __typename?: "RootQuery";
  publicProfilesCount?: number | null | undefined;
};

export type GetPublicUserDetailsQueryVariables = Exact<{
  nickname: Scalars["String"];
}>;

export type GetPublicUserDetailsQuery = {
  __typename?: "RootQuery";
  publicUser?:
    | {
        __typename?: "PublicUser";
        id: string;
        firstName: string;
        lastName: string;
        tradingSince: string;
        companyName?: string | null | undefined;
        nickname?: string | null | undefined;
        description?: string | null | undefined;
        exchanges?: Array<Exchange | null | undefined> | null | undefined;
        profilePhotoUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetPublicUserSyndicationDetailsQueryVariables = Exact<{
  nickname: Scalars["String"];
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetPublicUserSyndicationDetailsQuery = {
  __typename?: "RootQuery";
  publicUser?:
    | {
        __typename?: "PublicUser";
        id: string;
        totalAllocated?: any | null | undefined;
        profitableSyndicationsCount?: number | null | undefined;
        unprofitableSyndicationsCount?: number | null | undefined;
        totalSyndicationsTrades?: number | null | undefined;
        syndicationsCount?:
          | { __typename?: "ResultSize"; count?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSyndicationSubscriptionsQueryVariables = Exact<{
  syndicationId: Scalars["ID"];
  query: PageQuery;
  sort?: InputMaybe<ExecutionSort>;
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetSyndicationSubscriptionsQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        subscriptions?:
          | Array<
              | {
                  __typename?: "SharedExecution";
                  id?: string | null | undefined;
                  shareToken: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSyndicationSubscriptionsCountQueryVariables = Exact<{
  syndicationId: Scalars["ID"];
  filters?: InputMaybe<
    Array<InputMaybe<ExecutionFilter>> | InputMaybe<ExecutionFilter>
  >;
}>;

export type GetSyndicationSubscriptionsCountQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        subscriptionsCount?:
          | { __typename?: "ResultSize"; count?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSubscriptionQueryVariables = Exact<{
  executionId: Scalars["ID"];
  syndicationId: Scalars["ID"];
}>;

export type GetSubscriptionQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        subscription?:
          | {
              __typename?: "SharedExecution";
              id?: string | null | undefined;
              ownerNickname?: string | null | undefined;
              rangeStart?: string | null | undefined;
              rangeEnd?: string | null | undefined;
              startedAt?: string | null | undefined;
              endedAt?: string | null | undefined;
              allocation: any;
              type: ExecutionType;
              subscriptionStatus?:
                | SyndicationSubscriptionStatus
                | null
                | undefined;
              shareToken: string;
              name?: string | null | undefined;
              syndicationName?: string | null | undefined;
              exchange: Exchange;
              currencyPair: CurrencyPair;
              multiCoinCurrency?: Currency | null | undefined;
              candleSize: string;
              currencyPairDetails?:
                | {
                    __typename?: "CurrencyPairDetails";
                    id: string;
                    exchange: Exchange;
                    pair: CurrencyPair;
                    base: Currency;
                    quote: Currency;
                    settleCurrency: Currency;
                    positionCurrency: Currency;
                  }
                | null
                | undefined;
              measurements?:
                | {
                    __typename?: "Measurements";
                    absoluteProfit?: any | null | undefined;
                    avgBarsInTrade?: number | null | undefined;
                    numberOfTrades?: number | null | undefined;
                    maxDrawdown?: any | null | undefined;
                    percentProfitableTrades?: any | null | undefined;
                    profitability?: any | null | undefined;
                    profitFactor?: any | null | undefined;
                    buyHoldRatio?: any | null | undefined;
                    buyHoldReturn?: any | null | undefined;
                    avgTradePrice?: any | null | undefined;
                    avgPositionPrice?: any | null | undefined;
                    lastTick?: string | null | undefined;
                    positionProfitLoss?: any | null | undefined;
                    avgMonthlyProfit?: any | null | undefined;
                    avgWinMonth?: any | null | undefined;
                    avgLoseMonth?: any | null | undefined;
                    percProfitableMonths?: any | null | undefined;
                    positionAbsoluteProfit?: any | null | undefined;
                    positionAmount?: any | null | undefined;
                    balance?: any | null | undefined;
                    riskScore?: any | null | undefined;
                    sharpeRatio?: any | null | undefined;
                    sortinoRatio?: any | null | undefined;
                    totalRealizedGain?: any | null | undefined;
                    totalRealizedLoss?: any | null | undefined;
                    consistencyScore?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BacktestProgressSubscriptionVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BacktestProgressSubscription = {
  __typename?: "Subscription";
  backtestProgress?: number | null | undefined;
};

export type PortfolioDailyLogQueryVariables = Exact<{
  range?: InputMaybe<Scalars["Int"]>;
}>;

export type PortfolioDailyLogQuery = {
  __typename?: "RootQuery";
  portfolioDailyLog?:
    | Array<
        | {
            __typename?: "PortfolioDailyLog";
            id: string;
            logDate?: string | null | undefined;
            usdValue?: any | null | undefined;
            btcValue?: any | null | undefined;
            updatedAt?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetColumnsPresetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetColumnsPresetsQuery = {
  __typename?: "RootQuery";
  columnsPresets?:
    | Array<
        | {
            __typename?: "ColumnsPreset";
            id: string;
            name: string;
            columns: string;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type InvoiceSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvoiceSubscriptionSubscription = {
  __typename?: "Subscription";
  invoiceEvents?:
    | { __typename?: "InvoiceEvent"; invoiceId: string; status: InvoiceStatus }
    | null
    | undefined;
};

export type ProductSubscriptionEventSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ProductSubscriptionEventSubscription = {
  __typename?: "Subscription";
  productSubscriptionEvents?:
    | {
        __typename?: "ProductSubscriptionEvent";
        subscriptionId: string;
        status: SubscriptionStatus;
      }
    | null
    | undefined;
};

export type AnnouncementSubscriptionVariables = Exact<{ [key: string]: never }>;

export type AnnouncementSubscription = {
  __typename?: "Subscription";
  announcement?:
    | {
        __typename?: "Announcement";
        id: string;
        message: string;
        type: AnnouncementType;
        createdAt: string;
        endTime?: string | null | undefined;
        isActive: boolean;
      }
    | null
    | undefined;
};

export type GetAnnouncementQueryVariables = Exact<{ [key: string]: never }>;

export type GetAnnouncementQuery = {
  __typename?: "RootQuery";
  announcement?:
    | {
        __typename?: "Announcement";
        id: string;
        message: string;
        type: AnnouncementType;
        createdAt: string;
        endTime?: string | null | undefined;
        isActive: boolean;
      }
    | null
    | undefined;
};

export type GetExecutionNotesQueryVariables = Exact<{
  executionId: Scalars["ID"];
}>;

export type GetExecutionNotesQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        notes?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetExecutionOrdersQueryVariables = Exact<{
  executionId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type GetExecutionOrdersQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        exchange: Exchange;
        currencyPair: CurrencyPair;
        syndicationId?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        measurements?:
          | {
              __typename?: "Measurements";
              numberOfTrades?: number | null | undefined;
              positionProfitLoss?: any | null | undefined;
              positionAbsoluteProfit?: any | null | undefined;
            }
          | null
          | undefined;
        orders?:
          | {
              __typename?: "OrderConnection";
              edges?:
                | Array<
                    | {
                        __typename?: "OrderConnectionEdge";
                        cursor?: string | null | undefined;
                        node?:
                          | {
                              __typename?: "Order";
                              externalId?: string | null | undefined;
                              source: OrderSource;
                              status: OrderStatus;
                              side: OrderSide;
                              placedTime: string;
                              placedAmount: any;
                              filledTime?: string | null | undefined;
                              commissionPaid: any;
                              limitPrice?: any | null | undefined;
                              filledAmount: any;
                              filledPrice: any;
                              filledValue: any;
                              effectiveAmount: any;
                              effectivePrice: any;
                              effectiveValue: any;
                              currencyPairDetails?:
                                | {
                                    __typename?: "CurrencyPairDetails";
                                    id: string;
                                    exchange: Exchange;
                                    pair: CurrencyPair;
                                    base: Currency;
                                    quote: Currency;
                                    settleCurrency: Currency;
                                    positionCurrency: Currency;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pageInfo?:
                | { __typename?: "PageInfo"; hasNextPage: boolean }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MostPopularSyndicationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  rangeDays?: InputMaybe<Scalars["Int"]>;
}>;

export type MostPopularSyndicationsQuery = {
  __typename?: "RootQuery";
  mostPopularSyndications?:
    | {
        __typename?: "PublicSyndicationConnection";
        edges?:
          | Array<
              | {
                  __typename?: "PublicSyndicationConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "PublicSyndication";
                        performance?: any | null | undefined;
                        id: string;
                        name?: string | null | undefined;
                        exchange: Exchange;
                        currencyPair?: CurrencyPair | null | undefined;
                        multiCoinCurrency?: Currency | null | undefined;
                        startedAt?: string | null | undefined;
                        analysisWithRange?:
                          | {
                              __typename?: "Analysis";
                              bestMonth?: any | null | undefined;
                              worstMonth?: any | null | undefined;
                              avgEquityPeakDays?: any | null | undefined;
                              realizedProfitPerc?: any | null | undefined;
                              dailyProfits?:
                                | {
                                    __typename?: "DailyChart";
                                    firstDay?: string | null | undefined;
                                    values?:
                                      | Array<any | null | undefined>
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              monthlyProfits?:
                                | {
                                    __typename?: "MonthlyChart";
                                    firstMonth?: string | null | undefined;
                                    values?:
                                      | Array<any | null | undefined>
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        owner: {
                          __typename?: "PublicUser";
                          firstName: string;
                          lastName: string;
                          companyName?: string | null | undefined;
                          nickname?: string | null | undefined;
                        };
                        currencyPairDetails?:
                          | {
                              __typename?: "CurrencyPairDetails";
                              id: string;
                              exchange: Exchange;
                              pair: CurrencyPair;
                              base: Currency;
                              quote: Currency;
                              settleCurrency: Currency;
                              positionCurrency: Currency;
                            }
                          | null
                          | undefined;
                        multiCoinPackExecutions?:
                          | Array<
                              | {
                                  __typename?: "MultiCoinPackCurrencyPercentage";
                                  currency: Currency;
                                  percentage: any;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                        syndicationSettings?:
                          | {
                              __typename?: "SyndicationSettings";
                              id: string;
                              maxAllocation: any;
                              minAllocation: any;
                              description?: string | null | undefined;
                              monthlyFeeUsd?: any | null | undefined;
                              allocationFeePerc?: any | null | undefined;
                              successFeePerc?: any | null | undefined;
                              accessControl?:
                                | SyndicationAccessControl
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        measurements?:
                          | {
                              __typename?: "SyndicationMeasurements";
                              maxDrawdown?: any | null | undefined;
                              avgMonthlyProfit?: any | null | undefined;
                              percProfitableMonths?: any | null | undefined;
                              riskScore?: any | null | undefined;
                              subscriptionCount?: number | null | undefined;
                              subscriptionAllocation?: any | null | undefined;
                              profitability?: any | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MostPopularCreatorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
}>;

export type MostPopularCreatorsQuery = {
  __typename?: "RootQuery";
  mostPopularCreators?:
    | {
        __typename?: "PublicUserConnection";
        edges?:
          | Array<
              | {
                  __typename?: "PublicUserConnectionEdge";
                  cursor?: string | null | undefined;
                  node?:
                    | {
                        __typename?: "PublicUser";
                        id: string;
                        nickname?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PortfolioRealizedProfitsLogQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PortfolioRealizedProfitsLogQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    portfolioRealizedProfitsLog?:
      | Array<
          | {
              __typename?: "RealizedProfitsLog";
              logDate?: string | null | undefined;
              assetsUnderManagement?: any | null | undefined;
              totalAllocationInDollars?: any | null | undefined;
              totalAllocation?: any | null | undefined;
              totalRealizedProfitability?: any | null | undefined;
              totalRealizedProfits?: any | null | undefined;
              totalRealizedProfitsCarryForward?: any | null | undefined;
              totalAllocationCarryForward?: any | null | undefined;
              totalRealizedProfitabilityCarryForward?: any | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type ExecutionRealizedProfitsLogQueryVariables = Exact<{
  id: Scalars["ID"];
  rangeDays?: InputMaybe<Scalars["Int"]>;
}>;

export type ExecutionRealizedProfitsLogQuery = {
  __typename?: "RootQuery";
  execution?:
    | {
        __typename?: "Execution";
        id: string;
        name?: string | null | undefined;
        type: ExecutionType;
        status: ExecutionStatus;
        runNumber?: string | null | undefined;
        scriptDetails?:
          | {
              __typename?: "ScriptDetails";
              id: string;
              scriptId: string;
              revisionId: string;
              name: string;
              version?: number | null | undefined;
              isLatest?: boolean | null | undefined;
              createdAt?: string | null | undefined;
              parameters: Array<
                | {
                    __typename?: "ScriptParameterDetails";
                    fieldName: string;
                    readableName?: string | null | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
        syndication?:
          | {
              __typename?: "PublicSyndication";
              name?: string | null | undefined;
            }
          | null
          | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        realizedProfitsLog?:
          | Array<
              | {
                  __typename?: "RealizedProfitsLog";
                  logDate?: string | null | undefined;
                  assetsUnderManagement?: any | null | undefined;
                  totalAllocationInDollars?: any | null | undefined;
                  totalAllocation?: any | null | undefined;
                  totalRealizedProfitability?: any | null | undefined;
                  totalRealizedProfits?: any | null | undefined;
                  totalRealizedProfitsCarryForward?: any | null | undefined;
                  totalAllocationCarryForward?: any | null | undefined;
                  totalRealizedProfitabilityCarryForward?:
                    | any
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        analysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotRealizedProfitsLogQueryVariables = Exact<{
  id: Scalars["ID"];
  rangeDays?: InputMaybe<Scalars["Int"]>;
}>;

export type BotRealizedProfitsLogQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        analysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BotBacktestRealizedProfitsLogQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BotBacktestRealizedProfitsLogQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        name?: string | null | undefined;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        backtestAnalysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SharedExecutionRealizedProfitsLogQueryVariables = Exact<{
  shareToken: Scalars["ID"];
  rangeDays?: InputMaybe<Scalars["Int"]>;
}>;

export type SharedExecutionRealizedProfitsLogQuery = {
  __typename?: "RootQuery";
  sharedExecution?:
    | {
        __typename?: "SharedExecution";
        shareToken: string;
        name?: string | null | undefined;
        type: ExecutionType;
        status: ExecutionStatus;
        scriptName?: string | null | undefined;
        realizedProfitsLog?:
          | Array<
              | {
                  __typename?: "RealizedProfitsLog";
                  logDate?: string | null | undefined;
                  assetsUnderManagement?: any | null | undefined;
                  totalAllocationInDollars?: any | null | undefined;
                  totalAllocation?: any | null | undefined;
                  totalRealizedProfitability?: any | null | undefined;
                  totalRealizedProfits?: any | null | undefined;
                  totalRealizedProfitsCarryForward?: any | null | undefined;
                  totalAllocationCarryForward?: any | null | undefined;
                  totalRealizedProfitabilityCarryForward?:
                    | any
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        analysis?:
          | {
              __typename?: "Analysis";
              bestMonth?: any | null | undefined;
              worstMonth?: any | null | undefined;
              avgEquityPeakDays?: any | null | undefined;
              realizedProfitPerc?: any | null | undefined;
              dailyProfits?:
                | {
                    __typename?: "DailyChart";
                    firstDay?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
              monthlyProfits?:
                | {
                    __typename?: "MonthlyChart";
                    firstMonth?: string | null | undefined;
                    values?: Array<any | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SubscriberRealizedProfitsLogQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SubscriberRealizedProfitsLogQuery = {
  __typename?: "RootQuery";
  publicSyndication?:
    | {
        __typename?: "PublicSyndication";
        id: string;
        currencyPairDetails?:
          | {
              __typename?: "CurrencyPairDetails";
              id: string;
              exchange: Exchange;
              pair: CurrencyPair;
              base: Currency;
              quote: Currency;
              settleCurrency: Currency;
              positionCurrency: Currency;
            }
          | null
          | undefined;
        subscriberRealizedProfitsLog?:
          | Array<
              | {
                  __typename?: "RealizedProfitsLog";
                  logDate?: string | null | undefined;
                  assetsUnderManagement?: any | null | undefined;
                  totalAllocationInDollars?: any | null | undefined;
                  totalAllocation?: any | null | undefined;
                  totalRealizedProfitability?: any | null | undefined;
                  totalRealizedProfits?: any | null | undefined;
                  totalRealizedProfitsCarryForward?: any | null | undefined;
                  totalAllocationCarryForward?: any | null | undefined;
                  totalRealizedProfitabilityCarryForward?:
                    | any
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMyPromoterDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyPromoterDetailsQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    id: string;
    promoterDetails?:
      | {
          __typename?: "Promoter";
          id?: number | null | undefined;
          custId?: string | null | undefined;
          email?: string | null | undefined;
          defaultRefId?: string | null | undefined;
          promotions?:
            | Array<{
                __typename?: "Promotion";
                id?: number | null | undefined;
                status?: string | null | undefined;
                refId?: string | null | undefined;
                promoCode?: string | null | undefined;
                promoterId?: number | null | undefined;
                campaignId?: number | null | undefined;
                referralLink?: string | null | undefined;
              }>
            | null
            | undefined;
          leads?:
            | Array<{
                __typename?: "ReferralLead";
                id?: number | null | undefined;
                state?: string | null | undefined;
                email?: string | null | undefined;
                customerSince?: string | null | undefined;
                planName?: string | null | undefined;
                suspicion?: string | null | undefined;
                registrationDate?: string | null | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type VerifyRecaptchaQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type VerifyRecaptchaQuery = {
  __typename?: "RootQuery";
  verifyRecaptcha?:
    | {
        __typename?: "RecaptchaInfo";
        success?: boolean | null | undefined;
        challengeTimestamp?: string | null | undefined;
        hostname?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PaymentSourceQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentSourceQuery = {
  __typename?: "RootQuery";
  paymentSource?:
    | {
        __typename?: "PaymentSource";
        id: string;
        type: PaymentSourceType;
        status: PaymentSourceStatus;
        name: string;
        meta?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvoicesQuery = {
  __typename?: "RootQuery";
  invoices?:
    | Array<{
        __typename?: "Invoice";
        id: string;
        createdAt: string;
        total: any;
        totalPaid: any;
        status: InvoiceStatus;
        currency: string;
        pending: any;
        balance: any;
        periodStart?: string | null | undefined;
        periodEnd?: string | null | undefined;
        mayCreatePayment: boolean;
        payments: Array<
          | {
              __typename?: "InvoicePayment";
              id: string;
              paymentProvider: string;
              status: string;
              statusReason?: string | null | undefined;
              amount: any;
              externalId?: string | null | undefined;
              metadata?:
                | {
                    __typename?: "CheckoutPaymentMetadata";
                    redirectLink3DS2: string;
                  }
                | { __typename?: "NashLinkPaymentMetadata"; paymentUrl: string }
                | null
                | undefined;
              refunds: Array<{
                __typename?: "InvoicePaymentRefund";
                id: string;
                amount: any;
                reason: string;
              }>;
            }
          | null
          | undefined
        >;
        items: Array<
          | {
              __typename?: "InvoiceItem";
              id: string;
              itemType?: string | null | undefined;
              amount?: any | null | undefined;
              description?: string | null | undefined;
              associations?:
                | Array<
                    | {
                        __typename?: "BillingSubscription";
                        id: string;
                        startTime?: string | null | undefined;
                        endTime?: string | null | undefined;
                      }
                    | {
                        __typename?: "Product";
                        id: string;
                        name: string;
                        price: any;
                        currency: string;
                        entitlements?:
                          | Array<{
                              __typename?: "SyndicationEntitlement";
                              type: string;
                              referenceId: string;
                              syndication: {
                                __typename?: "PublicSyndication";
                                id: string;
                                name?: string | null | undefined;
                                currencyPair?: CurrencyPair | null | undefined;
                                startedAt?: string | null | undefined;
                                owner: {
                                  __typename?: "PublicUser";
                                  firstName: string;
                                  lastName: string;
                                  companyName?: string | null | undefined;
                                  nickname?: string | null | undefined;
                                };
                                currencyPairDetails?:
                                  | {
                                      __typename?: "CurrencyPairDetails";
                                      id: string;
                                      exchange: Exchange;
                                      pair: CurrencyPair;
                                      base: Currency;
                                      quote: Currency;
                                      settleCurrency: Currency;
                                      positionCurrency: Currency;
                                    }
                                  | null
                                  | undefined;
                                syndicationSettings?:
                                  | {
                                      __typename?: "SyndicationSettings";
                                      id: string;
                                      monthlyFeeUsd?: any | null | undefined;
                                      allocationFeePerc?:
                                        | any
                                        | null
                                        | undefined;
                                      successFeePerc?: any | null | undefined;
                                      accessControl?:
                                        | SyndicationAccessControl
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined;
                              };
                            }>
                          | null
                          | undefined;
                      }
                  >
                | null
                | undefined;
              successFeeLineItemMetadata?:
                | {
                    __typename?: "SuccessFeeLineItemMetadata";
                    previousTotalValuePeak?: any | null | undefined;
                    newTotalValuePeak?: any | null | undefined;
                    totalValueDeltaUsd?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }>
    | null
    | undefined;
};

export type UserSubscriptionSubscriptionVariables = Exact<{
  subscriptionId: Scalars["ID"];
}>;

export type UserSubscriptionSubscription = {
  __typename?: "Subscription";
  userSubscription?:
    | {
        __typename?: "UserSubscriptionStatusUpdate";
        subscriptionId: string;
        status: string;
      }
    | null
    | undefined;
};

export type GetMyInvoicesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<InvoiceFilter>;
}>;

export type GetMyInvoicesQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    id: string;
    invoices?:
      | {
          __typename?: "InvoiceConnection";
          edges?:
            | Array<
                | {
                    __typename?: "InvoiceConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "Invoice";
                          id: string;
                          createdAt: string;
                          total: any;
                          totalPaid: any;
                          status: InvoiceStatus;
                          currency: string;
                          pending: any;
                          balance: any;
                          periodStart?: string | null | undefined;
                          periodEnd?: string | null | undefined;
                          mayCreatePayment: boolean;
                          payments: Array<
                            | {
                                __typename?: "InvoicePayment";
                                id: string;
                                paymentProvider: string;
                                status: string;
                                statusReason?: string | null | undefined;
                                amount: any;
                                externalId?: string | null | undefined;
                                metadata?:
                                  | {
                                      __typename?: "CheckoutPaymentMetadata";
                                      redirectLink3DS2: string;
                                    }
                                  | {
                                      __typename?: "NashLinkPaymentMetadata";
                                      paymentUrl: string;
                                    }
                                  | null
                                  | undefined;
                                refunds: Array<{
                                  __typename?: "InvoicePaymentRefund";
                                  id: string;
                                  amount: any;
                                  reason: string;
                                }>;
                              }
                            | null
                            | undefined
                          >;
                          items: Array<
                            | {
                                __typename?: "InvoiceItem";
                                id: string;
                                itemType?: string | null | undefined;
                                amount?: any | null | undefined;
                                description?: string | null | undefined;
                                associations?:
                                  | Array<
                                      | {
                                          __typename?: "BillingSubscription";
                                          id: string;
                                          startTime?: string | null | undefined;
                                          endTime?: string | null | undefined;
                                        }
                                      | {
                                          __typename?: "Product";
                                          id: string;
                                          name: string;
                                          price: any;
                                          currency: string;
                                          entitlements?:
                                            | Array<{
                                                __typename?: "SyndicationEntitlement";
                                                type: string;
                                                referenceId: string;
                                                syndication: {
                                                  __typename?: "PublicSyndication";
                                                  id: string;
                                                  name?:
                                                    | string
                                                    | null
                                                    | undefined;
                                                  currencyPair?:
                                                    | CurrencyPair
                                                    | null
                                                    | undefined;
                                                  startedAt?:
                                                    | string
                                                    | null
                                                    | undefined;
                                                  owner: {
                                                    __typename?: "PublicUser";
                                                    firstName: string;
                                                    lastName: string;
                                                    companyName?:
                                                      | string
                                                      | null
                                                      | undefined;
                                                    nickname?:
                                                      | string
                                                      | null
                                                      | undefined;
                                                  };
                                                  currencyPairDetails?:
                                                    | {
                                                        __typename?: "CurrencyPairDetails";
                                                        id: string;
                                                        exchange: Exchange;
                                                        pair: CurrencyPair;
                                                        base: Currency;
                                                        quote: Currency;
                                                        settleCurrency: Currency;
                                                        positionCurrency: Currency;
                                                      }
                                                    | null
                                                    | undefined;
                                                  syndicationSettings?:
                                                    | {
                                                        __typename?: "SyndicationSettings";
                                                        id: string;
                                                        monthlyFeeUsd?:
                                                          | any
                                                          | null
                                                          | undefined;
                                                        allocationFeePerc?:
                                                          | any
                                                          | null
                                                          | undefined;
                                                        successFeePerc?:
                                                          | any
                                                          | null
                                                          | undefined;
                                                        accessControl?:
                                                          | SyndicationAccessControl
                                                          | null
                                                          | undefined;
                                                      }
                                                    | null
                                                    | undefined;
                                                };
                                              }>
                                            | null
                                            | undefined;
                                        }
                                    >
                                  | null
                                  | undefined;
                                successFeeLineItemMetadata?:
                                  | {
                                      __typename?: "SuccessFeeLineItemMetadata";
                                      previousTotalValuePeak?:
                                        | any
                                        | null
                                        | undefined;
                                      newTotalValuePeak?:
                                        | any
                                        | null
                                        | undefined;
                                      totalValueDeltaUsd?:
                                        | any
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type GetMySubscriptionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<BillingSubscriptionFilter>;
}>;

export type GetMySubscriptionsQuery = {
  __typename?: "RootQuery";
  me: {
    __typename?: "User";
    id: string;
    subscriptions?:
      | {
          __typename?: "BillingSubscriptionConnection";
          edges?:
            | Array<
                | {
                    __typename?: "BillingSubscriptionConnectionEdge";
                    cursor?: string | null | undefined;
                    node?:
                      | {
                          __typename?: "BillingSubscription";
                          id: string;
                          nextBillingTime?: string | null | undefined;
                          statusDetails?:
                            | {
                                __typename?: "BillingSubscriptionStatusDetails";
                                mayUnsubscribe: boolean;
                                mayRevokeUnsubscription: boolean;
                                mayUseEntitlements: boolean;
                                hasPaymentPending: boolean;
                                hasCreationFailed: boolean;
                                hasLatestRenewalFailed: boolean;
                                willBeCancelledAtEndOfCycle: boolean;
                                userHasUnsubscribed: boolean;
                                creationFailure?:
                                  | {
                                      __typename?: "SubscriptionCreationFailure";
                                      reason: SubscriptionCreationFailureReason;
                                    }
                                  | null
                                  | undefined;
                                latestRenewalFailure?:
                                  | {
                                      __typename?: "SubscriptionRenewalFailure";
                                      reason: SubscriptionRenewalFailureReason;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          product: {
                            __typename?: "Product";
                            id: string;
                            name: string;
                            price: any;
                            currency: string;
                            entitlements?:
                              | Array<{
                                  __typename?: "SyndicationEntitlement";
                                  type: string;
                                  referenceId: string;
                                  syndication: {
                                    __typename?: "PublicSyndication";
                                    id: string;
                                    name?: string | null | undefined;
                                    currencyPair?:
                                      | CurrencyPair
                                      | null
                                      | undefined;
                                    startedAt?: string | null | undefined;
                                    owner: {
                                      __typename?: "PublicUser";
                                      firstName: string;
                                      lastName: string;
                                      companyName?: string | null | undefined;
                                      nickname?: string | null | undefined;
                                    };
                                    currencyPairDetails?:
                                      | {
                                          __typename?: "CurrencyPairDetails";
                                          id: string;
                                          exchange: Exchange;
                                          pair: CurrencyPair;
                                          base: Currency;
                                          quote: Currency;
                                          settleCurrency: Currency;
                                          positionCurrency: Currency;
                                        }
                                      | null
                                      | undefined;
                                    syndicationSettings?:
                                      | {
                                          __typename?: "SyndicationSettings";
                                          id: string;
                                          monthlyFeeUsd?:
                                            | any
                                            | null
                                            | undefined;
                                          allocationFeePerc?:
                                            | any
                                            | null
                                            | undefined;
                                          successFeePerc?:
                                            | any
                                            | null
                                            | undefined;
                                          accessControl?:
                                            | SyndicationAccessControl
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  };
                                }>
                              | null
                              | undefined;
                          };
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          pageInfo?:
            | { __typename?: "PageInfo"; hasNextPage: boolean }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type MainPanelNavigationQueryVariables = Exact<{ [key: string]: never }>;

export type MainPanelNavigationQuery = {
  __typename?: "RootQuery";
  syndicationsCount?:
    | { __typename?: "ResultSize"; count?: number | null | undefined }
    | null
    | undefined;
};
