import React from "react";
import { Exchange } from "__generated__/graphql";
import ExchangeInfoLinks, {
  ExchangeLinks,
} from "core/exchange/ExchangeInfoLinks";

export function getExchangeLinks(exchange: Exchange): ExchangeLinks {
  switch (exchange) {
    case Exchange.Bybit:
    case Exchange.BybitLinear:
      return {
        video: "https://www.youtube.com/embed/RMGEBID9gKA",
        guide: "https://docs.tuned.com/connect-to-bybit",
      };
    case Exchange.Binance:
      return {
        video: "https://www.youtube.com/embed/v-ZQMPgHJAk",
        guide: "https://docs.tuned.com/connect-to-binance-spot",
      };
    case Exchange.BinanceFutures:
      return {
        video: "https://www.youtube.com/embed/Mkcf1sU9B8E",
        guide: "https://docs.tuned.com/connect-to-binance-usd-m-futures",
      };
    case Exchange.BinanceCoinFutures:
      return {
        video: "https://www.youtube.com/embed/IKXt0AIC1d8",
        guide: "https://docs.tuned.com/connect-to-binance-coin-m-futures",
      };
    case Exchange.Coinbase:
      return {
        video: "https://www.youtube.com/embed/U9zxgqeQY74",
        guide: "https://docs.tuned.com/connect-to-coinbase-pro",
      };
    case Exchange.Kraken:
      return {
        video: "https://www.youtube.com/embed/-Arv0I_4Bbc",
        guide: "https://docs.tuned.com/connect-to-kraken",
      };
    case Exchange.Bitmex:
      return {
        guide: "https://docs.tuned.com/connect-to-bitmex",
      };
    case Exchange.HuobiCoinSwaps:
      return {
        guide: "https://docs.tuned.com/connect-to-huobi-coin-m-swaps",
      };
    case Exchange.CryptoDotCom:
      return {
        guide: "https://docs.tuned.com/",
      };
    case Exchange.Forex:
      return {
        guide: "https://docs.tuned.com/",
      };
    case Exchange.Nash:
      throw new Error("Nash is no longer used");
  }
}

type Props = Readonly<{
  exchange: Exchange;
}>;

function ExchangeGuideLinks({ exchange }: Props) {
  return <ExchangeInfoLinks links={getExchangeLinks(exchange)} />;
}

export default ExchangeGuideLinks;
