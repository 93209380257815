import React, { useState, useEffect, Suspense, lazy } from "react";
import { ModalProvider } from "react-modal-hook/dist";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { Amplify } from "aws-amplify";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { BrowserOptions } from "@sentry/browser";

import { UserContextProvider, useUserContext } from "./contexts/UserContext";
import TunedApolloProvider from "./contexts/TunedApolloProvider";
import { NavigationContextProvider } from "./contexts/NavigationContext";
import { ExchangeContextProvider } from "./contexts/ExchangeContext";
import { AccountSettingsContextProvider } from "./contexts/AccountSettingsContext";
import { AlertContextProvider } from "./contexts/AlertContext";
import { AnnouncementContextProvider } from "./contexts/AnnouncementContext";
import { CredentialContextProvider } from "./contexts/CredentialContext";
import { AdvancedSearchContextProvider } from "./contexts/AdvancedSearchContext";
import { TourContextProvider } from "contexts/TourContext";
import { ReferralContextProvider } from "contexts/ReferralContext";
import { ShareLinkModalProvider } from "contexts/ShareLinkContext";
import { ConnectCredentialModalProvider } from "contexts/ConnectCredentialContext";
import { PageNavigatorContextProvider } from "contexts/PageNavigatorContext";
import { InformationModalProvider } from "contexts/InformationModalContext";
import { GettingStartedContextProvider } from "contexts/GettingStartedContext";
import SentryRoute from "./components/router/SentryRoute";
import SideNavigationPanel from "components/pagenavigator/SideNavigationPanel";
import Theme from "themes/Theme";
import AnnouncementBanner from "core/userstatusbanner/AnnouncementBanner";
import UserStatusBanner from "core/userstatusbanner/UserStatusBanner";
import { isDevelopment } from "./helpers/environment";
import {
  BATCHTEST_SUBTAB,
  ACCOUNT_TAB,
  BOTS_TAB,
  CANDIDATES_SUBTAB,
  EXPERIMENTS_SUBTAB,
  LIVE_TAB,
  MY_BOTS_SUBTAB,
  RELEASE_CANDIDATES_SUBTAB,
  EDITOR_TAB,
  SCRIPTS_TAB,
  TRADERS_TAB,
  INVEST_TAB,
  TRADER_TAB,
  INVITE_TAB,
  REGISTER_TAB,
  LOGIN_TAB,
  MOBILE_BLOCKER_TAB,
  GUEST_TAB,
  SUBSCRIBE_TAB,
  NOT_FOUND_TAB,
  LIVE_BOTS_SUBTAB,
  SETUPS_TAB,
  // PORTFOLIO_PAGE,
  TESTS_TAB,
  PAPER_TRADES,
} from "helpers/navigation";
import { useInitAnonId } from "helpers/analytics";

// Cannot be imported with React.lazy since it is used as a Suspense fallback
import SplashPage from "pages/splashpage/SplashPage";

import "./styles/base.scss";
import "./core/forms/form.scss";
import "./core/modal/modal.scss";

const HomePageLazy = lazy(() => import("pages/homepage/HomePage"));
const ErrorPageLazy = lazy(() => import("pages/error-page/ErrorPage"));
const LoginPageLazy = lazy(() => import("pages/loginpage/LoginPage"));
const MobileBlockerPageLazy = lazy(
  () => import("pages/mobileonboardingpage/MobileBlockerPage"),
);
const CognitoUserManagerLazy = lazy(
  () => import("pages/cognitoUserManagement/CognitoUserManager"),
);
const UsersPageLazy = lazy(() => import("pages/userspage/UsersPage"));
const InvitePageLazy = lazy(() => import("pages/invitepage/InvitePage"));
const PendingPageLazy = lazy(() => import("pages/pendingpage/PendingPage"));
const AccountSettingsPageLazy = lazy(
  () => import("pages/accountsettings/AccountSettingsPage"),
);
// const PortfolioPageLazy = lazy(
//   () => import("pages/portfoliopage/PortfolioPage"),
// );
const LoadingPageLazy = lazy(() => import("pages/splashpage/LoadingPage"));

if (process.env.REACT_APP_USE_COGNITO?.toLowerCase() === "true") {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

      cookieStorage: {
        domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
        path: process.env.REACT_APP_COOKIE_STORAGE_PATH,
        secure:
          process.env.REACT_APP_COOKIE_STORAGE_SECURE?.toLowerCase() === "true",
      },
    },
  });
}

/**
 * sampleRate: error events
 * tracesSampleRate: performance events
 */
const SAMPLING_CONFIG: {
  [env: string]: Pick<BrowserOptions, "sampleRate" | "tracesSampleRate">;
} = {
  prod: {
    sampleRate: 1.0,
    tracesSampleRate: 0.25,
  },
  // d1
  nonprod: {
    sampleRate: 1.0,
    tracesSampleRate: 0.5,
  },
  staging: {
    sampleRate: 1.0,
    tracesSampleRate: 0.5,
  },
  // WARNING: Do not commit changes to local environment,
  // modify only for testing purposes
  local: {
    sampleRate: 0.0,
    tracesSampleRate: 0.0,
  },
};

const history = createBrowserHistory();

// REACT_APP_TUNED_ENV_TYPE is set by the release pipeline in buildspec.yml
const environment =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_TUNED_ENV_TYPE
    : "local";

Sentry.init({
  dsn: "https://a0676638215f4cc6a60f428aafd0e1a1@o936490.ingest.sentry.io/5981438",
  environment,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  debug: process.env.NODE_ENV === "development",
  release: process.env.RELEASE_NAME,
  ...(environment
    ? SAMPLING_CONFIG[environment]
    : { sampleRate: 1.0, tracesSampleRate: 0.0 }),
});

const Preloader: React.FunctionComponent<{}> = ({ children }) => {
  const { isLoading } = useUserContext();
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(true);
  const [pineModuleLoading, setPineModuleLoading] = useState<boolean>(true);

  useEffect(
    () => {
      // Add a 10-sec timeout so we don't hang forever if it fails
      const errorTimeout = setTimeout(() => {
        if (isDevelopment) throw Error("Unable to load pine module");
      }, 10000);

      function checkPineLoaded() {
        if (
          pineModuleLoading &&
          (window as any).analyze_pine_script !== undefined
        ) {
          clearTimeout(errorTimeout);
          setPineModuleLoading(false);
        } else {
          setTimeout(checkPineLoaded, 100);
        }
      }

      checkPineLoaded();
    },
    /*eslint-disable react-hooks/exhaustive-deps*/ [],
  ); // We explicitly want this to run once

  // Ensure the Checkout.com SDK has loaded before completing the preloader
  useEffect(
    () => {
      // Add a 10-sec timeout so we don't hang forever if it fails
      const errorTimeout = setTimeout(() => {
        if (isDevelopment)
          throw Error("Unable to load third-party dependencies");
      }, 10000);

      function checkSDKsLoaded() {
        if (checkoutLoading && (window as any).Frames !== undefined) {
          clearTimeout(errorTimeout);
          setCheckoutLoading(false);
        } else {
          setTimeout(checkSDKsLoaded, 100);
        }
      }

      // TODO: Remove this condition when payments is ready for prod
      checkSDKsLoaded();
    },
    /*eslint-disable react-hooks/exhaustive-deps*/ [],
  ); // We explicitly want this to run once

  return (
    <Router history={history}>
      <Route
        path="/"
        render={() => {
          if (isLoading || pineModuleLoading || checkoutLoading) {
            return <SplashPage />;
          }

          return children;
        }}
      />
    </Router>
  );
};

const BaseProviders = ({ children }: { children: React.ReactNode }) => (
  <TunedApolloProvider>
    <AlertContextProvider>
      <ModalProvider>
        <UserContextProvider>
          <TourContextProvider>
            <NavigationContextProvider>
              <ExchangeContextProvider>
                <AccountSettingsContextProvider>
                  <CredentialContextProvider>
                    <AnnouncementContextProvider>
                      <AdvancedSearchContextProvider>
                        <ReferralContextProvider>
                          <ShareLinkModalProvider>
                            <ConnectCredentialModalProvider>
                              <PageNavigatorContextProvider>
                                <InformationModalProvider>
                                  <GettingStartedContextProvider>
                                    {children}
                                  </GettingStartedContextProvider>
                                </InformationModalProvider>
                              </PageNavigatorContextProvider>
                            </ConnectCredentialModalProvider>
                          </ShareLinkModalProvider>
                        </ReferralContextProvider>
                      </AdvancedSearchContextProvider>
                    </AnnouncementContextProvider>
                  </CredentialContextProvider>
                </AccountSettingsContextProvider>
              </ExchangeContextProvider>
            </NavigationContextProvider>
          </TourContextProvider>
        </UserContextProvider>
      </ModalProvider>
    </AlertContextProvider>
  </TunedApolloProvider>
);

/**
 *
 * Wraps main surfaces with a side panel for navigation between them
 * Terminal, Portfolio, Invest, and Profile
 *
 */
function MainRoutes() {
  return (
    <SideNavigationPanel>
      <Switch>
        <Route
          path={`/(${LIVE_TAB}|${BATCHTEST_SUBTAB}|${EXPERIMENTS_SUBTAB}|${PAPER_TRADES}|${CANDIDATES_SUBTAB}|${RELEASE_CANDIDATES_SUBTAB}|${EDITOR_TAB}|${GUEST_TAB}|${SUBSCRIBE_TAB}|${BOTS_TAB}|${SCRIPTS_TAB}|${TRADERS_TAB}|${SETUPS_TAB})`}
          component={HomePageLazy}
        />
        {/* <Route path={`/${PORTFOLIO_PAGE}`} component={PortfolioPageLazy} /> */}
        <SentryRoute
          path={`/(${TRADER_TAB}|${INVEST_TAB})/:userId?/:syndicationId([0-9]+)?`}
          component={UsersPageLazy}
        />
        <SentryRoute
          path={`/${INVITE_TAB}/:shareToken`}
          component={InvitePageLazy}
        />
      </Switch>
    </SideNavigationPanel>
  );
}

function Routes() {
  const isGoogleBot = navigator.userAgent.toLowerCase().includes("googlebot");
  return (
    <>
      <AnnouncementBanner />
      <UserStatusBanner />
      <Switch>
        <Route
          path={`/${MOBILE_BLOCKER_TAB}`}
          component={MobileBlockerPageLazy}
        />
        <Route
          path={`/${LOGIN_TAB}`}
          exact
          component={
            process.env.REACT_APP_USE_COGNITO?.toLowerCase() === "true"
              ? CognitoUserManagerLazy
              : LoginPageLazy
          }
        />
        <Route
          path={`/${REGISTER_TAB}`}
          component={
            process.env.REACT_APP_USE_COGNITO?.toLowerCase() === "true"
              ? CognitoUserManagerLazy
              : LoginPageLazy
          }
        />

        <Route
          path={`/(${LIVE_TAB}|${TESTS_TAB}|${EDITOR_TAB}|${GUEST_TAB}|${SUBSCRIBE_TAB}|${BOTS_TAB}|${SCRIPTS_TAB}|${TRADERS_TAB}|${SETUPS_TAB}|${TRADER_TAB}|${INVEST_TAB}|${INVITE_TAB}|${PAPER_TRADES})`}
          component={MainRoutes}
        />
        <Route path={`/${ACCOUNT_TAB}`} component={AccountSettingsPageLazy} />
        <Route path="/checkout/pending" component={PendingPageLazy} />
        <Route
          path={`/${NOT_FOUND_TAB}`}
          render={({ history }) => <ErrorPageLazy history={history} />}
        />
        <Route exact path="/" component={LoadingPageLazy} />

        {!isGoogleBot && (
          <>
            <Redirect from="/explore" to={`/${INVEST_TAB}`} />
            <Redirect
              from="/syndications/:botId([0-9]+)?"
              to={`/${MY_BOTS_SUBTAB}/:botId([0-9]+)?`}
            />
            <Redirect
              from="/simulations/:executionId([0-9]+)?"
              to={`/${EXPERIMENTS_SUBTAB}/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/tests/experiments/:executionId([0-9]+)?"
              to={`/${EXPERIMENTS_SUBTAB}/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/candidates/:executionId([0-9]+)?"
              to={`/${CANDIDATES_SUBTAB}/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/tests/candidates/:executionId([0-9]+)?"
              to={`/${CANDIDATES_SUBTAB}/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/prerelease/:executionId([0-9]+)?"
              to={`/${RELEASE_CANDIDATES_SUBTAB}/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/tests/releasecandidates/:executionId([0-9]+)?"
              to={`/${RELEASE_CANDIDATES_SUBTAB}/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/multivariants/:multivariantId([0-9]+)?/:executionId([0-9]+)?"
              to={`/${BATCHTEST_SUBTAB}/:multivariantId([0-9]+)?/:executionId([0-9]+)?`}
            />
            <Redirect
              from="/tests/batchtesting/:multivariantId([0-9]+)?/:executionId([0-9]+)?"
              to={`/${BATCHTEST_SUBTAB}/:multivariantId([0-9]+)?/:executionId([0-9]+)?`}
            />
            <Redirect from="/strategies" to={`/${LIVE_BOTS_SUBTAB}`} />

            <Route
              path="/subscriptions"
              // @ts-ignore
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: location.pathname.replace(
                      /subscriptions/,
                      "account/billing",
                    ),
                  }}
                />
              )}
            />
          </>
        )}
      </Switch>
    </>
  );
}

function App() {
  useInitAnonId();

  return (
    <Suspense fallback={<SplashPage />}>
      <Theme>
        <BaseProviders>
          <Preloader>
            <Routes />
          </Preloader>
        </BaseProviders>
      </Theme>
    </Suspense>
  );
}

export default App;
